import { BuildingTypes } from '@swiftctrl/api-client-react'
import { Swift, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { AddressDisplay } from '../../components'

interface Props {
  address?: BuildingTypes['addresses'][0]
  phoneNumber: string
}

export const GeneralInfos: Swift.FC<Props> = ({ address, phoneNumber }) => {
  return (
    <StyledGeneralInfos>
      <AddressDisplay address={address} />
      <Typography.Link href={`tel:${phoneNumber}`} key={phoneNumber} type="secondary">
        {phoneNumber}
      </Typography.Link>
    </StyledGeneralInfos>
  )
}

const StyledGeneralInfos = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
`
