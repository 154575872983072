import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { AxiosRequestConfig } from 'axios'
import { mapValues } from 'lodash'
import { rooms } from './rooms.mocks'
import { selfMockData } from './self.mock'

export const mockSwitches = {
  self: false,
  roomBrowse: false,
}

const globalMockSwitchStatus: boolean = false

export const globalMockSwitch = (): typeof mockSwitches =>
  globalMockSwitchStatus ? mapValues(mockSwitches, () => true) : mockSwitches
export interface MockConfig extends Pick<AxiosRequestConfig, 'url' | 'method'> {
  switch: boolean
  data: unknown
  id?: string
}

export const mockConfig: MockConfig[] = [
  {
    url: 'profiles/self',
    method: 'get',
    switch: globalMockSwitch().self,
    data: selfMockData as ProfilesTypes,
  },
  {
    url: 'rooms',
    method: 'get',
    switch: globalMockSwitch().roomBrowse,
    data: { pageParams: [], pages: [rooms] },
  },
]
