import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import { BreadcrumbItem } from '../models'
import { paths } from '../utils'
import { useTranslation } from './useTranslation'

const { Reservations, Search, Admin, RoomReservations } = paths

export const useBreadcrumbs = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const lastLocation = useLastLocation()
  // ! this consider as a hack until we revisit the breadcrumbs
  const [searchState, setSearchState] = useState('')

  useEffect(() => {
    if (lastLocation?.pathname === `/${Search}`) {
      setSearchState(lastLocation?.search)
    }
  }, [lastLocation?.pathname])

  /**
   * Function that checks the URL and returns the adequate list of breadcrumbs
   * @param {string} path
   * @returns {BreadcrumbItem} List of breadcrumbs
   */

  const generateBreadcrumbs = (path: string): BreadcrumbItem[] => {
    if (path.match(/^\/reservation\//)) {
      return [
        {
          name: t('reservations_my_reservations'),
          path: lastLocation
            ? `${lastLocation.pathname}${lastLocation.search}`
            : `/${Reservations}`,
        },
        {
          name: t('common_details'),
        },
      ]
    }
    if (path.match(/^\/spaces\//)) {
      return [
        {
          name: t('search_search'),
          path: `/${Search}${searchState}`,
        },
        {
          name: 'Swift Building',
          path: `/${paths.Building}/1`,
        },
        {
          name: t('common_details'),
        },
      ]
    }
    if (path.match(/^\/admin\//)) {
      return [
        {
          name: t('admin_room_reservations_breadcrumb_title'),
          path: lastLocation
            ? `${lastLocation.pathname}${lastLocation.search}`
            : `/${Admin}/${RoomReservations}`,
        },
        {
          name: t('common_details'),
        },
      ]
    }
    if (path.match(/^\/building\//)) {
      return [
        {
          name: t('search_search'),
          path: `/${Search}${searchState}`,
        },
        {
          name: 'Swift Building',
        },
      ]
    }
    return []
  }

  const breadcrumbs = generateBreadcrumbs(pathname)

  return { breadcrumbs }
}
