import {
  createInstance,
  OptimizelyProvider as OptimizelyReactProvider,
  ReactSDKClient,
} from '@optimizely/react-sdk'
import { minutesToMilliseconds } from 'date-fns'
import React from 'react'
import { config } from '../utils'
const {
  optimizely: { sdkKey, proxyUrl },
} = config

const optimizely: ReactSDKClient = createInstance({
  sdkKey,
  datafileOptions: {
    // Tell the SDK to keep polling for changes
    // Note: doesn't re-render by default, the `useFeature` will still need to specify autoUpdate if wanted
    autoUpdate: true,
    updateInterval: minutesToMilliseconds(1),
    urlTemplate: proxyUrl,
  },
  logLevel: 'warn',
})

export const OptimizelyProvider: React.FC = ({ children }) => (
  <OptimizelyReactProvider optimizely={optimizely} user={{ id: ' ' }}>
    {children}
  </OptimizelyReactProvider>
)
