import { CloseOutlined, Swift, Row } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { useTranslation } from '../../../hooks'

export const AvailabilitiesLegends: Swift.FC = () => {
  const { t } = useTranslation()
  return (
    <Row justify="end" align="middle">
      <StyledAvailableAvailabilitiesLegendBox />
      <StyledAvailabilitiesLegendsText>
        {t('room_details_availabilities_legend_available')}
      </StyledAvailabilitiesLegendsText>
      <StyledSelectedAvailabilitiesLegendBox />
      <StyledAvailabilitiesLegendsText>
        {t('room_details_availabilities_legend_booked')}
      </StyledAvailabilitiesLegendsText>
      <StyledUnavailableLegend>
        <CloseOutlined />
      </StyledUnavailableLegend>
      <StyledAvailabilitiesLegendsText>
        {t('room_details_availabilities_legend_closed')}
      </StyledAvailabilitiesLegendsText>
    </Row>
  )
}

const StyledAvailabilitiesLegendBox = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 2px;
  margin-left: 20px;
`

const StyledUnavailableLegend = styled(StyledAvailabilitiesLegendBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  svg {
    color: #bfbfbf;
  }
`

const StyledAvailabilitiesLegendsText = styled.div`
  margin-left: 10px;
`

const StyledSelectedAvailabilitiesLegendBox = styled(StyledAvailabilitiesLegendBox)`
  background-color: #1890ff;
`
const StyledAvailableAvailabilitiesLegendBox = styled(StyledAvailabilitiesLegendBox)`
  background-color: #bae7ff;
`
