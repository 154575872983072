import { CitiesTypes } from '@swiftctrl/api-client-react'
import { SearchOutlined, Swift } from '@swiftctrl/swift-component-library'
import { Input } from 'antd-mobile'
import { InputRef } from 'antd-mobile/es/components/input'
import { createRef, useState } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'
import styled, { CSSObject } from 'styled-components'
import { BackButton } from '../../../../components'
import { useBoolean, useStoreParams, useTranslation } from '../../../../hooks'
import { getLocationInputValue } from '../../../../utils'
import { MobileLocationDropdown } from './MobileLocationDropdown'

interface Props {
  onFocusBlur: (isFocused: boolean) => void
  searchValue: string
  onChange: (text: string) => void
}

const defaultStyle: CSSObject = {
  transition: 'opacity 0.15s cubic-bezier(0.78, 0.23, 0.85, 0.98) ,transform 0.15s ease-out',
  opacity: 0,
  display: 'none',
  zIndex: -1,
  width: '100%',
}
const translationStyles: Record<TransitionStatus, CSSObject> = {
  entering: { transform: 'translateY(-70px)', opacity: 0, display: 'block' },
  entered: { transform: 'translateY(0px)', opacity: 1, display: 'block' },
  exiting: { transform: 'translateY(-30px)', opacity: 0 },
  exited: { display: 'none', opacity: 0 },
  unmounted: {},
}

export const MobileLocationSearch: Swift.FC<Props> = ({ onFocusBlur, onChange, searchValue }) => {
  const { updateStoreParams, currentStoreParams } = useStoreParams()
  const { t } = useTranslation()
  const [cityLocation, setCityLocation] = useState<CitiesTypes>()

  const [isDropdownOpen, openDropdown, closeDropdown] = useBoolean()
  const inputDivRef = createRef<HTMLDivElement>()
  const inputRef = createRef<InputRef>()

  const handleOnFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    openDropdown()
    onFocusBlur(true)
  }

  const handleCloseDropdown = () => {
    closeDropdown()
    onFocusBlur(false)
    if (searchValue.trim() === '') {
      onChange('')
      updateStoreParams({
        ...currentStoreParams,
        city: undefined,
        state: undefined,
        longitude: undefined,
        latitude: undefined,
      })
    } else {
      onChange(
        getLocationInputValue({
          city: currentStoreParams?.city || '',
          state: currentStoreParams?.state || '',
          country: currentStoreParams?.country || '',
        }),
      )
    }
  }

  const handleOnSelect = (location: CitiesTypes) => {
    setCityLocation(location)
    updateStoreParams({
      ...currentStoreParams,
      state: location.state,
      city: location.city,
      country: location.country,
    })
    onChange(getLocationInputValue(location))
    closeDropdown()
    onFocusBlur(false)
  }

  return (
    <>
      {isDropdownOpen && <BackButton />}
      <StyledLocationInput ref={inputDivRef}>
        <SearchOutlined />
        <Input
          onClear={() => {
            onChange('')
            setCityLocation(undefined)
          }}
          clearable={true}
          onFocus={handleOnFocus}
          placeholder={t('search_bar_where_are_you_going')}
          value={searchValue}
          onChange={onChange}
          ref={inputRef}
          type="text"
        />
      </StyledLocationInput>
      <Transition in={isDropdownOpen} timeout={{ appear: 50, enter: 50, exit: 0 }}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...translationStyles[state],
            }}
          >
            <MobileLocationDropdown
              key={String(isDropdownOpen)}
              inputRef={inputDivRef}
              isOpen={isDropdownOpen}
              onClose={handleCloseDropdown}
              onSelect={handleOnSelect}
              filterValue={searchValue}
              cityLocation={cityLocation}
            />
          </div>
        )}
      </Transition>
    </>
  )
}

const StyledLocationInput = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  margin-top: ${({ theme }) => theme.spacing.large};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.smedium};
  svg {
    margin-right: ${({ theme }) => theme.spacing.xSmall};
  }
`
