import { ScheduleTypes } from '@swiftctrl/api-client-react'
import { CloseOutlined, ColumnType } from '@swiftctrl/swift-component-library'
import dayjs from 'dayjs'
import styled, { useTheme } from 'styled-components'
import { RoomAvailability } from '../../models'
import {
  convertHours24To12,
  dayIsClosed,
  getPresentDay,
  getPresentHour,
  getTranslationLabel,
} from '../../utils'
import { useTranslation } from '../useTranslation'

export const useAvailabilitiesColumns = (
  timeRange: { start: number; end: number },
  handleHourClick: (day: RoomAvailability, hour: number) => void,
  schedule: ScheduleTypes,
  startTime: number | null,
  endTime: number | null,
  bookedDay: string | null,
) => {
  const { t } = useTranslation()
  const { isMobileView } = useTheme()

  const renderCell = (day: RoomAvailability, hour: number) => {
    const hourSplit = convertHours24To12(hour.toString()).toLowerCase().split(' ')
    const hourDisplay: number = +hourSplit[0]
    // When there's a booking day selected
    if (bookedDay === day.date.split('T')[0]) {
      // If in booking time range
      if (startTime && endTime && startTime < hour && hour < endTime) {
        return <StyledSqaureBoxes $status="booked" onClick={() => handleHourClick(day, hour)} />
      }
      // if it's the start time
      if (startTime === hour) {
        return (
          <StyledSqaureBoxes
            onClick={() => handleHourClick(day, hour)}
            $status="booked"
            $start={endTime === startTime}
          >
            {t('room_details_availabilities_start')}
          </StyledSqaureBoxes>
        )
      }
      // if it's the end time
      if (endTime === hour) {
        return (
          <StyledSqaureBoxes onClick={() => handleHourClick(day, hour)} $status="booked">
            {t('room_details_availabilities_end')}
          </StyledSqaureBoxes>
        )
      }
    }
    // Display the availabilities
    if (day.availabilities.includes(hour) && !dayIsClosed(day.dayOfTheWeek, schedule)) {
      if (
        dayjs(day.date).isSame(new Date(), 'day') &&
        day.dayOfTheWeek === getPresentDay() &&
        hour <= getPresentHour()
      ) {
        return (
          <StyledUnavailableCell>
            <CloseOutlined />
          </StyledUnavailableCell>
        )
      } else {
        return (
          <StyledSqaureBoxes
            onClick={() => handleHourClick(day, hour)}
            id={`${hour}_${day.date}_${hourDisplay}`}
            $status="available"
          />
        )
      }
    } else {
      return (
        <StyledUnavailableCell>
          <CloseOutlined />
        </StyledUnavailableCell>
      )
    }
  }

  // Initialize table with date column
  const columns: ColumnType<RoomAvailability>[] = [
    {
      dataIndex: 'day',
      key: 'day',
      fixed: 'left',
      onCell: () => ({
        style: {
          backgroundColor: '#fafafa',
          minWidth: isMobileView ? 52 : 104,
          fontSize: isMobileView ? '12px' : 'default',
        },
      }),
      width: isMobileView ? 90 : 183,
      render: (_, day, i) => (
        <StyledAvailabilitiesRowDay>
          {t(
            getTranslationLabel(
              isMobileView ? 'days_of_the_week_mobile_' : 'days_of_the_week_',
              day.dayOfTheWeek,
            ),
          )}
          <br />
          <span>{day.displayDate}</span>
        </StyledAvailabilitiesRowDay>
      ),
    },
  ]

  // Fill the table with availabilities Data
  for (let hour = timeRange.start; hour <= timeRange.end; hour++) {
    const hourSplit = convertHours24To12(hour.toString()).toLowerCase().split(' ')
    const hourDisplay: number = +hourSplit[0]
    const hour_type: string = hourSplit[1]

    columns.push({
      title: () => (
        <StyledAvailabilitiesColumnTitle>
          {hourDisplay}
          <br />
          <span>{hour_type}</span>
        </StyledAvailabilitiesColumnTitle>
      ),
      align: 'center',
      key: 'action',
      width: 72,
      onCell: () => ({
        style: {
          paddingRight: 16,
          paddingLeft: 16,
        },
      }),
      render: (_, day) => renderCell(day, hour),
    })
  }

  // This column is added to cover up the shifting of cells to the right
  columns.push({
    key: 'designPurpose',
    width: 30,
  })

  return { columns }
}

const StyledSqaureBoxes = styled.div<{ $status?: 'available' | 'booked'; $start?: boolean }>`
  border-radius: 2px;
  height: 40px;
  width: 40px;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
  color: #ffffff;
  text-align: center;
  padding: 10px ${({ theme }) => theme.spacing.none};
  margin-left: 60%;
  background-color: ${({ $status }) =>
    $status === 'available' ? '#bae7ff' : $status === 'booked' ? '#1890ff' : '#f0f2f5'};
  cursor: ${({ $status }) => ($status === 'available' ? 'pointer' : 'unset')};
  outline: ${({ $start }) => ($start ? '5px solid #bae7ff' : 'none')};
`

const StyledUnavailableCell = styled(StyledSqaureBoxes)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  cursor: not-allowed;
  svg {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
    color: #bfbfbf;
  }
`

const StyledAvailabilitiesColumnTitle = styled.div`
  text-align: center;
  width: max-content;
  span {
    color: #bfbfbf;
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  }
`

const StyledAvailabilitiesRowDay = styled.div`
  span {
    color: #8c8c8c;
  }
`
