import { setCurrentUser } from './currentUserSlice'
import { setParams } from './paramsSlice'
import { setReservation } from './reservationSlice'
import { setRoute } from './routeSlice'

export const Actions = {
  setCurrentUser,
  setParams,
  setReservation,
  setRoute,
}
