export const admin_room_reservations_en = {
  admin_room_reservations_breadcrumb_title: 'Room reservations',
  admin_room_reservations_header_title: 'Room reservations',
  admin_room_reservations_upcoming_tab: 'Upcoming',
  admin_room_reservations_cancelled_tab: 'Cancelled',
  admin_room_reservations_completed_tab: 'Completed',
  admin_room_reservations_all_tab: 'All',
  admin_room_reservations_search_placeholder: 'Search',
  admin_header_switch_button_label: 'Switch to admin',
  visitor_header_switch_button_label: 'Switch to visitor',
  admin_room_reservations_mobile_message: 'Admin is available on desktop and tablet',
  admin_mobile_switch_button_label: 'Switch back to visitor',
}
