import { Divider, Modal, Swift } from '@swiftctrl/swift-component-library'
import styled, { useTheme } from 'styled-components'

import { CustomDrawer } from '../../../components'
import { useTranslation } from '../../../hooks'
import { getTranslationLabel } from '../../../utils'

interface Props {
  showAmenities: boolean
  closeAmenitiesModal: () => void
  amenitiesIncluded: string[] | undefined
  amenitiesNotIncluded: string[] | undefined
}

export const AmenitiesModal: Swift.FC<Props> = ({
  showAmenities,
  closeAmenitiesModal,
  amenitiesIncluded,
  amenitiesNotIncluded,
}) => {
  const { t } = useTranslation()
  const { isMobileView } = useTheme()
  const AmenitiesContent = (
    <>
      {amenitiesIncluded?.length !== 0 && (
        <div>
          <StyledAmenitiesIncludedTitle>
            {t('room_details_amenities_included')}
          </StyledAmenitiesIncludedTitle>
          <StyledAmenitiesList>
            {amenitiesIncluded?.map((item) => (
              <li key={item}>{t(getTranslationLabel('room_details_amenities_', item))}</li>
            ))}
          </StyledAmenitiesList>
        </div>
      )}

      {amenitiesIncluded?.length !== 0 && amenitiesNotIncluded?.length !== 0 && <Divider />}

      {amenitiesNotIncluded?.length !== 0 && (
        <div>
          <StyledAmenitiesIncludedTitle>
            {t('room_details_amenities_not_included')}
          </StyledAmenitiesIncludedTitle>
          <StyledAmenitiesNotIncludedTitle>
            {amenitiesNotIncluded?.map((item) => (
              <li key={item}>{t(getTranslationLabel('room_details_amenities_', item))}</li>
            ))}
          </StyledAmenitiesNotIncludedTitle>
        </div>
      )}
    </>
  )
  return !isMobileView ? (
    <Modal
      title={t('room_details_modal_amenities_title')}
      open={showAmenities}
      footer={null}
      onCancel={closeAmenitiesModal}
    >
      {AmenitiesContent}
    </Modal>
  ) : (
    <CustomDrawer
      destroyOnClose
      title={t('room_details_modal_amenities_title')}
      open={showAmenities}
      onClose={closeAmenitiesModal}
    >
      {AmenitiesContent}
    </CustomDrawer>
  )
}

export const StyledAmenitiesList = styled.ul`
  margin: ${({ theme }) => theme.spacing.none};
  padding: ${({ theme }) => theme.spacing.none};
  list-style: none;
  li {
    margin: ${({ theme }) => theme.spacing.none} ${({ theme }) => theme.spacing.none}
      ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.none};
  }
`

const StyledAmenitiesIncludedTitle = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
`

const StyledAmenitiesNotIncludedTitle = styled(StyledAmenitiesList)`
  text-decoration: line-through;
`
