import { isNull, isObject } from 'lodash'
import { ReservationDetailsType, StoreUpdater } from '../../models'
import { Actions } from '../../store'
import { useDispatch, useSelector } from './useStoreHook'

export const useStoreReservation = () => {
  const dispatch = useDispatch()

  const updateStoreReservation: StoreUpdater<ReservationDetailsType> = <T extends Object>(
    arg1: keyof T | T | null,
    arg2?: T[typeof arg1 & keyof T] | null,
  ) => {
    if (isNull(arg1)) {
      dispatch(Actions.setReservation(null))
      return
    }
    if (isObject(arg1)) {
      dispatch(Actions.setReservation(arg1))
    } else {
      dispatch(Actions.setReservation({ [arg1]: arg2 } as Partial<ReservationDetailsType>))
    }
  }

  const reservationDetails = useSelector((state) => state.reservation.data)

  return {
    reservationDetails,
    updateStoreReservation,
  }
}
