import { Swift } from '@swiftctrl/swift-component-library'
import { includes } from 'lodash'
import React from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { mobilePathWithOutHeader } from '../../utils'
import { MainNav } from './MainNav'

export const MobileMainNav: Swift.FC = () => {
  const { isMobileView } = useTheme()
  const location: RouteComponentProps['location'] = useLocation()
  const locationPathName = location.pathname.split('/')[1]
  return isMobileView && !includes(mobilePathWithOutHeader, locationPathName) ? <MainNav /> : null
}
