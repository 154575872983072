import { BuildingTypes, RoomsTypes } from '@swiftctrl/api-client-react'
import dayjs from 'dayjs'
import { createServer, Model, Registry } from 'miragejs'
import { ModelDefinition } from 'miragejs/-types'
import Schema from 'miragejs/orm/schema'
import { mocks, rooms, TemporaryRoomTypeExtension } from './mocks'
import { Booking } from './models'
import { config } from './utils'

type AppRegistry = Registry<
  {
    room: ModelDefinition<RoomsTypes & TemporaryRoomTypeExtension>
    building: ModelDefinition<BuildingTypes>
    booking: ModelDefinition<Booking>
  },
  {}
>
type AppSchema = Schema<AppRegistry>

const stripeEpiId = '64940065-631e-422a-a230-6678ba0b7e2d'

const {
  mixpanel: { proxyUrl },
} = config

export const initMockServer = () => {
  createServer({
    models: {
      room: Model,
      building: Model,
      booking: Model,
    },

    seeds(server) {
      mocks.rooms.forEach((room) => server.create('room', room as any))
      mocks.buildings.forEach((building) => server.create('building', building as any))
      mocks.bookingsV2.forEach((booking) => server.create('booking', booking as any))
    },

    routes() {
      const NativeXMLHttpRequest = window.XMLHttpRequest

      /**
       * Code below is a quick fix for mirage not able to let requests passthrough with an axios version later than 0.21.1
       */
      window.XMLHttpRequest = function XMLHttpRequest() {
        const request = new NativeXMLHttpRequest()
        //@ts-ignore
        delete request.onloadend
        return request
      } as any
      // ---- Fix END

      this.urlPrefix = process.env.REACT_APP_SPACE_API_URL || 'https://api.dev.swiftconnect.io'

      this.get('/profiles/self', () => mocks.selfMockData)

      this.get('/profiles/:profileId', () => mocks.selfMockData)

      this.get('/profiles/self/bookings', (schema: AppSchema, req) =>
        req.queryParams.toTime ? [] : schema.all('booking').models,
      )

      this.get('/rooms', (schema: AppSchema, req) => {
        return req.queryParams.city
          ? schema.all('room').filter((room) => room.city === req.queryParams.city).models
          : schema.all('room').models
      })

      this.get(
        '/rooms/:id',
        (schema: AppSchema, req) => schema.find('room', req.params.id)?.attrs || {},
      )

      this.get('/rooms/:id/availabilities', (_, req) => {
        return mocks.getAvailabilitiesMockData(
          req.queryParams['start-date'],
          req.queryParams['end-date'],
        )
      })

      this.get('/rooms/number-of-results', (schema: AppSchema) => ({
        numberOfResults: schema.all('room').models.length,
      }))

      this.get('/rooms/:id/price', (_, req) => {
        const startDate = dayjs(req.queryParams['start-date'])
        const endDate = dayjs(req.queryParams['end-date'])
        const numberOfHours = endDate.diff(startDate, 'hours')
        const price = Number(rooms[Number(req.params.id) - 1]?.price) * numberOfHours
        return { price: price }
      })

      this.get(
        '/buildings/:id',
        (schema: AppSchema, req) => schema.find('building', req.params.id)?.attrs || {},
      )

      this.get('/buildings', (schema: AppSchema, req) => schema.all('building').models)

      this.get('bookings/:bookingId', (schema, req) => {
        const booking = schema.all('booking').models[0].attrs
        return booking
      })

      this.post('bookings', (schema, req) => {
        const { room_id, start, end, total_guest_count } = JSON.parse(req.requestBody)
        const booking = {
          ...mocks.bookings[0],
          booking_id: stripeEpiId,
          room_id,
          start,
          end,
          total_guest_count,
        }
        schema.create('booking', booking as any)
        return stripeEpiId
      })

      this.post('/epi/stripe/:epiId/checkoutsession/:bookingId', (_, req) => {
        return JSON.parse(req.requestBody).successUrl
      })

      this.delete('/bookings/:bookingId', (schema: AppSchema, req) => {
        schema.find('booking', '1')?.destroy()
        return { successful: true }
      })

      this.get('/cities', () => mocks.cities)

      this.get('/amenities', () => mocks.amenities)

      this.passthrough('https://cdn.fe.dev.swiftconnect.io/:key')
      this.passthrough(`${proxyUrl}/*`)
      this.passthrough('/amenities')
      this.passthrough('/cities')
      this.passthrough('/epi/stripe/:epiId/checkoutsession/:bookingId')
      this.passthrough('/rooms')
      this.passthrough('/rooms/:id/availabilities')
      this.passthrough('/bookings')
      this.passthrough('/bookings/:bookingId')
      this.passthrough('/sessions')
      this.passthrough('/rooms/:id')
      this.passthrough('/buildings')
      this.passthrough('/buildings/:id')
      this.passthrough('/rooms/:id/price')
      this.passthrough('/rooms/:id/bookings')
      this.passthrough('/profiles/self')
      this.passthrough('/profiles/self/bookings')
      this.passthrough('https://api-js.mixpanel.com/*')
    },
  })
}
