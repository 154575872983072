import { BuildingTypes } from '@swiftctrl/api-client-react'
import {
  Button,
  Col,
  PushpinOutlined,
  Row,
  Swift,
  Typography,
} from '@swiftctrl/swift-component-library'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AddressDisplay } from '../../../components'
import { useTranslation } from '../../../hooks'
import { paths } from '../../../utils'

interface Props {
  name: string
  locationName?: string
  address?: BuildingTypes['addresses'][0]
  roomId: string
  tenantId: string
  tabAddress?: string
}

export const RoomInfos: Swift.FC<Props> = ({
  name,
  locationName,
  address,
  roomId,
  tenantId,
  tabAddress,
}) => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const handleOpenInMaps = () =>
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${address?.address_line_1}`,
      '_blank',
    )
  const handleViewRoomDetails = () =>
    push(`/${paths.RoomDetails}/${tenantId}/${roomId}`, { tabAddress })

  return (
    <StyledRow>
      <Col span={24}>
        <Typography.Title level={3}>{name}</Typography.Title>
      </Col>
      <Col span={24}>
        <Styled14pxFontSizeTypo>{locationName}</Styled14pxFontSizeTypo>
      </Col>
      <Col span={24}>
        <AddressDisplay address={address} />
      </Col>
      <Col span={24}>
        <StyledTextButton onClick={handleViewRoomDetails} type="text">
          {t('reservation_view_room_details')}
        </StyledTextButton>
      </Col>
      <Col span={24}>
        <StyledMapButton onClick={handleOpenInMaps} icon={<PushpinOutlined />}>
          {t('reservation_open_in_maps')}
        </StyledMapButton>
      </Col>
    </StyledRow>
  )
}

const StyledRow = styled(Row)`
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`

const StyledTextButton = styled(Button)`
  padding: 0px;
  span {
    text-decoration: underline;
    font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  }
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
`

const StyledMapButton = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.small};
`
const Styled14pxFontSizeTypo = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
`
