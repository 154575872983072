import { Swift } from '@swiftctrl/swift-component-library'
import React, { useContext, useEffect, useState } from 'react'
import { SessionExpiredPopup } from '../screen-components'
import {
  SessionSigningStatusTypes,
  config,
  getSessionSigningStatus,
  setSessionSigningStatus,
} from '../utils'

interface SessionContextProps {
  signInStatus: SessionSigningStatusTypes
}

const {
  appConstants: { SIGNING_LOCAL_STORAGE_KEY },
} = config

export const SessionContext = React.createContext<SessionContextProps>({ signInStatus: 'INIT' })

export const useSessionContext = () => useContext(SessionContext)

export const SessionProvider: Swift.FC = ({ children }) => {
  const [signInStatus, setSignInStatus] = useState(getSessionSigningStatus())

  useEffect(() => {
    const customUserEventChange = (e: CustomEvent<SessionSigningStatusTypes> | Event) => {
      const customEvent = e as CustomEvent<SessionSigningStatusTypes>
      setSignInStatus(customEvent.detail || 'INIT')
    }
    const localStorageSessionSetHandler = (e: StorageEvent) => {
      // This always comes from a remote tab
      if (e.key === SIGNING_LOCAL_STORAGE_KEY) {
        setSignInStatus((e.newValue as SessionSigningStatusTypes) || 'INIT')
      }
    }
    document.addEventListener('signInChanged', customUserEventChange, false)
    window.addEventListener('storage', localStorageSessionSetHandler, false)
    if (signInStatus !== 'SIGNED') {
      setSessionSigningStatus('INIT')
    }
  }, [])

  if (signInStatus === 'UNSIGNED') {
    return <SessionExpiredPopup />
  }

  return (
    <SessionContext.Provider key={signInStatus} value={{ signInStatus }}>
      {children}
    </SessionContext.Provider>
  )
}
