import { ProfilesTypes } from '@swiftctrl/api-client-react'

export const selfMockData: ProfilesTypes = {
  profile_id: '19091037-9175-4ff0-aae6-205034ef2477',
  person_id: '670fada9-adc6-4d2e-8c6c-548026e23632',
  first_name: 'Hans',
  last_name: 'test',
  middle_name: '',
  display_name: 'Hans Test',
  avatar: 'https://ca.slack-edge.com/T01HW93M3R6-U01JNLQ61JL-be876877687f-512',
  birth_date: '',
  emails: [
    {
      email_id: 'caaa474a-f531-466c-92c6-8be384df8f5b',
      email: 'admin@swiftconnect.io',
      status: 'CONFIRMED',
      overseer_id: '19091037-9175-4ff0-aae6-205034ef2477',
      overseer_name: 'mock Admin Profile',
      entity_name: 'mock Admin Profile Email',
      entity_description: '',
      entity_type_id: 'email',
      entity_status: 'ACTIVE',
      extra_s: null,
      extra_i: null,
      created_at: '2022-03-22T20:54:14Z',
      updated_at: '',
    },
  ],
  phones: [
    {
      phone_id: '8ef6462d-7463-4758-9975-3eb2ffcd7f71',
      country_code: 1,
      number: '642-587-2222',
      extension: '',
      status: 'CONFIRMED',
      sms_enabled: false,
      overseer_id: '19091037-9175-4ff0-aae6-205034ef2477',
      overseer_name: 'Mock Admin Profile',
      entity_name: '',
      entity_description: '',
      entity_type_id: 'phone',
      entity_status: 'ACTIVE',
      extra_s: null,
      extra_i: null,
      created_at: '2022-07-14T22:04:30Z',
      updated_at: '',
    },
    {
      phone_id: 'ea06f0de-a23b-4693-ba35-2ae63f876bc7',
      country_code: 0,
      number: '642-587-3333',
      extension: '',
      status: 'CONFIRMED',
      sms_enabled: false,
      overseer_id: '19091037-9175-4ff0-aae6-205034ef2477',
      overseer_name: 'Root Admin Profile',
      entity_name: '',
      entity_description: '',
      entity_type_id: 'phone',
      entity_status: 'ACTIVE',
      extra_s: null,
      extra_i: null,
      created_at: '2022-07-14T22:04:30Z',
      updated_at: '',
    },
    {
      phone_id: '95403922-e3de-4dd0-ab03-a7a94128e1aa',
      country_code: 0,
      number: '(207) 766-5100',
      extension: '',
      status: 'CONFIRMED',
      sms_enabled: false,
      overseer_id: '19091037-9175-4ff0-aae6-205034ef2477',
      overseer_name: 'Mock Admin Profile',
      entity_name: '',
      entity_description: '',
      entity_type_id: 'phone',
      entity_status: 'ACTIVE',
      extra_s: null,
      extra_i: null,
      created_at: '2022-07-14T22:04:30Z',
      updated_at: '',
    },
  ],
  addresses: [
    {
      address_id: '135443f4-5398-49f8-aefb-ad4585a442f3',
      address_line_1: '281 Tresser Boulevard',
      address_line_2: '',
      city: 'Stamford',
      coordinates: {
        coordinates_id: '',
        latitude: 65.123,
        longitude: 75.234,
      },
      country: 'USA',
      created_at: '',
      entity_description: '',
      entity_name: '',
      overseer_id: '8824a361-8829-4004-8e2c-6783d3676079',
      overseer_name: '',
      state: 'CT',
      updated_at: '',
      zip: '06901',
      entity_status: '',
      entity_type_id: 'address',
      extra_i: null,
      extra_s: null,
    },
  ],
  nick_name: '',
  honorific_prefix: '',
  honorific_suffix: '',
  preferred_language: '',
  locale: '',
  timezone: '',
  title: '',
  employee_number: '',
  manager_id: '00000000-0000-0000-0000-000000000000',
  assistant_id: '00000000-0000-0000-0000-000000000000',
  organization_id: '00000000-0000-0000-0000-000000000000',
  division_id: '00000000-0000-0000-0000-000000000000',
  department_id: '00000000-0000-0000-0000-000000000000',
  overseer_id: 'e609060a-6689-4abe-a6ae-9be0b3caebbf',
  overseer_name: '',
  entity_name: 'Root Admin Profile',
  entity_description: '',
  entity_type_id: 'profile',
  entity_status: 'ACTIVE',
  created_at: '2022-03-22T20:54:14Z',
  updated_at: '2022-07-14T22:04:30Z',
}
