import { Swift } from '@swiftctrl/swift-component-library'

export const Reservations: Swift.FC = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 2V9H7V2H13ZM13 0H7C5.9 0 5 0.9 5 2V11H15V2C15 0.9 14.1 0 13 0ZM20 7H17V10H20V7ZM3 7H0V10H3V7ZM18 12H2V18H4V14H16V18H18V12Z"
      fill="#3C4043"
    />
  </svg>
)
