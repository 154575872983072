import { TreeSelect } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../../hooks'
import { treeSelectDemoData } from '../../../utils'

interface Props {
  organizations?: string[]
  handleChange?: (organizations: string[]) => void
}

export const SelectOrganization: React.FC<Props> = ({ handleChange, organizations }) => {
  const { t } = useTranslation()
  const handleOrganizationSelect = (values: unknown) => handleChange?.(values as string[])
  return (
    <>
      <h2>
        {t(
          treeSelectDemoData?.length! > 1
            ? 'tab_search_filter_form_label_organizations'
            : 'tab_search_filter_form_label_organization',
        )}
      </h2>
      <StyledTreeSelect
        treeData={treeSelectDemoData}
        value={organizations}
        onChange={handleOrganizationSelect}
        placeholder={t('tab_search_filter_form_organization_select_placeholder')}
        treeCheckable
        showCheckedStrategy="SHOW_PARENT"
        showArrow
        allowClear
      />
    </>
  )
}

const StyledTreeSelect = styled(TreeSelect)`
  width: 472px;
`
