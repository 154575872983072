import { Col, Row, Skeleton, Swift } from '@swiftctrl/swift-component-library'
import { useTheme } from 'styled-components'

export const ReservationCardLoadingSkeleton: Swift.FC = () => {
  const { isMobileView } = useTheme()
  return isMobileView ? (
    <Col span={24}>
      <Skeleton
        avatar={{ shape: 'square', size: 'large', style: { height: 196, width: '92vw' } }}
        active
      />
      <Skeleton active paragraph={{ rows: 2 }} />
    </Col>
  ) : (
    <Row align="middle" gutter={[80, 0]}>
      <Col span={12}>
        <Skeleton
          avatar={{ shape: 'square', size: 'large', style: { height: 105, width: 105 } }}
          active
        />
      </Col>
      <Col span={12}>
        <Skeleton active paragraph={{ rows: 3 }} />
      </Col>
    </Row>
  )
}
