import { Swift, InfoCircleFilled } from '@swiftctrl/swift-component-library'
import { MobileResult } from '../../../components'
import { Button } from 'antd'
import { RouterProps, useHistory } from 'react-router-dom'
import { paths } from '../../../utils'
import { useTranslation } from '../../../hooks'

const { Search } = paths

export const MobileAdminFallback: Swift.FC = () => {
  const history: RouterProps['history'] = useHistory()
  const goToSearchScreen = () => history.push(`/${Search}`)
  const { t } = useTranslation()
  return (
    <MobileResult
      title={t('admin_room_reservations_mobile_message')}
      icon={<InfoCircleFilled />}
      extra={
        <Button onClick={goToSearchScreen} type="primary">
          {t('admin_mobile_switch_button_label')}
        </Button>
      }
    />
  )
}
