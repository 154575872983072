import { RoomsTypes } from '@swiftctrl/api-client-react'
import { Col, Row, Swift, Typography, UserOutlined } from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from '../hooks'
import { paths } from '../utils'

interface Props {
  room: RoomsTypes
  onClick?: () => void
}

const { RoomDetails } = paths

export const RoomCard: Swift.FC<Props> = ({ room, onClick }) => {
  const { t } = useTranslation()

  return (
    <Link
      to={{
        pathname: `/${RoomDetails}/${room.overseer_id}/${room.room_id}`,
      }}
    >
      <StyledRoomCard onClick={onClick}>
        <StyledImageContainer $image={room?.images?.[0]} />
        <StyledTitleAndPriceRow justify="space-between" align="middle">
          <Col>
            <StyledTitle level={3}>{room.entity_name}</StyledTitle>
          </Col>
          <StyledPriceColumn>
            <Typography.Title level={4}>${room.price}</Typography.Title>{' '}
            <span>{t('room_details_per_hour')}</span>
          </StyledPriceColumn>
        </StyledTitleAndPriceRow>
        <Row>
          <StyledGuestCol>
            <UserOutlined />
            {room.min_capacity}-{room.max_capacity}
          </StyledGuestCol>
        </Row>
      </StyledRoomCard>
    </Link>
  )
}

const StyledRoomCard = styled.div`
  :hover {
    cursor: pointer;
  }
  color: #000;
  svg {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
    margin-right: ${({ theme }) => theme.spacing.xSmall};
  }
  h4 {
    margin-bottom: ${({ theme }) => theme.spacing.none}!important;
  }
`
const StyledGuestCol = styled(Col)`
  display: flex;
  align-items: center;
`
const StyledImageContainer = styled.div<{ $image: string }>`
  background: ${({ $image }) => `url(${$image})`};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 217px;
`

const StyledTitleAndPriceRow = styled(Row)`
  margin-top: 24px;
  margin-bottom: ${({ theme }) => theme.spacing.xSmall};
`

const StyledPriceColumn = styled(Col)`
  span {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  }
  h4 {
    display: inline-block;
  }
`

const StyledTitle = styled(Typography.Title)`
  margin-bottom: ${({ theme }) => theme.spacing.none}!important;
`
