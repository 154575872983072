import { Drawer, Swift, DrawerProps } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

interface Props extends DrawerProps {
  fixedHeight?: string
  footerBorder?: boolean
}

export const CustomDrawer: Swift.FC<Props> = ({
  fixedHeight,
  footerBorder,
  children,
  ...drawerProps
}) => {
  return (
    <StyledDrawer
      {...drawerProps}
      headerStyle={{ padding: 16 }}
      $fixedHeight={fixedHeight}
      $footerBorder={footerBorder || false}
      maskClosable={false}
      placement="bottom"
    >
      {children}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)<{
  $shadow?: boolean
  $fixedHeight?: string
  $footerBorder: boolean
}>`
  .ant-drawer-content-wrapper {
    height: 96% !important;
    max-height: ${({ $fixedHeight }) => ($fixedHeight ? $fixedHeight : 'auto')} !important;
  }

  .ant-drawer-content {
    border-radius: 8px 8px 0px 0px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    button {
      margin: 0px;
      padding: 0px;
      svg {
        font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
        color: #000;
      }
    }
  }
  .ant-drawer-body {
    padding: 24px ${({ theme }) => theme.spacing.medium};
  }
  .ant-drawer-footer {
    padding: 0px;
    border: ${({ $footerBorder }) => (!$footerBorder ? 'none' : '')};
    box-shadow: 0px 9px 28px 8px rgb(0 0 0 / 5%), 0px 6px 16px rgb(0 0 0 / 8%),
      0px 3px 6px -4px rgb(0 0 0 / 12%);
  }
`
