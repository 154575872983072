import {
  Affix,
  Button,
  Col,
  FilterOutlined,
  Row,
  Swift,
  Tag,
} from '@swiftctrl/swift-component-library'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import styled, { CSSObject } from 'styled-components'
import { mixPanelTrack } from '../../../analytics'

import { useFeature, useHandleUrlParams, useTranslation } from '../../../hooks'
import { RestApiParams } from '../../../models'
import { differenceInDayFromToday, displaySearchParams, getTranslationLabel } from '../../../utils'
import { MobileSearchDrawer } from './mobile-search-drawer'
import { MobileFilterContainer, SelectedFilter } from './MobileFilterContainer'

interface Props {
  isFilterDrawerOpen: boolean
  openFilterDrawer: () => void
  closeFilterDrawer: () => void
  isSearchDrawerOpen: boolean
  openSearchDrawer: () => void
  closeSearchDrawer: () => void
}

export const MobileSearchBar: Swift.FC<Props> = ({
  isFilterDrawerOpen,
  openFilterDrawer,
  closeFilterDrawer,
  isSearchDrawerOpen,
  openSearchDrawer,
  closeSearchDrawer,
}) => {
  const { currentParams, updateParams } = useHandleUrlParams()
  const { t } = useTranslation()
  const [filterNumber, setFilterNumber] = useState<number>(0)
  const isFilterAvailable: boolean = filterNumber > 0
  const [showShadow, setShowShadow] = useState<boolean | undefined>(false)
  const [numberGuests, setNumberGuests] = useState<string | undefined>(String(currentParams.guests))
  const [amenitiesFilters, setAmenitiesFilters] = useState<string[]>(
    currentParams.amenities ? currentParams.amenities.split(',') : [],
  )

  const isAmenitiesFilterEnabled: boolean = useFeature('sc_amenitiesfilter')
  const [numberGuestsDisplay, setNumberGuestsDisplay] = useState<string>('')
  const defaultValue: string = displaySearchParams(currentParams)

  const applyFilters = (data: SelectedFilter) =>
    updateParams({ amenities: data.amenities.join(',') })

  const handleSearch = (params: RestApiParams) => {
    window.scroll(0, 0)

    mixPanelTrack('Search Spaces', {
      'Days From Today': differenceInDayFromToday(params.date),
      'Guest Count': Number(params.guests),
    })

    updateParams({ ...currentParams, ...params })
  }

  const openFilters = (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    openFilterDrawer()
  }

  const getFilterNumber = useCallback(() => {
    if (amenitiesFilters) {
      setFilterNumber(amenitiesFilters.length)
    }
  }, [amenitiesFilters])

  useEffect(() => {
    getFilterNumber()
  }, [amenitiesFilters, getFilterNumber])

  useEffect(() => {
    if (numberGuests !== null) setNumberGuestsDisplay(numberGuests + '')
  }, [numberGuestsDisplay, numberGuests])

  useEffect(() => {
    setNumberGuests(String(currentParams.guests))
    setAmenitiesFilters(currentParams.amenities ? currentParams.amenities.split(',') : [])
  }, [currentParams])

  return (
    <Affix onChange={setShowShadow}>
      <div>
        <StyledCol $shadow={showShadow!} $isFilter={isFilterAvailable}>
          <StyledRow
            justify="space-between"
            wrap={false}
            $shadow={showShadow!}
            $isFilter={isFilterAvailable}
          >
            <Col flex="auto">
              <StyledSearchInput onClick={openSearchDrawer}>
                <div>{defaultValue}</div>
              </StyledSearchInput>
            </Col>
            {isAmenitiesFilterEnabled && (
              <StyledBadgeCol flex="50px">
                <StyledButton
                  $isFilter={isFilterAvailable}
                  icon={<StyledFilterIcon $isFilter={isFilterAvailable} />}
                  size="large"
                  onClick={openFilters}
                />
              </StyledBadgeCol>
            )}
          </StyledRow>
          <StyledAmenitiesRow onClick={openFilters}>
            {amenitiesFilters &&
              amenitiesFilters.map((amenity) => (
                <StyledTag key={amenity}>
                  {t(getTranslationLabel('search_amenities_', amenity))}
                </StyledTag>
              ))}
          </StyledAmenitiesRow>
        </StyledCol>

        <MobileSearchDrawer
          currentParams={currentParams}
          visible={isSearchDrawerOpen}
          onClose={closeSearchDrawer}
          onSearch={handleSearch}
        />

        <MobileFilterContainer
          isOpen={isFilterDrawerOpen}
          applyFilters={applyFilters}
          onClose={closeFilterDrawer}
        />
      </div>
    </Affix>
  )
}

const StyledSearchInput = styled.div`
  height: 40px;
  padding: ${({ theme }) => theme.spacing.small} 12px;
  width: 100%;
  border: 1px solid #d9d9d9;
  display: flex;

  div {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
    -webkit-mask-image: linear-gradient(90deg, #000 90%, transparent);
    mask-image: linear-gradient(90deg, #000 96%, transparent);
    padding-right: 6px;
  }
`

const StyledRow = styled(Row)<{ $shadow: boolean; $isFilter: boolean }>`
  ${({ $shadow, $isFilter, theme }): CSSObject => {
    const isShadowWithOutFilter: boolean = $shadow && !$isFilter
    return {
      paddingBottom: $isFilter
        ? theme.spacing.small
        : $shadow
        ? theme.spacing.medium
        : theme.spacing.large,
      paddingTop: $shadow ? theme.spacing.medium : 24,
      paddingRight: isShadowWithOutFilter ? theme.spacing.medium : 0,
      paddingLeft: isShadowWithOutFilter ? theme.spacing.medium : 0,
      boxShadow: isShadowWithOutFilter
        ? '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)'
        : 'none',
    }
  }}
`

const StyledCol = styled(Col)<{ $shadow: boolean; $isFilter: boolean }>`
  ${({ $isFilter, $shadow, theme }): CSSObject => {
    const isShadowWithFilter: boolean = $shadow && $isFilter
    const isShadowWithOutFilter: boolean = $shadow && !$isFilter
    return {
      paddingLeft: isShadowWithOutFilter ? 0 : theme.spacing.medium,
      paddingRight: isShadowWithOutFilter ? 0 : theme.spacing.medium,
      paddingBottom: isShadowWithFilter ? theme.spacing.medium : $isFilter && !$shadow ? 24 : 0,
      backgroundColor: 'white',
      boxShadow: isShadowWithFilter
        ? '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)'
        : 'none',
    }
  }}
`

const StyledBadgeCol = styled(Col)`
  padding-left: ${({ theme }) => theme.spacing.small};
`

const StyledAmenitiesRow = styled(Row)`
  overflow-x: scroll;
  flex-flow: inherit;
  scrollbar-width: none;
  scrollbar-width: none;
  mask-image: linear-gradient(90deg, #000 90%, transparent);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledButton = styled(Button)<{ $isFilter: boolean }>`
  ${({ $isFilter }): CSSObject => ({
    borderColor: $isFilter ? '#1890ff' : 'default',
  })}
`
const StyledFilterIcon = styled(FilterOutlined)<{ $isFilter: boolean }>`
  ${({ $isFilter }): CSSObject => ({
    color: $isFilter ? '#1890ff' : 'default',
  })}
`

const StyledTag = styled(Tag)`
  margin-right: ${({ theme }) => theme.spacing.small} !important;
`
