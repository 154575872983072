import { isNull, isObject } from 'lodash'
import { RestApiParams, RootState, StoreUpdater } from '../../models'
import { Actions } from '../../store'
import { useDispatch, useSelector } from './useStoreHook'

export const useStoreParams = () => {
  const dispatch = useDispatch()

  const updateStoreParams: StoreUpdater<RestApiParams> = <T extends Object>(
    arg1: keyof T | T | null,
    arg2?: T[typeof arg1 & keyof T] | null,
  ) => {
    if (isNull(arg1)) {
      dispatch(Actions.setReservation(null))
      return
    }
    if (isObject(arg1)) {
      dispatch(Actions.setParams(arg1))
    } else {
      dispatch(Actions.setParams({ [arg1]: arg2 } as Partial<RestApiParams>))
    }
  }

  const currentStoreParams: RestApiParams | null = useSelector<RestApiParams | null>(
    (state: RootState) => state.params.data,
  )

  return {
    updateStoreParams,
    currentStoreParams,
  }
}
