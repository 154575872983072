import { config } from '../config'

const {
  appConstants: { maximumGuests, minimumGuests },
} = config

export const changeGuestNumber = (
  numberGuests: number,
  maxNumberOfGuests: number,
  minNumberOfGuests: number,
  increment: number,
) => {
  let newNumber = numberGuests + increment
  if (increment === 0) {
    newNumber = 0
  }
  if (maxNumberOfGuests !== 0) {
    if (newNumber > maxNumberOfGuests) {
      newNumber = maxNumberOfGuests
    }
  }
  if (minNumberOfGuests !== 0) {
    if (newNumber < minNumberOfGuests) {
      newNumber = minNumberOfGuests
    }
  }
  if (maxNumberOfGuests === 0 && minNumberOfGuests === 0) {
    if (newNumber < minimumGuests) {
      newNumber = minimumGuests
    }
    if (newNumber > maximumGuests) {
      newNumber = maximumGuests
    }
  }

  return newNumber
}
