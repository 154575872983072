import { RoomsTypes, ScheduleTypes } from '@swiftctrl/api-client-react'
import { Button, Col, Row, Swift } from '@swiftctrl/swift-component-library'
import { compact } from 'lodash'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { OpeningHoursDisplay } from '../../../components'
import { useBoolean, useTranslation } from '../../../hooks'
import { getTranslationLabel } from '../../../utils'
import { AmenitiesModal } from './AmenitiesModal'

interface Props {
  amenities: RoomsTypes['amenities']
  schedule?: ScheduleTypes
}

export const Amenities: Swift.FC<Props> = ({ amenities, schedule }) => {
  const { t } = useTranslation()
  // "Not included" amenities TBD with PO
  const [amenitiesNotIncluded] = useState<string[]>([])
  const [showAmenities, openAmenitiesModal, closeAmenitiesModal] = useBoolean()
  const { isMobileView } = useTheme()

  const validAmenitites = compact(amenities)

  const numberOfAmenities: number = 6

  return (
    <StyledAmenities $isOnMobile={isMobileView}>
      <Row gutter={isMobileView ? [0, 40] : [48, 16]}>
        <Col xs={{ span: 24 }} sm={isMobileView ? 24 : 12} order={isMobileView ? 2 : 1}>
          <StyledAmenitiesTitle $isOnMobile={isMobileView}>
            {t('room_details_amenities_title_details')}
          </StyledAmenitiesTitle>
          <StyledAmenitiesList>
            {validAmenitites?.map((item: string, index: number) => {
              return index < numberOfAmenities ? (
                <li key={item}>{t(getTranslationLabel('room_details_amenities_', item))}</li>
              ) : (
                ''
              )
            })}
          </StyledAmenitiesList>
          {Boolean(validAmenitites.length) && (
            <Button type="link" size="large" onClick={openAmenitiesModal}>
              {t('room_details_amenities_button')}
            </Button>
          )}
        </Col>

        <Col xs={{ span: 24 }} order={isMobileView ? 1 : 2} sm={isMobileView ? 24 : 12}>
          <StyledAmenitiesTitle $isOnMobile={isMobileView}>
            {t('room_details_amenities_title_hours')}
          </StyledAmenitiesTitle>
          {schedule && <OpeningHoursDisplay schedule={schedule} />}
        </Col>
      </Row>

      <AmenitiesModal
        showAmenities={showAmenities}
        closeAmenitiesModal={closeAmenitiesModal}
        amenitiesIncluded={amenities}
        amenitiesNotIncluded={amenitiesNotIncluded}
      />
    </StyledAmenities>
  )
}

const StyledAmenitiesList = styled.ul`
  margin: ${({ theme }) => theme.spacing.none};
  padding: ${({ theme }) => theme.spacing.none};
  list-style: none;
  li {
    margin: ${({ theme }) => theme.spacing.none} ${({ theme }) => theme.spacing.none}
      ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.none};
  }
`

const StyledAmenities = styled.div<{ $isOnMobile: boolean }>`
  background-color: #f0f2f5;
  padding: 24px;
  border-radius: 4px;
  margin-bottom: ${({ $isOnMobile, theme }) => ($isOnMobile ? '24px' : theme.spacing.large)};
  button {
    padding: ${({ theme }) => theme.spacing.none};
    height: inherit;
  }
`

const StyledAmenitiesTitle = styled.h2<{ $isOnMobile: boolean }>`
  margin-bottom: ${({ $isOnMobile, theme }) => ($isOnMobile ? '24px' : theme.spacing.xxLarge)};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  color: #8c8c8c;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
`
