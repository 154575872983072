import { RoomAvailability } from '../../models'

export const isBookingPossible = (
  startTime: number,
  endTime: number,
  availabilities: RoomAvailability['availabilities'],
): boolean => {
  if (!availabilities.length) {
    return false
  }
  let possible = true
  for (let i = startTime; i < endTime; i++) {
    if (!availabilities.includes(i)) {
      possible = false
      break
    }
  }
  return possible
}
