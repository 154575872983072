import {
  Button,
  CalendarOutlined,
  ClockCircleOutlined,
  Col,
  Divider,
  DollarOutlined,
  ExclamationCircleOutlined,
  Row,
  Swift,
  Tooltip,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

import { NumberGuestsInput } from '../../../../components'
import { useStoreReservation, useTranslation } from '../../../../hooks'
import { BookingVerticalProps } from '../../../../models'
import {
  displayTimeRange,
  formatWithZero,
  getTranslationLabel,
  isReservationValid,
} from '../../../../utils'

export const BookingVertical: Swift.FC<BookingVerticalProps> = ({
  room,
  numberGuests,
  setNumberGuests,
  sendToAvailabilityTable,
  validateRoomAvailability,
  cost,
}) => {
  const { reservationDetails } = useStoreReservation()

  const { t } = useTranslation()
  const displayDate = (date: string): string => {
    let day = new Date(date).getUTCDate()
    let dayWeek = new Date(date).getUTCDay()
    let month = new Date(date).getUTCMonth() + 1

    return (
      t(getTranslationLabel('days_of_the_week_', dayWeek)) +
      ', ' +
      month +
      ' / ' +
      formatWithZero(day)
    )
  }

  const displayTime: string = `${displayTimeRange(
    { hours: reservationDetails.startTime },
    { hours: reservationDetails.endTime },
  )} (${reservationDetails.duration}${t(
    Number(reservationDetails.duration) > 1 ? 'booking_hours' : 'booking_hours_other',
  )})`

  return (
    <StyledBooking>
      <Row>
        <Col span={24}>
          <StyledBookingTitle $isReservation={isReservationValid(reservationDetails)}>
            {t('booking_title')}
          </StyledBookingTitle>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Row>
            <Col span={24}>
              <StyledPriceLabel>
                {t('booking_hourly')}&nbsp;
                <Tooltip
                  title={
                    reservationDetails.date
                      ? t('booking_hourly_tooltip_with_booking')
                      : t('booking_hourly_tooltip_no_booking')
                  }
                  placement="bottomLeft"
                  arrowPointAtCenter
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </StyledPriceLabel>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <StyledPrices>
                {room!.price}
                <StyledPriceDetails>/ {t('booking_hour')}</StyledPriceDetails>
              </StyledPrices>
            </Col>
          </Row>
        </Col>
        <Col span={2}></Col>
        <Col span={11}></Col>
      </Row>
      <Row>
        <StyledColDivider span={24}>
          <Divider />
        </StyledColDivider>
      </Row>
      <StyledReservation $isReservation={isReservationValid(reservationDetails)}>
        <Row>
          <Col span={24}>
            <StyledReservationDetailsLabel>
              {t('booking_reservation_details')}
            </StyledReservationDetailsLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledReservationDetails>
              <CalendarOutlined />

              {displayDate(reservationDetails.date)}
            </StyledReservationDetails>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <StyledReservationDetails>
              <ClockCircleOutlined />
              {displayTime}
            </StyledReservationDetails>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <StyledReservationDetails>
              <DollarOutlined />${cost?.price}
            </StyledReservationDetails>
          </Col>
        </Row>
      </StyledReservation>
      <NumberGuestsInput
        numberGuestsParent={numberGuests}
        setNumberGuestsParent={setNumberGuests}
        allowZeroGuests={false}
        maxNumberOfGuests={room?.max_capacity && room?.max_capacity}
        minNumberOfGuests={room?.min_capacity && room?.min_capacity}
        restrictedGuests
      />
      <StyledBookButton>
        <Col span={24}>
          {!isReservationValid(reservationDetails) ? (
            <Button size="large" type="primary" block ghost onClick={sendToAvailabilityTable}>
              {t('booking_select_time')}
            </Button>
          ) : (
            <Button
              size="large"
              type="primary"
              block
              disabled={!isReservationValid(reservationDetails)}
              onClick={validateRoomAvailability}
            >
              {t('booking_book_button')}
            </Button>
          )}
        </Col>
      </StyledBookButton>
      <Row>
        <Col span={24}>
          <StyledCancelMessage>{t('booking_cancel_message')}</StyledCancelMessage>
        </Col>
      </Row>
    </StyledBooking>
  )
}

const StyledBooking = styled.div`
  width: 407px;
  padding: ${({ theme }) => theme.spacing.xxLarge} ${({ theme }) => theme.spacing.large};
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  overflow: hidden;
  height: auto;
`

const StyledBookingTitle = styled.div<{ $isReservation?: boolean }>`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].lineHeight};
  margin-bottom: ${({ $isReservation, theme }) =>
    $isReservation ? '24px' : theme.spacing.xxLarge};
`
const StyledPriceLabel = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  color: #8c8c8c;
`

const StyledPrices = styled.div`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].lineHeight};
`

const StyledPriceDetails = styled.span`
  font-weight: normal;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  margin-left: ${({ theme }) => theme.spacing.small};
`

const StyledColDivider = styled(Col)`
  padding-top: 11px;
  padding-bottom: 11px;
`

const StyledReservation = styled.div<{ $isReservation?: boolean }>`
  display: ${({ $isReservation }) => ($isReservation ? 'block' : 'none')};
  padding-bottom: ${({ $isReservation, theme }) => ($isReservation ? '24px' : theme.spacing.none)};
`

const StyledReservationDetailsLabel = styled.div`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  padding-bottom: 24px;
`

const StyledReservationDetails = styled.div`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  padding-bottom: ${({ theme }) => theme.spacing.small};
  white-space: nowrap;
  span {
    margin-right: 9.5px;
    font-size: 21px;
  }
`

const StyledBookButton = styled(Row)`
  margin-top: 24px;
`
const StyledCancelMessage = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
  text-align: center;
  color: #8c8c8c;
`
