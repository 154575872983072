import { Col, Row, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

interface Props {
  title: string
  description?: string | React.ReactNode
}

export const ContactCard: Swift.FC<Props> = ({ title, description }) => (
  <>
    <StyledRow>
      <Col span={24}>
        <div>{title}</div>
      </Col>
    </StyledRow>
    <Row>
      <Col span={24}>
        <StyledInput>
          <div>{description}</div>
        </StyledInput>
      </Col>
    </Row>
  </>
)

const StyledRow = styled(Row)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
`
const StyledInput = styled.div`
  display: block;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  color: #8c8c8c;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
