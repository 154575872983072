import { createSlice } from '@reduxjs/toolkit'
import { isNull } from 'lodash'
import { Payload, RestApiParams, State } from '../../models'
import { storeSliceNames } from '../../utils'

const { params: name } = storeSliceNames

const initialState: State<RestApiParams> = { data: null }

const paramsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setParams: (state: State<RestApiParams>, action: Payload<Partial<RestApiParams>>) => {
      const { payload } = action
      if (isNull(payload)) {
        state.data = payload
        return
      }
      state.data = { ...state.data, ...payload }
    },
  },
})

export const {
  reducer: paramsReducer,
  actions: { setParams },
} = paramsSlice
