import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  MobileCalendar,
  RightOutlined,
  Swift,
} from '@swiftctrl/swift-component-library'
import { add, getHours, isBefore, set } from 'date-fns'
import styled from 'styled-components'

import { createCurrentTimeZoneDateAtMidnight } from '../../../../utils'

interface Props {
  selectedDate: string
  onDateChange: (date: string) => void
}

export const MobileSearchCalendar: Swift.FC<Props> = ({ selectedDate, onDateChange }) => {
  const handleChangeDate = (date: Date | null) => {
    if (date) {
      onDateChange(date.toISOString().split('T')[0])
    }
  }

  const selectedDateInTimeZone = createCurrentTimeZoneDateAtMidnight(selectedDate)

  const disablePastDates = (date: Date) => {
    const todaysDateAtMidnight = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    const tomorrowsDate = add(todaysDateAtMidnight, { days: 1 })
    const currentHour = getHours(new Date())

    if (currentHour === 23) {
      return isBefore(date, tomorrowsDate)
    }
    return isBefore(date, todaysDateAtMidnight)
  }

  return (
    <StyledMobileSearchCalendar>
      <MobileCalendar
        selectionMode="single"
        value={selectedDateInTimeZone}
        onChange={handleChangeDate}
        shouldDisableDate={disablePastDates}
        prevMonthButton={<LeftOutlined />}
        nextMonthButton={<RightOutlined />}
        nextYearButton={<DoubleRightOutlined />}
        prevYearButton={<DoubleLeftOutlined />}
      />
    </StyledMobileSearchCalendar>
  )
}

const StyledMobileSearchCalendar = styled.div`
  .adm-calendar-header {
    padding-top: ${({ theme }) => theme.spacing.smedium};
    svg {
      color: #1890ff;
      font-size: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].fontSize};
      transform: translateY(1px) !important;
    }
  }
  .adm-calendar-cell-top {
    transform: translateY(4px);
  }
  .adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected {
    background-color: #1890ff;
  }
  .adm-calendar-cell .adm-calendar-cell-today {
    color: #1890ff;
  }
  .adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected-end {
    border-radius: 2px;
  }
`
