import { format } from 'date-fns'
import { config } from './config'
import { createCurrentTimeZoneDateAtMidnight } from './date-utils'

const {
  appConstants: { dateFullDisplay },
} = config

export const bookingDateDisplay = (bookingDate: string) => {
  const stringDate = bookingDate.split('T')[0]
  const date = createCurrentTimeZoneDateAtMidnight(stringDate)
  return format(date, dateFullDisplay)
}
