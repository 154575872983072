import { BuildingTypes, RoomsTypes } from '@swiftctrl/api-client-react'
import { Col, Divider, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import { RouterProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AddressDisplay } from '../../components'
import { useTranslation } from '../../hooks'
import { ReservationDetailsType } from '../../models'
import { getFormattedPayment, roomPath } from '../../utils'

interface Props {
  reservationDetails: ReservationDetailsType
  room: RoomsTypes
  clickable?: boolean
  building?: Partial<BuildingTypes>
}

export const CheckoutRoomDetails: Swift.FC<Props> = ({
  reservationDetails,
  room,
  clickable = true,
  building,
}) => {
  const { t } = useTranslation()
  const history: RouterProps['history'] = useHistory()

  const goToRoom = () => history.push(roomPath(room.overseer_id, room.room_id))

  return (
    <StyledDetailsContainer>
      <Row>
        <StyledRoomDetailsCol
          onClick={clickable ? goToRoom : undefined}
          $clickable={clickable}
          span={24}
          sm={12}
          lg={24}
        >
          <div>
            <StyledOfficeImage src={room?.images[0]} alt="Room selected" />
          </div>

          <div>
            <StyledOfficeTitle>{room.entity_name}</StyledOfficeTitle>
            <StyledOrganizationInfo>
              {building?.entity_name} | {building?.overseer_name}
            </StyledOrganizationInfo>
            <StyledOfficeLocation>
              <AddressDisplay address={building?.addresses?.[0]} />
            </StyledOfficeLocation>
          </div>
        </StyledRoomDetailsCol>

        <StyledReservationContainer span={24} sm={12} lg={24}>
          <StyledHorizontalDivider type="horizontal" />
          <StyledVerticalDivider type="vertical" />
          <StyledReservationRows>
            <Row>
              <Col>
                <StyledReservationPricingLabel>
                  {t('reservation_details_pricing_details_label')}
                </StyledReservationPricingLabel>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <StyledReservationPricing>
                  {reservationDetails?.duration}{' '}
                  {reservationDetails?.duration === 1
                    ? t('reservation_details_hours_one')
                    : t('reservation_details_hours_other')}
                  <StyledReservationPrice>
                    {getFormattedPayment(Number(reservationDetails.cost))}
                  </StyledReservationPrice>
                </StyledReservationPricing>
              </Col>
            </Row>
            <div>
              <Row>
                <Col span={12}>
                  <StyledReservationTaxes>
                    {t('reservation_details_taxes_fees_label')}
                  </StyledReservationTaxes>
                </Col>
                <Col span={12}>
                  <StyledReservationPrice>
                    {t('reservation_details_calculated_at_checkout_label')}
                  </StyledReservationPrice>
                </Col>
              </Row>
              {/* <Row>
                <Col span={24}>
                  <StyledReservationTotalLabel>
                    {t('reservation_details_total')} (USD)
                    <StyledReservationTotal>
                      {getFormattedPayment(Number(reservationDetails.cost))}
                    </StyledReservationTotal>
                  </StyledReservationTotalLabel>
                </Col>
              </Row> */}
            </div>
          </StyledReservationRows>
        </StyledReservationContainer>
      </Row>
    </StyledDetailsContainer>
  )
}

const StyledRoomDetailsCol = styled(Col)<{ $clickable: boolean }>`
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 575px) {
    display: block;
  }
`
const StyledDetailsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xxLarge} ${({ theme }) => theme.spacing.large};
  width: 407px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`
const StyledHorizontalDivider = styled(Divider)`
  margin-top: 27px;
  margin-bottom: 27px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
  @media only screen and (max-width: 575px) {
    display: block;
  }
`
const StyledVerticalDivider = styled(Divider)`
  display: none;
  height: 100%;
  margin-left: 27px;
  margin-right: 27px;
  @media only screen and (max-width: 991px) {
    display: inherit;
  }
  @media only screen and (max-width: 575px) {
    display: none;
  }
`
const StyledOfficeImage = styled.img`
  width: 100px;
  height: 100px;
  background-color: gray;
  border-radius: 2px;
  overflow: hidden;
  margin-right: 24px;
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 24px;
  }
`
const StyledOfficeTitle = styled.div`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`
const StyledOfficeLocation = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
  color: #8c8c8c;
`
const StyledReservationContainer = styled(Col)`
  display: block;
  @media only screen and (max-width: 991px) {
    display: flex;
    flex-direction: row;
  }
`
const StyledReservationRows = styled.div`
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing.small};
`
const StyledReservationPricingLabel = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
`
const StyledReservationPricing = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
const StyledReservationTaxes = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
const StyledReservationPrice = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  float: right;
  text-align: right;
`
const StyledReservationTotalLabel = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
`
const StyledReservationTotal = styled.div`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  float: right;
  text-align: right;
`
const StyledOrganizationInfo = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
`
