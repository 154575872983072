import { Swift } from '@swiftctrl/swift-component-library'
import { useIsPrivateRoute, useIsPublicRoute } from '../hooks'

interface RenderIfScopeProps {
  is: 'private' | 'public'
}

export const RenderIfScope: Swift.FC<RenderIfScopeProps> = ({ is, children }) => {
  const isPublic: boolean = useIsPublicRoute()
  const isPrivate: boolean = useIsPrivateRoute()
  if (is === 'public' && isPublic) {
    return isPublic ? <>{children}</> : null
  }
  if (is === 'private' && isPrivate) {
    return isPrivate ? <>{children}</> : null
  }
  return null
}
