import {
  BookingTypes,
  ProfilesTypes,
  RoomsTypes,
  useBooking,
  useProfile,
} from '@swiftctrl/api-client-react'
import {
  Button,
  Col,
  Divider,
  DownloadOutlined,
  ExclamationCircleOutlined,
  Modal,
  ModalFuncProps,
  Row,
  Swift,
} from '@swiftctrl/swift-component-library'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { mixPanelTrack } from '../../../analytics'
import { Breadcrumbs, ContactCard } from '../../../components'
import { useFeature, useHandleUrlParams, useTranslation } from '../../../hooks'
import { differenceInDayFromToday, displayDate } from '../../../utils'

interface Props {
  booking: BookingTypes
  room: RoomsTypes
}

export const ReservationDetails: Swift.FC<Props> = ({ booking, room }) => {
  const { t } = useTranslation()
  const { mutate: cancelBooking } = useBooking().delete()
  // const { currentUser } = useStoreCurrentUser()
  const history = useHistory()
  const { currentParams } = useHandleUrlParams()

  const { data } = useProfile()
    .at(booking.creator_profile_id)
    .read({ queryKey: ['profile-read'] })

  const profile = data?.data as ProfilesTypes

  const address = profile?.addresses[0]
  const phoneNumber = profile?.phones[0]

  const bookingDate = displayDate(new Date(booking.start_time), true)
  const bookingStartTime = dayjs.utc(booking.start_time).format('hh:mmA')
  const bookingEndTime = dayjs.utc(booking.end_time).format('hh:mmA')
  const isPastReservation = new Date() > new Date(booking.start_time)
  // const isPaymentFeatureEnable: boolean = useFeature('sc_paymentinformation')
  const isCancelBookingEnable: boolean = useFeature('sc_bookings_cancel')
  const isInvoiceBookingEnable: boolean = useFeature('sc_bookings_seeinvoice')

  const onCancel = (modalRef: {
    destroy: () => void
    update: (
      configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps),
    ) => void
  }) => {
    modalRef.update((prevConfig: ModalFuncProps) => ({
      ...prevConfig,
      okButtonProps: { loading: true, type: 'primary', danger: true },
    }))

    return new Promise((resolve, reject) => {
      cancelBooking(
        { id: booking.booking_id },
        {
          onSuccess: (response) => {
            if (response.status === 204) {
              history.push('/reservations', { afterCancel: true })
              resolve(true)
            }
          },
          onError: () => {
            reject()
          },
        },
      )
    })
  }

  const confirm = () => {
    const modal = Modal.confirm({
      title: t('reservation_modal_title'),
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          {t('reservation_modal_message')}
          <StyledContent>
            {` ${room.entity_name}, ${bookingDate}, ${bookingStartTime} - ${bookingEndTime}`}
          </StyledContent>
        </>
      ),
      okText: t('reservation_modal_cancel_button'),
      cancelText: t('reservation_modal_no_button'),
      okButtonProps: { loading: false, type: 'primary', danger: true },
      autoFocusButton: null,
      centered: true,
    })

    modal.update((prevConfig) => ({
      ...prevConfig,
      onOk: () => {
        mixPanelTrack('Cancel My Reservation', {
          'Days From Today': differenceInDayFromToday(booking.end_time),
        })
        onCancel(modal)
      },
    }))
  }

  const showCancelButton: boolean = !isPastReservation && !currentParams.booking

  return (
    <div>
      <Row>
        <Col>
          <Breadcrumbs />
        </Col>
      </Row>
      <Row>
        <StyledReservationTitle>
          {t('reservation_success_reservation_details_title')}
        </StyledReservationTitle>
      </Row>
      <Row>
        <Col span={24}>
          <StyledPaymentLabel>
            {t('reservation_your_reservation_label')}
            <StyledEditReservation>
              <>
                {showCancelButton ? (
                  <>
                    {isCancelBookingEnable && (
                      <Button type="primary" size="large" onClick={confirm} danger>
                        {t('reservation_success_cancel_button')}
                      </Button>
                    )}
                  </>
                ) : null}
                {isInvoiceBookingEnable && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      window.open('', '_blank')
                    }}
                  >
                    <DownloadOutlined /> {t('reservation_success_invoice')}
                  </Button>
                )}
              </>
            </StyledEditReservation>
          </StyledPaymentLabel>
        </Col>
      </Row>
      <Row>
        <StyledReservationLabel span={24}>{t('reservation_date_label')}</StyledReservationLabel>
      </Row>
      <Row>
        <StyledReservationInput span={24}>
          {`${bookingDate}, ${bookingStartTime} - ${bookingEndTime}`}
        </StyledReservationInput>
      </Row>
      <Row>
        <StyledReservationLabel span={24}>{t('reservation_guests_label')}</StyledReservationLabel>
      </Row>
      <Row>
        <StyledReservationInput span={24}>
          {booking.guest_count}{' '}
          {booking.guest_count === 1 ? t('reservation_guests_one') : t('reservation_guests_other')}
        </StyledReservationInput>
      </Row>
      <Divider />

      <StyledTitleRow>
        <Col span={24}>{t('reservation_success_contact_info_label')}</Col>
      </StyledTitleRow>
      <ContactCard
        title={t('reservation_success_name_label')}
        description={profile?.display_name}
      />
      <ContactCard
        title={t('reservation_success_email_label')}
        description={profile?.emails[0].email}
      />
      {phoneNumber?.country_code && phoneNumber?.number && (
        <ContactCard
          title={t('reservation_success_phone_number_label')}
          description={`+${phoneNumber?.country_code} ${phoneNumber?.number}`}
        />
      )}
      <ContactCard
        title={t('reservation_success_address_label')}
        description={
          address && (
            <>
              {address?.address_line_1}, {address?.city}, {address?.state}, {address?.country}{' '}
              {address?.address_line_2 && <div>{address?.address_line_2}</div>}
              {address?.zip}
            </>
          )
        }
      />
      {/* {isPaymentFeatureEnable && (
        <>
          <Divider />
          <Row>
            <StyledPaymentLabel>{t('reservation_success_pay_label')}</StyledPaymentLabel>
          </Row>
          <Row>
            <StyledCardName>
              <CreditCardOutlined /> {booking.payment.payment_method}
            </StyledCardName>
          </Row>
          <Row>
            <StyledCardNumber>**** {booking.payment.credit_card_mask}</StyledCardNumber>
          </Row>{' '}
        </>
      )} */}
    </div>
  )
}

const StyledTitleRow = styled(Row)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.medium};
`
const StyledReservationTitle = styled(Col)`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].lineHeight};
  margin-bottom: 34px;
`
const StyledEditReservation = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  float: right;

  Button {
    margin-left: ${({ theme }) => theme.spacing.small};
  }
`
// const StyledCardNumber = styled(Col)`
//   font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
//   line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
//   color: #8c8c8c;
// `
// const StyledCardName = styled(Col)`
//   font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
//   font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
//   line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
// `
const StyledPaymentLabel = styled(Col)`
  display: block;
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  margin-bottom: 24px;
`
const StyledReservationLabel = styled(Col)`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
`
const StyledReservationInput = styled(Col)`
  display: block;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  color: #8c8c8c;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const StyledContent = styled.span`
  font-weight: 600;
`
