import { Swift } from '@swiftctrl/swift-component-library'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { RouterProps, useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { mixPanelTrack } from '../../analytics'

import {
  useBoolean,
  useHandleUrlParams,
  useInitialRender,
  useStoreReservation,
  useValidateRoomDetailParams,
} from '../../hooks'
import { RoomDetailsScreen, RoomDetailsScreenMobile } from '../../screens/'
import { config, createCurrentTimeZoneDateAtMidnight, paths } from '../../utils'

const {
  appConstants: { timeConvertDate },
} = config

const { Checkout } = paths

interface RouteParams {
  id: string | undefined
  tenantId: string | undefined
}

const RoomDetailsScreenRenderer: Swift.FC = () => {
  const { isMobileView } = useTheme()
  const { id: roomId, tenantId } = useParams<RouteParams>()
  const { reservationDetails, updateStoreReservation } = useStoreReservation()

  const { currentParams, cleanParams, updateParams } = useHandleUrlParams()

  const history: RouterProps['history'] = useHistory()
  const { validatedParams } = useValidateRoomDetailParams()

  const [validationDone, turnOnValidation] = useBoolean()
  const initialRender = useInitialRender()
  const reservationDetailsStringify: string = JSON.stringify(reservationDetails)

  useEffect(() => {
    if (!validationDone && !isEmpty(validatedParams)) {
      updateStoreReservation({
        ...reservationDetails,
        date: validatedParams?.date ? validatedParams.date : format(new Date(), timeConvertDate),
        startTime: validatedParams.startTime ? validatedParams.startTime : 0,
        endTime: validatedParams.endTime ? validatedParams.endTime : 0,
        numberOfGuests: validatedParams.guests,
        tenantId,
        roomId,
        duration: validatedParams.endTime! - validatedParams.startTime!,
      })
      turnOnValidation()
    }

    if (validationDone) {
      updateParams({
        ...validatedParams,
        date: format(
          reservationDetails.date
            ? createCurrentTimeZoneDateAtMidnight(reservationDetails.date)
            : new Date(),
          timeConvertDate,
        ),
        guests: reservationDetails.numberOfGuests,
        startTime: reservationDetails.startTime,
        endTime: reservationDetails.endTime,
      })
      if (!reservationDetails.startTime || !reservationDetails.endTime) {
        cleanParams(['startTime', 'endTime'])
      }
    }
  }, [reservationDetailsStringify, validationDone, initialRender])

  useEffect(() => {
    if (
      currentParams.book === 'true' &&
      validationDone &&
      reservationDetails.date &&
      reservationDetails.startTime &&
      reservationDetails.endTime &&
      reservationDetails.numberOfGuests &&
      !isMobileView
    ) {
      return history.push(`/${Checkout}`)
    }
  }, [reservationDetailsStringify, validationDone, currentParams.book])

  useEffect(() => {
    mixPanelTrack('View Space')
  }, [])

  return isMobileView ? (
    <RoomDetailsScreenMobile roomId={roomId!} tenantId={tenantId!} />
  ) : (
    <RoomDetailsScreen roomId={roomId!} tenantId={tenantId!} />
  )
}

export default RoomDetailsScreenRenderer
