import {
  BuildingView,
  Default404,
  Default5XX,
  RoomDetailsScreenRenderer,
  SearchScreen,
} from '../../lazyComponents'
import { MainRouteProps } from '../../models'
import { paths } from '../../utils'

const { RoomDetails, Search, Building, DefaultFallback404, DefaultFallback5XX } = paths

export const usePublicRoutes = (): MainRouteProps[] => {
  const routes: MainRouteProps[] = [
    {
      path: `/${RoomDetails}/:tenantId?/:id?/:checkoutSessionId?`,
      component: RoomDetailsScreenRenderer,
      exact: true,
    },
    {
      path: `/${Search}`,
      component: SearchScreen,
      exact: true,
    },
    {
      path: `/${Building}/:id?`,
      component: BuildingView,
      exact: true,
    },
    {
      path: `/${DefaultFallback404}`,
      component: Default404,
      exact: true,
    },
    {
      path: `/${DefaultFallback5XX}`,
      component: Default5XX,
      exact: true,
    },
  ]

  return routes
}
