import {
  Button,
  ClockCircleOutlined,
  CloseOutlined,
  Col,
  Row,
  SwapRightOutlined,
} from '@swiftctrl/swift-component-library'
import { Select } from 'antd'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

interface TimeOption {
  value: number
  display: string
}

const timeOptions: TimeOption[] = [
  { value: 0, display: '12:00 AM' },
  { value: 1, display: '1:00 AM' },
  { value: 2, display: '2:00 AM' },
  { value: 3, display: '3:00 AM' },
  { value: 4, display: '4:00 AM' },
  { value: 5, display: '5:00 AM' },
  { value: 6, display: '6:00 AM' },
  { value: 7, display: '7:00 AM' },
  { value: 8, display: '8:00 AM' },
  { value: 9, display: '9:00 AM' },
  { value: 10, display: '10:00 AM' },
  { value: 11, display: '11:00 AM' },
  { value: 12, display: '12:00 PM' },
  { value: 13, display: '1:00 PM' },
  { value: 14, display: '2:00 PM' },
  { value: 15, display: '3:00 PM' },
  { value: 16, display: '4:00 PM' },
  { value: 17, display: '5:00 PM' },
  { value: 18, display: '6:00 PM' },
  { value: 19, display: '7:00 PM' },
  { value: 20, display: '8:00 PM' },
  { value: 21, display: '9:00 PM' },
  { value: 22, display: '10:00 PM' },
  { value: 23, display: '11:00 PM' },
  { value: 0, display: '12:00 AM' },
]
interface Props {
  onStartTimeChange: (value: number) => void
  onEndTimeChange: (value: number) => void
  onClear: () => void
  onFocusStartTime?: () => void
  onFocusEndTime?: () => void
  startTime: number
  endTime: number
  hideClearButton: boolean
  minimumHourInterval: number
  disabledHours?: number[]
  onDropDownVisible?: (open: boolean) => void
  keepInterval?: boolean
}

export const CustomTimePicker = ({
  endTime,
  startTime,
  onEndTimeChange,
  onStartTimeChange,
  onClear,
  hideClearButton,
  minimumHourInterval,
  disabledHours,
  onFocusStartTime,
  onFocusEndTime,
  onDropDownVisible,
  keepInterval,
}: Props) => {
  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false)
  const [isEndTimeOpen, setIsEndTimeOpen] = useState(false)
  const { isMobileView } = useTheme()
  const handleStartDropDownVisible = (open: boolean) => {
    setIsStartTimeOpen(open)
    if (!open && isEndTimeOpen) {
      onDropDownVisible && onDropDownVisible(true)
    } else {
      onDropDownVisible && onDropDownVisible(open)
    }
  }
  const handleEndDropDownVisible = (open: boolean) => {
    setIsEndTimeOpen(open)
    if (!open && isStartTimeOpen) {
      onDropDownVisible && onDropDownVisible(true)
    } else {
      onDropDownVisible && onDropDownVisible(open)
    }
  }

  const handleChangeStartTime = (value: unknown) => {
    const selectedTime = Number(value)
    onStartTimeChange(selectedTime)
    // If endtime is 0 convert it to 24, highest possible value in the day
    const convertedEndtime = endTime || 24
    if (
      minimumHourInterval &&
      (selectedTime === convertedEndtime || selectedTime > convertedEndtime)
    ) {
      onEndTimeChange(selectedTime + minimumHourInterval)
    }
    if (keepInterval) {
      const newEndTime = selectedTime + convertedEndtime - startTime
      onEndTimeChange(newEndTime > 24 ? 0 : newEndTime)
    }
  }

  const handleChangeEndTime = (value: unknown) => {
    onEndTimeChange(Number(value))
  }

  const formatDifferenceLabel = (startTime: number, endTime: number): string => {
    const convertedEndTime = endTime || 24
    if (startTime < convertedEndTime) {
      const difference = convertedEndTime - startTime
      return `(${difference}hr${difference > 1 ? 's' : ''})`
    }
    return ''
  }

  return (
    <StyledCustomTimePicker align="middle">
      <StyledColumn span={isMobileView ? 10 : 11}>
        <ClockCircleOutlined />
        <StyledSelect
          bordered={false}
          size="small"
          value={startTime}
          onChange={handleChangeStartTime}
          smallpadding={1}
          onFocus={onFocusStartTime}
          onDropdownVisibleChange={handleStartDropDownVisible}
        >
          {timeOptions.map((time, i) =>
            i !== timeOptions.length - 1 ? (
              <Select.Option
                key={time.value}
                value={time.value}
                disabled={disabledHours?.includes(time.value)}
              >
                {time.display}
              </Select.Option>
            ) : null,
          )}
        </StyledSelect>
      </StyledColumn>
      <StyledColumn span={11}>
        <SwapRightOutlined />
        <StyledSelect
          bordered={false}
          size="small"
          value={endTime}
          onChange={handleChangeEndTime}
          onFocus={onFocusEndTime}
          onDropdownVisibleChange={handleEndDropDownVisible}
        >
          {timeOptions.map((time, i) =>
            i !== 0 ? (
              <Select.Option
                disabled={time.value !== 0 && time.value <= startTime}
                key={time.value}
                value={time.value}
              >
                {`${time.display} ${formatDifferenceLabel(startTime, time.value)}`}
              </Select.Option>
            ) : null,
          )}
        </StyledSelect>
      </StyledColumn>
      <Col span={2}>
        {!hideClearButton && (
          <StyledClearButton
            shape="circle"
            size="small"
            type="text"
            icon={<CloseOutlined />}
            onClick={onClear}
          />
        )}
      </Col>
    </StyledCustomTimePicker>
  )
}

const StyledCustomTimePicker = styled(Row)`
  .anticon-swap-right {
    font-size: 18px;
  }

  height: 40px;
  padding: 6.5px 11px;
  border: 1px solid #d9d9d9;
`
const StyledSelect = styled(Select)<{ smallpadding?: number }>`
  .ant-select-arrow {
    display: none;
  }
  font-size: 16px;
  .ant-select-selector:first-of-type {
    padding-left: ${(props) => (props.smallpadding ? '2px!important' : 'inherit')};
  }
  .ant-select-selection-item {
    padding-right: 0px !important;
  }
  width: 250px;
`
const StyledColumn = styled(Col)`
  display: flex;
  align-items: center;
`

const StyledClearButton = styled(Button)`
  opacity: 0.35;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s;
  margin-left: ${({ theme }) => (theme.isMobileView ? `${theme.spacing.medium}` : '13px')};
`
