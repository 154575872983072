import React, { useEffect } from 'react'
import { Modal } from '@swiftctrl/swift-component-library'
import { useIsPublicRoute, useTranslation } from '../../hooks'
import { goToAccountsApp } from '../../utils'

export const SessionExpiredPopup = () => {
  const { t } = useTranslation()
  const [modal, contextHolder] = Modal.useModal()
  const isPublic = useIsPublicRoute()

  useEffect(() => {
    const onOk = () => goToAccountsApp('signin')
    const onCancel = () => window.location.reload()
    modal.confirm({
      title: t('session_expired_modal_title'),
      content: t('session_expired_modal_content'),
      okText: t('session_expired_modal_call_to_action'),
      cancelText: t('session_expired_stay_as_guest_title'),
      onOk,
      onCancel,
      closable: false,
      cancelButtonProps: {
        style: {
          display: isPublic ? 'inline-block' : 'none',
        },
      },
    })
  }, [modal, t, isPublic])
  return <>{contextHolder}</>
}
