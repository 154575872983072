import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { Swift } from '@swiftctrl/swift-component-library'
import { Avatar } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useStoreCurrentUser } from '../../hooks'
import { AvatarMenuProps } from '../../models'
import { getInitials } from '../../utils'
import { LoginButton } from '../login-button'

export const AvatarContainer: Swift.FC<AvatarMenuProps> = ({
  showAvatarMenu,
  openAvatarModal,
  closeAvatarModal,
}) => {
  const { currentUser } = useStoreCurrentUser()
  const { isMobileView } = useTheme()
  const profileInfo: ProfilesTypes | null = currentUser
  const initials: string = getInitials(profileInfo?.display_name) || '?'

  const toggleMenu = () => {
    if (showAvatarMenu) {
      closeAvatarModal()
    } else {
      openAvatarModal()
    }
  }

  return (
    <>
      {profileInfo ? (
        <StyledAvatarOpenMenu className="avatar_open-menu">
          {isMobileView ? (
            <div onClick={toggleMenu}>
              <Avatar size={'default'} src={profileInfo?.avatar}>
                {initials}
              </Avatar>
            </div>
          ) : (
            <StyledDiv>
              <StyledToggle onClick={toggleMenu}>
                <Avatar size={40} src={profileInfo.avatar}>
                  {initials}
                </Avatar>
              </StyledToggle>
            </StyledDiv>
          )}
        </StyledAvatarOpenMenu>
      ) : (
        <LoginButton />
      )}
    </>
  )
}

const StyledAvatarOpenMenu = styled.div`
  width: ${({ theme }) => (!theme.isMobileView ? '220px' : 'auto')};
  position: absolute;
  right: 0;
  top: ${({ theme }) => (theme.isMobileView ? 'none' : '6px')};
  bottom: 18px;
`

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledToggle = styled.div`
  cursor: pointer;
`
