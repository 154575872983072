import {
  CheckoutScreen,
  ReservationDetailsScreenRenderer,
  ReservationsScreen,
} from '../../lazyComponents'
import { MainRouteProps } from '../../models'
import { paths } from '../../utils'

const { Checkout, ReservationDetails, Reservations } = paths

export const usePrivateRoutes = (): MainRouteProps[] => {
  const routes: MainRouteProps[] = [
    {
      path: `/${Reservations}`,
      component: ReservationsScreen,
      exact: true,
    },
    {
      path: `/${ReservationDetails}/:bookingId?/:sessionId?`,
      component: ReservationDetailsScreenRenderer,
      exact: true,
    },
    {
      path: `/${Checkout}`,
      component: CheckoutScreen,
      exact: true,
    },
  ]

  return routes
}
