import { Button, Spin, Swift } from '@swiftctrl/swift-component-library'

import { useTranslation } from '../../hooks'

interface Props {
  loading: boolean
  onClick: () => void
}

export const CheckoutButton: Swift.FC<Props> = ({ loading, onClick }) => {
  const { t } = useTranslation()
  return loading ? (
    <Spin />
  ) : (
    <Button onClick={onClick} type="primary" size="large">
      {t('checkout_confirm_button')}
    </Button>
  )
}
