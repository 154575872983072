import { Swift } from '@swiftctrl/swift-component-library'
import { config } from '../utils'

interface Props {
  address?: string
}

const {
  googleMap: { apiKey },
} = config

export const GoogleMap: Swift.FC<Props> = ({ address }) =>
  address ? (
    <iframe
      title="Google Map"
      width="100%"
      height="343"
      frameBorder="0"
      style={{ border: 0 }}
      aria-hidden="false"
      src={generateGoogleMapsLink(address)}
    />
  ) : null

const generateGoogleMapsLink = (address: string) => {
  const formatAddress = address.split(' ').join('+')
  return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${formatAddress}`
}
