import { ScheduleTypes } from '@swiftctrl/api-client-react'
import styled from 'styled-components'
import { OpeningHoursDisplay } from '../../components'
import { useTranslation } from '../../hooks'

interface Props {
  schedule?: ScheduleTypes
}

export const BuildingOpeningHours = ({ schedule }: Props) => {
  const { t } = useTranslation()
  return (
    <StyledBuildingOpeningHours>
      <StyledOpeningHoursTitle>{t('room_details_amenities_title_hours')}</StyledOpeningHoursTitle>
      {schedule && <OpeningHoursDisplay schedule={schedule} />}
    </StyledBuildingOpeningHours>
  )
}

const StyledBuildingOpeningHours = styled.div`
  background-color: #f0f2f5;
  padding-top: 24px;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 36px;
  border-radius: 4px;
`

const StyledOpeningHoursTitle = styled.h2`
  margin-bottom: 16px !important;
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  color: #8c8c8c;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
`
