import {
  ClockCircleOutlined,
  CloseOutlined,
  Col,
  MobilePicker,
  MobilePickerColumnItem,
  MobilePickerValue,
  Row,
  SwapRightOutlined,
  Swift,
  Typography,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

import { useBoolean, useStoreParams, useTranslation } from '../../../../hooks'

const timeOptions: Array<MobilePickerColumnItem[]> = [
  [
    { value: '0', label: '12:00 AM' },
    { value: '1', label: '1:00 AM' },
    { value: '2', label: '2:00 AM' },
    { value: '3', label: '3:00 AM' },
    { value: '4', label: '4:00 AM' },
    { value: '5', label: '5:00 AM' },
    { value: '6', label: '6:00 AM' },
    { value: '7', label: '7:00 AM' },
    { value: '8', label: '8:00 AM' },
    { value: '9', label: '9:00 AM' },
    { value: '10', label: '10:00 AM' },
    { value: '11', label: '11:00 AM' },
    { value: '12', label: '12:00 PM' },
    { value: '13', label: '1:00 PM' },
    { value: '14', label: '2:00 PM' },
    { value: '15', label: '3:00 PM' },
    { value: '16', label: '4:00 PM' },
    { value: '17', label: '5:00 PM' },
    { value: '18', label: '6:00 PM' },
    { value: '19', label: '7:00 PM' },
    { value: '20', label: '8:00 PM' },
    { value: '21', label: '9:00 PM' },
    { value: '22', label: '10:00 PM' },
    { value: '23', label: '11:00 PM' },
    { value: '24', label: '12:00 AM' },
  ],
]
interface Props {
  hideClearButton?: boolean
  onClear: () => void
  startTime: number
  endTime: number
  disablePastHours?: boolean
  keepDuration?: boolean
}

export const MobileTimePicker: Swift.FC<Props> = ({
  hideClearButton,
  onClear,
  startTime,
  endTime,
  disablePastHours,
  keepDuration,
}) => {
  const { t } = useTranslation()
  const [startTimePickerVisible, openStartTimePicker, closeStartTimePicker] = useBoolean()
  const [endTimePickerVisible, openEndTimePicker, closeEndTimePicker] = useBoolean()
  const { updateStoreParams } = useStoreParams()

  const timeArray = timeOptions[0]
  const currentHour = new Date().getHours()

  const handleStartTimeChange = (value: MobilePickerValue[]) => {
    const duration = endTime - startTime
    const selectedStartTime = Number(value[0])
    updateStoreParams('startTime', selectedStartTime)
    if (keepDuration) {
      const projectedEndTime = selectedStartTime + duration
      const newEndTime = projectedEndTime >= 24 ? 24 : projectedEndTime
      updateStoreParams('endTime', newEndTime)
    } else if (selectedStartTime > endTime) {
      updateStoreParams('startTime', selectedStartTime + 1)
    }
  }

  const getHoursDisplay = () => {
    const duration = endTime - startTime
    return `${duration}${t(duration > 1 ? 'booking_hours' : 'booking_hours_other')}`
  }

  const getTimeDisplay = (value: number, withDuration?: boolean) => {
    const selectedTime = timeArray.find((option) => value === Number(option.value))
    return withDuration
      ? `${selectedTime?.label} (${getHoursDisplay()})`
      : selectedTime?.label || ''
  }

  const getFormattedEndHours = () => [
    timeArray
      .filter((time, index) => {
        const isFirstMidnigh = index === 0
        const isPastStartTime = Number(time.value) < startTime + 1
        return !isFirstMidnigh && !isPastStartTime
      })
      .map((time) => {
        const duration = Number(time.value) - startTime
        const showDuration = duration !== 0
        if (!showDuration) {
          return time
        }
        const hoursDisplay = t(duration > 1 ? 'booking_hours' : 'booking_hours_other')
        return { ...time, label: `${time.label} (${duration} ${hoursDisplay})` }
      }),
  ]

  const getFormattedStartHours = () => {
    return [
      timeArray.filter((time) => {
        const isLastMidnight = Number(time.value) === 24
        const shouldDisablePastHour = disablePastHours && Number(time.value) < currentHour + 1
        return !(isLastMidnight || shouldDisablePastHour)
      }),
    ]
  }

  return (
    <StyledMobileTimePicker align="middle" justify="space-between">
      <StyledColumn flex="auto" onClick={openStartTimePicker}>
        <ClockCircleOutlined />
        <StyledTimeDisplay>{getTimeDisplay(startTime)}</StyledTimeDisplay>
      </StyledColumn>
      <StyledColumn onClick={openEndTimePicker} flex="auto">
        <SwapRightOutlined />
        <StyledTimeDisplay>{getTimeDisplay(endTime, true)}</StyledTimeDisplay>
      </StyledColumn>
      <Col onClick={!hideClearButton ? onClear : undefined}>
        <StyledClearButton $visible={!hideClearButton} />
      </Col>
      <MobilePicker
        visible={startTimePickerVisible}
        columns={getFormattedStartHours()}
        onClose={closeStartTimePicker}
        onConfirm={handleStartTimeChange}
        value={[`${startTime}`]}
      />
      <MobilePicker
        visible={endTimePickerVisible}
        columns={getFormattedEndHours()}
        onClose={closeEndTimePicker}
        onConfirm={(value) => updateStoreParams('endTime', Number(value[0]))}
        value={[`${endTime}`]}
      />
    </StyledMobileTimePicker>
  )
}

const StyledMobileTimePicker = styled(Row)`
  svg {
    font-size: 18px;
  }

  height: 40px;
  padding: 6.5px 11px;
  border: 1px solid #d9d9d9;
`

const StyledColumn = styled(Col)`
  display: flex;
  align-items: center;
`

const StyledClearButton = styled(CloseOutlined)<{ $visible: boolean }>`
  opacity: ${({ $visible }) => ($visible ? '0.35' : '0')};
  transition: opacity 0.2s;
  transform: translateY(2px);
`

const StyledTimeDisplay = styled(Typography.Text)`
  margin-left: ${({ theme }) => theme.spacing.xSmall};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
`
