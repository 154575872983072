import { Button, Modal, Swift, Typography } from '@swiftctrl/swift-component-library'
import styled, { useTheme } from 'styled-components'
import { CustomDrawer } from '../../components'
import { useBoolean, useTranslation } from '../../hooks'
import { Guidelines as GuidelinesContent } from '../../legal-texts'

export const Guidelines: Swift.FC = () => {
  const [showGuidelines, setShowGuidelines, setHideGuidelines] = useBoolean()
  const { isMobileView } = useTheme()
  const { t } = useTranslation()
  return (
    <StyledContainer>
      <div>
        <Typography.Text>{t('room_details_guidelines_text')}</Typography.Text>
      </div>
      <Button type="link" size="large" onClick={setShowGuidelines}>
        {t('room_details_guidelines_button')}
      </Button>
      {!isMobileView ? (
        <Modal
          title={t('room_details_modal_guidelines_title')}
          visible={showGuidelines}
          footer={null}
          onCancel={setHideGuidelines}
        >
          <GuidelinesContent />
        </Modal>
      ) : (
        <CustomDrawer
          destroyOnClose
          visible={showGuidelines}
          onClose={setHideGuidelines}
          title={t('room_details_modal_guidelines_title')}
        >
          <GuidelinesContent />
        </CustomDrawer>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  ${({ theme }) => ({
    backgroundColor: '#e6f7ff',
    padding: 24,
    '.ant-typography': {
      fontSize: theme.designTokens.fonts.sizes['14px'].fontSize,
    },
    button: {
      padding: 0,
      height: 'max-content',
      marginTop: theme.spacing.xSmall,
    },
  })}
`
