import { config } from '../config'
import { SessionSigningStatusTypes } from './sessionSigningStatusTypes'

const {
  appConstants: { SIGNING_LOCAL_STORAGE_KEY },
} = config

export const getSessionSigningStatus = () => {
  const signingStatus = localStorage.getItem(SIGNING_LOCAL_STORAGE_KEY) || 'INIT'
  return signingStatus as SessionSigningStatusTypes
}
