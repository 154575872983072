import {
  BuildingBrowseTypes,
  BuildingTypes,
  ModifiedBrowseRoomFilterConfig,
  RoomsTypes,
  useBuilding,
  useRoom,
} from '@swiftctrl/api-client-react'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { Col, InfoCircleFilled, Result, Row, Swift } from '@swiftctrl/swift-component-library'
import { Button } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { CenteredPreLoader } from '../../../components'
import { useHandleUrlParams, useTranslation } from '../../../hooks'
import { config } from '../../../utils'
import { RoomListDetails } from '../room-list-detail'
import { RoomSkeleton } from '../room-skeleton'

const {
  appConstants: { numberOfSkeletons },
  SWIFT_ROOT_ENTITY_ID,
} = config

interface Props {
  openFilter: () => void
  openSearch: () => void
}

const browse_page_limit = 12

export const ListView: Swift.FC<Props> = ({ openFilter, openSearch }) => {
  const { currentParams, updateParams } = useHandleUrlParams()
  const qBuilding = getQueryHandler<BuildingBrowseTypes>()
  const qRoom = getQueryHandler<ModifiedBrowseRoomFilterConfig>()
  const { t } = useTranslation()

  const startTime: string = `${currentParams.date}T${currentParams.startTime}:00:00`
  const endTime: string = `${currentParams.date}T${currentParams.endTime}:00:00`

  const { data: roomList } = useRoom()
    .browse({
      baseId: SWIFT_ROOT_ENTITY_ID,
      browseOptions: {
        queryKey: 'room-browse',
      },
      limit: 1000,
      filters: [
        qRoom(
          'WHERE',
          'city',
          'EQ',
          currentParams.city || currentParams.state || currentParams.country
            ? `${currentParams.city},${currentParams.state},${currentParams.country}`
            : undefined,
        ),
        qRoom('AND', 'max_capacity', 'GTE', currentParams.guests),
        qRoom('AND', 'min_capacity', 'LTE', currentParams.guests),
        qRoom('AND', 'availabilities', 'GTE', startTime),
        qRoom('AND', 'availabilities', 'LTE', endTime),
        qRoom('AND', 'amenities', 'EQ', currentParams.amenities),
      ],
    })
    .query({
      staleTime: 0,
    })

  const {
    data: roomsData,
    isLoading: isLoadingRooms,
    error,
    fetchNextPage,
    hasNextPage,
  } = useRoom()
    .browse({
      baseId: SWIFT_ROOT_ENTITY_ID,
      browseOptions: {
        queryKey: 'room-browse',
      },
      select: [
        'amenities',
        'entity_name',
        'entity_description',
        'images',
        'max_capacity',
        'min_capacity',
        'overseer_name',
        'room_id',
        'price',
      ],
      limit: browse_page_limit,
      filters: [
        qRoom(
          'WHERE',
          'city',
          'EQ',
          currentParams.city || currentParams.state || currentParams.country
            ? `${currentParams.city},${currentParams.state},${currentParams.country}`
            : undefined,
        ),
        qRoom('AND', 'max_capacity', 'GTE', currentParams.guests),
        qRoom('AND', 'min_capacity', 'LTE', currentParams.guests),
        qRoom('AND', 'availabilities', 'GTE', startTime),
        qRoom('AND', 'availabilities', 'LTE', endTime),
        qRoom('AND', 'amenities', 'EQ', currentParams.amenities),
      ],
    })
    .infiniteQuery({
      getNextPageParam: (lastPage, allPages) => {
        const totalItems = allPages.reduce((acc, curr: any) => acc + curr.data.length, 0)
        const nextPage = allPages.length
        return (lastPage as any).data.length === browse_page_limit &&
          !(totalItems === roomList?.data.length)
          ? nextPage * browse_page_limit
          : undefined
      },
      retry: false,
    })

  const rooms = roomsData?.pages.reduce((acc, curr) => [...acc, ...curr.data], [] as RoomsTypes[])
  const buildingsIds = getBuildingsIdsFromRooms(rooms)

  const { data: buildingsResponse, isLoading: isLoadingBuildings } = useBuilding()
    .browse({
      browseOptions: { queryKey: 'building-browse' },
      baseId: SWIFT_ROOT_ENTITY_ID,
      filters: [qBuilding('WHERE', 'building_id', 'ANY', buildingsIds.join(','))],
      select: ['overseer_name', 'building_id'],
    })
    .query({ enabled: Boolean(rooms?.length) })

  const buildings = buildingsResponse?.data
  const isLoading = isLoadingRooms || isLoadingBuildings

  const roomsList = getRoomList(rooms, buildings)
  const fetchMoreRooms = () => {
    updateParams({ page: currentParams.page! + 1 })
    fetchNextPage!()
  }

  if (error) return <Result title={t('fallback_500_title')} />
  if (isLoadingRooms) return <CenteredPreLoader />

  if (roomsList.length === 0 || (!roomsList.length && !isLoading)) {
    return (
      <StyledSearchEmptyContainer>
        <Result
          icon={<InfoCircleFilled />}
          title={t('search_bar_room_list_no_results')}
          subTitle={
            currentParams.amenities
              ? t('search_bar_room_list_adjust_filter')
              : t('search_bar_room_list_adjust_date_time')
          }
          extra={
            <Button type="primary" onClick={currentParams.amenities ? openFilter : openSearch}>
              {currentParams.amenities
                ? t('search_apply_filter_button')
                : t('search_adjust_date_time_button')}
            </Button>
          }
        />
      </StyledSearchEmptyContainer>
    )
  }

  return (
    <StyledInfiniteScrollContainer>
      <InfiniteScroll
        dataLength={roomsList.length}
        next={fetchMoreRooms}
        hasMore={hasNextPage || false}
        loader={
          <Row>
            {Array.from({ length: numberOfSkeletons }).map((_, index) => (
              <Col xs={24} sm={24} md={12} lg={12} xl={8} key={index}>
                <RoomSkeleton key={index} />
              </Col>
            ))}
          </Row>
        }
        scrollThreshold={0.5}
      >
        <Row>
          {roomsList.map((room, i) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} key={room.room_id}>
              <RoomListDetails room={room} />
            </Col>
          ))}
        </Row>
      </InfiniteScroll>
    </StyledInfiniteScrollContainer>
  )
}

const getBuildingsIdsFromRooms = (rooms?: RoomsTypes[]) => {
  if (!rooms || !rooms.length) {
    return []
  }
  const buildingsIds = new Set(rooms.map((room) => room.overseer_id))
  return Array.from(buildingsIds)
}

const getRoomList = (rooms?: RoomsTypes[], buildings?: BuildingTypes[]) => {
  if (!rooms?.length) {
    return []
  }
  const roomsArray: (RoomsTypes & { organizationName: string })[] = []
  rooms.forEach((room) => {
    const organizationName = buildings?.find(
      (building) => room.overseer_id === building.building_id,
    )?.overseer_name
    roomsArray.push({ ...room, organizationName: organizationName || '' })
  })

  return roomsArray
}

const StyledInfiniteScrollContainer = styled.div`
  padding-top: ${({ theme }) => (theme.isMobileView ? '0px' : theme.spacing.medium)};
`
const StyledSearchEmptyContainer = styled.div`
  .ant-result {
    padding: ${({ theme }) => (theme.isMobileView ? theme.spacing.small : theme.spacing.medium)};
    ${({ theme }) => theme.spacing.medium};
  }
`
