import { useAuth } from '@swiftctrl/api-client-react'
import { goToAccountsApp, setSessionSigningStatus } from '../../utils'

import { useStoreCurrentUser } from '../store'

export const useLogOut = () => {
  const { updateStoreCurrentUser } = useStoreCurrentUser()
  return useAuth().signOut({
    onSuccess: () => {
      updateStoreCurrentUser(null)
      setSessionSigningStatus('UNSIGNED', true)
      goToAccountsApp('signin')
    },
  })
}
