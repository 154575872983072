import { RoomsTypes as RoomsTypesSDK } from '@swiftctrl/api-client-react'
export interface TemporaryRoomTypeExtension {
  city: string
}

type RoomsTypes = RoomsTypesSDK & TemporaryRoomTypeExtension

export const rooms: RoomsTypes[] = [
  {
    room_id: '1',
    floor: '5',
    min_capacity: 1,
    max_capacity: 10,
    amenities: [
      'large_display',
      'white_board',
      'projector',
      'conference_phone',
      'tea_and_coffee',
      'regular_phone',
      'drinks',
    ],
    price: '40',
    images: [
      'https://swiftconnectnetwork.spaces.nexudus.com/en/publicresources/getimage/1414914579',
    ],
    entity_name: 'Big office',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    overseer_name: 'Swift Building 2',
    overseer_id: '2',
    created_at: '',
    updated_at: '',
    city: 'Montreal',
  },
  {
    room_id: '2',
    floor: '5',
    min_capacity: 1,
    max_capacity: 7,
    amenities: [
      'large_display',
      'white_board',
      'projector',
      'conference_phone',
      'tea_and_coffee',
      'regular_phone',
      'drinks',
    ],
    price: '27',
    images: [
      'https://swiftconnectnetwork.spaces.nexudus.com/en/publicresources/getimage/1414914693',
    ],
    entity_name: 'Small office',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    overseer_name: 'Swift Building 3',
    overseer_id: '3',
    created_at: '',
    updated_at: '',
    city: 'San Francisco',
  },
  {
    room_id: '3',
    floor: '5',
    min_capacity: 1,
    max_capacity: 12,
    amenities: [
      'large_display',
      'white_board',
      'projector',
      'conference_phone',
      'tea_and_coffee',
      'regular_phone',
      'drinks',
    ],
    price: '30',
    images: [
      'https://mobile-cuisine.com/wp-content/uploads/2021/08/conference-room-768441_1920.jpg',
    ],
    entity_name: 'Meeting Room South',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    overseer_name: 'Swift Building',
    overseer_id: '1',
    created_at: '',
    updated_at: '',
    city: 'Stamford',
  },
  {
    room_id: '4',
    floor: '5',
    min_capacity: 1,
    max_capacity: 20,
    amenities: [
      'large_display',
      'white_board',
      'projector',
      'conference_phone',
      'tea_and_coffee',
      'regular_phone',
      'drinks',
    ],
    price: '40',
    images: [
      'https://work-clockwise.com/wp-content/uploads/2022/05/Clockwise_TheGeneratorBuilding_Bristol_MeetingRoomGM2GM3.jpg',
    ],
    entity_name: 'Meeting Room East',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    overseer_name: 'Swift Building',
    overseer_id: '1',
    created_at: '',
    updated_at: '',
    city: 'Stamford',
  },
  {
    room_id: '5',
    floor: '5',
    min_capacity: 1,
    max_capacity: 10,
    amenities: [
      'large_display',
      'white_board',
      'projector',
      'conference_phone',
      'tea_and_coffee',
      'regular_phone',
      'drinks',
    ],
    price: '25',
    images: [
      'https://www.aucklandnz.com/sites/build_auckland/files/styles/carousel_banner/public/2021-05/hsbc_project_room.png',
    ],
    entity_name: 'Room Laurier',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    overseer_name: 'Swift Building 2',
    overseer_id: '2',
    created_at: '',
    updated_at: '',
    city: 'Montreal',
  },
]
