import { AmenitiesTypes } from '@swiftctrl/api-client-react'

export const amenities: AmenitiesTypes[] = [
  { amenity: 'large_display', amenity_id: '1' },
  { amenity: 'white_board', amenity_id: '2' },
  { amenity: 'projector', amenity_id: '3' },
  { amenity: 'conference_phone', amenity_id: '4' },
  { amenity: 'tea_and_coffee', amenity_id: '5' },
  { amenity: 'regular_phone', amenity_id: '6' },
  { amenity: 'drinks', amenity_id: '7' },
]
