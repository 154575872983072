import { Card, Col, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import { RouterProps, useHistory } from 'react-router-dom'
import styled, { CSSObject } from 'styled-components'

import { Reservation } from '../../models'
import { paths } from '../../utils'
import { ReservationDetailsInfos } from './ReservationDetailsInfos'
import { TotalWithTag } from './TotalWithTag'

interface Props {
  reservation: Reservation
}

const { Admin, ReservationDetails } = paths

export const ReservationCard: Swift.FC<Props> = ({ reservation }) => {
  const { room, payment, booking, contactInformation } = reservation
  const history: RouterProps['history'] = useHistory()
  const goToRoom = () => history.push(`/${Admin}/${ReservationDetails}/${reservation.booking.id}`)
  return (
    <StyledReservationCard onClick={goToRoom}>
      <Row align="middle" justify="space-between" gutter={16}>
        <StyledImageAndAddressColumn span={8}>
          <Row gutter={24}>
            <Col>
              <img src={`${room.image}`} alt="roomImage" />
            </Col>
            <Col>
              <Typography.Title level={5}>{room.name}</Typography.Title>
              <address>{room.location.name}</address>
              <span>{room.location.name}</span>
            </Col>
          </Row>
        </StyledImageAndAddressColumn>
        <Col span={8}>
          <Row>
            <Col sm={1} xs={0}></Col>
            <Col sm={23} xs={24}>
              <ReservationDetailsInfos contactInformation={contactInformation} booking={booking} />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row align="top" justify="end">
            <Col>
              <StyledEmptyDiv />
              <TotalWithTag inline payment={payment} />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledReservationCard>
  )
}

const StyledReservationCard = styled(Card)`
  cursor: pointer;
  img {
    height: 80px;
    width: 80px;
  }
`
const StyledEmptyDiv = styled.div`
  height: 30px;
`

const StyledImageAndAddressColumn = styled(Col)`
  ${({ theme }): CSSObject => ({
    h5: {
      marginBottom: theme.spacing.small,
    },
    span: {
      fontSize: 12,
      color: '#8C8C8C',
    },
    address: {
      marginBottom: 0,
    },
    borderRight: '1px solid #F0F0F0',
  })}
`
