import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { RestApiParams } from '../../models'

interface UpdateParamsConfig {
  replace: boolean
}
export interface UseHandlerUrlTypes {
  updateParams: (params: RestApiParams, config?: UpdateParamsConfig) => void
  cleanParams: (paramsToDelete: Array<keyof RestApiParams>) => void
  currentParams: RestApiParams
}

export const useHandleUrlParams = (): UseHandlerUrlTypes => {
  const history: RouteComponentProps['history'] = useHistory()
  const { stringify, parse } = queryString

  const parseCriteria = (criteria: string) => parse(criteria, { parseNumbers: true, sort: false })

  const [criteria, setCriteria] = useState<Object>(parseCriteria(history.location.search))

  useEffect(() => {
    if (!isEqual(criteria, parseCriteria(window.location.search))) {
      setCriteria(parseCriteria(history.location.search))
    }
  }, [history.location.search])

  const updateParams = (
    newParams: RestApiParams,
    config: UpdateParamsConfig = { replace: true },
  ): void => {
    const newCriteria: RestApiParams = { ...criteria, ...newParams }
    if (isEmpty(newParams) || isEqual(newCriteria, criteria)) {
      return
    }

    const search: string = stringify(newCriteria, { skipEmptyString: true })
    if (config?.replace) {
      history.replace({ search })
    } else {
      history.push({ search })
    }
  }

  const cleanParams = (paramsToDelete?: Array<keyof RestApiParams>): void => {
    let newCriteria = {}
    if (paramsToDelete && paramsToDelete.length > 0) {
      newCriteria = { ...omit(criteria, paramsToDelete) }
    }
    history.push({ search: stringify(newCriteria) })
  }

  return {
    updateParams,
    cleanParams,
    currentParams: criteria,
  }
}
