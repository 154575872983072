export const reservations_en = {
  reservations_my_reservations: 'My reservations',
  reservations_upcoming: 'Upcoming',
  reservations_past: 'Past',
  reservations_list_item_reservation_details: 'Reservation details',
  reservations_list_item_hours_one: 'hour',
  reservations_list_item_hours_other: 'hours',
  reservations_list_item_total: 'Total',
  reservations_list_item_details_btn: 'Details',
  reservations_list_item_cancel_btn: 'Cancel',
  reservations_empty_list_message_upcoming:
    "When you're ready to start planning your next trip, we're here to help.",
  reservations_empty_list_message_past:
    "You don't have any past trips yet—but when you do, you'll find them here.",
  reservations_empty_list_explore_button: 'Explore SpaceCloud',
  reservations_cancel_title: 'Cancel reservation',
  reservations_cancel_no_btn: 'No',
  reservations_cancel_btn: 'Cancel reservation',
  reservations_cancel_success: 'Your reservation has been canceled',
  reservation_mobile_cancel_success: 'Canceled',
}
