export const dates_en = {
  days_of_the_week_0: 'Sunday',
  days_of_the_week_1: 'Monday',
  days_of_the_week_2: 'Tuesday',
  days_of_the_week_3: 'Wednesday',
  days_of_the_week_4: 'Thursday',
  days_of_the_week_5: 'Friday',
  days_of_the_week_6: 'Saturday',
  days_of_the_week_mobile_0: 'Sun',
  days_of_the_week_mobile_1: 'Mon',
  days_of_the_week_mobile_2: 'Tue',
  days_of_the_week_mobile_3: 'Wed',
  days_of_the_week_mobile_4: 'Thu',
  days_of_the_week_mobile_5: 'Fri',
  days_of_the_week_mobile_6: 'Sat',
  month_0: 'January',
  month_1: 'February',
  month_2: 'March',
  month_3: 'April',
  month_4: 'May',
  month_5: 'June',
  month_6: 'July',
  month_7: 'August',
  month_8: 'September',
  month_9: 'October',
  month_10: 'November',
  month_11: 'December',
}
