import styled, { CSSObject } from 'styled-components'
import { TagValue } from './ReservationStatusTag'
import { Payment } from '../../models'
import { ReservationStatusTag } from './ReservationStatusTag'
import { Swift } from '@swiftctrl/swift-component-library'

interface Props {
  payment: Payment
  inline?: boolean
}

export const TotalWithTag: Swift.FC<Props> = ({ payment, inline }) => {
  if (inline) {
    return (
      <StyledInlineTotalWithTag>
        <span>{`Total (${payment.currency}) $${payment.total}`}</span>
        <ReservationStatusTag value={payment.status as TagValue} />
      </StyledInlineTotalWithTag>
    )
  }
  return (
    <StyledTotalWithTag>
      <ReservationStatusTag value={payment.status as TagValue} />
      <span>{`Total (${payment.currency}) $${payment.total}`}</span>
    </StyledTotalWithTag>
  )
}

const StyledInlineTotalWithTag = styled.div`
  ${({ theme }): CSSObject => ({
    'span:nth-of-type(1)': {
      fontWeight: 600,
      marginRight: theme.spacing.small,
    },
    'span:nth-of-type(2)': {
      marginRight: 0,
    },
    display: 'flex',
    height: 'max-content',
    alignItems: 'center',
  })}
`

const StyledTotalWithTag = styled.div`
  ${({ theme }): CSSObject => ({
    'span:nth-of-type(2)': {
      fontWeight: 600,
    },
    'span:nth-of-type(1)': {
      marginBottom: 4,
      marginRight: 0,
    },
    display: 'flex',
    height: 'max-content',
    flexDirection: 'column',
    alignItems: 'flex-end',
  })}
`
