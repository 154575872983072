import { CheckCircleOutlined, Swift, Typography } from '@swiftctrl/swift-component-library'
import { format } from 'date-fns'
import styled, { CSSObject } from 'styled-components'
import { useTranslation } from '../../hooks'
import { BookingReservation, ContactInformation } from '../../models'
import { config } from '../../utils'

interface Props {
  contactInformation: ContactInformation
  booking: BookingReservation
  noMargin?: boolean
}
export const ReservationDetailsInfos: Swift.FC<Props> = ({
  contactInformation,
  booking,
  noMargin,
}) => {
  const { Text } = Typography
  const {
    appConstants: { timeRangeFieldFormat, dateDisplayFormat },
  } = config
  const { t } = useTranslation()
  const startDate = new Date(booking.startAt)
  const endDate = new Date(booking.endAt)
  const duration = endDate.getHours() - startDate.getHours()
  const durationHours = duration > 1 ? t('booking_hours') : t('booking_hours_other')
  const date = format(new Date(booking.startAt), dateDisplayFormat)
  const startHour = format(startDate, timeRangeFieldFormat)
  const endHour = format(endDate, timeRangeFieldFormat)
  return (
    <StyledReservationDetailsInfos>
      <StyledContactInfo noMargin={noMargin}>
        <CheckCircleOutlined />
        <Text>{contactInformation.displayName}</Text> -{' '}
        <StyledEmailAddress>
          {contactInformation.billing_address || 'justfordemo@email.com'}
        </StyledEmailAddress>
      </StyledContactInfo>
      <div>
        {`${date}`} &#9702; {`${startHour} - ${endHour} (${duration}${durationHours})`}
      </div>
    </StyledReservationDetailsInfos>
  )
}

const StyledReservationDetailsInfos = styled.div`
  height: max-content;
`

const StyledEmailAddress = styled.span`
  color: #8c8c8c;
`

const StyledContactInfo = styled.div<{ noMargin?: boolean }>`
  ${({ theme }): CSSObject => ({
    'span:nth-of-type(3)': {
      fontSize: 12,
    },
    svg: {
      marginRight: theme.spacing.small,
      color: '#52c41a',
      height: theme.icon.sizes.small,
      width: theme.icon.sizes.small,
    },
  })}
  margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '8px')};
`
