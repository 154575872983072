import { AddressTypes, ProfilesTypes, useProfile } from '@swiftctrl/api-client-react'
import { Col, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { AddressDisplay } from '../../../components'
import { useStoreCurrentUser, useTranslation } from '../../../hooks'

interface Props {
  profile_id: string
}

export const ContactInformations: Swift.FC<Props> = ({ profile_id }) => {
  const { t } = useTranslation()
  const { currentUser } = useStoreCurrentUser()

  const { data } = useProfile()
    .at(profile_id)
    .read({ queryKey: ['profile-read'] })

  const profile = data?.data as ProfilesTypes
  const phoneNumber = profile?.phones[0]

  return (
    <StyledContactInformation>
      <StyledTitleRow>
        <Col span={24}>
          <Typography.Text type="secondary">
            {t('reservation_success_contact_info_label')}
          </Typography.Text>
        </Col>
      </StyledTitleRow>
      <StyledRow>
        <Col span={24}>
          <Typography.Title level={5}>{t('reservation_success_name_label')}</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>{profile?.display_name}</Typography.Text>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={24}>
          <Typography.Title level={5}>{t('reservation_success_email_label')}</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>{currentUser?.emails[0].email}</Typography.Text>
        </Col>
      </StyledRow>
      {phoneNumber?.country_code && phoneNumber?.number && (
        <StyledRow>
          <Col span={24}>
            <Typography.Title level={5}>
              {t('reservation_success_phone_number_label')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Text>{`+${phoneNumber.country_code} ${phoneNumber.number}`}</Typography.Text>
          </Col>
        </StyledRow>
      )}
      <Row>
        <Col span={24}>
          <Typography.Title level={5}>{t('reservation_success_address_label')}</Typography.Title>
        </Col>
        <Col span={24}>
          <AddressDisplay address={profile?.addresses[0] as unknown as AddressTypes} />
        </Col>
      </Row>
    </StyledContactInformation>
  )
}

const StyledContactInformation = styled.div`
  h5 {
    margin: 0px;
  }
`
const StyledTitleRow = styled(Row)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
