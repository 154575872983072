import { Carousel, Swift } from '@swiftctrl/swift-component-library'
import styled, { useTheme } from 'styled-components'
import ImagePlaceHolder from '../assets/image-place-holder.png'

interface Props {
  images?: string[]
}

export const ImageCarousel: Swift.FC<Props> = ({ images }) => {
  const { isMobileView } = useTheme()
  const height: string = isMobileView ? '180px' : '328px'

  const slicedImages: string[] | undefined = images?.slice(0, 10)

  return (
    <StyledCarousel $height={height} dotPosition="bottom">
      {slicedImages ? (
        slicedImages.map((image: string, index: number) => (
          <StyledCoverImage $image={image} key={index} />
        ))
      ) : (
        <StyledCoverImage $image={ImagePlaceHolder} />
      )}
    </StyledCarousel>
  )
}

const StyledCarousel = styled(Carousel)<{ $height: string }>`
  .slick-track {
    height: ${({ $height }) => $height};
  }
  .slick-slide > div {
    height: 100%;
  }
`

export const StyledCoverImage = styled.div<{ $image: string }>`
  height: 100%;
  width: 100%;
  padding-top: 50%;
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-position: center;
  border-radius: 2px;
`
