import {
  Affix,
  Badge,
  Button,
  Col,
  FilterOutlined,
  Input,
  Row,
  Swift,
  Tag,
  useBreakpoint,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import { Popover } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { mixPanelTrack } from '../../../analytics'

import { useFeature, useHandleUrlParams, useTranslation } from '../../../hooks'
import { differenceInDayFromToday, displaySearchParams, getTranslationLabel } from '../../../utils'
import { FiltersModal } from '../filters-modal'
import { SmartSearch } from './smart-search'

const { Search } = Input

interface Props {
  isFilterModalOpen: boolean
  openFilterModal: () => void
  closeFilterModal: () => void
  isSearchModalOpen: boolean
  openSearchModal: () => void
  closeSearchModal: () => void
}

export const SearchBar: Swift.FC<Props> = ({
  isFilterModalOpen,
  openFilterModal,
  closeFilterModal,
  isSearchModalOpen,
  openSearchModal,
  closeSearchModal,
}) => {
  const { currentParams, updateParams } = useHandleUrlParams()
  const { t } = useTranslation()
  const [filterNumber, setFilterNumber] = useState<number>(0)
  const [showShadow, setShowShadow] = useState<boolean | undefined>(false)
  const screen = useBreakpoint()
  const isAmenitiesFilterEnabled: boolean = useFeature('sc_amenitiesfilter')

  const [numberGuests, setNumberGuests] = useState<string | undefined>(String(currentParams.guests))
  const [amenitiesFilters, setAmenitiesFilters] = useState<string[]>(
    currentParams.amenities ? currentParams.amenities.split(',') : [],
  )
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>(amenitiesFilters)
  const [numberGuestsDisplay, setNumberGuestsDisplay] = useState<string>('')
  const defaultValue: string = displaySearchParams(currentParams)

  const applyFilters = (amenities: string[]) => {
    updateParams({ amenities: amenities.join(',') })
    setAmenitiesFilters(amenities)
    mixPanelTrack('Search Spaces (Filter)', {
      'Days From Today': differenceInDayFromToday(currentParams.date),
      'Guest Count': Number(currentParams.guests),
    })
    closeFilterModal()
  }

  const openFilters = () => {
    setSelectedAmenities(amenitiesFilters)
    openFilterModal()
  }

  const removeTag = (removedTag: string) => {
    const newAmenitiesFilters = amenitiesFilters
    const index = newAmenitiesFilters.indexOf(removedTag)

    if (index > -1) {
      newAmenitiesFilters.splice(index, 1)
    }

    setAmenitiesFilters(newAmenitiesFilters)
    getFilterNumber()
    applyFilters(newAmenitiesFilters)
  }

  const getFilterNumber = useCallback(() => {
    if (amenitiesFilters) {
      setFilterNumber(amenitiesFilters.length)
    }
  }, [amenitiesFilters])

  useEffect(() => {
    getFilterNumber()
  }, [amenitiesFilters, getFilterNumber])

  useEffect(() => {
    if (numberGuests !== null) setNumberGuestsDisplay(numberGuests + '')
  }, [numberGuestsDisplay, numberGuests])

  useEffect(() => {
    setNumberGuests(String(currentParams.guests))
    setAmenitiesFilters(currentParams.amenities ? currentParams.amenities.split(',') : [])
  }, [currentParams])

  return (
    <Affix onChange={setShowShadow}>
      <MainSearchContainer $shadow={showShadow}>
        <SearchBarContainer $shadow={showShadow} $visibleFilter={amenitiesFilters.length > 0}>
          <Row>
            <Col flex={screen.md ? '422px' : undefined} xs={24} sm={24}>
              <StyledPopover
                getPopupContainer={(node) => node}
                placement="top"
                visible={isSearchModalOpen}
                onVisibleChange={isSearchModalOpen ? closeSearchModal : openSearchModal}
                trigger="click"
                arrowContent={false}
                autoAdjustOverflow
                content={
                  <SmartSearch
                    closeSmartSearch={closeSearchModal}
                    showSmartSearch={isSearchModalOpen}
                  />
                }
              >
                <StyledSearch
                  value={defaultValue}
                  placeholder={t('search_bar_where_are_you_going')}
                  size="large"
                />
              </StyledPopover>
              <StyledNumberGuestsIcon
                $invisible={numberGuestsDisplay === '' || numberGuestsDisplay === '0'}
              >
                <UserOutlined />
                <StyledNumberGuests>
                  {Number(numberGuestsDisplay) >= 10 ? '10+' : numberGuestsDisplay}
                </StyledNumberGuests>
              </StyledNumberGuestsIcon>
            </Col>
            {isAmenitiesFilterEnabled && (
              <Col flex={screen.md ? '115px' : undefined}>
                <StyledBadge size="small" count={filterNumber} color="#BFBFBF">
                  <StyledFilterButton
                    type="link"
                    icon={<FilterOutlined />}
                    size="large"
                    onClick={openFilters}
                  >
                    {t('search_filter_button')}
                  </StyledFilterButton>
                </StyledBadge>
              </Col>
            )}
          </Row>

          {amenitiesFilters.length > 0 && (
            <SearchTagContainer>
              <Col span={24}>
                {amenitiesFilters.map((amenity) => (
                  <Tag key={amenity} closable onClose={() => removeTag(amenity)}>
                    {t(getTranslationLabel('search_amenities_', amenity))}
                  </Tag>
                ))}
              </Col>
            </SearchTagContainer>
          )}

          <FiltersModal
            showFilters={isFilterModalOpen}
            selectedAmenities={selectedAmenities}
            setSelectedAmenities={setSelectedAmenities}
            closeFilters={closeFilterModal}
            applyFilters={applyFilters}
          />
        </SearchBarContainer>
      </MainSearchContainer>
    </Affix>
  )
}

const MainSearchContainer = styled.div<{ $shadow?: boolean }>`
  box-shadow: ${({ $shadow }) =>
    $shadow
      ? ` 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12)`
      : 'none'};
`

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0px;
    transform: translateY(-24px) translateX(1px);
  }
`

const SearchBarContainer = styled.div<{ $shadow?: boolean; $visibleFilter?: boolean }>`
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};
  width: 95%;
  max-width: 1440px;
  margin: auto;
  background-color: #fff;
  padding-top: ${({ theme }) => theme.spacing.large};
  padding-bottom: ${({ theme, $shadow, $visibleFilter }) =>
    $shadow
      ? $visibleFilter
        ? 0
        : theme.spacing.medium
      : !$visibleFilter
      ? theme.spacing.medium
      : 0};
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 0;
  }
  .ant-input-group > .ant-input:first-child {
    border-right: none;
  }
`

const SearchTagContainer = styled(Row)<{ $shadow?: boolean; $visibleFilter?: boolean }>`
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  .ant-tag {
    margin-right: ${({ theme }) => theme.spacing.medium};
  }
`
const StyledSearch = styled(Search)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  width: 508px;
  @media only screen and (max-width: 767px) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
    width: 100%;
  } ;
`
const StyledNumberGuests = styled.span`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  margin-left: ${({ theme }) => theme.spacing.xSmall};
`
const StyledFilterButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  color: #000000;

  :focus {
    color: #000000;
  }

  :hover {
    color: #40a9ff;
  }
  @media only screen and (max-width: 767px) {
    padding-left: ${({ theme }) => theme.spacing.none};
    margin-left: ${({ theme }) => theme.spacing.none};
  } ;
`
const StyledBadge = styled(Badge)`
  .ant-badge-count {
    transform: translate(22%, 0%);
    width: 16px;
    height: 20px;
    line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
  }
  .ant-badge-multiple-words {
    padding: ${({ theme }) => theme.spacing.none} ${({ theme }) => theme.spacing.xSmall};
    width: 22px;
  }
`

const StyledNumberGuestsIcon = styled.div<{ $invisible?: boolean }>`
  z-index: 200;
  font-size: 18px;
  right: 56px;
  top: 0.45em;
  visibility: ${({ $invisible }) => ($invisible ? 'hidden' : 'visible')};
  display: ${({ $invisible }) => ($invisible ? 'none' : 'default')};
  position: ${({ $invisible }) => ($invisible ? 'default' : 'absolute')};
`
