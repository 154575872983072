import { combineReducers } from '@reduxjs/toolkit'

import { currentUserReducer, paramsReducer, reservationReducer, routeReducer } from './slices'

const reducers = {
  currentUser: currentUserReducer,
  params: paramsReducer,
  reservation: reservationReducer,
  route: routeReducer,
}

export const rootReducer = combineReducers(reducers)
