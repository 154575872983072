import { BuildingTypes } from '@swiftctrl/api-client-react'
import { Col, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AddressDisplay } from '../../components'
import { useFeature, useStoreRoute } from '../../hooks'
import { paths } from '../../utils'

const { Building } = paths

interface Props {
  building?: Partial<BuildingTypes> | undefined
  tenantId: string
  roomId: string
}

export const OrganizationAndAddress: Swift.FC<Props> = ({ building, tenantId, roomId }) => {
  const { currentStoreRoute } = useStoreRoute()
  const isBuildingFeatureEnable: boolean = useFeature('sc_buildings')
  return (
    <Row>
      <Col span={24}>
        {isBuildingFeatureEnable ? (
          <StyledLink
            to={{
              pathname: `/${Building}/${building?.building_id}`,
              //@ts-ignore - this is a hack to get the last location to work since library doesn't support custom state value through typescript
              state: { tabAddress: currentStoreRoute?.state?.tabAddress, tenantId, roomId },
            }}
          >
            {building?.entity_name} | {building?.overseer_name}
          </StyledLink>
        ) : (
          <Typography.Text>
            {building?.entity_name} | {building?.overseer_name}
          </Typography.Text>
        )}
      </Col>
      <Col span={24}>
        <AddressDisplay address={building?.addresses![0]} />
      </Col>
    </Row>
  )
}

const StyledLink = styled(Link)`
  color: #262626;

  &:hover {
    color: #262626;
  }
`
