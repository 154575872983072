export const admin_room_reservations_tab_filter_en = {
  tab_search_filter_button_label: 'Filter',
  tab_search_filter_footer_clear_all_button_label: 'Clear all',
  tab_search_filter_footer_cancel_button_label: 'Cancel',
  tab_search_filter_footer_show_result_button_label: 'Show {{count}} results',
  tab_search_filter_form_label_dates: 'Dates',
  tab_search_filter_form_label_organizations: 'Organizations, buildings & rooms',
  tab_search_filter_form_label_organization: 'Buildings & rooms',
  tab_search_filter_form_label_reservation_status: 'Reservation Status',
  tab_search_filter_form_label_payment_status: 'Payment Status',
  tab_search_filter_form_reservation_status_upcoming_checkbox_label: 'Upcoming',
  tab_search_filter_form_reservation_status_cancelled_checkbox_label: 'Cancelled',
  tab_search_filter_form_reservation_status_completed_checkbox_label: 'Completed',
  tab_search_filter_form_payment_status_paid_checkbox_label: 'Paid',
  tab_search_filter_form_payment_status_refunded_checkbox_label: 'Refunded',
  tab_search_filter_form_organization_select_placeholder: 'Select',
  tab_search_filter_form_start_date_placeholder: 'Start date',
  tab_search_filter_form_end_date_placeholder: 'End date',
}
