import { AddressTypes, BookingTypes, useBooking } from '@swiftctrl/api-client-react'
import {
  Button,
  Col,
  Divider,
  ExclamationCircleOutlined,
  Modal,
  ModalFuncProps,
  Row,
  Swift,
} from '@swiftctrl/swift-component-library'
import dayjs from 'dayjs'
import { useState } from 'react'
import { RouterProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ImagePlaceHolder from '../../assets/image-place-holder.png'
import { AddressDisplay } from '../../components'
import { useFeature, useHandleUrlParams, useStoreCurrentUser, useTranslation } from '../../hooks'
import { displayDate, paths } from '../../utils'

const { ReservationDetails } = paths

interface Props {
  type: string
  booking: BookingTypes & {
    buildingId?: string
    organizationName?: string
    addresses?: AddressTypes[]
    buildingName?: string
    images?: string[]
    roomId?: string
  }
  afterCancel: () => void
}

export const ReservationsItem: Swift.FC<Props> = ({ type, booking, afterCancel }) => {
  const { t } = useTranslation()
  const history: RouterProps['history'] = useHistory()
  const { currentUser } = useStoreCurrentUser()
  const isCancelBookingEnable: boolean = useFeature('sc_bookings_cancel')
  const { currentParams } = useHandleUrlParams()

  // const [bookingStatus, setBookingStatus] = useState(booking?.payment?.status)
  const [isCanceling, setCanceling] = useState(false)
  const { mutate: cancelBooking } = useBooking().delete()
  // const isPaymentFeatureEnable: boolean = useFeature('sc_paymentinformation')

  const bookingDuration =
    new Date(booking.end_time).getHours() - new Date(booking.start_time).getHours()

  const onCancel = (modalRef: {
    destroy: () => void
    update: (
      configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps),
    ) => void
  }) => {
    modalRef.update((prevConfig: ModalFuncProps) => ({
      ...prevConfig,
      okButtonProps: { loading: true, type: 'primary', danger: true },
    }))
    setCanceling(true)

    return new Promise((resolve, reject) => {
      setCanceling(true)
      cancelBooking(
        { id: booking.booking_id },
        {
          onSuccess: (response) => {
            if (response.status === 204) {
              setCanceling(false)
              // setBookingStatus('cancel')
              afterCancel()
              resolve(true)
            }
          },
          onError: () => {
            setCanceling(false)
            reject()
          },
        },
      )
    })
  }

  const confirm = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    if (!isCanceling) {
      const modal = Modal.confirm({
        title: t('reservations_cancel_title'),
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            {t('reservation_modal_message')}
            <StyledContent>
              {` ${booking?.entity_name}, ${displayDate(booking.start_time, true)}, ${
                booking.start_time
              } - ${booking.end_time}`}
            </StyledContent>
          </>
        ),
        okText: t('reservations_cancel_btn'),
        cancelText: t('reservations_cancel_no_btn'),
        okButtonProps: { loading: false, type: 'primary', danger: true },
        autoFocusButton: null,
        centered: true,
      })

      modal.update((prevConfig) => ({
        ...prevConfig,
        onOk: () => onCancel(modal),
      }))
    }
  }

  const path = (id: string) => `${ReservationDetails}/${id}`

  const goToReservationDetails = () =>
    history.push({
      pathname: path(booking.booking_id.toString()),
      state: {
        tabAddress: currentParams.tab,
      },
    })

  // const isLoading = isLoadingBuilding || isLoadingRoom

  // if (isLoading || !building || !room) {
  //   return <ReservationCardLoadingSkeleton />
  // }

  return (
    <StyledItemContainer onClick={goToReservationDetails}>
      <StyledItemRow>
        <StyledItemImageContainer>
          {booking.images && <img src={booking?.images[0] || ImagePlaceHolder} alt="A room" />}
        </StyledItemImageContainer>
        <StyledItemDetailsContainer>
          <Row>
            <Col sm={24} lg={9}>
              <div>
                {booking.images && (
                  <StyledItemMobileImg src={booking?.images[0] || ImagePlaceHolder} alt="A room" />
                )}
                <div>
                  <StyledRoomName>{booking.entity_name}</StyledRoomName>
                  <StyledBusinessName>
                    {booking.buildingName} | {booking.organizationName}
                  </StyledBusinessName>
                  {booking?.addresses && <AddressDisplay address={booking?.addresses[0]} />}
                </div>
              </div>
            </Col>
            <StyledItemRow sm={24} lg={15}>
              <StyledDivider type="vertical" />
              <StyledItemDetailsContainer>
                <StyledTitle>{t('reservations_list_item_reservation_details')}</StyledTitle>
                <StyledItemDetails>
                  {currentUser?.display_name}
                  <br />
                  {`${dayjs.utc(booking.start_time).format('MMMM DD, YYYY')}, 
                  ${dayjs.utc(booking.start_time).format('hh:mmA')} - ${dayjs
                    .utc(booking.end_time)
                    .format('hh:mmA')}`}
                </StyledItemDetails>
                <StyledPriceDetails>
                  <Col sm={8}>
                    {bookingDuration}{' '}
                    {t(
                      bookingDuration > 1
                        ? 'reservations_list_item_hours_other'
                        : 'reservations_list_item_hours_one',
                    )}
                  </Col>
                  {/* {isPaymentFeatureEnable && (
                    <StyledPrice sm={16}>
                      {booking.payment.total &&
                        `${t('reservations_list_item_total')} (${
                          booking.payment.currency
                        }) ${getFormattedPayment(booking.payment.total)}`}
                    </StyledPrice>
                  )} */}
                </StyledPriceDetails>
              </StyledItemDetailsContainer>
            </StyledItemRow>
          </Row>
        </StyledItemDetailsContainer>
        <StyledButtons>
          <Button type="default" size="large" style={{ width: '100%' }}>
            {t('reservations_list_item_details_btn')}
          </Button>
          {type === 'upcoming' && isCancelBookingEnable && (
            <Button type="primary" size="large" onClick={confirm} danger>
              {t('reservations_list_item_cancel_btn')}
            </Button>
          )}
        </StyledButtons>
      </StyledItemRow>
    </StyledItemContainer>
  )
}

const StyledItemContainer = styled.div`
  border: 1px solid #d9d9d9;
  padding: ${({ theme }) => theme.spacing.xxLarge} 34px ${({ theme }) => theme.spacing.xxLarge} 32px;
  margin-bottom: 24px;
  color: #242424;
  cursor: pointer;
  img {
    width: 100px;
    height: 100px;
  }
`

const StyledItemRow = styled(Col)`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`
const StyledItemImageContainer = styled.div`
  width: 100px;
  margin-right: 24px;
  margin-top: ${({ theme }) => theme.spacing.smedium};
  @media only screen and (max-width: 991px) {
    display: none;
  }
`
const StyledItemDetailsContainer = styled.div`
  flex-grow: 1;
`
const StyledItemMobileImg = styled.img`
  display: none;
  @media only screen and (max-width: 991px) {
    display: block;
    margin-right: 24px;
  }
`
const StyledRoomName = styled.p`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.smedium};
  @media only screen and (max-width: 991px) {
    margin-top: ${({ theme }) => theme.spacing.none};
  }
`
const StyledBusinessName = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.none};
`

const StyledDivider = styled(Divider)`
  height: 100%;
  margin-right: 46px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`
const StyledTitle = styled.p`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  @media only screen and (max-width: 991px) {
    margin-top: 30px;
  }
`
const StyledItemDetails = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
const StyledPriceDetails = styled(Row)`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
`
// const StyledPrice = styled(Col)`
//   text-align: right;
// `
const StyledButtons = styled.div`
  width: 84px;
  margin: auto auto auto ${({ theme }) => theme.spacing.xxLarge};
  display: flex;
  flex-direction: column;
  Button:nth-child(2) {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin: 30px ${({ theme }) => theme.spacing.none} ${({ theme }) => theme.spacing.none};
    ${({ theme }) => theme.spacing.none};
  }
`
const StyledContent = styled.span`
  font-weight: 600;
`
