import { buildings } from './buildings.mocks'
import { buildingRooms } from './buildingRooms'
import { rooms } from './rooms.mocks'
import { getAvailabilitiesMockData } from './availabilities.mocks'
import { selfMockData } from './self.mock'
import { bookings, bookingsV2 } from './bookings.mocks'
import { cities } from './cities.mocks'
import { amenities } from './amenities.mocks'

export const mocks = {
  buildings,
  buildingRooms,
  rooms,
  getAvailabilitiesMockData,
  selfMockData,
  bookings,
  cities,
  amenities,
  bookingsV2,
}
