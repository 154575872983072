import { useBooking, useStripeSession } from '@swiftctrl/api-client-react'
import { useMobileView } from '../mobile'
import { useStoreReservation } from '../store'

export const useCreateBooking = () => {
  const { isMobile, isMobileLandscape } = useMobileView()
  const isMobileView: boolean = isMobile || isMobileLandscape
  const { reservationDetails } = useStoreReservation()
  const { mutate: stripeSession, isLoading: createStripeSessionLoading } =
    useStripeSession().booking.add()
  const mutation = useBooking().add({
    onSuccess: (data) => {
      stripeSession(
        {
          epiId: process.env.REACT_APP_STRIPE_EPI_ID!,
          bookingId: data.data.booking_id,
          successUrl: isMobileView
            ? `${window.location.origin}/spaces/${reservationDetails!
                .tenantId!}/${reservationDetails!
                .roomId!}/{CHECKOUT_SESSION_ID}?booking=confirm&bookingId=${data.data.booking_id}`
            : `${window.location.origin}/reservation/${data.data.booking_id}/{CHECKOUT_SESSION_ID}?booking=confirm`,
          cancelUrl: `${window.location.origin}/spaces/${reservationDetails!
            .tenantId!}/${reservationDetails!.roomId!}?booking=cancel&bookingId=${
            data.data.booking_id
          }&date=${reservationDetails?.date}&guests=${
            reservationDetails?.numberOfGuests
          }&startTime=${reservationDetails?.startTime}&endTime=${reservationDetails?.endTime}`,
        },
        {
          onSuccess: (response) => {
            if (!response) {
              return
            }
            window.location.replace(response.data)
          },
        },
      )
    },
  })

  return { ...mutation, createStripeSessionLoading }
}
