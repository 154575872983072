import { useTranslation as useTranslation_i18next } from 'react-i18next'
import { TranslationFunction } from '../models'

export const useTranslation = () => {
  const { t, i18n } = useTranslation_i18next()

  return {
    t: t as TranslationFunction,
    i18n,
  }
}
