import { Swift } from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import { LastLocationType, useLastLocation } from 'react-router-last-location'

import { useStoreRoute } from '../hooks'
import { paths } from '../utils'

const { Search, ReservationDetails, Reservations } = paths

export const RememberLastLocation: Swift.FC = () => {
  const lastLocation: LastLocationType = useLastLocation()

  const { currentStoreRoute, updateStoreRoute } = useStoreRoute()
  const [tabAddress, setTabAddress] = useState<string>()

  const isdReservationListRoute: boolean = lastLocation?.pathname.split('/')[1] === Reservations

  useEffect(() => {
    if (isdReservationListRoute) {
      setTabAddress(lastLocation?.search)
    }
  }, [isdReservationListRoute, lastLocation?.search])

  useEffect(() => {
    if (
      lastLocation?.pathname.split('/')[1] === Search ||
      lastLocation?.pathname.split('/')[1] === ReservationDetails
    ) {
      const modifiedLastLocation = { ...lastLocation }
      if (lastLocation?.pathname.split('/')[1] === ReservationDetails && tabAddress) {
        modifiedLastLocation.search = tabAddress
      }
      updateStoreRoute({
        ...currentStoreRoute,
        ...modifiedLastLocation,
      })
    }
  }, [lastLocation?.pathname])

  return null
}
