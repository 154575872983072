import { LastLocationType } from 'react-router-last-location'

import { isNull, isObject } from 'lodash'
import { RootState, StoreUpdater } from '../../models'
import { Actions } from '../../store'
import { useDispatch, useSelector } from './useStoreHook'

export const useStoreRoute = () => {
  const dispatch = useDispatch()

  const updateStoreRoute: StoreUpdater<LastLocationType extends object ? LastLocationType : {}> = <
    T extends Object,
  >(
    arg1: keyof T | T | null,
    arg2?: T[typeof arg1 & keyof T] | null,
  ) => {
    if (isNull(arg1)) {
      dispatch(Actions.setReservation(null))
      return
    }
    if (isObject(arg1)) {
      dispatch(Actions.setRoute(arg1))
    } else {
      dispatch(Actions.setRoute({ [arg1]: arg2 } as Partial<LastLocationType>))
    }
  }

  const currentStoreRoute: LastLocationType | null = useSelector<LastLocationType | null>(
    (state: RootState) => state.route.data,
  )

  return { currentStoreRoute, updateStoreRoute }
}
