import { includes } from 'lodash'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { MainRouteProps } from '../../models'
import { usePublicRoutes } from './usePublicRoutes'

export const useIsPublicRoute = (): boolean => {
  const location: RouteComponentProps['location'] = useLocation()
  const publicRoutes: MainRouteProps[] = usePublicRoutes()
  const publicRouteList: string[] = ['', '/']
  publicRoutes.map((route: MainRouteProps) => publicRouteList.push(route.path.split('/')[1]))
  const locationPathName = location.pathname.split('/')[1]
  return includes(publicRouteList, locationPathName)
}
