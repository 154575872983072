import { Button, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

import { useTranslation } from '../../../hooks'
import { bookingDisplayDate, bookingDisplayTime, getShortDate } from '../../../utils'
import { Mode } from './ReservationFooter'

interface Props {
  startTime: number
  endTime: number
  date: string
  isValid: boolean
  onClick?: () => void
  mode: Mode
  forTable?: boolean
}

export const ReservationTimeDetails: Swift.FC<Props> = ({
  date,
  startTime,
  endTime,
  isValid,
  onClick,
  mode,
  forTable = false,
}) => {
  const { t } = useTranslation()
  return (
    <StyledTextButton
      $isValid={isValid}
      $forTable={forTable}
      $mode={mode}
      onClick={onClick}
      type="text"
    >
      {isValid
        ? `${bookingDisplayDate(date, true)}. ${getShortDate(date)}, ${bookingDisplayTime(
            startTime,
            endTime,
            true,
          )}`
        : t(
            forTable
              ? 'room_details_select_date_and_time_in_table'
              : 'room_details_select_date_and_time',
          )}
    </StyledTextButton>
  )
}

const StyledTextButton = styled(Button)<{
  $mode: Mode
  $forTable: boolean
  $isValid: boolean
}>`
  padding: 0px;
  span {
    text-decoration: ${({ $mode }) => ($mode === 'review' ? 'underline' : 'none')};
    font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  }
  color: ${({ $forTable, $isValid }) => ($forTable && !$isValid ? '#BFBFBF' : 'default')};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  height: ${({ theme }) => theme.spacing.xMedium};
`
