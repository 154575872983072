import { Button, CloseOutlined, Swift, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

interface Props {
  onClose: () => void
  text: string
}

export const DrawerHeader: Swift.FC<Props> = ({ onClose, text }) => (
  <StyledHeader>
    <Typography.Title level={5}>{text}</Typography.Title>
    <Button onClick={onClose} type="text">
      <CloseOutlined />
    </Button>
  </StyledHeader>
)

const StyledHeader = styled.div`
  height: 56px;
  h5 {
    margin-bottom: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 0px;
  }
  svg {
    height: ${({ theme }) => theme.icon.sizes.small};
    width: ${({ theme }) => theme.icon.sizes.small};
  }
  border-bottom: 1px solid #f0f0f0;
  padding: ${({ theme }) => theme.spacing.medium};
`
