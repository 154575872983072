import { includes } from 'lodash'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { MainRouteProps } from '../../models'
import { usePrivateRoutes } from './usePrivateRoutes'

export const useIsPrivateRoute = (): boolean => {
  const location: RouteComponentProps['location'] = useLocation()
  const privateRoutes: MainRouteProps[] = usePrivateRoutes()
  const privateRouteList: string[] = ['', '/']
  privateRoutes.map((route: MainRouteProps) => privateRouteList.push(route.path.split('/')[1]))
  const locationPathName = location.pathname.split('/')[1]
  return includes(privateRouteList, locationPathName)
}
