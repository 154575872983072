import { AddressTypes, BookingTypes } from '@swiftctrl/api-client-react'
import { Col, Divider, Swift } from '@swiftctrl/swift-component-library'
import dayjs from 'dayjs'
import { RouterProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ImagePlaceHolder from '../../assets/image-place-holder.png'
import { useHandleUrlParams } from '../../hooks'
import { bookingDateDisplay, paths } from '../../utils'
import { AddressDisplay } from '../data-display'

interface Props {
  booking: BookingTypes & {
    buildingId?: string
    organizationName?: string
    addresses?: AddressTypes[]
    buildingName?: string
    images?: string[]
    roomId?: string
  }
  hideMarinBottom?: boolean
}

const { ReservationDetails } = paths

export const ReservationMobileCard: Swift.FC<Props> = ({ booking, hideMarinBottom }) => {
  const history: RouterProps['history'] = useHistory()
  const { currentParams } = useHandleUrlParams()
  const goToReservationDetails = () =>
    history.push({
      pathname: `${ReservationDetails}/${booking.booking_id}`,
      state: {
        tabAddress: currentParams.tab,
      },
    })

  // if (isLoading) {
  //   return <ReservationCardLoadingSkeleton />
  // }

  return (
    <CardContainer onClick={goToReservationDetails} $hideMarinBottom={hideMarinBottom}>
      <Col span={24}>
        {booking?.images && (
          <StyledImageContainer $image={booking?.images[0] || ImagePlaceHolder} />
        )}
      </Col>
      <StyledInfoContainer span={24}>
        <StyledOfficeName>{booking?.entity_name}</StyledOfficeName>
        <StyledOfficeLocationName>
          {booking?.buildingName} | {booking?.organizationName}
        </StyledOfficeLocationName>
        <AddressDisplay address={booking?.addresses?.[0]} />
        <StyledDivider />
        <StyledDateContainer>
          {`${bookingDateDisplay(booking.start_time)}`} &bull;
          {` ${dayjs.utc(booking.start_time).format('hh:mmA')} - ${dayjs
            .utc(booking.end_time)
            .format('hh:mmA')}`}
        </StyledDateContainer>
      </StyledInfoContainer>
    </CardContainer>
  )
}

const CardContainer = styled(Col)<{ $hideMarinBottom?: boolean }>`
  border: 1px solid #d9d9d9;
  border-radius: 2px 2px 0px 0px;
  margin-bottom: ${({ $hideMarinBottom }) => ($hideMarinBottom ? '0px' : '24px')};
`

const StyledImageContainer = styled.img<{ $image: string }>`
  width: 100%;
  padding-top: 50%;
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-position: center;
`

const StyledOfficeName = styled.p`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const StyledOfficeLocationName = styled.p`
  margin-bottom: 0px;
`

const StyledInfoContainer = styled(Col)`
  padding: ${({ theme }) => theme.spacing.medium};
`
const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.medium} 0;
`
const StyledDateContainer = styled.p`
  margin-bottom: 0px;
`
