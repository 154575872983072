import { Swift } from '@swiftctrl/swift-component-library'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { usePrivateRoutes, useStoreCurrentUser } from '../../hooks'
import { MainRouteProps } from '../../models'
import { paths } from '../../utils'
import { RenderIfScope } from '../RenderIfScope'
import { RouteWithSubRoutes } from './RouteWithSubRoutes'

const { Search, DefaultFallback404 } = paths

export const PrivateRoutes: Swift.FC = () => {
  const defaultRoute: string = `/${Search}`
  const mainRoutes: MainRouteProps[] = usePrivateRoutes()

  const { currentUser } = useStoreCurrentUser()

  if (!currentUser) {
    return null
  }

  return (
    <RenderIfScope is="private">
      <Switch>
        <Route exact path="/">
          <Redirect to={defaultRoute} />
        </Route>
        {mainRoutes.map((route: MainRouteProps) => (
          <RouteWithSubRoutes key={String(route.path)} {...route} />
        ))}
        <Route path="*">
          <Redirect to={`/${DefaultFallback404}`} />
        </Route>
      </Switch>
    </RenderIfScope>
  )
}
