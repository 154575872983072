import {
  Button,
  CalendarOutlined,
  CloseOutlined,
  Col,
  DatePicker,
  DatePickerProps,
  Row,
  Swift,
} from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import styled, { CSSObject } from 'styled-components'

type Props = DatePickerProps & {
  onClear: () => void
  showClearButton: boolean
}

export const SmartSearchDatePicker: Swift.FC<Props> = (props) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const { onClear, showClearButton, ...datePickerProps } = props

  const handlePopupContainer = (container: HTMLElement): HTMLElement =>
    container?.parentElement || document.getElementById('root')!
  return (
    <StyledContainer
      className={datePickerOpen ? 'ant-input-affix-wrapper:focus' : ''}
      $isDatePickerOpen={datePickerOpen}
      align="middle"
      justify="start"
    >
      <StyledIconColumn span={1}>
        <CalendarOutlined />
      </StyledIconColumn>
      <Col span={showClearButton ? 22 : 23}>
        <StyledDatePicker
          {...datePickerProps}
          onOpenChange={setDatePickerOpen}
          suffixIcon={null}
          bordered={false}
          allowClear={false}
          getPopupContainer={handlePopupContainer}
        />
      </Col>
      {showClearButton && (
        <Col span={1}>
          <StyledClearButton
            shape="circle"
            size="small"
            type="text"
            icon={<CloseOutlined />}
            onClick={onClear}
          />
        </Col>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled(Row)<{ $isDatePickerOpen: boolean }>`
  ${({ $isDatePickerOpen }): CSSObject =>
    $isDatePickerOpen
      ? {
          border: '1px solid #40a9ff',
          boxShadow: '0 0 0 2px rgb(24 144 255 / 20%)',
          borderRightWidth: ' 1px !important',
        }
      : {
          border: '1px solid #d9d9d9',
        }}
  padding: 6.5px 11px;
  :hover {
    border: 1px solid #40a9ff;
  }
`

const StyledIconColumn = styled(Col)`
  ${({ theme }): CSSObject => ({
    svg: {
      marginRight: theme.spacing.small,
      width: theme.icon.sizes.small,
      height: theme.icon.sizes.small,
    },
  })}
`

const StyledDatePicker = styled(DatePicker)`
  padding: 0px;
  width: 100%;
  .ant-picker-input {
    padding-left: ${({ theme }) => (theme.isMobileView ? theme.spacing.small : 0)};
  }
`

const StyledClearButton = styled(Button)`
  opacity: 0.35;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s;
  margin-left: -4px;
`
