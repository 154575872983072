import {
  RoomsTypes,
  useBooking,
  useBuilding,
  useQueryClient,
  useRoom,
  useSwiftClient,
} from '@swiftctrl/api-client-react'
import {
  Alert,
  Button,
  Col,
  Modal,
  Row,
  Swift,
  UserOutlined,
  message,
} from '@swiftctrl/swift-component-library'
import { isEmpty, isNil, omitBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Link, RouterProps, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { mixPanelTrack } from '../../analytics'
import imagePlaceholder from '../../assets/image-place-holder.png'
import {
  AddressDisplay,
  Breadcrumbs,
  CenteredPreLoader,
  Fallback,
  GoogleMap,
  ScreenContainer,
} from '../../components'
import {
  useFeature,
  useHandleUrlParams,
  useStoreCurrentUser,
  useStoreParams,
  useStoreReservation,
  useTranslation,
  useWindowSize,
} from '../../hooks'
import { Guidelines } from '../../legal-texts'
import { Dimensions } from '../../models'
import {
  Amenities,
  Availabilities,
  BookingHorizontal,
  BookingVertical,
  ImageGallery,
} from '../../screen-components'
import {
  convertHourToDateISO,
  differenceInDayFromToday,
  emptyReservation,
  goToAccountsApp,
  isRoomAvailable,
  numberGuestsLabel,
  paths,
  removeTimezone,
} from '../../utils'

interface Props {
  roomId: string
  tenantId: string
}

const { Building } = paths

interface LocationState {
  numberOfGuests?: number
  bookingError?: boolean
}

const { Search, Checkout } = paths

export const RoomDetailsScreen: Swift.FC<Props> = ({ roomId, tenantId }) => {
  const { t } = useTranslation()
  const { innerWidth } = useWindowSize()
  const swiftClient = useSwiftClient()
  const history: RouterProps['history'] = useHistory()
  const { currentUser } = useStoreCurrentUser()
  const { reservationDetails, updateStoreReservation } = useStoreReservation()
  const { currentParams, cleanParams, updateParams } = useHandleUrlParams()
  const { currentStoreParams } = useStoreParams()
  const queryClient = useQueryClient()
  const loggedIn: boolean = !!currentUser
  const {
    data: room,
    isFetching: isLoadingRoom,
    isError: isFetchRoomError,
    error: fetchRoomError,
    isFetched: isRoomFetched,
  } = useRoom()
    .at(roomId)
    .read({ queryKey: ['room-read'] })

  const {
    data: building,
    isFetching: isLoadingBuilding,
    isError: isFetchBuildingError,
  } = useBuilding()
    .at(tenantId)
    .read({ queryKey: ['read-building'] }, { enabled: isRoomFetched })

  const isBuildingFeatureEnable: boolean = useFeature('sc_buildings')

  const roomData = room?.data as RoomsTypes
  const buildingData = building?.data

  const { mutate: cancelBooking } = useBooking().delete()

  const isFetchScreenError = isFetchBuildingError || isFetchRoomError
  const isLoadingScreen = isLoadingBuilding || isLoadingRoom
  const startTimeDate = new Date(
    convertHourToDateISO(reservationDetails.date, reservationDetails.startTime),
  )
  const endTimeDate = new Date(
    convertHourToDateISO(reservationDetails.date, reservationDetails.endTime),
  )

  const { data: costData } = useRoom()
    .at(reservationDetails.roomId!)
    .price(
      {
        browseOptions: {
          queryKey: 'room-price',
        },
        startTime: convertHourToDateISO(
          reservationDetails.date,
          reservationDetails.startTime,
        ).split('.')[0],
        endTime: convertHourToDateISO(reservationDetails.date, reservationDetails.endTime).split(
          '.',
        )[0],
      },
      { enabled: Boolean(reservationDetails.startTime) },
    )

  const cost = costData?.data

  // Local State
  const [bookingError, setBookingError] = useState<boolean>(false)
  const [errorWarning, setErrorWarning] = useState<boolean>(false)
  const [divDimensions, setDivDimentions] = useState<Dimensions>({ width: 0, height: 0 })
  const [refreshAvailablititesTable, setRefreshAvailabilitiesTable] = useState(false)
  const [showGuidelines, setShowGuidelines] = useState<boolean>(false)
  const [bookingComponentType, setBookingComponentType] = useState<'Vertical' | 'Horizontal'>(
    'Vertical',
  )

  const availabilitiesRef = useRef<HTMLDivElement>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const location = useLocation<LocationState>()
  const confirmationBookingError = location?.state?.bookingError

  useEffect(() => {
    if (currentParams.booking === 'cancel' && currentParams.bookingId) {
      cancelBooking(
        { id: currentParams.bookingId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['availabilities'])
          },
        },
      )
      mixPanelTrack('Book Space (Abort)', {
        'Days From Today': differenceInDayFromToday(
          removeTimezone(
            new Date(convertHourToDateISO(currentParams.date!, currentParams.startTime!)),
          ),
        ),
        'Guest Count': currentParams.guests,
      })

      cleanParams(['booking', 'bookingId', 'startTime', 'endTime', 'date', 'guests'])
    }
  }, [])

  const setNumberOfGuests = (guests: number) => {
    updateStoreReservation({ ...reservationDetails, numberOfGuests: guests })
  }

  const cancelReservation = () => {
    updateStoreReservation(emptyReservation)
  }

  const scrollToAvailabilityTable = () => {
    if (availabilitiesRef.current) {
      window.scrollTo(
        0,
        availabilitiesRef.current.offsetTop - availabilitiesRef.current.offsetHeight + 250,
      )
    }
  }

  // Error handlers

  const setDisplayBookingError = (error: boolean) => {
    if (error) {
      cancelReservation()
      setBookingError(true)
      setRefreshAvailabilitiesTable(!refreshAvailablititesTable)
      cancelReservation()
    } else {
      setBookingError(false)
    }
  }

  const sendErrorWarning = () => {
    setErrorWarning(true)
  }

  const warning = () => {
    message.config({
      maxCount: 1,
    })
    return message.warning(t('room_details_error_something_wrong'))
  }

  // Logic related to the carousel

  useEffect(() => {
    if (divRef.current) {
      const width = divRef.current.offsetWidth
      const height = Math.round(Number(width * 0.6))
      setDivDimentions({ width: width, height: height })

      const handleResize = () => {
        if (divRef?.current) {
          setDivDimentions({ width: 0, height: 0 })

          const width = divRef?.current.offsetWidth
          const height = Math.round(Number(width * 0.6))

          setDivDimentions({ width: width, height: height })
        }
      }

      window.addEventListener('resize', handleResize)
    }
  }, [isLoadingScreen])

  useEffect(() => {
    const handleOnScroll = () => {
      if (availabilitiesRef.current) {
        if (
          window.scrollY >
          availabilitiesRef.current.offsetTop - availabilitiesRef.current.offsetHeight
        ) {
          setBookingComponentType('Horizontal')
        } else {
          setBookingComponentType('Vertical')
        }
      }
    }
    document.addEventListener('scroll', handleOnScroll)

    return () => document.removeEventListener('scroll', handleOnScroll)
  }, [])

  useEffect(() => {
    if (confirmationBookingError) {
      setDisplayBookingError(confirmationBookingError)
    }
  }, [confirmationBookingError])

  // Modal handlers
  const openGuidelinesModal = () => {
    setShowGuidelines(true)
  }

  const closeGuidelinesModal = () => {
    setShowGuidelines(false)
  }

  const validateRoomAvailability = () => {
    setBookingError(false)
    const today = new Date()
    const roomId = roomData?.room_id
    const tenantId = roomData?.overseer_id
    if (!roomId || !tenantId) {
      sendErrorWarning()
      return false
    }

    if (startTimeDate > endTimeDate) {
      return false
    }

    const startTimeDateNoTZ = new Date(removeTimezone(startTimeDate))
    const startTimeDateIsToday = startTimeDateNoTZ.getDate() === today.getDate()
    const startTimeIsSameTimeOrLess = startTimeDateNoTZ.getHours() <= today.getHours()
    if (startTimeDateIsToday && startTimeIsSameTimeOrLess) {
      setBookingError(true)
      cancelReservation()
      return false
    }

    mixPanelTrack('Book Space (Start)', {
      'Days From Today': differenceInDayFromToday(removeTimezone(startTimeDate)),
      'Guest Count': reservationDetails.numberOfGuests,
    })
    swiftClient.room
      .at(roomId)
      .availabilities.browse({
        startTime: removeTimezone(startTimeDate),
        endTime: removeTimezone(endTimeDate),
      })
      .then((data) => {
        const cancelBookingAndThrowError = () => {
          setBookingError(true)
          cancelReservation()
        }

        const availabilities = data.data[0].availabilities
        if (!availabilities) {
          cancelBookingAndThrowError()
          return
        }

        const available = isRoomAvailable(
          removeTimezone(startTimeDate),
          removeTimezone(endTimeDate),
          availabilities,
        )
        if (!available) {
          setBookingError(true)
          cancelReservation()
          queryClient.invalidateQueries(['availabilities'])
        }
        if (available && loggedIn) {
          return history.push({
            pathname: `/${Checkout}`,
          })
        }
        if (available && !loggedIn) {
          updateParams({ book: 'true' })
          goToAccountsApp('signin')
        }
      })
      .catch((error) => {
        setBookingError(true)
      })
  }

  const closeBookingError = () => {
    setBookingError(false)
  }

  const searchParams = () =>
    isEmpty(currentStoreParams)
      ? ''
      : '?' +
        new URLSearchParams(omitBy(currentStoreParams, isNil) as Record<string, string>).toString()

  if (fetchRoomError?.request?.status === 404) {
    return (
      <ScreenContainer>
        <Fallback
          status="404"
          section={{
            label: t('fallback_room_details_screen_description'),
            buttonLabel: t('fallback_room_details_screen_button_label'),
          }}
        />
      </ScreenContainer>
    )
  }

  if (isFetchScreenError) {
    return (
      <ScreenContainer>
        <Fallback status="500" />
      </ScreenContainer>
    )
  }

  if (isLoadingScreen) {
    return <CenteredPreLoader />
  }

  return (
    <ScreenContainer>
      <Breadcrumbs
        clickEnable={isBuildingFeatureEnable}
        breadcrumbs={[
          {
            name: t('search_search'),
            path: `/${Search}${searchParams()}`,
          },
          {
            name: buildingData?.entity_name || '',
            path: `/${paths.Building}/${buildingData?.building_id}`,
          },
          {
            name: t('common_details'),
          },
        ]}
      />
      <Row wrap={false}>
        <Col flex="auto">
          <StyledRoomDetails ref={divRef}>
            <StyledTitleContainer>
              <Row>
                <Col span={20}>
                  <StyledDetailsTitle>{roomData?.entity_name}</StyledDetailsTitle>
                </Col>

                <StyledCapacityContainer span={4}>
                  {roomData?.min_capacity ? (
                    <div>
                      <StyledCapacityIcon /> {numberGuestsLabel(roomData)}
                    </div>
                  ) : null}
                </StyledCapacityContainer>
              </Row>
            </StyledTitleContainer>
            <StyledAddressContainer>
              {isBuildingFeatureEnable ? (
                <StyledLink to={`/${Building}/${buildingData?.building_id}`}>
                  {roomData?.overseer_name} | {buildingData?.overseer_name}
                </StyledLink>
              ) : (
                <>
                  {roomData?.overseer_name} | {buildingData?.overseer_name}
                </>
              )}

              <AddressDisplay address={buildingData?.addresses?.[0]} />
            </StyledAddressContainer>
            <ImageGallery
              images={roomData?.images?.length === 0 ? [imagePlaceholder] : roomData?.images}
              galleryDimensions={divDimensions}
            />

            <StyledDescription>{roomData?.entity_description}</StyledDescription>

            <StyledGuidelines>
              <p>{t('room_details_guidelines_text')}</p>
              <Button type="link" size="large" onClick={openGuidelinesModal}>
                {t('room_details_guidelines_button')}
              </Button>
            </StyledGuidelines>
            <Amenities amenities={roomData?.amenities} schedule={buildingData?.schedule} />
          </StyledRoomDetails>
        </Col>
        {innerWidth > 1220 && (
          <Col flex="406px">
            <StyledBookingContainer>
              {roomData && bookingComponentType === 'Vertical' && (
                <BookingVertical
                  room={roomData}
                  numberGuests={reservationDetails.numberOfGuests || 0}
                  setNumberGuests={setNumberOfGuests}
                  sendToAvailabilityTable={scrollToAvailabilityTable}
                  setBookingError={setDisplayBookingError}
                  sendErrorWarning={sendErrorWarning}
                  validateRoomAvailability={validateRoomAvailability}
                  cost={cost}
                />
              )}
            </StyledBookingContainer>
          </Col>
        )}
        {bookingComponentType === 'Horizontal' || innerWidth < 1220 ? (
          <StyledStickyBooking>
            {roomData && (
              <BookingHorizontal
                building={buildingData}
                room={roomData}
                numberGuests={reservationDetails.numberOfGuests || 0}
                setNumberGuests={setNumberOfGuests}
                sendToAvailabilityTable={scrollToAvailabilityTable}
                setBookingError={setDisplayBookingError}
                sendErrorWarning={sendErrorWarning}
                validateRoomAvailability={validateRoomAvailability}
                cost={cost}
              />
            )}
          </StyledStickyBooking>
        ) : null}
      </Row>
      {bookingError ? (
        <StyledError>
          <Alert
            message={t('room_details_booking_error_title')}
            description={t('room_details_booking_error_description')}
            type="error"
            closable
            onClose={closeBookingError}
          />
        </StyledError>
      ) : null}
      {errorWarning ? <div>{warning()}</div> : null}
      <div ref={availabilitiesRef}>
        <Availabilities roomId={roomData?.room_id} schedule={buildingData?.schedule!} />
      </div>
      <StyledMap>
        <GoogleMap address={buildingData?.addresses?.[0].address_line_1} />
      </StyledMap>
      <Modal
        title={t('room_details_modal_guidelines_title')}
        open={showGuidelines}
        footer={null}
        onCancel={closeGuidelinesModal}
      >
        <Guidelines />
      </Modal>
    </ScreenContainer>
  )
}

const StyledLink = styled(Link)`
  color: #262626;

  &:hover {
    color: #262626;
  }
`

const StyledRoomDetails = styled.div`
  margin-right: 56px;
  @media only screen and (max-width: 1219px) {
    margin-right: ${({ theme }) => theme.spacing.none};
  }
`
const StyledTitleContainer = styled.div`
  margin-bottom: 24px;
`
const StyledDetailsTitle = styled.h1`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  margin: ${({ theme }) => theme.spacing.none};
`
const StyledCapacityContainer = styled(Col)`
  text-align: right;
  padding: 7px ${({ theme }) => theme.spacing.none};
`
const StyledCapacityIcon = styled(UserOutlined)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].fontSize};
  vertical-align: text-bottom;
`
const StyledAddressContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  p {
    margin: ${({ theme }) => theme.spacing.none};
  }
`
const StyledDescription = styled.div`
  margin-top: ${({ theme }) => theme.spacing.large};
  p {
    margin: ${({ theme }) => theme.spacing.none};
  }
`
const StyledGuidelines = styled.div`
  background-color: #e6f7ff;
  border-radius: 2px;
  padding: 24px;
  margin: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.none};
  p {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
  button {
    padding: ${({ theme }) => theme.spacing.none};
    height: inherit;
  }
`
const StyledBookingContainer = styled.div`
  width: 407px;
  position: -webkit-sticky;
  position: sticky;
  top: 32px;
  z-index: 500;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`
const StyledError = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
const StyledStickyBooking = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 999;
  @media only screen and (max-width: 1219px) {
    display: block;
    visibility: visible;
    bottom: 0;
  }
  @media only screen and (min-width: 1220px) {
    top: 0;
  }
`
const StyledMap = styled.div`
  width: 100%;
  height: 379px;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  @media only screen and (max-width: 1219px) {
    margin-bottom: 222px;
  }
`

export default RoomDetailsScreen
