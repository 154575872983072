import { Swift } from '@swiftctrl/swift-component-library'
import { useGetUserProfile } from '../hooks'
import { Preloader } from './Preloader'

export const CurrentUserProvider: Swift.FC = ({ children }) => {
  const { isLoading } = useGetUserProfile()

  if (isLoading) {
    return <Preloader />
  }
  return <>{children}</>
}
