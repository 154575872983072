import { RoomsTypes, useBuilding, useRoom } from '@swiftctrl/api-client-react'
import { Col, ColProps, Row, Swift, useBreakpoint } from '@swiftctrl/swift-component-library'
import { useEffect } from 'react'
import { RouterProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CheckoutScreenLoadingSkeleton, ScreenContainer } from '../../components'
import { useStoreReservation } from '../../hooks'
import { CheckoutRoomDetails, ReservationCheckout } from '../../screen-components'
import { convertHourToDateISO, paths } from '../../utils'

const CheckoutScreen: Swift.FC = () => {
  const screens = useBreakpoint()
  const history: RouterProps['history'] = useHistory()
  const { reservationDetails } = useStoreReservation()

  const {
    data: roomResponse,
    isFetching: isLoadingRoom,
    isFetched: isRoomFetched,
  } = useRoom()
    .at(reservationDetails.roomId!)
    .read({ queryKey: ['room-read'] })

  const { data: buildingResponse, isFetching: isLoadingBuilding } = useBuilding()
    .at(reservationDetails.tenantId!)
    .read({ queryKey: ['building-read'] }, { enabled: isRoomFetched })

  const { data: costData } = useRoom()
    .at(reservationDetails.roomId!)
    .price({
      browseOptions: {
        queryKey: 'room-price',
      },
      startTime: convertHourToDateISO(reservationDetails.date, reservationDetails.startTime).split(
        '.',
      )[0],
      endTime: convertHourToDateISO(reservationDetails.date, reservationDetails.endTime).split(
        '.',
      )[0],
    })

  useEffect(() => {
    if (reservationDetails.roomId === '') {
      history.push(paths.Search)
    }
  })

  const room = roomResponse?.data as RoomsTypes
  const building = buildingResponse?.data

  const breakpointProps: ColProps = {
    md: 24,
    sm: 24,
    xs: 24,
  }

  const ReservationCheckoutFlexProps = {
    true: { flex: '1 0px', order: 0 },
    false: { ...breakpointProps, order: 1 },
  }

  const ReservationRoomDetailsFlexProps = {
    false: { ...breakpointProps, order: 0 },
    true: { flex: '407px', order: 1 },
  }

  const cost = costData?.data
  return (
    <ScreenContainer>
      {isLoadingRoom || isLoadingBuilding ? (
        <CheckoutScreenLoadingSkeleton />
      ) : (
        <StyledContainerCheckout>
          <Row gutter={[56, 0]}>
            <StyledDetailsContainer
              {...ReservationRoomDetailsFlexProps[`${screens.lg}` as 'true' | 'false']}
            >
              {room && (
                <CheckoutRoomDetails
                  reservationDetails={{ ...reservationDetails, cost: cost?.price + '' }}
                  room={room}
                  clickable={false}
                  building={building}
                />
              )}
            </StyledDetailsContainer>
            <Col {...ReservationCheckoutFlexProps[`${screens.lg}` as 'true' | 'false']}>
              <ReservationCheckout reservationDetails={reservationDetails} />
            </Col>
          </Row>
        </StyledContainerCheckout>
      )}
    </ScreenContainer>
  )
}

export default CheckoutScreen

const StyledContainerCheckout = styled.div`
  width: 100%;
  margin: auto;
`
const StyledDetailsContainer = styled(Col)`
  padding-bottom: 24px;
`
