import { Swift } from '@swiftctrl/swift-component-library'
import styled, { CSSObject } from 'styled-components'

export const AdminScreenContainer: Swift.FC = ({ children }) => {
  return <StyledScreenContainer>{children}</StyledScreenContainer>
}

export const StyledScreenContainer = styled.div`
  ${({ theme }): CSSObject => ({
    paddingTop: theme.spacing.xxLarge,
    paddingRight: theme.spacing.xxLarge,
    paddingLeft: theme.spacing.xxLarge,
  })}
`
