import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import './i18n/i18n'
import './index.css'

dayjs.extend(utc)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
