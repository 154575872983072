import { Availability, RoomAvailabilitiesTypes } from '@swiftctrl/api-client-react'
import dayjs from 'dayjs'
import { RoomAvailability } from '../../models'

/**
 * Convert Api availabilities to spacecloud availabilities
 * @param availabilitiesApi
 * @returns List of spacecloud formatted availabilities
 */
export const formatAvailabilities = (
  availabilitiesApi?: RoomAvailabilitiesTypes[],
): RoomAvailability[] => {
  if (!availabilitiesApi) {
    return []
  }
  const availabilities: RoomAvailability[] = []
  availabilitiesApi.forEach((day) => {
    const date = dayjs(day.date)
    const dayOfTheWeek = date.day()
    const displayDate = date.format('MM/DD')
    availabilities.push({
      availabilities: day.availabilities ? convertRangesToHoursArray(day.availabilities) : [],
      date: date.toISOString(),
      dayOfTheWeek,
      displayDate,
    })
  })
  return availabilities
}

/**
 * Converts a range of hours to available hours array
 * @param ranges
 * @returns hours array
 */
export const convertRangesToHoursArray = (ranges: Availability[]): number[] => {
  const availabilities: number[] = []
  ranges.forEach((availability) => {
    const startHour = getNextAvailableStartHour(availability.from)
    const endHour = Number(availability.to.split(':')[0])
    for (let hour = startHour; hour < endHour; hour++) {
      availabilities.push(hour)
    }
  })
  return availabilities
}

/**
 * Takes an hour/minute string and returns the next available hour number
 * @param hour hh:mm format
 * @returns number
 */
const getNextAvailableStartHour = (hourString: string): number => {
  const timeArray = hourString.split(':')
  const minutes = Number(timeArray[1])
  const hour = Number(timeArray[0])
  return minutes === 0 ? hour : hour + 1
}
