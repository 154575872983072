import { createSlice } from '@reduxjs/toolkit'
import { LastLocationType } from 'react-router-last-location'

import { isNull } from 'lodash'
import { Payload, State } from '../../models'
import { storeSliceNames } from '../../utils'

const { route: name } = storeSliceNames

const initialState: State<LastLocationType> = { data: null }

const routeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setRoute: (state: State<LastLocationType>, action: Payload<Partial<LastLocationType>>) => {
      const { payload } = action
      if (isNull(payload)) {
        state.data = payload
        return
      }
      state.data = { ...state.data!, ...payload! }
    },
  },
})

export const {
  reducer: routeReducer,
  actions: { setRoute },
} = routeSlice
