import { Card, Col, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import { RouterProps, useHistory } from 'react-router-dom'
import styled, { CSSObject } from 'styled-components'

import { Reservation } from '../../models'
import { paths } from '../../utils'
import { ReservationDetailsInfos } from './ReservationDetailsInfos'
import { TotalWithTag } from './TotalWithTag'

interface Props {
  reservation: Reservation
}

const { Admin, ReservationDetails } = paths

export const ReservationCardCompact: Swift.FC<Props> = ({ reservation }) => {
  const { room, payment, booking, contactInformation } = reservation
  const history: RouterProps['history'] = useHistory()
  const goToRoom = () => history.push(`/${Admin}/${ReservationDetails}/${reservation.booking.id}`)
  return (
    <StyledReservationCard onClick={goToRoom}>
      <Row align="bottom" justify="space-between">
        <StyledImageAndAddressColumn span={18}>
          <Row align="bottom" gutter={24}>
            <Col>
              <img src={`${room.image}`} alt="roomImage" />
            </Col>
            <Col>
              <StyledAddressContainer>
                <Typography.Title level={5}>{room.name}</Typography.Title>
                <address>{room.location.name}</address> - <span>{room.location.name}</span>
              </StyledAddressContainer>
              <ReservationDetailsInfos
                noMargin
                contactInformation={contactInformation}
                booking={booking}
              />
            </Col>
          </Row>
        </StyledImageAndAddressColumn>
        <Col span={6}>
          <Row align="top" justify="end">
            <Col>
              <TotalWithTag payment={payment} />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledReservationCard>
  )
}

const StyledAddressContainer = styled.div`
  ${({ theme }): CSSObject => ({
    marginBottom: theme.spacing.medium,
  })}
`

const StyledReservationCard = styled(Card)`
  ${({ theme }): CSSObject => ({
    cursor: 'pointer',
    img: {
      height: 112,
      width: 112,
    },
  })}
`

const StyledImageAndAddressColumn = styled(Col)`
  ${({ theme }): CSSObject => ({
    h5: {
      marginBottom: 0,
    },
    'span:nth-of-type(1)': {
      fontSize: 12,
      color: '#8C8C8C',
    },
    address: {
      display: 'inline',
    },
  })}
`
