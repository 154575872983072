import { RoomsTypes } from '@swiftctrl/api-client-react'

export const numberGuestsLabel = (room: Partial<RoomsTypes>) => {
  const hasGuests = room.max_capacity && room.min_capacity
  if (!hasGuests) return null

  if (room.min_capacity === room.max_capacity) {
    return String(room.min_capacity)
  }

  return room.min_capacity + '-' + room.max_capacity
}
