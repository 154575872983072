export const convertHours24To12 = (timeString: string) => {
  let newTime = timeString
  const hourSplit = timeString.split(':')

  const hour = +hourSplit[0]
  const minutes = hourSplit[1]

  const newHour = hour % 12 || 12
  const hourType = hour < 12 ? 'AM' : 'PM'

  if (minutes) newTime = `${newHour}:${minutes} ${hourType}`
  else newTime = `${newHour} ${hourType}`

  return newTime
}

export const convertHours24To12WithMinutes = (timeString: string) => {
  let newTime = timeString
  const hourSplit = timeString.split(':')

  const hour = +hourSplit[0]
  const minutes = hourSplit[1]

  const newHour = hour % 12 || 12
  const hourType = hour < 12 ? 'am' : 'pm'

  if (minutes) newTime = `${newHour}:${minutes} ${hourType}`
  else newTime = `${newHour}:00${hourType}`

  return newTime
}
