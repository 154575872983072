import { useAmenities } from '@swiftctrl/api-client-react'
import {
  Button,
  Checkbox,
  CheckboxValueType,
  Col,
  DownOutlined,
  Row,
  Spin,
  Swift,
  UpOutlined,
} from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useTranslation } from '../../../../hooks'
import { config } from '../../../../utils'
import { AmenitiesList } from './AmenitiesList'

interface Props {
  selectedAmenities: string[]
  setSelectedAmenities: (selectedAmenities: string[]) => void
}

const { SWIFT_ROOT_ENTITY_ID } = config

export const FiltersAmenities: Swift.FC<Props> = ({ selectedAmenities, setSelectedAmenities }) => {
  const { t } = useTranslation()
  const [amenities1, setAmenities1] = useState<string[]>()
  const [amenities2, setAmenities2] = useState<string[]>()
  const [amenities3, setAmenities3] = useState<string[]>()
  const [showAll, setShowAll] = useState<boolean>(false)
  const { data, isSuccess, isLoading } = useAmenities()
    .browse({
      browseOptions: { queryKey: 'amenities' },
      baseId: SWIFT_ROOT_ENTITY_ID,
      select: ['amenity'],
    })
    .query()

  const stringifyData = JSON.stringify(data)

  useEffect(() => {
    if (isSuccess && data) {
      const amenities1: string[] = []
      const amenities2: string[] = []
      const amenities3: string[] = []

      const allAmenities = data.data
      allAmenities.forEach((amenity, index: number) => {
        if (index < 3) {
          amenities1.push(amenity.amenity)
        } else if (index < 6) {
          amenities2.push(amenity.amenity)
        } else {
          amenities3.push(amenity.amenity)
        }
      })

      setAmenities1(amenities1)
      setAmenities2(amenities2)
      setAmenities3(amenities3)
    }
  }, [isSuccess, stringifyData])

  const checkAmenity = (checkedValues: CheckboxValueType[]) => {
    setSelectedAmenities(checkedValues as string[])
  }

  const toggleShowAll = () => {
    setShowAll((value) => !value)
  }

  return (
    <>
      <StyledFiltersTitle>{t('search_filter_amenities')}</StyledFiltersTitle>
      <StyledCheckGroup onChange={checkAmenity} value={selectedAmenities}>
        <StyledFilterAmenitiesList>
          {isLoading && (
            <LoadingRow justify="center">
              <Spin />
            </LoadingRow>
          )}
          <Col span={24} sm={12}>
            <AmenitiesList amenities={amenities1} />
          </Col>

          <Col span={24} sm={12}>
            <AmenitiesList amenities={amenities2} />
          </Col>
        </StyledFilterAmenitiesList>

        <StyledFilterAmenitiesList $hidden={!showAll}>
          <AmenitiesList amenities={amenities3} horizontal />
        </StyledFilterAmenitiesList>
      </StyledCheckGroup>
      {data?.data?.length! > 6 && (
        <StyledFilterShowAllButton
          type="link"
          size="large"
          icon={showAll ? <UpOutlined /> : <DownOutlined />}
          onClick={toggleShowAll}
        >
          {showAll ? t('search_filter_amenities_show_less') : t('search_filter_amenities_show_all')}
        </StyledFilterShowAllButton>
      )}
    </>
  )
}

const StyledFilterAmenitiesList = styled(Row)<{ $hidden?: boolean }>`
  margin-right: ${({ theme }) => theme.spacing.small};
  display: ${({ $hidden }) => ($hidden ? 'none' : 'default')};
  li {
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
  ul {
    margin: ${({ theme }) => theme.spacing.none};
    padding: ${({ theme }) => theme.spacing.none};
    list-style: none;
  }
  .ant-checkbox-wrapper {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`
const StyledFiltersTitle = styled.h2`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
const StyledFilterShowAllButton = styled(Button)`
  padding-left: ${({ theme }) => theme.spacing.none};
`
const StyledCheckGroup = styled(Checkbox.Group)`
  width: 100%;
`
const LoadingRow = styled(Row)`
  width: 100%;
`
