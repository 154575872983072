export const checkout_screen_en = {
  checkout_confirm_button: 'Confirm and pay',
  checkout_page_title: 'Confirm and pay',
  checkout_edit_link: 'Edit',
  checkout_confirm_message_part1: 'By selecting the button below, I agree to the',
  checkout_confirm_message_rental_rules: 'Rental Rules',
  checkout_confirm_message_covid_requirements: "SwiftConnect's COVID-19 Safety Requirements",
  checkout_confirm_message_part2: 'and the',
  checkout_confirm_message_guests_refund: 'Guest Refund Policy',
}
