import { Swift } from '@swiftctrl/swift-component-library'

interface Props {
  onClick: () => void
}

export const ClickableText: Swift.FC<Props> = ({ onClick, children }) => (
  <b>
    <u>
      <span onClick={onClick} className="link-decoration">
        {children}
      </span>
    </u>
  </b>
)
