export const room_details_en = {
  room_details_guidelines_text:
    'Your health and safety in the workplace is important to us. Please read through our professional distancing guidelines before choosing your workspace capacity.',
  room_details_guidelines_button: 'Read guidelines',
  room_details_amenities_title_details: 'Office Details',
  room_details_amenities_title_hours: 'Opening Hours',
  room_details_amenities_button: 'View all amenities',
  room_details_amenities_included: 'Included',
  room_details_amenities_not_included: 'Not included',
  room_details_opening_hours_closed: 'CLOSED',
  room_details_availabilities_start: 'Start',
  room_details_availabilities_end: 'End',
  room_details_availabilities_legend_available: 'Available',
  room_details_availabilities_legend_booked: 'Selected',
  room_details_availabilities_legend_closed: 'Unavailable',
  room_details_modal_guidelines_title: 'Guidelines',
  room_details_modal_amenities_title: 'Amenities',
  room_details_amenities_internet_access: 'Fast, Secure Wi-Fi',
  room_details_amenities_internet: 'Fast, Secure Wi-Fi',
  room_details_amenities_security_lock: 'Security lock',
  room_details_amenities_large_display: 'Large display',
  room_details_amenities_white_board: 'White board',
  room_details_amenities_projector: 'Projector',
  room_details_amenities_conference_phone: 'Conference phone',
  room_details_amenities_tea_and_coffee: 'Tea and coffee',
  room_details_amenities_regular_phone: 'Regular phone',
  room_details_amenities_catering_services: 'Catering services',
  room_details_amenities_privacy_screen: 'Privacy screen',
  room_details_amenities_drinks: 'Drinks',
  room_details_amenities_air_conditioning: 'Air conditioning',
  room_details_amenities_heating: 'Heating',
  room_details_amenities_natural_light: 'Natural light',
  room_details_amenities_standing_desk: 'Standing desk',
  room_details_amenities_quiet_zone: 'Quiet zone',
  room_details_amenities_wireless_charger: 'Wireless charger',
  room_details_amenities_cctv: 'CCTV',
  room_details_booking_title: 'Booking',
  room_details_booking_error_title: 'The time selected is no longer available',
  room_details_booking_error_description: 'Please select a new time frame',
  room_details_error_something_wrong: 'Seems like something when wrong, please try again',
  room_details_review: 'Review',
  room_details_next: 'Next',
  room_details_select_date_and_time: 'Select date and time',
  room_details_select_date_and_time_in_table: 'No date and time selected',
  room_details_per_hour: '/ hour',
  room_details_reservation_details: 'Reservation details',
  room_details_mobile_save_modal_title: 'Save changes?',
  room_details_mobile_save_modal_confirm_label: 'Save',
  room_details_mobile_save_modal_cancel_label: 'Discard',
  room_details_reservation_confirmation_modal_title: 'Reservation confirmed',
  room_details_reservation_confirmation_modal_footer_button_label: 'View reservation details',
  room_details_reservation_confirmation_modal_result_title: 'Thank you for your reservation',
}
