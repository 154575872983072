import { Spin, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const Preloader: Swift.FC = () => (
  <CenteredStyledDiv>
    <Spin size="large" />
  </CenteredStyledDiv>
)

const CenteredStyledDiv = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
