import { BookingTypes, BuildingTypes, RoomsTypes, useBooking } from '@swiftctrl/api-client-react'
import {
  CalendarOutlined,
  ClockCircleOutlined,
  Col,
  Divider,
  Result,
  Row,
  Skeleton,
  Swift,
} from '@swiftctrl/swift-component-library'
import { Button } from 'antd'
import { parse } from 'query-string'
import { useEffect, useRef } from 'react'
import { RouterProps, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { mixPanelTrack } from '../../../analytics'
import { AddressDisplay, CustomDrawer } from '../../../components'
import { useHandleUrlParams, useOverflow, useTranslation } from '../../../hooks'
import {
  bookingDateDisplay,
  differenceInDayFromToday,
  getBookingTimeDisplay,
  paths,
} from '../../../utils'

interface Props {
  onClose: () => void
  visible: boolean
  room: RoomsTypes
  address?: BuildingTypes['addresses'][0]
}
interface ReservationSearchParams {
  start: string
  end: string
  roomId: string
  guests: string
  bookingId: string
  booking?: 'confirm'
}

const { ReservationDetails } = paths

export const BookingConfirmationModal: Swift.FC<Props> = ({ onClose, visible, room, address }) => {
  const history: RouterProps['history'] = useHistory()
  const ref = useRef<HTMLDivElement>(null)
  const isOverflow: boolean | undefined = useOverflow(ref)
  const { t } = useTranslation()
  const { cleanParams } = useHandleUrlParams()

  // Search params are temporary to get the booking infos and complete the flow with the mocks
  const { search } = useLocation()
  const urlParamsBooking = parse(search) as unknown as ReservationSearchParams

  const { data: bookingResponse, isLoading } = useBooking()
    .at(urlParamsBooking.bookingId)
    .read({ queryKey: ['booking-read'] }, { enabled: Boolean(urlParamsBooking.bookingId) })

  const booking = bookingResponse?.data as BookingTypes
  const bookingStringify: string = JSON.stringify(booking)

  const handleClose = () => {
    cleanParams(['booking', 'bookingId'])
    onClose()
  }

  const goToReservationDetails = () => history.push(`/${ReservationDetails}/${booking?.booking_id}`)

  useEffect(() => {
    if (bookingStringify && booking.end_time) {
      mixPanelTrack('Book Space (Success)', {
        'Days From Today': differenceInDayFromToday(booking.end_time),
        'Guest Count': booking.guest_count,
      })
    }
  }, [bookingStringify])

  return (
    <CustomDrawer
      title={t('room_details_reservation_confirmation_modal_title')}
      visible={visible}
      onClose={handleClose}
      fixedHeight="625px"
      footer={
        <StyledAffixRow $shadow={isOverflow} justify="center">
          <StyledButton block onClick={goToReservationDetails}>
            {t('room_details_reservation_confirmation_modal_footer_button_label')}
          </StyledButton>
        </StyledAffixRow>
      }
    >
      <Col ref={ref} span={24}>
        <StyledResult
          status="success"
          title={t('room_details_reservation_confirmation_modal_result_title')}
        />
        <StyledDivider />
        <Col span={24}>
          {isLoading && (
            <>
              <Skeleton paragraph={{ rows: 2 }} active />
              <Skeleton paragraph={{ rows: 2 }} active />
            </>
          )}
          {booking && room && (
            <>
              <StyledInfoContainer>
                <StyledName>{room.entity_name}</StyledName>
                <StyledBasicText>{room.overseer_name}</StyledBasicText>
                <AddressDisplay address={address} />
              </StyledInfoContainer>
              <Col span={24}>
                <StyledRow align="middle">
                  <StyledIconContainer>
                    <CalendarOutlined />
                  </StyledIconContainer>
                  <StyledBasicText>{bookingDateDisplay(booking.start_time)}</StyledBasicText>
                </StyledRow>
                <StyledRow align="middle">
                  <StyledIconContainer>
                    <ClockCircleOutlined />
                  </StyledIconContainer>
                  <StyledBasicText>
                    {getBookingTimeDisplay(booking.start_time, booking.end_time)}
                  </StyledBasicText>
                </StyledRow>
                {/* <StyledRow align="middle">
                  <StyledIconContainer>
                    <DollarOutlined />
                  </StyledIconContainer>
                  <StyledBasicText>{getFormattedPayment(booking.payment.total!)}</StyledBasicText>
                </StyledRow> */}
              </Col>
            </>
          )}
        </Col>
      </Col>
    </CustomDrawer>
  )
}

const StyledResult = styled(Result)`
  padding-bottom: ${({ theme }) => theme.spacing.xxLarge};
  padding-top: ${({ theme }) => theme.spacing.small};
  padding-left: 0px;
  padding-right: 0px;
`

const StyledDivider = styled(Divider)`
  margin-top: 0px;
`

const StyledName = styled.p`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const StyledBasicText = styled.p`
  margin-bottom: 0px;
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
`

const StyledIconContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.spacing.medium} !important;
`

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`

const StyledInfoContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`
const StyledButton = styled(Button)`
  height: 40px;
`

const StyledAffixRow = styled(Row)<{ $shadow?: boolean }>`
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: #fff;
  box-shadow: ${({ $shadow }) =>
    $shadow
      ? ` 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12)`
      : 'none'};
`
