import mixpanel from 'mixpanel-browser'
import { config, mapToMixpanel } from '../utils'

const {
  mixpanel: { token, proxyUrl },
} = config

export type MixPanelTrackKey =
  | 'View Space'
  | 'View Space - Change Date'
  | 'Book Space (Start)'
  | 'Book Space (Start Payment)'
  | 'Book Space (Success)'
  | 'Book Space (Abort)'
  | 'Book Space (Failure)'
  | 'Browse My Reservations'
  | 'View My Reservation'
  | 'Cancel My Reservation'
  | 'Search Spaces'
  | 'Search Spaces (Filter)'
export interface MixPanelTrackSuperProperties {
  'App Version': string
  App: string
}

export interface MixPanelTrackProperties {
  'Days From Today': number
  'Guest Count': number
}

export const initMixpanel = () =>
  mixpanel.init(token!, { debug: process.env.NODE_ENV === 'development', api_host: proxyUrl })

export const identifyMixpanel = (id: string) => {
  const uuid = mapToMixpanel('mixpanel', id)
  mixpanel.identify(uuid)
}

export const mixPanelTrack = (
  key: MixPanelTrackKey,
  properties?: Partial<MixPanelTrackProperties>,
) => mixpanel.track(key, properties)

export const mixPanelRegister = (properties: Partial<MixPanelTrackSuperProperties>) =>
  mixpanel.register(properties)

export const mixPanelReset = () => mixpanel.reset()
