import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'
import { mixPanelTrack } from '../../analytics'
import { ReservationsMobileScreen } from './ReservationsMobileScreen'
import { ReservationsScreen } from './ReservationsScreen'

interface Props {}

const ReservationListRenderer: React.FC<Props> = () => {
  const { isMobileView } = useTheme()
  useEffect(() => {
    mixPanelTrack('Browse My Reservations')
  }, [])
  return isMobileView ? <ReservationsMobileScreen /> : <ReservationsScreen />
}

export default ReservationListRenderer
