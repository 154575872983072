import { ScheduleTypes } from '@swiftctrl/api-client-react'
import { getSubSchedule } from '../schedule-utils'

export const getTimeRange = (schedule?: ScheduleTypes) => {
  if (!schedule) {
    return {}
  }

  const subSchedule = getSubSchedule(schedule)

  if (!subSchedule) {
    return {}
  }

  let start: number = 23
  let end: number = 0

  for (let day of subSchedule?.day_times) {
    if (day.start_time !== null) {
      const open = +Number(day.start_time.split(':')[0])
      const close = +Number(day.end_time.split(':')[0])

      if (open < start) start = open

      if (close > end) end = close
    }
  }

  start = start > 0 ? start - 1 : 0
  end = end < 23 ? end + 1 : 23

  const total = end - start

  if (total < 8) {
    const difference = 7 - total
    const additionalHours = Math.round(difference / 2)
    const minimumAm = 0 + start
    const maximumPm = 23 - end

    if (additionalHours > minimumAm) {
      start = start - minimumAm
      end = end + difference - minimumAm
    } else if (additionalHours > maximumPm) {
      start = start - difference + maximumPm
      end = end + maximumPm
    } else {
      start = start - additionalHours
      end = end + additionalHours
    }
  }
  return { start, end }
}
