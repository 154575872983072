export const general_en = {
  login_button: 'Login',
  common_details: 'Details',
  calendar: 'Calendar',
  apply: 'Apply',
  fallback_500_title: 'Something went wrong',
  fallback_500_description: 'It looks like something went wrong. Please try again later.',
  fallback_404_default_title: 'There’s nothing here...',
  fallback_404_default_description:
    "It looks like you've reached a URL that doesn’t exist. Please return to home by clicking the button below.",
  fallback_404_button_label: 'Go back home',
  fallback_404_screen_description:
    "Looks like this page doesn't exist. Please return to {{section}} by clicking the button below.",
  fallback_404_screen_button_label: 'Go to {{section}}',
  fallback_room_details_screen_description:
    "Looks like this page doesn't exist. Please return to 'search' by clicking the button below.",
  fallback_room_details_screen_button_label: 'Go to search',
  fallback_room_reservation_screen_description:
    "Looks like this page doesn't exist. Please return to 'My reservation' by clicking the button below.",
  fallback_room_reservation_screen_button_label: 'Go to My reservation',
  session_expired_modal_call_to_action: 'Sign in again',
  session_expired_stay_as_guest_title: 'Stay as guest',
  session_expired_modal_title: 'Session Expired',
  session_expired_modal_content:
    'Your session has expired. You will have to sign in to resume your session.',
}
