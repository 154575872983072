import { Affix, MobileToast, Swift, Tabs } from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useLocation } from 'react-router-dom'
import { useHandleUrlParams, useTranslation } from '../../hooks'
import { TabType } from '../../models'
import { ReservationsList } from '../../screen-components'
import { reservationListParamsSchema } from '../../validation'

const { TabPane } = Tabs

interface LocationState {
  afterCancel?: boolean
}

export const ReservationsMobileScreen: Swift.FC = () => {
  const { currentParams, updateParams } = useHandleUrlParams()
  const validTabAddresses = reservationListParamsSchema.isValidSync(currentParams.tab)
  const { t } = useTranslation()
  const [showShadow, setShowShadow] = useState<boolean | undefined>(false)

  useEffect(() => {
    if (!validTabAddresses) {
      updateParams({ tab: 'upcoming' })
    }
  }, [])

  const handleTabChange = (key: string) => updateParams({ tab: key as TabType })

  const location = useLocation<LocationState>()
  const isCanceled = location?.state?.afterCancel

  useEffect(() => {
    if (isCanceled) {
      MobileToast.show({
        icon: 'success',
        content: t('reservation_mobile_cancel_success'),
      })
    }
  }, [isCanceled])

  return (
    <>
      <Affix onChange={setShowShadow}>
        <StyledDiv $shadow={showShadow}>
          <StyledHeader $shadow={showShadow}>{t('reservations_my_reservations')}</StyledHeader>
          <Tabs
            defaultActiveKey="upcoming"
            size={showShadow ? 'small' : 'large'}
            onChange={handleTabChange}
            activeKey={currentParams.tab}
          >
            <TabPane tab={t('reservations_upcoming')} key="upcoming"></TabPane>
            <TabPane tab={t('reservations_past')} key="past"></TabPane>
          </Tabs>
        </StyledDiv>
      </Affix>
      <StyledMobileContainerTop>
        <ReservationsList mobileView />
      </StyledMobileContainerTop>
    </>
  )
}

const StyledMobileContainerTop = styled.div`
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};
  margin-bottom: 24px;
`

const StyledHeader = styled.h1<{ $shadow?: boolean }>`
  margin-bottom: ${({ theme, $shadow }) => ($shadow ? '8px' : '16px')};
  margin-top: 0px;
  font-size: ${({ theme, $shadow }) =>
    $shadow
      ? theme.designTokens.fonts.sizes['14px'].fontSize
      : theme.designTokens.fonts.sizes['24px'].fontSize};
  background-color: #fff;
`

const StyledDiv = styled.div<{ $shadow?: boolean }>`
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme, $shadow }) => ($shadow ? theme.spacing.medium : '24px')};
  background-color: #fff;

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs > .ant-tabs-nav {
    margin: 0 0 24px 0;
  }

  box-shadow: ${({ $shadow }) =>
    $shadow
      ? `0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12)`
      : 'none'};
`
