import { AddressTypes, BuildingTypes } from '@swiftctrl/api-client-react'
import { Typography } from '@swiftctrl/swift-component-library'
import { pick } from 'lodash'
import styled from 'styled-components'

interface Props {
  address?: AddressTypes
}

export const AddressDisplay = ({ address }: Props) => {
  const fullAddressString = getFullAddress(address)
  return <StyledAddressDisplay type="secondary">{fullAddressString}</StyledAddressDisplay>
}

const getFullAddress = (address?: BuildingTypes['addresses'][0]) => {
  if (!address) {
    return ''
  }
  const addressObject = pick(address, [
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'country',
    'zip',
  ])

  const addressArray: string[] = []

  Object.values(addressObject).forEach((value) => {
    if (Boolean(value)) {
      addressArray.push(value)
    }
  })

  return addressArray.join(', ')
}

const StyledAddressDisplay = styled(Typography.Text)`
  display: block;
`
