import { CalendarOutlined, Col, Row, Swift } from '@swiftctrl/swift-component-library'
import { format } from 'date-fns'
import styled from 'styled-components'
import { MobileCalendarDrawer } from './MobileCalendarDrawer'
import { useBoolean } from '../../hooks'
import { config } from '../../utils'

interface Props {
  value: Date
  onDateChange: (val: Date | null) => void
  disablePastDate?: boolean
}

export const MobileDatePicker: Swift.FC<Props> = ({ value, onDateChange, disablePastDate }) => {
  const [isCalendarOpen, openCalendar, closeCalendar] = useBoolean()
  const {
    appConstants: { dateLongDisplayVariant },
  } = config

  return (
    <>
      <StyledDatePickerBox justify="space-between" onClick={openCalendar}>
        <Col>{format(value, dateLongDisplayVariant)}</Col>
        <Col>
          <CalendarOutlined />
        </Col>
      </StyledDatePickerBox>
      <MobileCalendarDrawer
        selectedDate={value}
        onDateChange={onDateChange}
        onClose={closeCalendar}
        visible={isCalendarOpen}
        disablePastDates={disablePastDate}
      />
    </>
  )
}

const StyledDatePickerBox = styled(Row)`
  padding: 6.5px 11px 6.5px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 214px;
`
