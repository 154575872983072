import { ClientProps } from '@swiftctrl/api-client-react'

export const config = {
  swiftConnectUrls: {
    baseUrl: process.env.REACT_APP_SPACE_API_URL,
    accountUrl: process.env.REACT_APP_ACCOUNTS_URL,
    securityUrl: process.env.REACT_APP_SECURITY_URL,
    spaceApiUrl: process.env.REACT_APP_SPACE_API_URL,
    spaceUrl: process.env.REACT_APP_SPACE_URL,
  },
  googleMap: {
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  },
  SWIFT_ROOT_ENTITY_ID: '10000000-0000-4000-8000-000000000000',
  appConstants: {
    numberOfSkeletons: 12,
    cityDistanceKm: 45000,
    apiDirectoryVersion: process.env.REACT_APP_SPACE_API_VERSION,
    maximumGuests: 10,
    minimumGuests: 0,
    listResultsPerPage: 50,
    timeRangeDisplayFormat: 'hhA',
    timeRangeFieldFormat: 'hh:mm a',
    dateDisplayFormat: 'MMM. do, yyyy',
    dateDisplayFormatWithoutPonctuation: 'MMMM do yyyy',
    dateLongDisplayFormat: 'MMMM do, YYYY',
    dateLongDisplayVariant: 'MMMM do, yyyy',
    dateFullDisplay: 'iiii, MMMM do yyyy',
    shortDate: 'MM/dd',
    timeFormateForDesktop: 'h:mmaa',
    timeFormatForMobile: 'haa',
    timeFormatOriginal: 'h:mmaaa',
    timeConvertDate: 'yyyy-MM-dd',
    monthDate: 'MMMM do',
    mobileMaxPortraitLength: 500,
    mobileMaxLandscapeLength: 900,
    mobileMaxLandscapeHeight: 500,
    SIGNING_LOCAL_STORAGE_KEY: 'SPACE_CLOUD_SIGN',
  },

  defaultNodeSdkConfig: {
    environment: {
      baseUrl: process.env.REACT_APP_SPACE_API_URL,
      apiVersion: parseInt(process.env.REACT_APP_SPACE_API_VERSION!, 10),
    },
  } as ClientProps['config'],
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_SDK_KEY,
    externalMapping: {
      mixpanel: '9ceb5381-20a3-469b-9113-7455b453908f',
    },
    proxyUrl: 'https://6r05-fr3r3-mx.swiftconnect.io',
  },
  optimizely: {
    sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
    proxyUrl: `https://6r05-fr3r3-0p71.swiftconnect.io/${process.env.REACT_APP_OPTIMIZELY_SDK_KEY}`,
  },
}
