import { Swift, Tabs } from '@swiftctrl/swift-component-library'
import { useEffect } from 'react'
import styled from 'styled-components'
import { ScreenContainer } from '../../components'
import { useHandleUrlParams, useTranslation } from '../../hooks'
import { TabType } from '../../models'
import { ReservationsList } from '../../screen-components'
import { reservationListParamsSchema } from '../../validation'

export const ReservationsScreen: Swift.FC = () => {
  const { currentParams, updateParams } = useHandleUrlParams()
  const validTabAddresses = reservationListParamsSchema.isValidSync(currentParams.tab)
  const { t } = useTranslation()

  useEffect(() => {
    if (!validTabAddresses) {
      updateParams({ tab: 'upcoming' })
    }
  }, [])

  const handleTabChange = (key: string) => updateParams({ tab: key as TabType })

  const { TabPane } = Tabs

  return (
    <ScreenContainer>
      <StyledTitle className="reservations-title">{t('reservations_my_reservations')}</StyledTitle>

      <Tabs
        onChange={handleTabChange}
        defaultActiveKey="upcoming"
        activeKey={currentParams.tab}
        size="large"
      >
        <TabPane tab={t('reservations_upcoming')} key="upcoming">
          <ReservationsList />
        </TabPane>

        <TabPane tab={t('reservations_past')} key="past">
          <ReservationsList />
        </TabPane>
      </Tabs>
    </ScreenContainer>
  )
}

const StyledTitle = styled.h1`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.xxLarge};
`
