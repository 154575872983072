import { PreLoader } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const CenteredPreLoader = () => {
  return (
    <StyledContainer>
      <PreLoader />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 2em;
  display: flex;
  justify-content: center;
`
