import { Col, Row, Swift } from '@swiftctrl/swift-component-library'
import { ReservationCardLoadingSkeleton } from './ReservationCardLoadingSkeleton'

export const ReservationsListLoadingSkeleton: Swift.FC = () => (
  <Row gutter={[0, 64]}>
    <Col span={24}>
      <ReservationCardLoadingSkeleton />
    </Col>
    <Col span={24}>
      <ReservationCardLoadingSkeleton />
    </Col>
    <Col span={24}>
      <ReservationCardLoadingSkeleton />
    </Col>
  </Row>
)
