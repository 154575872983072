import { Button, Drawer, Row, Swift } from '@swiftctrl/swift-component-library'
import { add, format, getHours, setHours } from 'date-fns'
import { useState } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'
import styled, { CSSObject } from 'styled-components'

import { useScreenScrollLock, useStoreParams, useTranslation } from '../../../../hooks'
import { RestApiParams } from '../../../../models'
import { config, getLocationInputValue, setHoursFromNow } from '../../../../utils'
import { MobileSearch } from './MobileSearch'
import { MobileSearchCalendarPage } from './MobileSearchCalendarPage'

interface Props {
  visible: boolean
  onClose: () => void
  currentParams: RestApiParams
  onSearch: (storeParams: RestApiParams) => void
}

export type MobileSearchDrawerState = 'Search' | 'Calendar'

const defaultStyle: React.CSSProperties = {
  transition: `transform 250ms ease-out, opacity 300ms ease-in`,
  width: '100%',
  position: 'absolute',
}

const calendarTransitionStyles: Record<TransitionStatus, CSSObject> = {
  entering: { transform: 'translateX(100%)' },
  entered: { transform: 'translateX(0%)', position: 'relative' },
  exiting: {
    transform: 'translateX(100%)',
    position: 'fixed',
    top: 0,
    overflow: 'hidden',
    height: 'calc(100vh - 72px)',
  },
  exited: { transform: 'translateX(100%)' },
  unmounted: {},
}

const searchTransitionStyles: Record<TransitionStatus, CSSObject> = {
  entering: { transform: 'translateX(-100%)' },
  entered: { transform: 'translateX(0%)', position: 'relative' },
  exiting: { transform: 'translateX(-100%)' },
  exited: { transform: 'translateX(-100%)' },
  unmounted: {},
}

export const MobileSearchDrawer: Swift.FC<Props> = ({
  currentParams,
  visible,
  onClose,
  onSearch,
}) => {
  const { t } = useTranslation()

  const {
    appConstants: { timeConvertDate },
  } = config

  const { currentStoreParams, updateStoreParams } = useStoreParams()

  const isSearchParamsDirty = JSON.stringify(currentParams) !== JSON.stringify(currentStoreParams)

  const nextAvailableDate =
    getHours(new Date()) === 23
      ? format(add(setHours(new Date(), 0), { days: 1 }), timeConvertDate)
      : format(new Date(), timeConvertDate)

  const hourNow = new Date(setHoursFromNow(1)).getHours()
  const hourNext = new Date(setHoursFromNow(2)).getHours()

  const [step, setStep] = useState<MobileSearchDrawerState>('Search')
  const [selectedDate, setSelectedDate] = useState(
    currentStoreParams?.date || currentParams.date || '',
  )
  const [locationSearchValue, setLocationSearchValue] = useState<string>(
    getLocationInputValue({
      city: currentParams.city,
      state: currentParams.state,
    }),
  )

  const handleLocationSearchChange = (text: string) => setLocationSearchValue(text)

  const handleChangeSelectedDate = (date: string) => setSelectedDate(date)
  const handleSwitchToSearch = () => setStep('Search')
  const handleSwitchToCalendar = () => setStep('Calendar')

  const handleSearch = () => {
    onSearch(currentStoreParams!)
    onClose()
  }

  const handleOnClose = () => {
    updateStoreParams(currentParams)
    setSelectedDate(currentParams.date || '')
    setLocationSearchValue(
      getLocationInputValue({
        city: currentParams.city,
        state: currentParams.state,
      }),
    )
    onClose()
  }

  const handleOnCancelCalendar = () => {
    setSelectedDate(currentStoreParams?.date || '')
    updateStoreParams({
      ...currentStoreParams,
      startTime: currentParams.startTime,
      endTime: currentParams.endTime,
    })
    handleSwitchToSearch()
  }

  const handleApplyDate = () => {
    updateStoreParams({ ...currentStoreParams, date: selectedDate })
    handleSwitchToSearch()
  }

  const handleOnClear = () => {
    setSelectedDate(nextAvailableDate)
    setLocationSearchValue('')
    updateStoreParams({
      city: undefined,
      state: undefined,
      latitude: undefined,
      longitude: undefined,
      date: nextAvailableDate,
      startTime: hourNow,
      endTime: hourNext,
      guests: 1,
    })
  }

  useScreenScrollLock(visible)

  return (
    <StyledMobileSearchDrawer
      placement="bottom"
      height="100%"
      closable={false}
      visible={visible}
      destroyOnClose
      footer={
        <StyledDrawerActionBar justify={step === 'Search' ? 'space-between' : 'end'} align="middle">
          {step === 'Search' ? (
            <>
              <StyledClearAllButton onClick={handleOnClear} type="link">
                {t('search_filter_clear_all')}
              </StyledClearAllButton>
              <Button
                disabled={!isSearchParamsDirty}
                onClick={handleSearch}
                type="primary"
                size="large"
              >
                {t('search_search')}
              </Button>
            </>
          ) : (
            <Button type="primary" size="large" onClick={handleApplyDate}>
              {t('search_apply')}
            </Button>
          )}
        </StyledDrawerActionBar>
      }
    >
      <StyledContainer $step={step}>
        <Transition
          unmountOnExit
          in={step === 'Search'}
          timeout={{ appear: 0, enter: 50, exit: 250 }}
        >
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...searchTransitionStyles[state],
              }}
            >
              <MobileSearch
                locationSearchOnChange={handleLocationSearchChange}
                selectedDate={selectedDate}
                onCalendarClick={handleSwitchToCalendar}
                onClose={handleOnClose}
                locationSearchValue={locationSearchValue}
              />
            </div>
          )}
        </Transition>
        <Transition
          unmountOnExit
          in={step === 'Calendar'}
          timeout={{ appear: 0, enter: 50, exit: 250 }}
        >
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...calendarTransitionStyles[state],
              }}
            >
              <MobileSearchCalendarPage
                onDateChange={handleChangeSelectedDate}
                onCancel={handleOnCancelCalendar}
                selectedDate={selectedDate}
              />
            </div>
          )}
        </Transition>
      </StyledContainer>
    </StyledMobileSearchDrawer>
  )
}

const StyledMobileSearchDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-drawer-footer {
    height: 72px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  }
`
const StyledDrawerActionBar = styled(Row)`
  height: 100%;
`

const StyledClearAllButton = styled(Button)`
  padding: 0px;
`

const StyledContainer = styled.div<{ $step: MobileSearchDrawerState }>`
  overflow: hidden;
  > div {
    padding: ${({ theme }) => theme.spacing.medium};
  }
`
