import {
  Button,
  CloseOutlined,
  Col,
  Input,
  MinusOutlined,
  PlusOutlined,
  Row,
  Swift,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useTranslation } from '../../hooks'
import { ComponentSizes } from '../../models'
import { changeGuestNumber, config } from '../../utils'

interface Props {
  numberGuestsParent: number
  setNumberGuestsParent: (value: number) => void
  allowZeroGuests: boolean
  minNumberOfGuests?: number
  maxNumberOfGuests?: number
  resetButton?: boolean
  large?: boolean
  resetCounter?: boolean
  staticSize?: boolean
  onFocusGuest?: () => void
  restrictedGuests?: boolean
}

const {
  appConstants: { maximumGuests, minimumGuests },
} = config

export const NumberGuestsInput: Swift.FC<Props> = ({
  numberGuestsParent,
  setNumberGuestsParent,
  allowZeroGuests,
  minNumberOfGuests,
  maxNumberOfGuests,
  resetButton = false,
  large = false,
  resetCounter = false,
  staticSize = false,
  onFocusGuest,
  restrictedGuests,
  className,
}) => {
  const [toResetCounter, setToResetCounter] = useState(resetCounter)
  const [numberGuests, setNumberGuests] = useState(numberGuestsParent)
  const [showNumberGuests] = useState(true)

  const { t } = useTranslation()

  const showGuestNumberLabel = (withGuestsLabel: boolean) => {
    if (!showNumberGuests || (maxNumberOfGuests !== 0 && numberGuests <= minimumGuests)) {
      return undefined
    }

    const numberOfGuestWithLabel: string = `${numberGuests} ${
      withGuestsLabel
        ? t(
            numberGuests > 1
              ? 'search_bar_smart_search_guests_other'
              : 'search_bar_smart_search_guests_one',
          )
        : ''
    }`
    if (minNumberOfGuests !== 0 && numberGuests >= maximumGuests) {
      return numberOfGuestWithLabel
    }
    if (maxNumberOfGuests === 0 && minNumberOfGuests === 0) {
      if (numberGuests <= minimumGuests) {
        return undefined
      }
      if (numberGuests >= maximumGuests) {
        return numberOfGuestWithLabel
      }
    }
    return numberOfGuestWithLabel
  }

  useEffect(() => {
    if (resetCounter) {
      setToResetCounter(resetCounter)
    }
  }, [resetCounter])

  useEffect(() => {
    if (toResetCounter) {
      setNumberGuests(0)
    }
  }, [toResetCounter])

  useEffect(() => {
    setNumberGuests(numberGuestsParent)
  }, [numberGuestsParent])

  const resetNumberOfGuests = () => {
    const newNumber = changeGuestNumber(numberGuests, maxNumberOfGuests!, minNumberOfGuests!, 0)
    setNumberGuests(newNumber)
    setNumberGuestsParent(newNumber)
  }

  const onManualChange = (element: HTMLInputElement) => {
    let value = +element.value
    if (restrictedGuests) {
      if (value > maxNumberOfGuests!) {
        value = maxNumberOfGuests!
        element.blur()
      }
      if (value < minNumberOfGuests!) {
        value = minNumberOfGuests!
        element.blur()
      }
    }
    setNumberGuests(Math.round(value))
    setNumberGuestsParent(Math.round(value))
  }

  const [isFocused, setIsFocused] = useState(false)
  const guestInput = (
    <StyledGuestField
      type={isFocused ? 'number' : 'text'}
      min={0}
      value={!isFocused ? showGuestNumberLabel(true) : undefined}
      placeholder={t('search_bar_smart_search_how_many_guests')}
      size="large"
      prefix={<UserOutlined />}
      onChange={(e) => onManualChange(e.target)}
      onFocus={(_) => {
        onFocusGuest?.()
        setIsFocused(true)
      }}
      onBlur={(_) => setIsFocused(false)}
    />
  )

  const flexibleLayout = {
    input: {
      xs: 16,
      sm: 16,
      md: 16,
      lg: large ? 10 : 12,
      xl: 16,
      xxl: 16,
    },
    buttons: {
      xs: 4,
      sm: 4,
      md: 4,
      lg: large ? 7 : 6,
      xl: 4,
      xxl: 4,
    },
  }

  const staticInputSize = 16
  const staticButtonSize = 4

  const staticLayout = {
    input: {
      xs: staticInputSize,
      sm: staticInputSize,
      md: staticInputSize,
      lg: staticInputSize,
      xl: staticInputSize,
      xxl: staticInputSize,
    },
    buttons: {
      xs: staticButtonSize,
      sm: staticButtonSize,
      md: staticButtonSize,
      lg: staticButtonSize,
      xl: staticButtonSize,
      xxl: staticButtonSize,
    },
  }

  const componentSize: ComponentSizes = staticSize ? staticLayout : flexibleLayout

  return (
    <StyledMainContainer className={className}>
      <Col
        xs={componentSize.input.xs}
        sm={componentSize.input.sm}
        md={componentSize.input.md}
        lg={componentSize.input.lg}
        xl={componentSize.input.xl}
        xxl={componentSize.input.xxl}
      >
        <StyledInputContainer>{guestInput}</StyledInputContainer>
        <StyledInputContainer $invertDisplayInMediaQuery>{guestInput}</StyledInputContainer>
        <StyledClearButton
          $visible={resetButton && numberGuests !== 0}
          shape="circle"
          size="small"
          type="text"
          icon={<CloseOutlined />}
          onClick={resetNumberOfGuests}
        />
      </Col>
      <Col
        xs={componentSize.buttons.xs}
        sm={componentSize.buttons.sm}
        md={componentSize.buttons.md}
        lg={componentSize.buttons.lg}
        xl={componentSize.buttons.xl}
        xxl={componentSize.buttons.xxl}
      >
        <StyledSmallButton
          size="large"
          disabled={
            restrictedGuests
              ? (numberGuests === 1 && !allowZeroGuests) ||
                numberGuests === 0 ||
                numberGuests === -1 ||
                (numberGuests === minNumberOfGuests && maxNumberOfGuests !== 0)
                ? true
                : false
              : numberGuests <= 0
          }
          onClick={() => {
            const newNumber = changeGuestNumber(
              numberGuests,
              maxNumberOfGuests!,
              minNumberOfGuests!,
              -1,
            )
            setNumberGuests(newNumber)
            setNumberGuestsParent(newNumber)
          }}
        >
          <MinusOutlined />
        </StyledSmallButton>
      </Col>
      <Col
        xs={componentSize.buttons.xs}
        sm={componentSize.buttons.sm}
        md={componentSize.buttons.md}
        lg={componentSize.buttons.lg}
        xl={componentSize.buttons.xl}
        xxl={componentSize.buttons.xxl}
      >
        <StyledSmallButton
          size="large"
          disabled={
            restrictedGuests
              ? (numberGuests === 10 && maxNumberOfGuests === 0) ||
                (numberGuests === maxNumberOfGuests && maxNumberOfGuests !== 0)
                ? true
                : false
              : numberGuests >= 10
          }
          onClick={() => {
            const newNumber = changeGuestNumber(
              numberGuests,
              maxNumberOfGuests!,
              minNumberOfGuests!,
              1,
            )
            setNumberGuests(newNumber)
            setNumberGuestsParent(newNumber)
          }}
        >
          <PlusOutlined />
        </StyledSmallButton>
      </Col>
    </StyledMainContainer>
  )
}

const StyledMainContainer = styled(Row)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: ${({ theme }) => theme.spacing.none};
  }

  .anticon-close {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  }
`

const StyledGuestField = styled(Input)`
  margin: ${({ theme }) => theme.spacing.xSmall} ${({ theme }) => theme.spacing.none};
`
const StyledClearButton = styled(Button)<{ $visible?: boolean }>`
  color: rgb(175, 175, 175);
  position: absolute;
  bottom: 12px;
  z-index: 250;
  right: 10px;
  display: ${({ $visible }) => ($visible ? 'default' : 'none')};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`

const StyledInputContainer = styled.div<{ $invertDisplayInMediaQuery?: boolean }>`
  @media only screen and (max-width: 1200px) {
    display: ${({ $invertDisplayInMediaQuery: $invertInMediaQuery }) =>
      $invertInMediaQuery ? 'block' : 'none'};
  }

  @media only screen and (min-width: 1200px) {
    display: ${({ $invertDisplayInMediaQuery: $invertInMediaQuery }) =>
      $invertInMediaQuery ? 'none' : 'block'};
  }
`

const StyledSmallButton = styled(Button)`
  margin-top: 0.25em;
  position: relative;
  float: right;
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
  width: 90%;
`
