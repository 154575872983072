import { Swift, Tag } from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

type GreenTagValues = 'completed' | 'paid'
type RedTagValues = 'refunded' | 'cancelled'
type BlueTagValues = 'upcoming'

export type TagValue = GreenTagValues | RedTagValues | BlueTagValues
type TagColor = 'blue' | 'green' | 'red'

interface Props {
  value: TagValue
}

/** We can scale this with new value in future if needed and its a quick one.
 * all we need is update respected color array and update the corresponding type */
const greenTagValues: TagValue[] = ['completed', 'paid']
const redTagValues: TagValue[] = ['refunded', 'cancelled']
const blueTagValues: TagValue[] = ['upcoming']

export const ReservationStatusTag: Swift.FC<Props> = ({ value }) => {
  const [color, setColor] = useState<TagColor>('blue')
  const displayValue = value.charAt(0).toUpperCase() + value.slice(1)
  useEffect(() => {
    switch (true) {
      case greenTagValues.includes(value):
        return setColor('green')
      case redTagValues.includes(value):
        return setColor('red')
      case blueTagValues.includes(value):
        return setColor('blue')
      default:
        return setColor('blue')
    }
  }, [value])

  return <StyledTag color={color}>{displayValue}</StyledTag>
}

const StyledTag = styled(Tag)`
  width: max-content;
`
