import { RoomsTypes, useRoom } from '@swiftctrl/api-client-react'
import { Button, Col, Row, Spin, Swift, Typography } from '@swiftctrl/swift-component-library'
import styled, { useTheme } from 'styled-components'
import { RoomCard } from '../../components'
import { useTranslation } from '../../hooks'
import { RoomSkeleton } from '../search'

interface Props {
  buildingId: string
}

const browse_page_limit = 6

export const AvailableRooms: Swift.FC<Props> = ({ buildingId }) => {
  const { t } = useTranslation()
  const { isMobileView } = useTheme()

  const {
    data: roomsData,
    isLoading: isLoadingRoom,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useRoom()
    .browse({
      browseOptions: {
        queryKey: 'building-rooms-list',
      },
      baseId: buildingId,
      select: ['room_id', 'images', 'entity_name', 'max_capacity', 'min_capacity', 'price'],
      limit: browse_page_limit,
    })
    .infiniteQuery({
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length
        return (lastPage as any).data.length === browse_page_limit
          ? nextPage * browse_page_limit
          : undefined
      },
    })

  const { data: roomsCountData, isLoading: isLoadingRoomsCountData } = useRoom()
    .browse({
      browseOptions: {
        queryKey: 'building-rooms-count',
      },
      baseId: buildingId,
    })
    .query()

  const count = roomsCountData?.data.length

  const rooms = getRoomList(roomsData)

  return (
    <>
      <StyledRow
        $isMobileView={isMobileView}
        gutter={[0, 16]}
        justify="space-between"
        align="middle"
      >
        <Col>
          <StyledTitle level={3}>
            {t('rooms')} {isLoadingRoomsCountData ? <Spin /> : `(${count})`}
          </StyledTitle>
        </Col>
      </StyledRow>

      {isLoadingRoom ? (
        <Row gutter={[24, 24]}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} key={index}>
              <RoomSkeleton key={index} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row gutter={[24, 24]}>
          {rooms.map((room, i) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} key={room.room_id}>
              <RoomCard room={room} />
            </Col>
          ))}
        </Row>
      )}

      {hasNextPage && (
        <StyledShowmore $isMobileView={isMobileView}>
          <Button size="large" type="link" loading={isFetching} onClick={() => fetchNextPage()}>
            {t('show_more')}
          </Button>
        </StyledShowmore>
      )}
    </>
  )
}

const getRoomList = (rooms?: any) => {
  if (!rooms?.pages.length) {
    return []
  }
  const roomsArray: RoomsTypes[] = []
  rooms?.pages.forEach((response: any) => {
    response.data.forEach((room: any) => {
      roomsArray.push(room)
    })
  }, [])
  return roomsArray
}

const StyledTitle = styled(Typography.Title)`
  margin-bottom: ${({ theme }) => theme.spacing.none}!important;
`
const StyledRow = styled(Row)<{ $isMobileView: boolean }>`
  margin-bottom: ${({ theme, $isMobileView }) => ($isMobileView ? theme.spacing.medium : '24px')};
`

const StyledShowmore = styled.div<{ $isMobileView: boolean }>`
  margin-top: ${({ $isMobileView, theme }) => ($isMobileView ? theme.spacing.medium : '24px')};
  button {
    padding-left: ${({ theme }) => theme.spacing.none};
  }
`
