import { mixed, number, object, ref, string } from 'yup'

import { ReservationParams, RestApiParams, Shape } from '../models'
import { isDateValid } from '../utils'

export const roomDetailsParamsSchema = object().shape<Shape<RestApiParams>>({
  guests: number().min(1).integer().required(),
  date: string()
    .test('date', 'Wrong date format', (value: string | undefined) =>
      value ? isDateValid(value) : false,
    )
    .required(),
  startTime: number().min(0).integer().lessThan(24),
  endTime: number().positive().integer().moreThan(ref('startTime')).lessThan(25),
  booking: mixed().oneOf<ReservationParams['booking']>(['confirm', 'cancel', 'pending']),
  bookingId: mixed().oneOf([number(), string()]),
})
