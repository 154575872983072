import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

import { RestApiParams } from '../../models'
import { roomDetailsParamsSchema } from '../../validation'
import { useHandleUrlParams } from '../router'
import { config, getInitialRoomDetailParams, isDatePassed } from './../../utils'

const {
  appConstants: { timeConvertDate },
} = config

export const useValidateRoomDetailParams = () => {
  const { currentParams } = useHandleUrlParams()

  const [validatedParams, setValidatedParams] = useState<RestApiParams>({})

  useEffect(() => {
    if (isEmpty(currentParams)) {
      return setValidatedParams(getInitialRoomDetailParams())
    }
    const validParams: boolean = roomDetailsParamsSchema.isValidSync(currentParams)

    if (validParams) {
      const isPast: boolean = isDatePassed(currentParams.date!)
      const modifiedParams: RestApiParams = {
        ...currentParams,
        date: isPast ? format(new Date(), timeConvertDate) : currentParams.date,
      }
      setValidatedParams(modifiedParams)
    } else {
      setValidatedParams(getInitialRoomDetailParams())
    }
  }, [])

  return { validatedParams }
}
