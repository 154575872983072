import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { Button, Divider, Swift, Typography } from '@swiftctrl/swift-component-library'
import { Avatar } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useLogOut, useStoreCurrentUser, useTranslation } from '../../../hooks'
import { getInitials, paths } from '../../../utils'
import { Logout, Reservations as ReservationsIcon } from '../../Icons'

interface Props {
  onClose: () => void
}

export const AvatarMenuContent: Swift.FC<Props> = ({ onClose }) => {
  const { Reservations } = paths
  const { t } = useTranslation()
  const { currentUser } = useStoreCurrentUser()
  const profileInfo: ProfilesTypes | null = currentUser
  const initials: string = getInitials(profileInfo?.display_name) || '?'
  const { mutate: signOut } = useLogOut()
  const { push } = useHistory()
  const navigateTo = (path: string) => {
    push(path)
    onClose()
  }

  return (
    <StyledAvatarMenuContent>
      <Avatar size={40} src={profileInfo?.avatar}>
        {initials}
      </Avatar>
      <Typography.Title level={4}>
        {`${profileInfo?.first_name} ${profileInfo?.last_name}`}
      </Typography.Title>
      <Typography.Text type="secondary">{profileInfo?.emails[0].email}</Typography.Text>
      <StyledDivider />
      <StyledReservationButton
        icon={<ReservationsIcon />}
        type="text"
        onClick={() => navigateTo(`/${Reservations}`)}
      >
        {t('avatar_my_reservations_link')}
      </StyledReservationButton>
      <Button
        type="text"
        onClick={() => {
          signOut()
          onClose()
        }}
      >
        <Logout />
        {t('avatar_logout_link')}
      </Button>
    </StyledAvatarMenuContent>
  )
}

const StyledAvatarMenuContent = styled.div`
  h4 {
    margin-top: ${({ theme }) => theme.spacing.small};
    margin-bottom: 0px;
  }
  .ant-typography-secondary {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
    font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  }
  button {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
    span {
      margin: 0px;
    }
    svg {
      margin-right: ${({ theme }) => theme.spacing.medium};
      height: 24px !important;
      width: 24px !important;
    }
    padding: 0px;
  }
  .ant-btn > .anticon + span {
    margin: 0px;
  }
`
const StyledReservationButton = styled(Button)`
  margin-top: 40px;
`

const StyledDivider = styled(Divider)`
  margin-top: 40px;
`
