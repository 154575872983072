import { useAmenities } from '@swiftctrl/api-client-react'
import { Col, Row } from '@swiftctrl/swift-component-library'
import { includes, remove } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { MobileTag } from '../../../components'
import { useTranslation } from '../../../hooks'
import { config, getTranslationLabel } from '../../../utils'

interface Props {
  title: string
  onChange: (amenities: string[]) => void
  selectedFilters: string[]
}

const amenitiesValueToTranslationKey: Record<string, string> = {
  'Fast, Secure Wi-Fi': 'internet_access',
  'Large display': 'large_display',
  'White board': 'white_board',
  Projector: 'projector',
  'Conference phone': 'conference_phone',
  'Tea and coffee': 'tea_and_coffee',
  'Regular phone': 'regular_phone',
  Catering: 'catering',
  'Privacy screen': 'privacy_screen',
  Drinks: 'drinks',
  'Air conditioning': 'air_conditioning',
  CCTV: 'cctv',
  'Standing desk': 'standing_desk',
  'Wireless charger': 'wireless_charger',
  'Voice recorder': 'voice_recorder',
  'Standard phone': 'standard_phone',
  'Sound proof room': 'soundproof_room',
  Heating: 'heating',
  Internet: 'internet',
  'Natural light': 'natural_light',
  'Quiet zone': 'quiet_zone',
  'Security lock': 'security_lock',
}

const { SWIFT_ROOT_ENTITY_ID } = config

export const MobileAmenitiesFilterContainer: React.FC<Props> = ({
  title,
  onChange,
  selectedFilters,
}) => {
  const { t } = useTranslation()
  const { data } = useAmenities()
    .browse({
      browseOptions: { queryKey: 'amenities' },
      baseId: SWIFT_ROOT_ENTITY_ID,
      select: ['amenity'],
    })
    .query()
  const amenities = data?.data

  const filterSelected = (key: string): boolean => includes(selectedFilters, key)

  const handleFilterTap = (name: string) => {
    const translationAmenityKey = amenitiesValueToTranslationKey[name]
    if (filterSelected(translationAmenityKey)) {
      const modifiedFilters: string[] = remove(
        selectedFilters,
        (filter: string) => filter !== translationAmenityKey,
      )
      onChange(modifiedFilters)
    } else {
      onChange([...selectedFilters, translationAmenityKey])
    }
  }

  return (
    <Col span={24}>
      <Title>{title}</Title>
      <StyledRow>
        {amenities?.map((amenity) => (
          <CustomMobileTag
            key={amenity.amenity_id}
            onTap={handleFilterTap}
            title={t(getTranslationLabel('search_amenities_', amenity.amenity))}
            color={
              filterSelected(
                amenitiesValueToTranslationKey[
                  t(getTranslationLabel('search_amenities_', amenity.amenity))
                ],
              )
                ? 'processing'
                : 'default'
            }
          />
        ))}
      </StyledRow>
    </Col>
  )
}

const Title = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
`
const StyledRow = styled(Row)`
  padding-top: ${({ theme }) => theme.spacing.medium};
`
const CustomMobileTag = styled(MobileTag)`
  margin-right: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
