import { createSlice } from '@reduxjs/toolkit'
import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { isNull } from 'lodash'
import { Payload, State } from '../../models'
import { storeSliceNames } from '../../utils'

const { currentUser: name } = storeSliceNames

const initialState: State<ProfilesTypes> = { data: null }

const currentUserSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentUser: (state: State<ProfilesTypes>, action: Payload<Partial<ProfilesTypes>>) => {
      const { payload } = action
      if (isNull(payload)) {
        state.data = payload
        return
      }
      state.data = { ...state.data!, ...payload! }
    },
  },
})

export const {
  reducer: currentUserReducer,
  actions: { setCurrentUser },
} = currentUserSlice
