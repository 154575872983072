import { createSlice } from '@reduxjs/toolkit'

import { isNull } from 'lodash'
import { Payload, ReservationDetailsType, State } from '../../models'
import { emptyReservation, storeSliceNames } from '../../utils'

const { reservation: name } = storeSliceNames

const initialState: { data: ReservationDetailsType } = { data: emptyReservation }

const reservationSlice = createSlice({
  name,
  initialState,
  reducers: {
    setReservation: (
      state: State<ReservationDetailsType>,
      action: Payload<Partial<ReservationDetailsType>>,
    ) => {
      const { payload } = action
      if (isNull(payload)) {
        state.data = payload
        return
      }

      state.data = { ...state.data!, ...payload! }
    },
  },
})

export const {
  reducer: reservationReducer,
  actions: { setReservation },
} = reservationSlice
