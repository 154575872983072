import { ScheduleTypes, useRoom } from '@swiftctrl/api-client-react'
import { Row, Swift, Table } from '@swiftctrl/swift-component-library'
import { DatePicker } from 'antd'
import { isToday, isValid } from 'date-fns'
import dayjs from 'dayjs'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { mixPanelTrack } from '../../../analytics'
import {
  useAvailabilitiesColumns,
  useStoreReservation,
  useValidateRoomDetailParams,
} from '../../../hooks'
import { RoomAvailability } from '../../../models'
import {
  disablePastDates,
  formatAvailabilities,
  getTimeRange,
  isBookingPossible,
  isDatePassed,
  isReservationValid,
} from '../../../utils'
import { AvailabilitiesLegends } from './AvailabilitiesLegends'

interface Props {
  roomId?: string
  schedule: ScheduleTypes
}

export const Availabilities: Swift.FC<Props> = ({ roomId, schedule }) => {
  const { isMobileView } = useTheme()
  // opening hours timerange
  const time = getTimeRange(schedule)
  const timeRange = {
    start: Number(time?.start || 0),
    end: Number(time?.end || 23),
  }
  const { reservationDetails, updateStoreReservation } = useStoreReservation()

  const { startTime, endTime, date, duration } = reservationDetails
  const [localEndTime, setLocalEndTime] = useState<number | null>(null)
  const { validatedParams } = useValidateRoomDetailParams()
  const stringifyValidatedParams = JSON.stringify(validatedParams)
  const bookedDate = date ? new Date(date).toISOString().split('T')[0] : ''

  const [selectedDateTimeSlots, setSelectedDateTimeSlots] = useState<number[]>()

  const dateFormat = 'MMMM Do, YYYY'
  const [searchDate, setSearchDate] = useState<Date>(new Date())

  const reservationDetailsStringify = JSON.stringify(reservationDetails)

  const searchDateWithoutTime = searchDate.toISOString().split('T')[0]

  const requestStartTime = useMemo(
    () =>
      isToday(searchDate)
        ? dayjs(new Date()).endOf('hour').toISOString().split('.')[0]
        : dayjs.utc(searchDateWithoutTime).toISOString().split('.')[0],
    [searchDateWithoutTime, searchDate],
  )

  const { data, isLoading } = useRoom()
    .at(roomId!)
    .availabilities.browse({
      browseOptions: {
        queryKey: 'availabilities',
      },
      startTime: requestStartTime,
      endTime: dayjs
        .utc(searchDateWithoutTime)
        .add(4, 'days')
        .endOf('day')
        .toISOString()
        .split('.')[0],
    })
    .query()

  const daysAvailabilities = formatAvailabilities(data?.data)
  const daysAvailabilitiesStringify: string = JSON.stringify(daysAvailabilities)

  useEffect(() => {
    if (
      validatedParams.date &&
      isValid(new Date(validatedParams.date)) &&
      !isDatePassed(validatedParams.date)
    ) {
      setSearchDate(dayjs(validatedParams.date).toDate())
    }
  }, [stringifyValidatedParams])

  useEffect(() => {
    if (
      reservationDetails.date &&
      isToday(searchDate) &&
      !isToday(new Date(reservationDetails.date)) &&
      !Boolean(reservationDetails.roomId)
    ) {
      setSearchDate(new Date(reservationDetails.date))
    }
  }, [reservationDetails.date])

  useEffect(() => {
    if (!isReservationValid(reservationDetails)) {
      return resetBookingState()
    }
    if (endTime) {
      setLocalEndTime(endTime - 1)
    }
  }, [reservationDetailsStringify, daysAvailabilitiesStringify])

  useEffect(() => {
    if (daysAvailabilities) {
      const modifiedDate = new Date(reservationDetails.date)
      const dateISO = modifiedDate.toISOString()
      const timeList = daysAvailabilities?.find(
        (day) => day.date.split('T')[0] === dateISO.split('T')[0],
      )?.availabilities
      setSelectedDateTimeSlots(timeList)
    }
  }, [daysAvailabilitiesStringify])

  useEffect(() => {
    if (selectedDateTimeSlots) {
      if (
        isBookingPossible(
          reservationDetails.startTime,
          reservationDetails.endTime,
          selectedDateTimeSlots || [],
        )
      ) {
        updateStoreReservation('date', date)
        updateStoreReservation('startTime', reservationDetails.startTime)
        updateStoreReservation('endTime', reservationDetails.endTime)
      } else {
        resetBookingState()
      }
    }
  }, [selectedDateTimeSlots])

  // Reset all the booking values
  const resetBookingState = () => {
    updateStoreReservation('startTime', null)
    updateStoreReservation('endTime', null)
    updateStoreReservation('date', null)
  }

  const getTimeSlot = (
    startTime: number,
    selectedHour: number,
    availabilities: RoomAvailability['availabilities'],
  ) => {
    if (selectedHour === startTime) {
      if (duration === 1) {
        return resetBookingState()
      }
      if (duration > 1) {
        updateStoreReservation('startTime', selectedHour)
        setLocalEndTime(selectedHour)
        updateStoreReservation('endTime', selectedHour + 1)
        return updateStoreReservation('duration', 1)
      }
    }
    if (selectedHour > startTime && isBookingPossible(startTime, selectedHour, availabilities)) {
      updateStoreReservation('endTime', selectedHour + 1)
      setLocalEndTime(duration === 1 ? selectedHour - 1 : selectedHour)
      return updateStoreReservation('duration', selectedHour + 1 - startTime)
    }
    if (selectedHour < startTime) {
      updateStoreReservation('startTime', selectedHour)
      setLocalEndTime(selectedHour)
      updateStoreReservation('endTime', selectedHour + 1)
      return updateStoreReservation('duration', 1)
    }
  }

  const handleTimeSLot = (day: RoomAvailability, hour: number) => {
    const dayDate = day.date.split('T')[0]
    const sameDay: boolean = dayDate === date
    const futureDay: boolean = dayDate > date
    const pastDay: boolean = dayDate < date

    if ((!date && !startTime) || futureDay || pastDay) {
      updateStoreReservation('date', dayDate)
      updateStoreReservation('startTime', hour)
      setLocalEndTime(hour)
      updateStoreReservation('endTime', hour + 1)
      return updateStoreReservation('duration', 1)
    }
    if (date && startTime && sameDay) {
      return getTimeSlot(startTime, hour, day.availabilities)
    }
  }

  const { columns } = useAvailabilitiesColumns(
    timeRange,
    handleTimeSLot,
    schedule,
    startTime,
    localEndTime,
    bookedDate,
  )

  return (
    <StyledAvailabilitiesContainer>
      <StyledDatePickerContainer>
        <DatePicker
          value={moment(searchDate)}
          disabledDate={disablePastDates}
          format={dateFormat}
          allowClear={false}
          onChange={(momentDate) => {
            if (searchDate !== momentDate?.toDate()) {
              mixPanelTrack('View Space - Change Date')
            }
            momentDate && setSearchDate(momentDate?.toDate())
          }}
        />
      </StyledDatePickerContainer>
      {isMobileView && (
        <StyledAvailabilitiesLegends justify="start">
          <AvailabilitiesLegends />
        </StyledAvailabilitiesLegends>
      )}
      <StyledAvailabilitiesTableContainer>
        <Table
          dataSource={daysAvailabilities?.map((day, i) => ({ ...day, key: i }))}
          columns={columns}
          scroll={{ x: 'auto' }}
          pagination={false}
          loading={!daysAvailabilities || isLoading}
        />
      </StyledAvailabilitiesTableContainer>
      {!isMobileView && <AvailabilitiesLegends />}
    </StyledAvailabilitiesContainer>
  )
}

const StyledAvailabilitiesContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large};
  .ant-table-tbody > tr > td {
    padding: 11px 11px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: unset;
  }

  .ant-table-content {
    height: 427px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td:not(.ant-table-cell-fix-left) {
    background-color: unset;
  }
`

const StyledAvailabilitiesLegends = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
const StyledDatePickerContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: right;
  .ant-picker {
    width: 190px;
    height: 32px;
  }
`

const StyledAvailabilitiesTableContainer = styled.div`
  border: 1px solid #f0f0f0;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
