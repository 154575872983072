import { RoomsTypes, ScheduleTypes } from '@swiftctrl/api-client-react'
import { message, Modal, Swift } from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useInitialRender, useStoreReservation, useTranslation } from '../../../hooks'
import { ReservationDetailsType } from '../../../models'
import { Availabilities } from '../availabilities'
import { ReservationFooter } from '../reservation-mobile-footer'
import { DrawerHeader } from './DrawerHeader'

interface Props {
  onClose: () => void
  onClickNext: () => void
  room: RoomsTypes
  schedule?: ScheduleTypes
}

export const MobileAvailabilities: Swift.FC<Props> = ({ onClose, onClickNext, room, schedule }) => {
  const confirm = Modal.confirm
  const { reservationDetails, updateStoreReservation } = useStoreReservation()
  const initialRender = useInitialRender()
  const [initialReservationDetails, setInitialReservationDetails] =
    useState<ReservationDetailsType>()

  const [errorWarning] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (initialRender) {
      setInitialReservationDetails(reservationDetails)
    }
  }, [initialRender])

  const warning = () => {
    message.config({
      maxCount: 1,
    })
    return message.warning(t('room_details_error_something_wrong'))
  }

  const handleClose = () => {
    if (JSON.stringify(initialReservationDetails) !== JSON.stringify(reservationDetails)) {
      confirm({
        title: t('room_details_mobile_save_modal_title'),
        centered: true,
        okText: t('room_details_mobile_save_modal_confirm_label'),
        cancelText: t('room_details_mobile_save_modal_cancel_label'),
        onOk: () => onClose(),
        onCancel: () => {
          updateStoreReservation('date', initialReservationDetails?.date!)
          updateStoreReservation('startTime', initialReservationDetails?.startTime!)
          updateStoreReservation('endTime', initialReservationDetails?.endTime!)
          onClose()
        },
      })
      return
    }
    onClose()
  }

  return (
    <StyledContainer>
      <DrawerHeader onClose={handleClose} text={t('booking_calender_drawer_header')} />
      <StyledContent>
        {errorWarning ? <div className="error-warning">{warning()}</div> : null}
        <Availabilities roomId={room.room_id} schedule={schedule!} />
      </StyledContent>
      <ReservationFooter
        onMainButtonClick={onClickNext}
        hourlyPrice={Number(room.price)}
        mode="next"
        forTable
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledContent = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  height: calc(100% - 129px);
  overflow: auto;
  img {
    margin-top: ${({ theme }) => theme.spacing.medium};
    height: 200px;
    width: 100%;
  }
`
