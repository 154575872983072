import { Swift } from '@swiftctrl/swift-component-library'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { usePublicRoutes } from '../../hooks'
import { MainRouteProps } from '../../models'
import { paths } from '../../utils'
import { RenderIfScope } from '../RenderIfScope'
import { RouteWithSubRoutes } from './RouteWithSubRoutes'

const { Search, DefaultFallback404 } = paths

export const PublicRoutes: Swift.FC = () => {
  const defaultRoute: string = `/${Search}`
  const mainRoutes: MainRouteProps[] = usePublicRoutes()

  return (
    <RenderIfScope is="public">
      <Switch>
        <Route exact path="/">
          <Redirect to={defaultRoute} />
        </Route>
        {mainRoutes.map((route: MainRouteProps) => (
          <RouteWithSubRoutes key={String(route.path)} {...route} />
        ))}
        <Route path="*">
          <Redirect to={`/${DefaultFallback404}`} />
        </Route>
      </Switch>
    </RenderIfScope>
  )
}
