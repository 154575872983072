import { Swift, Result } from '@swiftctrl/swift-component-library'
import { Button } from 'antd'
import { RouterProps, useHistory } from 'react-router-dom'
import { useTranslation } from '../hooks'
import { paths } from '../utils'

interface Props5XX {
  status: '500'
}

interface Props404 {
  status: '404'
  section?: {
    label: string
    buttonLink?: string
    buttonLabel: string
  }
}

type Props = Props5XX | Props404

const { Search } = paths

export const Fallback: Swift.FC<Props> = (props) => {
  const { t } = useTranslation()
  const history: RouterProps['history'] = useHistory()
  const handleOnClick = () =>
    history.push(
      props.status === '404' && props.section && props.section.buttonLink
        ? props.section.buttonLink
        : `/${Search}`,
    )

  return (
    <Result
      status={props.status}
      title={t(props.status === '404' ? 'fallback_404_default_title' : 'fallback_500_title')}
      subTitle={
        props.status === '404'
          ? props.section
            ? props.section.label
            : t('fallback_404_default_description')
          : t('fallback_500_description')
      }
      extra={
        props.status === '404' ? (
          <Button onClick={handleOnClick} type="primary">
            {props.status === '404' && props.section
              ? props.section.buttonLabel
              : t('fallback_404_button_label')}
          </Button>
        ) : null
      }
    />
  )
}
