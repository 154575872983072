import { Col, Row, Swift } from '@swiftctrl/swift-component-library'
import { Drawer, Layout } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AvatarContainer, AvatarMenu, AvatarMenuContent } from '../../components'
import { useBoolean, useMobileView } from '../../hooks'
import { paths } from '../../utils'
import { SpaceCloudWhiteIcon } from '../Icons'

const { Home } = paths

const { Header } = Layout

export const MainNav: Swift.FC = () => {
  const [showAvatarMenu, openAvatarModal, closeAvatarModal] = useBoolean()
  const { isMobileView } = useMobileView()
  return (
    <StyledHeader>
      <Row>
        <StyledLogoCol span={12}>
          <Link to={Home}>
            <SpaceCloudWhiteIcon />
          </Link>
        </StyledLogoCol>
        <Col span={12}>
          <AvatarContainer
            showAvatarMenu={showAvatarMenu}
            openAvatarModal={openAvatarModal}
            closeAvatarModal={closeAvatarModal}
          />
        </Col>
      </Row>
      {!isMobileView ? (
        <AvatarMenu
          showAvatarMenu={showAvatarMenu}
          openAvatarModal={openAvatarModal}
          closeAvatarModal={closeAvatarModal}
        />
      ) : (
        <StyledDrawer
          onClose={closeAvatarModal}
          placement="right"
          visible={showAvatarMenu}
          width="85%"
        >
          <AvatarMenuContent onClose={closeAvatarModal} />
        </StyledDrawer>
      )}
    </StyledHeader>
  )
}

const StyledHeader = styled(Header)`
  height: ${({ theme }) => (theme.isMobileView ? '56px' : '80px')};
  padding-left: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => (theme.isMobileView ? theme.spacing.medium : '24px')};
  background-color: #f7f6f3;
`

const StyledLogoCol = styled(Col)`
  padding-top: ${({ theme }) =>
    theme.isMobileView ? theme.spacing.smedium : theme.spacing.medium};
  bottom: ${({ theme }) => (theme.isMobileView ? '6px' : '0px')};
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    align-items: flex-start;
    button {
      margin: 0px;
      padding: 0px;
      svg {
        font-size: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].fontSize} !important;
        color: #000;
      }
    }
  }
  .ant-drawer-header {
    border: none;
    padding: ${({ theme }) => theme.spacing.medium};
  }

  .ant-drawer-body {
    padding-top: ${({ theme }) => theme.spacing.small};
    padding-left: ${({ theme }) => theme.spacing.medium};
  }
`
