import { Swift } from '@swiftctrl/swift-component-library'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useBreadcrumbs } from '../hooks'
import { BreadcrumbItem } from '../models'
import { appTheme } from '../styles'

interface Props {
  breadcrumbs?: BreadcrumbItem[]
  clickEnable?: boolean
}

export const Breadcrumbs: Swift.FC<Props> = ({ breadcrumbs, clickEnable = true }) => {
  const { breadcrumbs: defaultBreadcrumbs } = useBreadcrumbs()
  return (
    <StyledBreadcrumbs>
      {(breadcrumbs || defaultBreadcrumbs).map((item, i) => (
        <Breadcrumb.Item key={i}>
          {item.path && clickEnable ? <Link to={item.path}>{item.name}</Link> : item.name}
        </Breadcrumb.Item>
      ))}
    </StyledBreadcrumbs>
  )
}

const StyledBreadcrumbs = styled(Breadcrumb)`
  margin-bottom: ${appTheme.spacing.small};
`
