export const paths = {
  Home: '/',
  RoomDetails: 'spaces',
  Reservations: 'reservations',
  Search: 'search',
  Checkout: 'checkout',
  ReservationDetails: 'reservation',
  Building: 'building',
  Admin: 'admin',
  RoomReservations: 'room-reservations',
  DefaultFallback404: '404',
  DefaultFallback5XX: '5xx',
}

export const mobilePathWithOutHeader = [paths.RoomDetails, paths.ReservationDetails, paths.Building]
