import { buildRedirectSearch } from '@swiftctrl/api-helpers'

export const goToAccountsApp = (page: 'signin' | 'signout') => {
  const url = `${process.env.REACT_APP_ACCOUNTS_URL!}/${page}`

  const accountsUrl = new URL(url)

  accountsUrl.search = buildRedirectSearch('space')

  window.location.href = accountsUrl.href
}
