import { Swift } from '@swiftctrl/swift-component-library'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import styled, { CSSObject, useTheme } from 'styled-components'

import { useBoolean, useHandleUrlParams, useStoreParams } from '../../hooks'
import { ListView, MobileSearchBar, SearchBar } from '../../screen-components'
import { getInitialSearchParams, isDateTimePassed } from '../../utils'
import { searchParamsSchema } from '../../validation'

const SearchScreen: Swift.FC = () => {
  const { currentParams, updateParams } = useHandleUrlParams()
  const currentParamsStringify: string = JSON.stringify(currentParams)
  const { isMobileView } = useTheme()
  const { updateStoreParams } = useStoreParams()
  const isPast: boolean = isDateTimePassed(currentParams?.date!, currentParams?.startTime!)
  const [isFilterPanelOpen, openFilterPanel, closeFilterPanel] = useBoolean()
  const [isSearchVisible, openSearch, closeSearch] = useBoolean()

  const isValidParams: boolean = searchParamsSchema.isValidSync(currentParams)
  const isUpdateToInitialSearchParams: boolean =
    (currentParams && !isValidParams) || isPast || isEmpty(currentParams)

  if (isUpdateToInitialSearchParams) {
    updateParams(getInitialSearchParams())
  }

  useEffect(() => {
    if (currentParams) {
      updateStoreParams('date', currentParams.date)
      updateStoreParams('endTime', currentParams.endTime)
      updateStoreParams('guests', currentParams.guests)
      updateStoreParams('startTime', currentParams.startTime)
      updateStoreParams('sorted', currentParams.sorted)
      if (currentParams.amenities) {
        updateStoreParams('amenities', currentParams.amenities)
      } else {
        updateStoreParams('amenities', undefined)
      }
      if (currentParams.city) {
        updateStoreParams('city', currentParams.city)
      } else {
        updateStoreParams('city', undefined)
      }
      if (currentParams.state) {
        updateStoreParams('state', currentParams.state)
      } else {
        updateStoreParams('state', undefined)
      }
      if (currentParams.country) {
        updateStoreParams('country', currentParams.country)
      } else {
        updateStoreParams('country', undefined)
      }
      if (currentParams.page) {
        updateStoreParams('page', currentParams.page)
      } else {
        updateStoreParams('page', undefined)
      }
    }
  }, [currentParamsStringify, isUpdateToInitialSearchParams])

  return (
    <>
      {!isMobileView && (
        <SearchBar
          openSearchModal={openSearch}
          closeFilterModal={closeFilterPanel}
          closeSearchModal={closeSearch}
          isFilterModalOpen={isFilterPanelOpen}
          isSearchModalOpen={isSearchVisible}
          openFilterModal={openFilterPanel}
        />
      )}
      <StyledSearchScreen>
        {isMobileView && (
          <MobileSearchBar
            isSearchDrawerOpen={isSearchVisible}
            openSearchDrawer={openSearch}
            openFilterDrawer={openFilterPanel}
            closeFilterDrawer={closeFilterPanel}
            closeSearchDrawer={closeSearch}
            isFilterDrawerOpen={isFilterPanelOpen}
          />
        )}
        <ListView openSearch={openSearch} openFilter={openFilterPanel} />
      </StyledSearchScreen>
    </>
  )
}

export default SearchScreen

const StyledSearchScreen = styled.div`
  ${({ theme }): CSSObject => ({
    width: theme.isMobileView ? 'auto' : '95%',
    maxWidth: theme.isMobileView ? 'none' : 1440,
    margin: theme.isMobileView ? 'none' : 'auto',
  })}
`
