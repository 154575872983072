import { Alert, Swift } from '@swiftctrl/swift-component-library'
import { inRange } from 'lodash'
import styled from 'styled-components'

import { useEffect } from 'react'
import { mixPanelTrack } from '../../analytics'
import { Fallback, ReservationDetailsLoadingSkeleton, ScreenContainer } from '../../components'
import { useHandleUrlParams, useTranslation } from '../../hooks'
import { ReservationDetails, ReservationRoomDetails } from '../../screen-components'
import { differenceInDayFromToday, getReservationDetailsFromBooking, paths } from '../../utils'
import { Props as ReservationDetailsProps } from '../reservation-details-screen-mobile'

interface ReservationSearchParams {
  start: string
  end: string
  roomId: string
  guests: string
  booking?: 'confirm'
}

const { Reservations } = paths

export const ReservationDetailsScreen: Swift.FC<ReservationDetailsProps> = ({
  booking,
  room,
  building,
  isLoading,
  error,
}) => {
  const { t } = useTranslation()

  const { currentParams } = useHandleUrlParams()

  const bookingStringify: string = JSON.stringify(booking)

  useEffect(() => {
    if (bookingStringify && booking?.end_time && currentParams.booking === 'confirm') {
      mixPanelTrack('Book Space (Success)', {
        'Days From Today': differenceInDayFromToday(booking.end_time),
        'Guest Count': booking.guest_count,
      })
    }
  }, [bookingStringify])

  if (error?.request.status === 404) {
    return (
      <Fallback
        status="404"
        section={{
          label: t('fallback_room_reservation_screen_description'),
          buttonLabel: t('fallback_room_reservation_screen_button_label'),
          buttonLink: `/${Reservations}`,
        }}
      />
    )
  }

  if (inRange(error?.request.status, 400, 600)) {
    return <Fallback status="500" />
  }

  if (isLoading) {
    return (
      <ScreenContainer>
        <ReservationDetailsLoadingSkeleton />
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer>
      <StyledContainerCheckout>
        {room && building && (
          <StyledReservationDetailsComponent>
            <ReservationRoomDetails
              reservationDetails={getReservationDetailsFromBooking(booking!)}
              room={room}
              building={building}
              // payment={booking.payment}
            />
          </StyledReservationDetailsComponent>
        )}
        <StyledReservationCheckout>
          {currentParams.booking && (
            <StyledAlertPaymentConfirmed>
              <Alert message="Payment Confirmed!" type="success" showIcon />
            </StyledAlertPaymentConfirmed>
          )}
          {booking && <ReservationDetails room={room!} booking={booking} />}
        </StyledReservationCheckout>
      </StyledContainerCheckout>
    </ScreenContainer>
  )
}

export default ReservationDetailsScreen

const StyledContainerCheckout = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin: auto;
  @media only screen and (max-width: 991px) {
    display: block;
  }
`
const StyledReservationDetailsComponent = styled.div`
  order: 2;
  margin-bottom: 24px;
  position: sticky;
  top: 32px;
  @media only screen and (max-width: 991px) {
    position: initial;
  }
`
const StyledReservationCheckout = styled.div`
  flex-grow: 1;
  margin-right: 56px;
  order: 1;
  @media only screen and (max-width: 991px) {
    margin: 0;
  }
`
const StyledAlertPaymentConfirmed = styled.div`
  margin-bottom: 24px;
`
