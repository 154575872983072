import { Col, Swift, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { useTranslation } from '../../../hooks'
import { bookingDateDisplay, getBookingTimeDisplay } from '../../../utils'

interface Props {
  startAt: string
  endAt: string
  guests: number
}

export const ReservationInfos: Swift.FC<Props> = ({ startAt, endAt, guests }) => {
  const { t } = useTranslation()

  return (
    <StyledReservationInfos>
      <Col span={24}>
        <Styled14PixelsFontSize type="secondary">
          {t('reservation_your_reservation_label')}
        </Styled14PixelsFontSize>
      </Col>
      <Typography.Title level={5}>{t('reservation_date_label')}</Typography.Title>
      <Col span={24}>
        <Typography.Text>{bookingDateDisplay(startAt)}</Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Text>{getBookingTimeDisplay(startAt, endAt)}</Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Title level={5}>{t('reservation_guests_label')}</Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Text>
          {guests} {guests === 1 ? t('reservation_guests_one') : t('reservation_guests_other')}
        </Typography.Text>
      </Col>
    </StyledReservationInfos>
  )
}

const StyledReservationInfos = styled.div`
  h5 {
    margin-top: ${({ theme }) => theme.spacing.medium};
    margin-bottom: 0px;
  }
`
const Styled14PixelsFontSize = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
`
