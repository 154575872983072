export const reservation_details_screen_en = {
  reservation_checkout_confirmButton: 'Confirm and pay',
  reservation_checkout_pageTitle: 'Confirm and pay',
  reservation_checkout_editLink: 'Edit',
  reservation_checkout_confirm_message_part1: 'by selecting the button below, I agree to the',
  reservation_checkout_confirm_message_rentalRules: 'Rental Rules',
  reservation_checkout_confirm_message_covid_requirements:
    "SwiftConnect's COVID-19 Safety Requirements",
  reservation_checkout_confirm_message_part2: 'and the',
  reservation_checkout_confirm_message_guests_refund: 'Guest Refund Policy',
  reservation_success_reservation_details_title: 'Reservation details',
  reservation_success_invoice: 'Invoice',
  reservation_success_contact_info_label: 'Contact Information',
  reservation_success_name_label: 'Name',
  reservation_success_email_label: 'Email',
  reservation_success_phone_number_label: 'Phone number',
  reservation_success_address_label: 'Address',
  reservation_success_pay_label: 'Paid with',
  reservation_success_cancel_button: 'Cancel',
  reservation_details_pricing_details_label: 'Pricing details',
  reservation_details_hours_one: 'hour',
  reservation_details_hours_other: 'hours',
  reservation_details_taxes_fees_label: 'Taxes and fees',
  reservation_details_calculated_at_checkout_label: 'Calculated at payment',
  reservation_details_total: 'Total',
  reservation_your_reservation_label: 'Your reservation',
  reservation_admin_reservation_label: 'Reservation status',
  reservation_date_label: 'Date',
  reservation_guests_label: 'Guests',
  reservation_guests_one: 'guest',
  reservation_guests_other: 'guests',
  reservation_cancel_policy_label: 'Cancellation policy',
  reservation_cancellation_message_part1: 'Free cancellation before 3:00pm, September 5th.',
  reservation_cancellation_message_part2:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  reservation_modal_title: 'Cancel reservation',
  reservation_modal_message: 'Are you sure you want to cancel your reservation for the',
  reservation_modal_cancel_button: 'Cancel reservation',
  reservation_modal_error_alert_title: 'Your selection is no longer available.',
  reservation_modal_error_alert_description: 'Please select a new time frame',
  reservation_modal_no_button: 'No',
  reservation_view_room_details: 'View room details',
  reservation_open_in_maps: 'Open in maps',
  reservation_tax_not_calculated: 'not calculated',
}
