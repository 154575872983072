import { TreeSelectProps } from 'antd'

export const treeSelectDemoData: TreeSelectProps['treeData'] = [
  {
    title: 'Organization A',
    value: 'organization-a',
    key: 'organization-a',
    children: [
      {
        title: 'Organization A-1',
        value: 'organization-a-1',
        key: 'organization-a-1',
      },
      {
        title: 'Organization A-2',
        value: 'organization-a-2',
        key: 'organization-a-2',
      },
      {
        title: 'Organization A-3',
        value: 'organization-a-3',
        key: 'organization-a-3',
        children: [
          {
            title: 'Organization A-3-1',
            value: 'organization-a-3-1',
            key: 'organization-a-3-1',
          },
        ],
      },
    ],
  },
  {
    title: 'Organization B',
    value: 'organization-b',
    key: 'organization-b',
    children: [
      {
        title: 'Organization B-1',
        value: 'organization-b-1',
        key: 'organization-b-1',
      },
      {
        title: 'Organization B-2',
        value: 'organization-b-2',
        key: 'organization-b-2',
        children: [
          {
            title: 'Organization B-2-1',
            value: 'organization-b-2-1',
            key: 'organization-b-2-1',
          },
          {
            title: 'Organization B-2-2',
            value: 'organization-b-2-2',
            key: 'organization-b-2-2',
          },
        ],
      },
      {
        title: 'Organization B-3',
        value: 'organization-b-3',
        key: 'organization-b-3',
      },
    ],
  },
]
