import {
  RoomsTypes,
  useBooking,
  useBuilding,
  useQueryClient,
  useRoom,
} from '@swiftctrl/api-client-react'
import { Affix, Col, Drawer, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import { RouterProps, useHistory } from 'react-router-dom'
import { LastLocationType, useLastLocation } from 'react-router-last-location'
import styled from 'styled-components'
import { mixPanelTrack } from '../../analytics'
import imagePlaceholder from '../../assets/image-place-holder.png'
import { BackButton, Fallback, GoogleMap, RoomDetailsScreenMobileLoader } from '../../components'
import {
  useBoolean,
  useHandleUrlParams,
  useInitialRender,
  useStoreReservation,
  useStoreRoute,
  useTranslation,
} from '../../hooks'
import {
  Amenities,
  BookingConfirmationModal,
  Guidelines,
  OrganizationAndAddress,
  PriceAndGuestCapacity,
  ReservationFooter,
  ReservationMobileDrawer,
  ReservationMobileDrawerState,
} from '../../screen-components'
import { differenceInDayFromToday, paths } from '../../utils'

const { ReservationDetails } = paths

interface Props {
  roomId: string
  tenantId: string
}

export const RoomDetailsScreenMobile: Swift.FC<Props> = ({ roomId, tenantId }) => {
  const [bookingConfirmationModal, openBookingConfirmationModal, closeBookingConfirmationModal] =
    useBoolean()
  const { t } = useTranslation()
  const { reservationDetails } = useStoreReservation()
  const queryClient = useQueryClient()

  // const isBuildingFeatureEnable: boolean = useFeature('sc_buildings')

  const {
    data: roomResponse,
    isFetching: isLoadingRoom,
    isError: isFetchRoomError,
    error: fetchRoomError,
    isFetched: isRoomFetched,
  } = useRoom()
    .at(roomId)
    .read({ queryKey: ['room-read'] })

  const {
    data: buildingResponse,
    isFetching: isLoadingBuilding,
    isError: isFetchBuildingError,
  } = useBuilding()
    .at(tenantId)
    .read({ queryKey: ['read-building'] }, { enabled: isRoomFetched })

  const [initialStep, setInitialStep] = useState<ReservationMobileDrawerState>('Review')
  const [isDrawerVisible, openDrawer, closeDrawer] = useBoolean()
  const { currentParams, cleanParams } = useHandleUrlParams()
  const { mutate: cancelBooking } = useBooking().delete()
  const history: RouterProps['history'] = useHistory()
  const { currentStoreRoute } = useStoreRoute()
  const lastLocation: LastLocationType = useLastLocation()
  const [lastLocationState, setLastLocationState] = useState<LastLocationType>()

  const room = roomResponse?.data as RoomsTypes
  const building = buildingResponse?.data

  const isInitialRender: boolean = useInitialRender()
  const isLoading = isLoadingBuilding || isLoadingRoom
  const isFetchScreenError = isFetchRoomError || isFetchBuildingError

  useEffect(() => {
    if (isInitialRender) {
      setLastLocationState(lastLocation)
    }
  }, [isInitialRender])

  useEffect(() => {
    if (currentParams.booking === 'cancel' && currentParams.bookingId) {
      cancelBooking(
        { id: currentParams.bookingId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['availabilities'])
          },
        },
      )
      mixPanelTrack('Book Space (Abort)', {
        'Days From Today': differenceInDayFromToday(reservationDetails.date),
        'Guest Count': reservationDetails.numberOfGuests,
      })
      cleanParams(['booking', 'bookingId'])
    }

    if (currentParams.booking === 'confirm' && currentParams.bookingId) {
      openBookingConfirmationModal()
    }
    if (currentParams.booking === 'pending') {
      openDrawer()
    }
  }, [currentParams.booking, currentParams.bookingId])

  const handleOpenReview = () => {
    mixPanelTrack('Book Space (Start)', {
      'Days From Today': differenceInDayFromToday(reservationDetails.date),
      'Guest Count': reservationDetails.numberOfGuests,
    })
    setInitialStep('Review')
    openDrawer()
  }

  const handleOpenBookingCalendar = () => {
    setInitialStep('BookingCalendar')
    openDrawer()
  }

  if (isLoading) {
    return <RoomDetailsScreenMobileLoader />
  }

  const handleCloseDrawer = () => {
    cleanParams(['booking'])
    closeDrawer()
  }
  const goBackToPreviousPage = () =>
    history.push(
      !currentStoreRoute
        ? `/${paths.Search}`
        : currentStoreRoute.pathname.split('/')[1] === ReservationDetails
        ? `${currentStoreRoute.pathname}`
        : `/${paths.Search}${lastLocationState!.search}`,
      //@ts-ignore - this is a hack to get the last location to work since library doesn't support custom state value through typescript
      { tabAddress: currentStoreRoute?.state?.tabAddress },
    )

  if (fetchRoomError?.request.status === 404) {
    return (
      <Fallback
        status="404"
        section={{
          label: t('fallback_room_details_screen_description'),
          buttonLabel: t('fallback_room_details_screen_button_label'),
        }}
      />
    )
  }

  if (isFetchScreenError) {
    return <Fallback status="500" />
  }

  return (
    <>
      <StyledRoomDetailsScreenMobile>
        <BackButton onClick={goBackToPreviousPage} />
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <StyledTitle level={2}>{room?.entity_name}</StyledTitle>
          </Col>
          <Col span={24}>
            <OrganizationAndAddress building={building} tenantId={tenantId} roomId={roomId} />
          </Col>
        </Row>
        <StyledRowWithTopMargin $size="16" gutter={[0, 16]}>
          <Col span={24}>
            <PriceAndGuestCapacity
              minGuests={room?.min_capacity}
              maxGuests={room?.max_capacity}
              hourlyPrice={Number(room?.price)}
            />
          </Col>
          <Col span={24}>
            <StyledImageContainer $image={room?.images![0] || imagePlaceholder} />
          </Col>
        </StyledRowWithTopMargin>
        <StyledRowWithTopMargin $size="24">
          <Col>
            <Typography.Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eget tellus amet,
              lacus amet, mollis. Neque sed pellentesque condimentum sed sit iaculis nam posuere.
              Purus scelerisque condimentum ac, senectus. Enim varius massa.
            </Typography.Text>
          </Col>
        </StyledRowWithTopMargin>
        <StyledRowWithTopMargin $size="24">
          <Col>
            <Guidelines />
          </Col>
        </StyledRowWithTopMargin>
        <StyledRowWithTopMargin $size="24">
          <Col span={24}>
            <Amenities amenities={room?.amenities} schedule={building?.schedule} />
          </Col>
        </StyledRowWithTopMargin>
        <GoogleMap address={building?.addresses?.[0].address_line_1} />
      </StyledRoomDetailsScreenMobile>
      <Affix offsetBottom={0}>
        <ReservationFooter
          onTextClick={handleOpenBookingCalendar}
          onMainButtonClick={handleOpenReview}
          hourlyPrice={Number(room.price)}
          mode="review"
        />
      </Affix>
      <StyledDrawer
        onClose={handleCloseDrawer}
        placement="bottom"
        visible={isDrawerVisible}
        closable={false}
        height="96%"
        destroyOnClose
      >
        <ReservationMobileDrawer
          room={room}
          building={building}
          initialStep={initialStep}
          onClose={handleCloseDrawer}
        />
      </StyledDrawer>
      <BookingConfirmationModal
        visible={bookingConfirmationModal}
        onClose={closeBookingConfirmationModal}
        room={room}
        address={building?.addresses?.[0]}
      />
    </>
  )
}

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
  margin-top: ${({ theme }) => theme.spacing.medium};
`

const StyledImageContainer = styled.img<{ $image: string }>`
  width: 100%;
  padding-top: 50%;
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-position: center;
  border-radius: 2px;
`

const StyledRoomDetailsScreenMobile = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  padding-bottom: 56px;
`

const StyledRowWithTopMargin = styled(Row)<{ $size: '16' | '24' }>`
  ${({ theme, $size }) => ({
    marginTop: $size === '16' ? theme.spacing.medium : 24,
  })}
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-drawer-content {
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
  }
`
