import { lazy } from 'react'

export const CheckoutScreen = lazy(() => import('./screens/checkout-screen/CheckoutScreen'))
export const ReservationDetailsScreen = lazy(
  () => import('./screens/reservation-details-screen/ReservationDetailsScreen'),
)
export const ReservationsScreen = lazy(
  () => import('./screens/reservations/ReservationListRenderer'),
)
export const RoomDetailsScreen = lazy(() => import('./screens/room-details/RoomDetailsScreen'))
export const RoomDetailsScreenRenderer = lazy(
  () => import('./components/screen-container/RoomDetailsScreenRenderer'),
)
export const SearchScreen = lazy(() => import('./screens/search-screen/SearchScreen'))
export const ReservationDetailsScreenRenderer = lazy(
  () => import('./components/screen-container/ReservationDetailsScreenRenderer'),
)

export const BuildingView = lazy(() => import('./screens/building-view/BuildingViewRenderer'))

export const Default404 = lazy(() => import('./screens/fallback/Default404'))
export const Default5XX = lazy(() => import('./screens/fallback/Default5XX'))
