import {
  Button,
  CalendarOutlined,
  Divider,
  MinusOutlined,
  PlusOutlined,
  Row,
  Swift,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

import { BackButton } from '../../../../components'
import { useBoolean, useStoreParams, useTranslation } from '../../../../hooks'
import { useDisplaySearchDateAndTime } from '../../../../hooks/dates'
import { config } from '../../../../utils'
import { MobileLocationSearch } from './MobileLocationSearch'

interface Props {
  onClose: () => void
  onCalendarClick: () => void
  selectedDate: string
  locationSearchValue: string
  locationSearchOnChange: (text: string) => void
}

export const MobileSearch: Swift.FC<Props> = ({
  onClose,
  onCalendarClick,
  selectedDate,
  locationSearchValue,
  locationSearchOnChange,
}) => {
  const { currentStoreParams, updateStoreParams } = useStoreParams()
  const { t } = useTranslation()

  const [isLocationFocused, focusLocationInput, unfocusLocationInput] = useBoolean()

  const {
    appConstants: { maximumGuests },
  } = config

  const searchDateAndTime = useDisplaySearchDateAndTime(
    { ...currentStoreParams, date: selectedDate } || {},
  )

  const handleAddGuest = () => {
    if (currentStoreParams?.guests !== undefined) {
      updateStoreParams({
        ...currentStoreParams,
        guests: currentStoreParams.guests + 1,
      })
    }
  }

  const handleRemoveGuest = () => {
    if (currentStoreParams?.guests !== undefined && currentStoreParams.guests !== 0) {
      updateStoreParams({
        ...currentStoreParams,
        guests: currentStoreParams?.guests - 1,
      })
    }
  }

  const handleLocationFocus = (isFocused: boolean) =>
    isFocused ? focusLocationInput() : unfocusLocationInput()

  return (
    <>
      {!isLocationFocused && <BackButton onClick={onClose} />}
      <MobileLocationSearch
        onChange={locationSearchOnChange}
        searchValue={locationSearchValue}
        onFocusBlur={handleLocationFocus}
      />
      {!isLocationFocused ? (
        <>
          <Divider />
          <StyledCalendarSection onClick={onCalendarClick}>
            <CalendarOutlined />
            {searchDateAndTime}
          </StyledCalendarSection>
          <Divider />
          <StyledGuestsSection justify="space-between" align="middle">
            <div>
              <UserOutlined />
              {currentStoreParams?.guests! >= maximumGuests ? (
                `${maximumGuests}+`
              ) : !currentStoreParams?.guests ? (
                <StyledPlaceholder>
                  {t('search_bar_smart_search_how_many_guests')}
                </StyledPlaceholder>
              ) : (
                currentStoreParams?.guests
              )}{' '}
              {currentStoreParams?.guests
                ? t(
                    currentStoreParams?.guests! < 2
                      ? 'search_bar_smart_search_guests_one'
                      : 'search_bar_smart_search_guests_other',
                  )
                : null}
            </div>
            <div>
              <Button
                disabled={currentStoreParams?.guests === 0}
                onClick={handleRemoveGuest}
                icon={<MinusOutlined />}
                size="large"
              />
              <Button
                size="large"
                disabled={currentStoreParams?.guests! === maximumGuests}
                onClick={handleAddGuest}
                icon={<PlusOutlined />}
              />
            </div>
          </StyledGuestsSection>
        </>
      ) : null}
    </>
  )
}

const StyledCalendarSection = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.smedium};
  svg {
    margin-right: ${({ theme }) => theme.spacing.xSmall};
  }
`
const StyledGuestsSection = styled(Row)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  padding: ${({ theme }) =>
    `${theme.spacing.small} 0px ${theme.spacing.small} ${theme.spacing.smedium} `};
  div:first-of-type svg {
    margin-right: ${({ theme }) => theme.spacing.xSmall};
  }
  button:first-of-type {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
  .ant-btn:hover:not([disabled]),
  .ant-btn:focus:not([disabled]),
  .ant-btn:active:not([disabled]) {
    color: inherit;
    border-color: #d9d9d9;
    background: #fff;
  }
`

const StyledPlaceholder = styled.span`
  color: #bfbfbf;
`
