import dayjs from 'dayjs'

export const getAvailabilitiesMockData = (startDate: string, endDate: string) => {
  const availabilities = []
  let start = dayjs(startDate)
  const end = dayjs(endDate)
  do {
    availabilities.push(createAvailabilityObject(start))
    start = start.add(1, 'day')
  } while (start.isBefore(end))
  return availabilities
}

const createAvailabilityObject = (date: dayjs.Dayjs) => {
  const dateString = date.format('YYYY-MM-DD')
  return {
    date: dateString,
    availabilities: [{ from: '08:00', to: '17:00' }],
  }
}
