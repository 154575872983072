import { CloseOutlined, Divider, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import { isSameDay } from 'date-fns'
import styled from 'styled-components'

import { useDisplaySearchDateAndTime, useStoreParams } from '../../../../hooks'
import { createCurrentTimeZoneDateAtMidnight } from '../../../../utils'
import { MobileSearchCalendar } from './MobileSearchCalendar'
import { MobileTimePicker } from './MobileTimePicker'

interface Props {
  onCancel: () => void
  selectedDate: string
  onDateChange: (date: string) => void
}

export const MobileSearchCalendarPage: Swift.FC<Props> = ({
  onCancel,
  onDateChange,
  selectedDate,
}) => {
  const { currentStoreParams, updateStoreParams } = useStoreParams()
  const searchDateAndTime = useDisplaySearchDateAndTime(
    { ...currentStoreParams, date: selectedDate } || {},
  )

  const currentHour = new Date().getHours()
  const nextAvailableStartTime = currentHour + 1

  const handleOnClear = () =>
    updateStoreParams({
      ...currentStoreParams,
      startTime: currentHour + 1,
      endTime: currentHour + 2,
    })

  const hideTimePickerClearButton =
    currentStoreParams?.startTime === nextAvailableStartTime &&
    currentStoreParams?.endTime === nextAvailableStartTime + 1

  const disablePastHours = isSameDay(createCurrentTimeZoneDateAtMidnight(selectedDate), new Date())

  return (
    <>
      <StyledHeader justify="space-between" align="middle">
        <Typography.Title level={5}>{searchDateAndTime}</Typography.Title>
        <CloseOutlined onClick={onCancel} />
      </StyledHeader>
      <StyledDivider />
      <StyledCalendarAndTimePickerContainer>
        <MobileSearchCalendar onDateChange={onDateChange} selectedDate={selectedDate} />
        <StyledDivider />
        <StyledMobileTimePickerContainer>
          <MobileTimePicker
            startTime={currentStoreParams?.startTime || 0}
            endTime={currentStoreParams?.endTime || 0}
            hideClearButton={hideTimePickerClearButton}
            onClear={handleOnClear}
            disablePastHours={disablePastHours}
            keepDuration
          />
        </StyledMobileTimePickerContainer>
      </StyledCalendarAndTimePickerContainer>
    </>
  )
}

const StyledMobileTimePickerContainer = styled.div`
  margin-top: 24px;
`
const StyledCalendarAndTimePickerContainer = styled.div`
  max-height: calc(${window.innerHeight}px - 146px);
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledHeader = styled(Row)`
  h5 {
    margin: 0px;
  }
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  svg {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  }
`
const StyledDivider = styled(Divider)`
  margin: 0px;
  margin-right: -${({ theme }) => theme.spacing.medium};
  margin-left: -${({ theme }) => theme.spacing.medium};
  width: 100vw;
`
