import { useRoom } from '@swiftctrl/api-client-react'
import { Button, Col, Row, Swift, Typography } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

import { useStoreReservation, useTranslation } from '../../../hooks'
import { convertHourToDateISO, isReservationValid } from '../../../utils'
import { ReservationTimeDetails } from './ReservationTimeDetails'

export type Mode = 'review' | 'next'

interface Props {
  mode?: Mode
  onMainButtonClick?: () => void
  onTextClick?: () => void
  hourlyPrice?: number
  forTable?: boolean
}

export const ReservationFooter: Swift.FC<Props> = ({
  mode,
  onTextClick,
  onMainButtonClick,
  hourlyPrice,
  forTable,
}) => {
  const { t } = useTranslation()
  const { reservationDetails } = useStoreReservation()

  const { data: costData } = useRoom()
    .at(reservationDetails.roomId!)
    .price(
      {
        browseOptions: {
          queryKey: 'room-price',
        },
        startTime: reservationDetails.date
          ? convertHourToDateISO(reservationDetails.date, reservationDetails.startTime).split(
              '.',
            )[0]
          : '',
        endTime: reservationDetails.date
          ? convertHourToDateISO(reservationDetails.date, reservationDetails.endTime).split('.')[0]
          : '',
      },
      { enabled: Boolean(reservationDetails.date) },
    )
  const { startTime, endTime, date } = reservationDetails
  const isValid = isReservationValid(reservationDetails)
  const priceDisplay = isValid ? `$${costData?.data.price || ''}` : `$${hourlyPrice}`

  return (
    <StyledReservationStickyBar $forTable={forTable}>
      <StyledRow justify="space-between" align="middle">
        <Col>
          <StyledPrice $forTable={forTable}>
            <StyledTotalPrice level={4}>{priceDisplay}</StyledTotalPrice>
            {!isValid && <Typography.Text>{t('room_details_per_hour')}</Typography.Text>}
            {isValid && (
              <Typography.Text type="secondary">
                ${hourlyPrice}
                {t('room_details_per_hour')}
              </Typography.Text>
            )}
          </StyledPrice>
          <ReservationTimeDetails
            isValid={isValid}
            startTime={startTime}
            endTime={endTime}
            date={date}
            onClick={onTextClick}
            mode={mode!}
            forTable={forTable}
          />
        </Col>
        <Col>
          <Button onClick={onMainButtonClick} disabled={!isValid} type="primary">
            {mode === 'review' ? t('room_details_review') : t('room_details_next')}
          </Button>
        </Col>
      </StyledRow>
    </StyledReservationStickyBar>
  )
}

const StyledRow = styled(Row)`
  height: 100%;
`

const StyledReservationStickyBar = styled.div<{ $forTable?: boolean }>`
  height: ${({ $forTable }) => ($forTable ? '74px' : '92px')};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
`
const StyledPrice = styled.div<{ $forTable?: boolean }>`
  display: flex;
  align-items: baseline;
  > .ant-typography {
    margin-right: ${({ theme }) => theme.sizes.small};
  }
  .ant-typography-secondary {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  }
  h4 {
    margin-bottom: 0px;
  }
  margin-bottom: ${({ theme, $forTable }) => ($forTable ? 0 : theme.spacing.xSmall)};
`

const StyledTotalPrice = styled(Typography.Title)`
  margin-right: ${({ theme }) => theme.spacing.small}!important;
`
