import { format, formatISO9075, fromUnixTime, isPast, isValid } from 'date-fns'
import dayjs from 'dayjs'
import { t } from 'i18next'
import moment from 'moment'

import { DisplayTimeRange } from '../models'
import { config } from './config'
import { getTranslationLabel } from './getTranslationLabel'

const {
  appConstants: { shortDate, timeConvertDate, timeFormateForDesktop, timeFormatForMobile },
} = config

export const daysToIntMap = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

/**
 * A function that creates a new date in any timezone as a UTC date
 * Example : if new Date() is 1st January 2022 at midnight in GMT-5 this function will return the same date in UTC
 * This function can be useful when working with dates as UTC and especially comparing them.
 * @returns number of millesconds
 */
// TODO ZEESHAN will look into this (Maybe change it to date-fns)
export const createNewDateAsUtcDate = () => {
  const dateNow = new Date()
  const currentHours = dateNow.getHours()
  const currentDay = dateNow.getDate()
  const currentMonth = dateNow.getMonth()
  const currentYear = dateNow.getFullYear()
  return Date.UTC(currentYear, currentMonth, currentDay, currentHours)
}

/**
 *
 * @param date Should be in yyyy-mm-dd format
 */
export const createCurrentTimeZoneDateAtMidnight = (date: string): Date => {
  const dateSplitted = date.split('-')
  return new Date(Number(dateSplitted[0]), Number(dateSplitted[1]) - 1, Number(dateSplitted[2]))
}

export const formatDate = (date: number) => formatISO9075(fromUnixTime(date))

export const formatWithZero = (date: number, negatives: boolean = false): string => {
  let numberString: string = String(date)
  if (numberString.length === (negatives ? 2 : 1)) return '0' + numberString

  return numberString
}

export const convertHourToDateISO = (date: string, hour: number) => {
  const dateString = new Date(date || 0).toISOString()
  return dateString.replace('00', formatWithZero(hour).toString())
}

export const convertToAmericanDate = (date: string): string => {
  if (date === '') return ''

  const splittedDate = date.split('-')
  const year = +splittedDate[0]
  const month = +splittedDate[1]
  const day = +splittedDate[2]

  return moment(new Date(year, month - 1, day)).format('MMMM Do, YYYY')
}

export const removeTimezone = (date: Date) => {
  return moment.utc(date).format('YYYY-MM-DD[T]HH:mm:ss')
}

export const setHoursFromNow = (numberHours: number): number =>
  new Date().setHours(new Date().getHours() + numberHours)

export const utcDateToAmerican = (dateString: string) => {
  return moment.tz(dateString, 'America/New_York').utc().toDate()
}

// TODO ! need to convert this to hook, no type safety
export const displayDate = (date: string | Date | undefined, weekDay?: boolean): string => {
  if (!date) return 'Error with the date'

  let day = new Date(date).getUTCDate()
  let dayWeek = new Date(date).getUTCDay()
  let month = new Date(date).getUTCMonth()
  let year = new Date(date).getFullYear()

  return weekDay
    ? t(getTranslationLabel('month_', month)) + ' ' + day + 'th ' + year
    : t(getTranslationLabel('days_of_the_week_', dayWeek)) +
        ', ' +
        t(getTranslationLabel('month_', month)) +
        ' ' +
        day +
        'th ' +
        year
}

export const getShortDate = (date: string) => {
  const day = new Date(date).getUTCDate()
  const month = new Date(date).getUTCMonth()
  const year = new Date(date).getFullYear()
  const newDate = new Date(year, month, day)
  return format(newDate, shortDate)
}

export const bookingDisplayDate = (date: string, shortDay?: boolean): string => {
  let day = new Date(date).getUTCDate()
  let dayWeek = new Date(date).getUTCDay()
  let month = new Date(date).getUTCMonth() + 1
  let displayDay = t(getTranslationLabel('days_of_the_week_', dayWeek))

  return shortDay
    ? displayDay.substring(0, 3)
    : displayDay + ', ' + month + ' / ' + formatWithZero(day)
}

export const timeConvertToDate = (hours: number, minutes?: number, date?: string): Date => {
  const [year, month, day] = (date || format(new Date(), timeConvertDate)).split('-')
  const jsMonth: number = Number(month) - 1
  return new Date(Number(year), jsMonth, Number(day), hours, minutes || 0, 0)
}

export const timeFormatter = (hours: number, minutes?: number, forMobile?: boolean) =>
  format(timeConvertToDate(hours, minutes), forMobile ? timeFormatForMobile : timeFormateForDesktop)

export const displayTimeRange = (
  startTime: DisplayTimeRange,
  endTime: DisplayTimeRange,
  forMobile?: boolean,
) =>
  `${timeFormatter(startTime.hours, startTime.minutes, forMobile)} - ${timeFormatter(
    endTime.hours,
    endTime.minutes,
    forMobile,
  )}`

export const openingHoursTimeFormatter = (startTime?: number, endTime?: number) => {
  if (!startTime || !endTime) {
    return 'CLOSED'
  }
  return displayTimeRange({ hours: startTime, minutes: 0 }, { hours: endTime, minutes: 0 })
}

export const isDateTimePassed = (date: string, time: number) => {
  const [year, month, day] = (date || format(new Date(), timeConvertDate)).split('-')
  return isPast(new Date(Number(year), Number(month) - 1, Number(day), time))
}

export const isDatePassed = (date: string): boolean => {
  const [year, month, day] = (date || format(new Date(), timeConvertDate)).split('-')
  return isPast(new Date(Number(year), Number(month) - 1, Number(day)))
}

export const isDateValid = (date: string): boolean => {
  const [year, month, day] = (date || format(new Date(), timeConvertDate)).split('-')
  return isValid(new Date(Number(year), Number(month) - 1, Number(day)))
}

export const bookingDisplayTime = (
  startTime: number,
  endTime: number,
  forMobile?: boolean,
): string => {
  return `${displayTimeRange({ hours: startTime }, { hours: endTime }, forMobile)} (${
    endTime - startTime
  }${t(Number(endTime - startTime) > 1 ? 'booking_hours' : 'booking_hours_other')})`
}

export const differenceInDayFromToday = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
): number => dayjs(date).startOf('day').diff(dayjs().startOf('day'), 'day')

export const formattedDateWithTime = (date?: string, time?: string | number): string =>
  `${date}T${time}:00:00`
