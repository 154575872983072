import { BuildingTypes, RoomsTypes } from '@swiftctrl/api-client-react'
import { Swift } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'
import styled, { CSSObject } from 'styled-components'
import { MobileAvailabilities } from './MobileAvailabilities'
import { MobileReviewAndBook } from './MobileReviewAndBook'

interface Props {
  onClose: () => void
  initialStep?: ReservationMobileDrawerState
  room: RoomsTypes
  building?: Partial<BuildingTypes>
}

export type ReservationMobileDrawerState = 'Review' | 'BookingCalendar'

const defaultStyle = {
  transition: `transform 250ms ease-out, opacity 300ms ease-in`,
  width: '100%',
  height: '100%',
}

const reviewTransitionStyles: Record<TransitionStatus, CSSObject> = {
  entering: { transform: 'translateX(100%)' },
  entered: { transform: 'translateX(0%)' },
  exiting: { transform: 'translateX(100%)' },
  exited: {},
  unmounted: {},
}

const calendarTransitionSTyles: Record<TransitionStatus, CSSObject> = {
  entering: { transform: 'translateX(-100%)' },
  entered: { transform: 'translateX(0%)' },
  exiting: { transform: 'translateX(-100%)' },
  exited: {},
  unmounted: {},
}

export const ReservationMobileDrawer: Swift.FC<Props> = ({
  onClose,
  initialStep,
  room,
  building,
}) => {
  const [step, setStep] = useState(initialStep || 'Review')
  const handleSwitchToReview = () => setStep('Review')
  const handleSwitchToCalendar = () => setStep('BookingCalendar')

  return (
    <StyledContainer $step={step}>
      <Transition
        unmountOnExit
        in={step === 'BookingCalendar'}
        timeout={{ appear: 0, enter: 50, exit: 250 }}
      >
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...calendarTransitionSTyles[state],
            }}
          >
            <MobileAvailabilities
              room={room}
              onClickNext={handleSwitchToReview}
              onClose={onClose}
              schedule={building?.schedule}
            />
          </div>
        )}
      </Transition>
      <Transition
        unmountOnExit
        in={step === 'Review'}
        timeout={{ appear: 0, enter: 50, exit: 250 }}
      >
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...reviewTransitionStyles[state],
            }}
          >
            <MobileReviewAndBook
              building={building}
              room={room}
              onClickEdit={handleSwitchToCalendar}
              onClose={onClose}
            />
          </div>
        )}
      </Transition>
    </StyledContainer>
  )
}

const StyledContainer = styled.div<{ $step: ReservationMobileDrawerState }>`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  > div {
    position: absolute;
    transform: translateX(${({ $step }) => ($step === 'Review' ? '100%' : '-100%')});
  }
`
