import { Col, Row, Swift, UserOutlined } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { useTranslation } from '../../hooks'

interface Props {
  maxGuests?: number
  minGuests?: number
  hourlyPrice?: number
}

export const PriceAndGuestCapacity: Swift.FC<Props> = ({ maxGuests, minGuests, hourlyPrice }) => {
  const { t } = useTranslation()
  return (
    <StyledRow justify="space-between">
      <Col>
        <span>{`$${hourlyPrice}`}</span> {`${t('room_details_per_hour')}`}
      </Col>
      <Col>
        <UserOutlined />
        {minGuests}-{maxGuests}
      </Col>
    </StyledRow>
  )
}

const StyledRow = styled(Row)`
  ${({ theme }) => ({
    svg: {
      marginRight: theme.spacing.small,
    },
    span: {
      fontWeight: theme.designTokens.fonts.weights.semibold,
    },
  })}
`
