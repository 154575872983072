import { Checkbox, Col, Swift } from '@swiftctrl/swift-component-library'

import { useTranslation } from '../../../../hooks'
import { getTranslationLabel } from '../../../../utils'

interface Props {
  horizontal?: boolean
  amenities?: string[]
}

export const AmenitiesList: Swift.FC<Props> = ({ amenities, horizontal }) => {
  const { t } = useTranslation()
  return (
    <>
      {horizontal ? (
        amenities?.map((amenity) => {
          return (
            <Col key={amenity} span={24} sm={12}>
              <ul>
                <li>
                  <Checkbox key={amenity} value={amenity}>
                    {t(getTranslationLabel('search_amenities_', amenity))}
                  </Checkbox>
                </li>
              </ul>
            </Col>
          )
        })
      ) : (
        <ul>
          {amenities?.map((amenity) => (
            <li key={amenity}>
              <Checkbox key={amenity} value={amenity}>
                {t(getTranslationLabel('search_amenities_', amenity))}
              </Checkbox>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
