import { RoomsTypes } from '@swiftctrl/api-client-react'
import {
  Col,
  Row,
  Swift,
  Tooltip,
  Typography,
  UserOutlined,
} from '@swiftctrl/swift-component-library'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import imagePlaceHolder from '../../../assets/image-place-holder.png'
import { useHandleUrlParams, useTranslation } from '../../../hooks'
import { numberGuestsLabel, paths } from '../../../utils'

interface Props {
  room: RoomsTypes & { organizationName: string }
}

const { RoomDetails, Search } = paths

export const RoomListDetails: Swift.FC<Props> = ({ room }) => {
  const { t } = useTranslation()
  const { currentParams } = useHandleUrlParams()

  return (
    <StyledRoomDetailsContainer>
      <Link
        to={{
          pathname: `/${RoomDetails}/${room.overseer_id}/${room.room_id}`,
          search: `?guests=${currentParams.guests}&date=${currentParams?.date}&startTime=${currentParams?.startTime}&endTime=${currentParams?.endTime}`,
          state: {
            from: Search,
          },
        }}
      >
        <StyledRoomImage>
          <StyledRoomPicture room={room} />
        </StyledRoomImage>
        <StyledDetailsAmenities>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <StyledRoomName> {room.entity_name} </StyledRoomName>
                <StyledRoomDescription>
                  {room.overseer_name} | {room.organizationName}
                </StyledRoomDescription>
              </Col>
              <StyledPriceColumn>
                <Typography.Title level={4}>${room.price}</Typography.Title>{' '}
                <span>{t('room_details_per_hour')}</span>
              </StyledPriceColumn>
            </Row>
          </Col>
        </StyledDetailsAmenities>
      </Link>
      <StyledDetailsAmenities>
        <Col span={12}>
          <StyledIconGuests>
            <Tooltip
              placement="bottom"
              title={numberGuestsLabel(room) + ' ' + t('search_bar_room_details_guests')}
            >
              <UserOutlined size={24} />
            </Tooltip>
          </StyledIconGuests>
          <StyledGuestNumber>{numberGuestsLabel(room)}</StyledGuestNumber>
        </Col>
      </StyledDetailsAmenities>
    </StyledRoomDetailsContainer>
  )
}

const StyledPriceColumn = styled(Col)`
  span {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
    color: #000;
  }
  h4 {
    display: inline-block;
  }
`

const StyledRoomPicture = styled.div<Props>`
  width: 100%;
  height: 100%;
  background: ${({ room: { images } }) => `url(${images[0] || imagePlaceHolder} )`} center / cover;
`
const StyledRoomDetailsContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.medium};
  margin-right: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xxLarge};
  :hover {
    cursor: pointer;
  }
`
const StyledRoomImage = styled(Row)`
  background-color: #f0f0f0;
  border-radius: 2px;
  height: 217px;
  overflow: hidden;
`
const StyledDetailsAmenities = styled(Row)`
  padding-top: 1.5em;
`
const StyledRoomName = styled.div`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].lineHeight};
  color: #262626;
`
const StyledRoomDescription = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  color: #262626;
`
const StyledIconGuests = styled.div`
  display: inline;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  padding-right: ${({ theme }) => theme.spacing.xSmall};
`
const StyledGuestNumber = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  display: inline;
  padding-top: ${({ theme }) => theme.spacing.small};
`
