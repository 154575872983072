import { admin_room_reservations_en } from './admin_room_reservations_en'
import { admin_room_reservations_tab_filter_en } from './admin_room_reservations_tab_filter_en'
import { avatar_menu_en } from './avatar_menu_en'
import { booking_en } from './booking_en'
import { building_view_en } from './building-view-en'
import { checkout_screen_en } from './checkout_screen_en'
import { dates_en } from './dates_en'
import { general_en } from './general_en'
import { reservations_en } from './reservations_en'
import { reservation_details_screen_en } from './reservation_details_screen_en'
import { room_details_en } from './room_details_en'
import { search_screen_en } from './search_screen_en'

export const en = {
  ...avatar_menu_en,
  ...booking_en,
  ...checkout_screen_en,
  ...dates_en,
  ...general_en,
  ...reservation_details_screen_en,
  ...reservations_en,
  ...search_screen_en,
  ...room_details_en,
  ...admin_room_reservations_en,
  ...admin_room_reservations_tab_filter_en,
  ...building_view_en,
}
