import { BuildingTypes, RoomsTypes, useRoom, useSwiftClient } from '@swiftctrl/api-client-react'
import {
  Alert,
  Button,
  CalendarOutlined,
  ClockCircleOutlined,
  Divider,
  Swift,
  Typography,
} from '@swiftctrl/swift-component-library'
import { format } from 'date-fns'
import styled from 'styled-components'
import { mixPanelTrack } from '../../../analytics'
import { AddressDisplay, NumberGuestsInput } from '../../../components'
import {
  useBoolean,
  useCreateBooking,
  useHandleUrlParams,
  useStoreCurrentUser,
  useStoreReservation,
  useTranslation,
} from '../../../hooks'
import {
  bookingDisplayTime,
  config,
  convertHourToDateISO,
  createCurrentTimeZoneDateAtMidnight,
  createNewDateAsUtcDate,
  differenceInDayFromToday,
  formattedDateWithTime,
  goToAccountsApp,
  isRoomAvailable,
  removeTimezone,
} from '../../../utils'
import { DrawerHeader } from './DrawerHeader'

interface Props {
  onClose: () => void
  onClickEdit: () => void
  room: RoomsTypes
  building?: Partial<BuildingTypes>
}

export const MobileReviewAndBook: Swift.FC<Props> = ({ onClose, onClickEdit, room, building }) => {
  const { reservationDetails, updateStoreReservation } = useStoreReservation()

  const swiftClient = useSwiftClient()
  const { date, numberOfGuests } = reservationDetails

  const startTime: string = formattedDateWithTime(
    reservationDetails?.date,
    reservationDetails?.startTime,
  )
  const endTime: string = formattedDateWithTime(
    reservationDetails?.date,
    reservationDetails?.endTime,
  )
  const { currentUser } = useStoreCurrentUser()
  const loggedIn: boolean = Boolean(currentUser)
  const [showErrorAlert, openErrorAlert, closeErrorAlert] = useBoolean()

  const startTimeDate: Date = new Date(
    convertHourToDateISO(reservationDetails.date, reservationDetails.startTime),
  )
  const endTimeDate: Date = new Date(
    convertHourToDateISO(reservationDetails.date, reservationDetails.endTime),
  )

  const { updateParams, cleanParams } = useHandleUrlParams()

  const {
    appConstants: { dateFullDisplay },
  } = config

  const handleSetNumberOfGuests = (guests: number) =>
    updateStoreReservation({ ...reservationDetails, numberOfGuests: guests })

  const { t } = useTranslation()

  const handleClose = () => {
    cleanParams(['booking'])
    onClose()
  }

  const handleEdit = () => {
    if (showErrorAlert) {
      updateStoreReservation({
        ...reservationDetails,
        numberOfGuests: 1,
        startTime: 0,
        endTime: 0,
        date: new Date(createNewDateAsUtcDate()).toISOString().split('T')[0],
        duration: 0,
      })
    }
    cleanParams(['booking'])
    onClickEdit()
  }

  const getPriceBreakdownDisplay = (startTime: number, endTime: number) => {
    const duration = endTime - startTime
    return `${duration} ${t(
      duration > 1 ? 'reservation_details_hours_other' : 'reservation_details_hours_one',
    )}`
  }

  const { data: costData } = useRoom()
    .at(reservationDetails.roomId!)
    .price(
      {
        browseOptions: {
          queryKey: 'room-price',
        },
        startTime: reservationDetails.date
          ? convertHourToDateISO(reservationDetails.date, reservationDetails.startTime).split(
              '.',
            )[0]
          : '',
        endTime: reservationDetails.date
          ? convertHourToDateISO(reservationDetails.date, reservationDetails.endTime).split('.')[0]
          : '',
      },
      { enabled: Boolean(reservationDetails.date) },
    )

  const price = costData?.data?.price

  const {
    mutate: createBooking,
    createStripeSessionLoading,
    isLoading: createBookingLoading,
  } = useCreateBooking()

  const createCheckout = () => {
    if (!loggedIn) {
      updateParams({ booking: 'pending' })
      return goToAccountsApp('signin')
    }
    cleanParams(['booking'])

    swiftClient.room
      .at(room.room_id)
      .availabilities.browse({
        startTime: removeTimezone(startTimeDate),
        endTime: removeTimezone(endTimeDate),
      })
      .then((data) => {
        const availabilities = data.data[0].availabilities
        const available = isRoomAvailable(
          removeTimezone(startTimeDate),
          removeTimezone(endTimeDate),
          availabilities,
        )
        if (available) {
          if (reservationDetails) {
            const payload = {
              room_id: room.room_id,
              guest_count: reservationDetails.numberOfGuests!,
              start_time: startTime,
              end_time: endTime,
            }
            createBooking(payload)
            mixPanelTrack('Book Space (Start Payment)', {
              'Days From Today': differenceInDayFromToday(removeTimezone(startTimeDate)),
              'Guest Count': reservationDetails.numberOfGuests,
            })
          }
        } else {
          openErrorAlert()
        }
      })
      .catch((error) => {
        openErrorAlert()
      })
  }

  return (
    <StyledContainer>
      <DrawerHeader onClose={handleClose} text={t('room_details_reservation_details')} />
      <StyledContent>
        <div>
          <StyledNameAndAddress>
            <Typography.Title level={5}>{room.entity_name}</Typography.Title>
            <Typography.Text>
              {room.overseer_name} | {building?.overseer_name}
            </Typography.Text>
            <AddressDisplay address={building?.addresses?.[0]} />
            <Divider />
          </StyledNameAndAddress>
          <div>
            {showErrorAlert && (
              <StyledAlert
                message={t('reservation_modal_error_alert_title')}
                description={t('reservation_modal_error_alert_description')}
                type="error"
                closable
                onClose={closeErrorAlert}
              />
            )}
            <StyledDateContainer>
              <StyledDate>
                <CalendarOutlined />{' '}
                {format(
                  createCurrentTimeZoneDateAtMidnight(new Date(date).toISOString().split('T')[0]),
                  dateFullDisplay,
                )}
              </StyledDate>
              <Button onClick={handleEdit} size="small" type="link">
                {t('checkout_edit_link')}
              </Button>
            </StyledDateContainer>
            <StyledTime>
              <ClockCircleOutlined />
              {bookingDisplayTime(reservationDetails.startTime, reservationDetails.endTime)}
            </StyledTime>
          </div>
          <NumberGuestsInput
            numberGuestsParent={numberOfGuests || 1}
            setNumberGuestsParent={handleSetNumberOfGuests}
            allowZeroGuests={false}
            maxNumberOfGuests={room?.max_capacity ? Number(room?.max_capacity) : 10}
            minNumberOfGuests={room?.min_capacity ? Number(room?.min_capacity) : 1}
            restrictedGuests
          />
          <Divider />
          <div>
            <StyledPrice>
              <Typography.Text>{`${getPriceBreakdownDisplay(
                reservationDetails.startTime,
                reservationDetails.endTime,
              )}`}</Typography.Text>
              <Typography.Text>${price}</Typography.Text>
            </StyledPrice>
            <StyledTaxesAndFees>
              <Typography.Text>{t('reservation_details_taxes_fees_label')}</Typography.Text>
              <Typography.Text type="secondary">
                {t('reservation_details_calculated_at_checkout_label')}
              </Typography.Text>
            </StyledTaxesAndFees>
          </div>
        </div>
        <StyledCheckoutButtonSection>
          <Button
            onClick={createCheckout}
            loading={createBookingLoading || createStripeSessionLoading}
            type="primary"
            disabled={showErrorAlert}
          >
            {t('reservation_checkout_confirmButton')}
          </Button>
          <Typography.Text type="secondary">{t('booking_cancel_message')}</Typography.Text>
        </StyledCheckoutButtonSection>
      </StyledContent>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  height: calc(100% - 61px);
`

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  .ant-alert-message {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  }
  .ant-alert-description {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  }
`
const StyledNameAndAddress = styled.div`
  h5 {
    margin-bottom: ${({ theme }) => theme.spacing.small};
    margin-top: ${({ theme }) => theme.spacing.small};
  }
  span {
    display: block;
  }
`

const StyledDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  }
`

const StyledPrice = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  display: flex;
  justify-content: space-between;
`

const StyledTaxesAndFees = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span:nth-of-type(2) {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  }
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const StyledDate = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.sizes.small};
    height: ${({ theme }) => theme.icon.sizes.small};
    width: ${({ theme }) => theme.icon.sizes.small};
  }
`
const StyledTime = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.sizes.small};
    height: ${({ theme }) => theme.icon.sizes.small};
    width: ${({ theme }) => theme.icon.sizes.small};
  }
`
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.medium};
  height: 100%;
  overflow: auto;
`

const StyledCheckoutButtonSection = styled.div`
  width: 100%;
  text-align: center;
  button {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
  .ant-typography {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  }
`
