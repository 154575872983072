import { CitiesTypes, useCities } from '@swiftctrl/api-client-react'
import {
  Button,
  CalendarOutlined,
  CloseOutlined,
  Col,
  Input,
  Menu,
  Row,
  SearchOutlined,
  Swift,
} from '@swiftctrl/swift-component-library'
import { add, format, getHours, isBefore, isSameDay, set, setHours } from 'date-fns'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { mixPanelTrack } from '../../../../analytics'
import { CustomTimePicker, NumberGuestsInput } from '../../../../components'
import { useBoolean, useHandleUrlParams, useStoreParams, useTranslation } from '../../../../hooks'
import { TimeRangeValues } from '../../../../models'
import { appTheme } from '../../../../styles'
import {
  config,
  createCurrentTimeZoneDateAtMidnight,
  differenceInDayFromToday,
  isLocationMatch,
  setHoursFromNow,
} from '../../../../utils'
import { SmartSearchDatePicker } from './SmartSearchDatePicker'

interface Props {
  closeSmartSearch: () => void
  showSmartSearch: boolean
}

const defaultCitiesData: CitiesTypes[] = [
  {
    city_id: '-1',
    city: 'No cities found',
    state: '',
    country: '',
  },
]

export const SmartSearch: Swift.FC<Props> = ({ closeSmartSearch, showSmartSearch }) => {
  const { currentStoreParams, updateStoreParams } = useStoreParams()
  const locationPickerRef = useRef<HTMLDivElement>(null)
  const {
    appConstants: { timeRangeFieldFormat, dateLongDisplayFormat },
    SWIFT_ROOT_ENTITY_ID,
  } = config
  const nextAvailableDate =
    getHours(new Date()) === 23
      ? format(add(setHours(new Date(), 0), { days: 1 }), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
  const hourNow = new Date(setHoursFromNow(1)).getHours()
  const hourNext = new Date(setHoursFromNow(2)).getHours()
  const [locationDropDown, openLocationDropDown, closeLocationDropDown] = useBoolean()
  const [locationDropDownSelected, openLocationDropDownSelected, closeLocationDropDownSelected] =
    useBoolean()
  const [currentCity, setCurrentCity] = useState('')
  const [dateSelected, setDateSelected] = useState('')
  const [numberGuests, setNumberGuests] = useState(0)
  const { currentParams, updateParams, cleanParams } = useHandleUrlParams()
  const [clearAll, enableClearAll, disableClearAll] = useBoolean()
  const [timeRangeValues, setTimeRangeValues] = useState<TimeRangeValues>({
    start: moment(currentParams.startTime, timeRangeFieldFormat),
    end: moment(currentParams.endTime, timeRangeFieldFormat),
  })
  const [cityLocation, setCityLocation] = useState<CitiesTypes>()

  const { data } = useCities()
    .browse({
      browseOptions: { queryKey: 'cities' },
      baseId: SWIFT_ROOT_ENTITY_ID,
      select: ['city_id', 'city', 'state', 'country'],
    })
    .query()

  const citiesData = data?.data || defaultCitiesData

  const [searchedText, setSearchedText] = useState('')
  const { t } = useTranslation()

  const smartSearchRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setSearchedText(
      currentParams.city || currentParams.state
        ? `${currentParams.city}, ${currentParams.state}`
        : '',
    )
    setCurrentCity(currentParams.city || '')
    setDateSelected(currentParams.date || '')
    setNumberGuests(currentParams.guests || 0)
    setTimeRangeValues({
      start: moment(currentParams.startTime, timeRangeFieldFormat),
      end: moment(currentParams.endTime, timeRangeFieldFormat),
    })
  }, [showSmartSearch])

  useEffect(() => {
    const handleClickInside = (event: MouseEvent) => {
      if (locationPickerRef.current && locationPickerRef.current.contains(event.target as Node)) {
        openLocationDropDownSelected()
      } else {
        locationPickerRef!.current!.scrollTo(0, 0)
        closeLocationDropDownSelected()
      }
    }
    document.addEventListener('mousedown', handleClickInside)
    return () => {
      document.removeEventListener('mousedown', handleClickInside)
    }
  }, [locationPickerRef])

  useEffect(() => {
    closeLocationDropDown()
  }, [currentCity])

  useEffect(() => {
    if (numberGuests !== 0) {
      setNumberGuests(numberGuests)
    }
  }, [numberGuests])

  const selectCity = (city: string) => {
    setCurrentCity(city)
  }

  const selectDate = (date: Date | null, dateString: string) => {
    const stringDate = date ? format(date, 'yyyy-MM-dd') : dateString
    setDateSelected(stringDate)

    if (format(new Date(), 'yyyy-MM-dd') === dateString) {
      setTimeRangeValues({
        start: moment(hourNow, timeRangeFieldFormat),
        end: moment(hourNext, timeRangeFieldFormat),
      })
    }
  }

  const searchButton = () => {
    mixPanelTrack('Search Spaces', {
      'Days From Today': differenceInDayFromToday(dateSelected),
      'Guest Count': numberGuests,
    })
    if (clearAll) {
      cleanParams(['city', 'state', 'country'])
    }

    updateParams({
      date: dateSelected,
      guests: numberGuests,
      startTime: timeRangeValues.start.hours(),
      endTime: timeRangeValues.end.hours() || 24,
      ...((cityLocation?.city || currentStoreParams?.city) && {
        city: cityLocation?.city || currentStoreParams?.city,
      }),
      ...((cityLocation?.state || currentStoreParams?.state) && {
        state: cityLocation?.state || currentStoreParams?.state,
      }),
      ...((cityLocation?.country || currentStoreParams?.country) && {
        country: cityLocation?.country || currentStoreParams?.country,
      }),
    })
    disableClearAll()
    closeSmartSearch()
  }

  const filterLocations = (search: string, locations?: CitiesTypes[]): CitiesTypes[] => {
    if (!locations) {
      return []
    }

    const trimmedSearch: string = search.trim()
    if (trimmedSearch) {
      const modifiedLocations: CitiesTypes[] = [...locations]
      const searchResult: CitiesTypes[] = modifiedLocations.filter((location) =>
        isLocationMatch(location, trimmedSearch),
      )
      const restResult: CitiesTypes[] = modifiedLocations.filter(
        (location) => !isLocationMatch(location, trimmedSearch),
      )
      modifiedLocations.splice(0, modifiedLocations.length)
      modifiedLocations.push(...searchResult, ...restResult)
      return modifiedLocations
    }
    return locations
  }

  const onSelectedCity = (location: CitiesTypes) => {
    setCityLocation(location)
    const selectedCityLabel = location.city + ', ' + location.state
    selectCity(selectedCityLabel)
    setSearchedText(selectedCityLabel)
    closeLocationDropDown()
    closeLocationDropDownSelected()
  }

  const onCitiesSearch = (city: string) => {
    if (!city) {
      setCityLocation(undefined)
    }
    setSearchedText(city)
  }

  const clearAllSearchParams = () => {
    selectCity('')
    setSearchedText('')
    selectDate(null, nextAvailableDate)
    setNumberGuests(1)
    setSearchedText('')
    setCityLocation(undefined)
    updateStoreParams('city', null)
    updateStoreParams('state', null)
    updateStoreParams('country', null)
    enableClearAll()

    setTimeRangeValues({
      start: moment(hourNow, timeRangeFieldFormat),
      end: moment(hourNext, timeRangeFieldFormat),
    })
  }

  const getDisabledHours = () => {
    var hours = []
    // space is added to create the selected date regardless of the timezone
    if (isSameDay(new Date(dateSelected + ' '), new Date()))
      for (var i = 0; i < hourNow; i++) {
        hours.push(i)
      }
    return hours
  }

  const onDateClear = () => selectDate(null, nextAvailableDate)

  const disablePastDates = (date: Date) => {
    const todaysDateAtMidnight = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    const tomorrowsDate = add(todaysDateAtMidnight, { days: 1 })
    const currentHour = getHours(new Date())

    if (currentHour === 23) {
      return isBefore(date, tomorrowsDate)
    }
    return isBefore(date, todaysDateAtMidnight)
  }

  const handleOnBlur = () => {
    if (locationDropDownSelected) {
      return
    }
    closeLocationDropDown()
  }

  return (
    <StyledSmartSearch ref={smartSearchRef}>
      <Row align="middle">
        <Col span={24}>
          <StyledSearchField
            type="text"
            placeholder={t('search_bar_smart_search_what_city')}
            size="large"
            prefix={<SearchOutlined />}
            onClick={openLocationDropDown}
            value={searchedText}
            onChange={(e) => onCitiesSearch(e.target.value)}
            onBlur={handleOnBlur}
          />
          <StyledLocationDropdown $visible={locationDropDown} ref={locationPickerRef}>
            <Menu selectedKeys={[String(cityLocation?.city_id)]} mode="inline">
              {filterLocations(searchedText, citiesData)!.map((location) => (
                <StyledMenuItem key={location.city_id} onClick={() => onSelectedCity(location)}>
                  <StyledCityName>{location.city}</StyledCityName>
                  <StyledCityDetails>
                    {location.state}, {location.country}
                  </StyledCityDetails>
                </StyledMenuItem>
              ))}
            </Menu>
          </StyledLocationDropdown>
          <StyledClearButton
            $visible={currentCity !== ''}
            shape="circle"
            icon={<CloseOutlined />}
            type="text"
            size="small"
            onClick={() => {
              setCurrentCity('')
              onCitiesSearch('')
              setCityLocation(undefined)
              updateStoreParams('city', null)
              updateStoreParams('state', null)
              updateStoreParams('country', null)
              enableClearAll()
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SmartSearchDatePicker
            value={createCurrentTimeZoneDateAtMidnight(dateSelected)}
            disabledDate={disablePastDates}
            suffixIcon={<StyledCalenderIcon />}
            onChange={selectDate}
            format={dateLongDisplayFormat}
            onClear={onDateClear}
            showClearButton={dateSelected !== nextAvailableDate}
            onFocus={closeLocationDropDown}
          />
        </Col>
      </Row>
      <StyledTimePickerRow>
        <Col>
          <CustomTimePicker
            keepInterval
            disabledHours={getDisabledHours()}
            minimumHourInterval={1}
            endTime={timeRangeValues.end.toDate().getHours()}
            startTime={timeRangeValues.start.toDate().getHours()}
            onClear={() =>
              setTimeRangeValues({
                start: moment(hourNow, timeRangeFieldFormat),
                end: moment(hourNext, timeRangeFieldFormat),
              })
            }
            onFocusStartTime={closeLocationDropDown}
            onFocusEndTime={closeLocationDropDown}
            onEndTimeChange={(value) =>
              setTimeRangeValues((prevState) => ({
                ...prevState,
                end: moment(value || 24, timeRangeFieldFormat),
              }))
            }
            onStartTimeChange={(value) =>
              setTimeRangeValues((prevState) => ({
                ...prevState,
                start: moment(value, timeRangeFieldFormat),
              }))
            }
            hideClearButton={
              timeRangeValues.start.hours() === hourNow && timeRangeValues.end.hours() === hourNext
            }
          />
        </Col>
      </StyledTimePickerRow>
      <StyledNumberOrGuests
        numberGuestsParent={numberGuests}
        setNumberGuestsParent={setNumberGuests}
        allowZeroGuests={true}
        resetButton={true}
        staticSize={true}
        onFocusGuest={closeLocationDropDown}
      />
      <Row>
        <Col span={24}>
          <StyledSearchButton size="large" type="primary" block onClick={searchButton}>
            {t('search_bar_smart_search_search_button')}
          </StyledSearchButton>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StyledSpanButton onClick={clearAllSearchParams}>
            {t('search_bar_smart_search_clear_all_button')}
          </StyledSpanButton>
        </Col>
      </Row>
    </StyledSmartSearch>
  )
}

const StyledNumberOrGuests = styled(NumberGuestsInput)`
  margin-top: ${({ theme }) => theme.spacing.xSmall};
`

const StyledSpanButton = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
  color: #1890ff;
  cursor: pointer;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
`

const StyledTimePickerRow = styled(Row)`
  margin-top: ${appTheme.spacing.small};
`

const StyledCalenderIcon = styled(CalendarOutlined)`
  padding-right: ${({ theme }) => theme.spacing.small};
`

const StyledSmartSearch = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.xMedium} ${theme.spacing.xMedium} ${theme.spacing.medium} ${theme.spacing.xMedium}`};
  width: ${({ theme }) => (theme.isMobileView ? 'auto' : '512px')};
  height: 320px;

  background: #ffffff;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;

  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  position: relative;
  float: left;
  z-index: 200;
  top: -2.6em;
  left: -2px;
`

const StyledLocationDropdown = styled.div<{ $visible?: boolean }>`
  width: 375px;
  z-index: 270;
  padding: ${({ theme }) => theme.spacing.xSmall} ${({ theme }) => theme.spacing.none};
  overflow-y: scroll;
  height: 250px;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  position: absolute;

  background: ${({ theme }) => theme.designTokens.colors.background.body.accent};

  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  ::-webkit-scrollbar {
    display: none;
  }
`
const StyledMenuItem = styled(Menu.Item)`
  min-height: 52px;
  :hover {
    background: #e6f7ff;
  }
`
const StyledCityDetails = styled.div`
  color: #8c8c8c;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
`
const StyledCityName = styled.div`
  color: #262626;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
`
const StyledSearchField = styled(Input)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`
const StyledSearchButton = styled(Button)`
  margin-top: 12px;
`
const StyledClearButton = styled(Button)<{ $visible?: boolean }>`
  color: rgb(175, 175, 175);
  position: absolute;
  bottom: 12px;
  z-index: 250;
  right: 10px;
  display: ${({ $visible }) => ($visible ? 'default' : 'none')};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  margin-bottom: ${({ theme }) => theme.spacing.xSmall};
`
