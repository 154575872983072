import { Availability } from '@swiftctrl/api-client-react'
import { convertRangesToHoursArray } from './format-availabilities'

export const isRoomAvailable = (
  startDate: string,
  endDate: string,
  availabilities: Availability[],
): boolean => {
  let available = true
  const availableHours = convertRangesToHoursArray(availabilities)
  const bookingHours: number[] = []
  const startBookingHour = new Date(startDate).getHours()
  const endBookingHour = new Date(endDate).getHours()
  for (let hour = startBookingHour; hour < endBookingHour; hour++) {
    bookingHours.push(hour)
  }
  bookingHours.forEach((hour) => {
    if (!availableHours.includes(hour)) {
      available = false
    }
  })
  return available
}
