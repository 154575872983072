import { Button } from 'antd'
import { Swift, InfoCircleFilled, Result } from '@swiftctrl/swift-component-library'
import { useTranslation } from '../../hooks'
import styled from 'styled-components'

interface Props {
  type: string
}

export const EmptyList: Swift.FC<Props> = ({ type }) => {
  const { t } = useTranslation()

  return (
    <StyledEmptyContainer>
      <Result
        icon={<InfoCircleFilled />}
        title={
          type === 'upcoming'
            ? t('reservations_empty_list_message_upcoming')
            : t('reservations_empty_list_message_past')
        }
        extra={
          <Button type="primary" href="/">
            {t('reservations_empty_list_explore_button')}
          </Button>
        }
      />
    </StyledEmptyContainer>
  )
}

const StyledEmptyContainer = styled.div`
  text-align: center;
  .ant-result {
    padding: ${({ theme }) => theme.spacing.none};
  }
  .ant-result-title {
    line-height: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].lineHeight};
  }
  svg {
    font-size: 63px;
  }
`
