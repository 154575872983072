import { ReservationDetailsType } from '../../models'

export const emptyReservation: ReservationDetailsType = {
  date: '',
  startTime: 0,
  endTime: 0,
  duration: 0,
  numberOfGuests: 1,
  roomId: '',
  tenantId: '',
  cost: '',
}
