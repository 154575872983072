import { Skeleton, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const ReservationDetailsMobileLoadingSkeleton: Swift.FC = () => (
  <StyledContainer>
    <StyledSkeleton active paragraph={{ rows: 4 }} />
    <StyledSkeleton active paragraph={{ rows: 4 }} />
    <StyledSkeleton active paragraph={{ rows: 6 }} />
    <StyledSkeleton active paragraph={{ rows: 9 }} />
    <Skeleton active paragraph={{ rows: 5 }} />
  </StyledContainer>
)

const StyledContainer = styled.div`
  padding: 24px ${({ theme }) => theme.spacing.medium};
`

const StyledSkeleton = styled(Skeleton)`
  margin-bottom: 64px;
`
