export const getInitials = (name?: string) => {
  if (!name || name.trim().length === 0) {
    return '-'
  }

  const trimmedName = name.trim()
  const nameArray = trimmedName.split(' ')
  const firstNameInitial = nameArray[0].charAt(0).toUpperCase()
  const lastNameInitial = nameArray[nameArray.length - 1].charAt(0).toUpperCase()

  if (nameArray.length === 1) {
    return firstNameInitial
  }

  return `${firstNameInitial}${lastNameInitial}`
}
