import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { isNull, isObject } from 'lodash'
import { RootState, StoreUpdater } from '../../models'
import { Actions } from '../../store'
import { useDispatch, useSelector } from './useStoreHook'

export const useStoreCurrentUser = () => {
  const dispatch = useDispatch()

  const updateStoreCurrentUser: StoreUpdater<ProfilesTypes> = <T extends Object>(
    arg1: keyof T | T | null,
    arg2?: T[typeof arg1 & keyof T] | null,
  ) => {
    if (isNull(arg1)) {
      dispatch(Actions.setCurrentUser(null))
      return
    }
    if (isObject(arg1)) {
      dispatch(Actions.setCurrentUser(arg1))
    } else {
      dispatch(Actions.setCurrentUser({ [arg1]: arg2 } as Partial<ProfilesTypes>))
    }
  }

  const currentUser: ProfilesTypes | null = useSelector<ProfilesTypes | null>(
    (state: RootState) => state.currentUser.data,
  )

  return { currentUser, updateStoreCurrentUser }
}
