import { Col, Row, Skeleton, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const ReservationCardLoading: Swift.FC = () => {
  return (
    <>
      <Row gutter={[24, 0]} align="middle">
        <Col flex="120px">
          <StyledImageLoader active shape="square" />
        </Col>
        <Col flex="auto">
          <Skeleton active />
        </Col>
      </Row>
    </>
  )
}

const StyledImageLoader = styled(Skeleton.Avatar)`
  span {
    width: 120px !important;
    height: 120px !important;
  }
`
