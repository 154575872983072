import { SwiftReactApiProvider } from '@swiftctrl/api-client-react'
import { antDMobileEnUS, MobileConfigProvider, Swift } from '@swiftctrl/swift-component-library'
import { ConfigProvider as AntDConfigProvider, Layout } from 'antd'
import { AxiosError } from 'axios'
import { Suspense } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { LastLocationProvider } from 'react-router-last-location'
import styled, { ThemeProvider } from 'styled-components'

import {
  CurrentUserProvider,
  MainNav,
  MobileMainNav,
  OptimizelyProvider,
  Preloader,
  RememberLastLocation,
} from './components'
import { useMobileView } from './hooks'
import { useSessionContext } from './state'
import { store } from './store'
import { appTheme } from './styles'
import { config, ScrollToTop, setSessionSigningStatus } from './utils'

const { Content } = Layout

export const AppProvider: Swift.FC = ({ children }) => {
  const { isMobileView } = useMobileView()
  const theme = { ...appTheme, isMobileView }
  const handlePopupContainer = (container?: HTMLElement): HTMLElement =>
    container?.parentElement || document.getElementById('root')!
  const { signInStatus } = useSessionContext()

  return (
    <AntDConfigProvider getPopupContainer={handlePopupContainer}>
      <SwiftReactApiProvider
        devtoolOptions={
          process.env.NODE_ENV === 'development' ? { initialIsOpen: false } : undefined
        }
        swiftClientConfig={{
          ...config.defaultNodeSdkConfig,
          interceptors: {
            response: [
              {
                onRejected: (error: AxiosError) => {
                  if (error.response?.status === 401) {
                    if (signInStatus === 'SIGNED') {
                      setSessionSigningStatus('UNSIGNED')
                    }
                    if (signInStatus === 'UNSIGNED') {
                      setSessionSigningStatus('INIT')
                    }
                  }
                  return Promise.reject(error)
                },
              },
            ],
          },
        }}
      >
        <MobileConfigProvider locale={antDMobileEnUS}>
          <ThemeProvider theme={theme}>
            <LastLocationProvider>
              <ScrollToTop />
              <ReduxProvider store={store}>
                <Suspense fallback={<Preloader />}>
                  <CurrentUserProvider>
                    <OptimizelyProvider>
                      <RememberLastLocation />
                      <Layout>
                        {isMobileView ? <MobileMainNav /> : <MainNav />}
                        <StyledContent>
                          <main>{children}</main>
                        </StyledContent>
                      </Layout>
                    </OptimizelyProvider>
                  </CurrentUserProvider>
                </Suspense>
              </ReduxProvider>
            </LastLocationProvider>
          </ThemeProvider>
        </MobileConfigProvider>
      </SwiftReactApiProvider>
    </AntDConfigProvider>
  )
}

const StyledContent = styled(Content)`
  background-color: ${appTheme.designTokens.colors.background.body.accent};
`
