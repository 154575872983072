import { BookingTypes } from '@swiftctrl/api-client-react'
import { ReservationDetailsType } from '../../models'

export const getReservationDetailsFromBooking = (booking: BookingTypes): ReservationDetailsType => {
  const startHour = new Date(booking.start_time).getHours()
  const endHour = new Date(booking.end_time).getHours()

  return {
    date: booking.start_time.split('T')[0],
    startTime: startHour,
    endTime: endHour,
    cost: '',
    duration: endHour - startHour,
    tenantId: '',
    roomId: booking.overseer_id,
    numberOfGuests: booking.guest_count,
  }
}
