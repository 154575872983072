import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  MobileCalendar as Calendar,
  MobileCalendarProps,
  MobileCalendarRef,
  RightOutlined,
  Swift,
} from '@swiftctrl/swift-component-library'
import { RefAttributes } from 'react'
import styled from 'styled-components'

export const MobileCalendar: Swift.FC<MobileCalendarProps & RefAttributes<MobileCalendarRef>> = ({
  ...props
}) => {
  return (
    <StyledMobileCalendar>
      <Calendar
        prevMonthButton={<LeftOutlined />}
        nextMonthButton={<RightOutlined />}
        nextYearButton={<DoubleRightOutlined />}
        prevYearButton={<DoubleLeftOutlined />}
        {...props}
      />
    </StyledMobileCalendar>
  )
}

const StyledMobileCalendar = styled.div`
  .adm-calendar-header {
    padding-top: ${({ theme }) => theme.spacing.smedium};
    svg {
      color: #1890ff;
      font-size: ${({ theme }) => theme.designTokens.fonts.sizes['24px'].fontSize};
      transform: translateY(1px) !important;
    }
  }
  .adm-calendar-cell-top {
    transform: translateY(4px);
  }
  .adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected {
    background-color: #1890ff;
  }
  .adm-calendar-cell .adm-calendar-cell-today {
    color: #1890ff;
  }
  .adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected-end {
    border-radius: 2px;
  }
`
