import { BookingTypes, BuildingTypes, RoomsTypes } from '@swiftctrl/api-client-react'
import { ErrorMessage } from '@swiftctrl/api-client-react/lib/types'
import { Divider, Swift } from '@swiftctrl/swift-component-library'
import { inRange } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import styled from 'styled-components'
import imagePlaceHolder from '../../assets/image-place-holder.png'
import { BackButton, Fallback, ReservationDetailsMobileLoadingSkeleton } from '../../components'
import { useTranslation } from '../../hooks'
import {
  ContactInformations,
  PricingDetails,
  ReservationInfos,
  RoomInfos,
} from '../../screen-components'
import { paths } from '../../utils'

const { Reservations } = paths

export interface Props {
  booking?: BookingTypes
  building?: BuildingTypes
  room?: RoomsTypes
  isLoading?: boolean
  error: ErrorMessage | null
}

export const ReservationDetailsScreenMobile: Swift.FC<Props> = ({
  booking,
  building,
  room,
  isLoading,
  error,
}) => {
  const history = useHistory()

  const lastLocation = useLastLocation()
  const { t } = useTranslation()

  const goBackToPreviousPage = () => history.push(`/${Reservations}${lastLocation?.search}`)

  if (isLoading) {
    return <ReservationDetailsMobileLoadingSkeleton />
  }

  if (error?.request.status === 404) {
    return (
      <Fallback
        status="404"
        section={{
          label: t('fallback_room_reservation_screen_description'),
          buttonLabel: t('fallback_room_reservation_screen_button_label'),
          buttonLink: `/${Reservations}`,
        }}
      />
    )
  }

  if (inRange(error?.request.status, 400, 600)) {
    return <Fallback status="500" />
  }

  return booking ? (
    <div>
      <StyledImage $image={room?.images[0] || imagePlaceHolder}>
        <BackButton onClick={goBackToPreviousPage} />
      </StyledImage>
      <StyledContent>
        <RoomInfos
          tenantId={room?.overseer_id!}
          roomId={room?.room_id!}
          name={room?.entity_name!}
          locationName={building?.entity_name}
          address={building?.addresses?.[0]}
          tabAddress={lastLocation?.search}
        />
        <Divider />
        <ReservationInfos
          startAt={booking.start_time}
          endAt={booking.end_time}
          guests={booking.guest_count}
        />
        <Divider />
        <PricingDetails booking={booking} room={room!} />
        <Divider />
        <ContactInformations profile_id={booking.creator_profile_id} />
      </StyledContent>
    </div>
  ) : (
    <></>
  )
}

const StyledImage = styled.div<{ $image?: string }>`
  aspect-ratio: 2 / 1;
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-position: center;
  padding: ${({ theme }) => theme.spacing.medium};
`

const StyledContent = styled.div`
  padding: 24px ${({ theme }) => theme.spacing.medium};
`
