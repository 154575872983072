import { CitiesTypes, useCities } from '@swiftctrl/api-client-react'
import { Menu, Swift } from '@swiftctrl/swift-component-library'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { isLocationMatch } from '../../../../utils'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSelect: (location: CitiesTypes) => void
  inputRef: React.RefObject<HTMLDivElement>
  filterValue: string
  cityLocation?: CitiesTypes
}

export const MobileLocationDropdown: Swift.FC<Props> = ({
  isOpen,
  onClose,
  onSelect,
  filterValue,
  inputRef,
  cityLocation,
}) => {
  const { data } = useCities()
    .browse({
      browseOptions: { queryKey: 'cities' },
      select: ['city_id', 'city', 'state', 'country'],
    })
    .query()
  const locations = data?.data

  const menuRef = useRef<HTMLDivElement>(null)

  const filterLocations = (search: string, locations?: CitiesTypes[]): CitiesTypes[] => {
    if (!locations) {
      return []
    }

    const trimmedSearch: string = search.trim()
    if (trimmedSearch) {
      const modifiedLocations: CitiesTypes[] = [...locations]
      const searchResult: CitiesTypes[] = modifiedLocations.filter((location) =>
        isLocationMatch(location, trimmedSearch),
      )
      const restResult: CitiesTypes[] = modifiedLocations.filter(
        (location) => !isLocationMatch(location, trimmedSearch),
      )
      modifiedLocations.splice(0, modifiedLocations.length)
      modifiedLocations.push(...searchResult, ...restResult)
      return modifiedLocations
    }
    return locations
  }
  useEffect(() => {
    const menu = menuRef.current
    const input = inputRef.current
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        !menu?.contains(event.target as Node) &&
        !input?.contains(event.target as Node)
      ) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen, onClose, inputRef])

  return (
    <div ref={menuRef}>
      <StyledLocationsMenu selectedKeys={[String(cityLocation?.city_id)]}>
        {filterLocations(filterValue, locations).map((location) => (
          <Menu.Item key={location.city_id} onClick={() => onSelect(location)}>
            <StyledLocationName>{location.city}</StyledLocationName>
            <StyledLocationDetails>{`${location.state}, ${location.country}`}</StyledLocationDetails>
          </Menu.Item>
        ))}
      </StyledLocationsMenu>
    </div>
  )
}

const StyledLocationsMenu = styled(Menu)`
  margin-left: -${({ theme }) => theme.spacing.medium};
  margin-right: -${({ theme }) => theme.spacing.medium};
  .ant-menu-item {
    font-style: normal;
    font-weight: normal;
    color: #262626;
    padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.smedium}`};
    margin-bottom: 0px !important;
    min-height: 52px;
  }
  max-height: 38vh;
  overflow: auto;
`

const StyledLocationName = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
  color: #262626;
`

const StyledLocationDetails = styled.div`
  color: #8c8c8c;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
`
