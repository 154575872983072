import { ArrowLeftOutlined, Button, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

interface Props {
  onClick?: () => void
}

export const BackButton: Swift.FC<Props> = ({ onClick }) => (
  <StyledBackButton onClick={onClick}>
    <ArrowLeftOutlined />
  </StyledBackButton>
)

const StyledBackButton = styled(Button)`
  height: 40px;
  width: 40px;
  padding: 0;
  :hover:not([disabled]),
  :focus:not([disabled]),
  :active:not([disabled]) {
    color: inherit;
    border-color: #d9d9d9;
    background: #fff;
  }
`
