import { useEffect } from 'react'

export const useScreenScrollLock = (active: boolean) => {
  useEffect(() => {
    if (active) {
      document.documentElement.style.setProperty('overflow', 'hidden')
    }
    return () => {
      document.documentElement.style.removeProperty('overflow')
    }
  }, [active])
}
