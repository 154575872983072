import { DatePicker } from 'antd'
import moment, { Moment } from 'moment'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../../hooks'
import { FormType } from '../../../models'
import { Row } from '@swiftctrl/swift-component-library'

interface Props {
  handleChange?: (dates: FormType['dates']) => void
  dates: FormType['dates']
}

export const DateContainer: React.FC<Props> = ({ handleChange, dates }) => {
  const { t } = useTranslation()
  const handleStartDateChange = (date: Moment | null) =>
    handleChange?.({ endDate: dates?.endDate, startDate: date?.toDate()! })

  const handleEndDateChange = (date: Moment | null) =>
    handleChange?.({ startDate: dates?.startDate, endDate: date?.toDate()! })

  return (
    <>
      <h2>{t('tab_search_filter_form_label_dates')}</h2>
      <Row justify="space-between">
        <StyledDatePicker
          value={dates?.startDate && moment(dates.startDate)}
          onChange={handleStartDateChange}
          placeholder={t('tab_search_filter_form_start_date_placeholder')}
        />
        <StyledDatePicker
          value={dates?.endDate && moment(dates.endDate)}
          onChange={handleEndDateChange}
          placeholder={t('tab_search_filter_form_end_date_placeholder')}
        />
      </Row>
    </>
  )
}

const StyledDatePicker = styled(DatePicker)`
  width: 228px;
`
