import { Divider, Swift } from '@swiftctrl/swift-component-library'

export const RentalRules: Swift.FC = () => (
  <div>
    <h2>Lorem ipsum</h2>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua. Ut eu sem integer vitae justo eget. A pellentesque sit amet
      porttitor eget dolor morbi non. Ullamcorper malesuada proin libero nunc consequat interdum
      varius sit. Donec pretium vulputate sapien nec. Lectus magna fringilla urna porttitor rhoncus.
      Sollicitudin aliquam ultrices sagittis orci a scelerisque. Cursus mattis molestie a iaculis at
      erat pellentesque adipiscing. Euismod quis viverra nibh cras pulvinar mattis nunc sed blandit.
      Leo in vitae turpis massa sed elementum tempus egestas. Tellus pellentesque eu tincidunt
      tortor aliquam nulla. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque.
    </p>

    <Divider />

    <h2>Lorem ipsum</h2>
    <p>
      Fermentum posuere urna nec tincidunt praesent semper feugiat. Id faucibus nisl tincidunt eget
      nullam non nisi est sit. Non arcu risus quis varius quam quisque id diam. Morbi enim nunc
      faucibus a pellentesque sit amet. Sed euismod nisi porta lorem mollis aliquam ut. Congue nisi
      vitae suscipit tellus mauris a. Massa vitae tortor condimentum lacinia quis vel. Laoreet
      suspendisse interdum consectetur libero id faucibus nisl. Aenean euismod elementum nisi quis
      eleifend. Ornare aenean euismod elementum nisi quis eleifend. Tincidunt ornare massa eget
      egestas purus viverra accumsan in nisl. Et malesuada fames ac turpis egestas sed tempus urna.
      Et molestie ac feugiat sed lectus vestibulum mattis. Aliquam nulla facilisi cras fermentum
      odio eu feugiat. Arcu risus quis varius quam quisque id diam vel quam. Quisque sagittis purus
      sit amet volutpat consequat. Nec feugiat nisl pretium fusce id velit ut tortor. Eget magna
      fermentum iaculis eu non diam phasellus vestibulum. Sem fringilla ut morbi tincidunt augue
      interdum.
    </p>
  </div>
)
