import { ModifiedBrowseRoomFilterConfig, useRoom } from '@swiftctrl/api-client-react'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { Form, Modal, Swift } from '@swiftctrl/swift-component-library'
import { Button } from 'antd'
import styled from 'styled-components'
import { useHandleUrlParams, useTranslation } from '../../../hooks'
import { config, formattedDateWithTime, searchParamsFormatter } from '../../../utils'
import { FiltersAmenities } from './filters-amenities'

interface Props {
  showFilters: boolean
  selectedAmenities: string[]
  setSelectedAmenities: (selectedAmenities: string[]) => void
  closeFilters: () => void
  applyFilters: (selectedFilters: string[]) => void
}

const { SWIFT_ROOT_ENTITY_ID } = config

export const FiltersModal: Swift.FC<Props> = ({
  showFilters,
  selectedAmenities,
  setSelectedAmenities,
  closeFilters,
  applyFilters,
}) => {
  const { currentParams } = useHandleUrlParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const qRoom = getQueryHandler<ModifiedBrowseRoomFilterConfig>()
  const startTime: string = formattedDateWithTime(currentParams?.date, currentParams?.startTime)
  const endTime: string = formattedDateWithTime(currentParams?.date, currentParams?.endTime)

  searchParamsFormatter(currentParams)

  const { data: roomsCountData, isLoading } = useRoom()
    .browse({
      browseOptions: {
        queryKey: 'building-rooms-count',
      },
      baseId: SWIFT_ROOT_ENTITY_ID,
      limit: 1000,
      filters: [
        qRoom(
          'WHERE',
          'city',
          'EQ',
          currentParams.city || currentParams.state || currentParams.country
            ? `${currentParams.city},${currentParams.state},${currentParams.country}`
            : undefined,
        ),
        qRoom('AND', 'max_capacity', 'GTE', currentParams.guests),
        qRoom('AND', 'min_capacity', 'LTE', currentParams.guests),
        qRoom('AND', 'availabilities', 'GTE', startTime),
        qRoom('AND', 'availabilities', 'LTE', endTime),
        qRoom('AND', 'amenities', 'EQ', selectedAmenities.join(',')),
      ],
    })
    .query({ retry: false })

  const count = roomsCountData?.data?.length

  const clearForm = () => {
    form.resetFields()
    setSelectedAmenities([])
  }

  return (
    <Modal
      centered
      title={t('search_filter')}
      open={showFilters}
      onCancel={closeFilters}
      footer={[
        <StyledClearAllButton key="clear" type="link" onClick={clearForm}>
          {t('search_filter_clear_all')}
        </StyledClearAllButton>,
        <Button key="cancel" onClick={closeFilters}>
          {t('search_filter_cancel')}
        </Button>,
        <Button
          loading={isLoading}
          key="show"
          type="primary"
          onClick={() => applyFilters(selectedAmenities)}
        >
          {t((count || 0) > 0 ? 'search_filter_show_rooms_other' : 'search_filter_show_rooms_one', {
            count: count,
          })}
        </Button>,
      ]}
    >
      <Form form={form} name="filters">
        <FiltersAmenities
          selectedAmenities={selectedAmenities}
          setSelectedAmenities={setSelectedAmenities}
        />
      </Form>
    </Modal>
  )
}

const StyledClearAllButton = styled(Button)`
  position: absolute;
  left: 0;
`
