import { useMediaQuery } from 'react-responsive'
import { config } from '../../utils'

const {
  appConstants: { mobileMaxLandscapeLength, mobileMaxPortraitLength, mobileMaxLandscapeHeight },
} = config

interface UseMobileView {
  onMobilePortrait?: (matches: boolean) => void
  onMobileLandscape?: (matches: boolean) => void
  onLandscape?: (matches: boolean) => void
  onPortrait?: (matches: boolean) => void
}

export const useMobileView = (handler?: UseMobileView) => {
  const isPortrait: boolean = useMediaQuery(
    { orientation: 'portrait' },
    undefined,
    handler?.onPortrait,
  )
  const isLandScape: boolean = useMediaQuery(
    { orientation: 'landscape' },
    undefined,
    handler?.onLandscape,
  )
  const isMobile: boolean = useMediaQuery(
    { maxWidth: mobileMaxPortraitLength },
    undefined,
    handler?.onMobilePortrait,
  )
  const isMobileLandscape: boolean = useMediaQuery(
    { maxWidth: mobileMaxLandscapeLength, maxHeight: mobileMaxLandscapeHeight },
    undefined,
    handler?.onMobileLandscape,
  )

  const isMobileView: boolean = isMobile || isMobileLandscape

  return { isPortrait, isLandScape, isMobile, isMobileLandscape, isMobileView }
}
