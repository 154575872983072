import { Carousel, LeftOutlined, RightOutlined, Swift } from '@swiftctrl/swift-component-library'
import { compact } from 'lodash'
import styled from 'styled-components'
import ImagePlaceHolder from '../../../assets/image-place-holder.png'
import { StyledCoverImage } from '../../../components'
import { Dimensions } from '../../../models'

interface Props {
  images?: string[]
  galleryDimensions: Dimensions
}

interface ImageProps {
  image?: string
  galleryDimensions: Dimensions
}

export const ImageGallery: Swift.FC<Props> = ({ images, galleryDimensions }) => {
  return compact(images).length === 0 ? (
    <StyledCoverImage $image={ImagePlaceHolder} />
  ) : (
    <ImageGalleryStyle galleryDimensions={galleryDimensions}>
      <Carousel arrows nextArrow={<RightOutlined />} prevArrow={<LeftOutlined />}>
        {images?.map((image, item) => {
          return (
            <ImageGalleryStyle key={item} galleryDimensions={galleryDimensions}>
              <ImageStyle galleryDimensions={galleryDimensions} image={image} />
            </ImageGalleryStyle>
          )
        })}
      </Carousel>
    </ImageGalleryStyle>
  )
}

const ImageGalleryStyle = styled.div<ImageProps>`
  width: ${({ galleryDimensions: { width } }) => width}px;
  height: ${({ galleryDimensions: { height } }) => height}px;
  max-height: 350px;
  .slick-prev {
    left: 9px;
  }

  .slick-next {
    right: 19px;
  }

  .slick-prev,
  .slick-next {
    color: rgba(256, 256, 256, 0.35);
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].fontSize};
    z-index: 500;
  }

  .slick-prev:focus,
  .slick-next:focus {
    color: rgba(256, 256, 256, 0.35);
  }

  .slick-prev:hover,
  .slick-next:hover {
    color: rgba(256, 256, 256, 0.8);
  }
`

const ImageStyle = styled.div<ImageProps>`
  width: ${({ galleryDimensions: { width } }) => width}px;
  height: ${({ galleryDimensions: { height } }) => height}px;
  background: url(${(props) => props.image}) center / cover;
  border-radius: 2px;
  max-height: 350px;
`
