export const booking_en = {
  booking_title: 'Booking',
  booking_hourly: 'Hourly',
  booking_hourly_tooltip_no_booking:
    'Prices are subject to change depending on the date and time selection.',
  booking_hourly_tooltip_with_booking:
    'In some cases, booking a full day is more advantageous than booking by the hour. Prices may vary depending on the time of the day or day of the week.',
  booking_daily: 'Daily',
  booking_daily_tooltip: 'Prices may vary depending on the day of the week.',
  booking_hour: 'hour',
  booking_day: 'day',
  booking_reservation_details: 'Reservation details',
  booking_cancel_message: 'Cancel for free up to 24 hours before',
  booking_hours: 'hrs',
  booking_hours_other: 'hr',
  booking_book_button: 'Review & Book',
  booking_select_time: 'Select Time & Date',
  booking_calender_drawer_header: 'Booking Calendar',
}
