import { Button, Row, Swift, UserOutlined } from '@swiftctrl/swift-component-library'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { useTranslation } from '../../hooks'
import { goToAccountsApp } from '../../utils'

export const LoginButton: Swift.FC = () => {
  const { t } = useTranslation()

  const login = () => goToAccountsApp('signin')
  const { isMobileView } = useTheme()

  return (
    <StyledLoginButton>
      {isMobileView ? (
        <Row justify="end">
          <Button type="text" icon={<UserOutlined />} onClick={login}>
            {t('login_button')}
          </Button>
        </Row>
      ) : (
        <Button size="large" type="text" icon={<UserOutlined />} onClick={login}>
          {t('login_button')}
        </Button>
      )}
    </StyledLoginButton>
  )
}

const StyledLoginButton = styled.div`
  text-align: right;
  padding-top: ${({ theme }) => (theme.isMobileView ? theme.spacing.smedium : theme.spacing.small)};
`
