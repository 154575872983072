import 'antd/dist/antd.css'

import { Swift } from '@swiftctrl/swift-component-library'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppProvider } from './AppProvider'
import { initMixpanel, mixPanelRegister, mixPanelReset } from './analytics'
import { DefaultRedirect, PrivateRoutes, PublicRoutes } from './components'
import { initMockServer } from './mock-server'
import { SessionProvider } from './state'

dayjs.extend(utc)

initMixpanel()

export const App: Swift.FC = () => {
  initMockServer()

  useEffect(() => {
    mixPanelReset()
    mixPanelRegister({
      'App Version': process.env.REACT_APP_VERSION,
      App: 'SpaceCloud',
    })
  }, [])

  return (
    <Router>
      <SessionProvider>
        <AppProvider>
          <PublicRoutes />
          <PrivateRoutes />
          <DefaultRedirect />
        </AppProvider>
      </SessionProvider>
    </Router>
  )
}
