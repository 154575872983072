import { BookingTypes } from '@swiftctrl/api-client-react'
import { Booking } from '../models'

export const bookings: Partial<Booking>[] = [
  {
    room_id: '1',
    booking_id: '1',
    creator_id: '1',
    host_id: '1',
    total_guest_count: 1,

    start: '2023-01-18T16:00:00',
    end: '2023-01-18T17:00:00',

    payment: {
      hourly_price: 30,
      subtotal: 30,
      taxes: 1.91,
      total: 31.91,
      payment_method: 'Visa',
      credit_card_mask: '4242',
      currency: 'USD',
      status: 'paid',
    },
  },
]

export const bookingsV2: Partial<BookingTypes>[] = [
  {
    creator: undefined,
    end_time: '2023-03-31T15:30:00Z',
    entity_description: '311',
    entity_name: 'my room 1',
    host: undefined,
    overseer_id: '1',
    start_time: '2023-03-31T15:00:00Z',
  },
  {
    creator: undefined,
    end_time: '2023-03-31T11:00:00Z',
    entity_description: '314',
    entity_name: 'my room 1',
    host: undefined,
    overseer_id: '2',
    start_time: '2023-03-31T10:15:00Z',
  },
  {
    creator: undefined,
    end_time: '2023-03-31T16:30:00Z',
    entity_description: '312',
    entity_name: 'my room 1',
    host: undefined,
    overseer_id: '3',
    start_time: '2023-03-31T16:00:00Z',
  },
]
