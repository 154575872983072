import { ScheduleTypes } from '@swiftctrl/api-client-react'
import dayjs from 'dayjs'

export const getSubSchedule = (schedule: ScheduleTypes) =>
  schedule.sub_schedules.find((subSchedule) => {
    const today = dayjs()
    const start = dayjs(subSchedule.date_range.start)
    const end = dayjs(subSchedule.date_range.end)
    return today.isBefore(end) && (today.isAfter(start) || today.isSame(start))
  })
