import { Swift } from '@swiftctrl/swift-component-library'

export const Guidelines: Swift.FC = () => (
  <div>
    <h2>Lorem ipsum</h2>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua. Ut eu sem integer vitae justo eget. A pellentesque sit amet
      porttitor eget dolor morbi non. Ullamcorper malesuada proin libero nunc consequat interdum
      varius sit. Donec pretium vulputate sapien nec. Lectus magna fringilla urna porttitor rhoncus.
      Sollicitudin aliquam ultrices sagittis orci a scelerisque. Cursus mattis molestie a iaculis at
      erat pellentesque adipiscing. Euismod quis viverra nibh cras pulvinar mattis nunc sed blandit.
      Leo in vitae turpis massa sed elementum tempus egestas. Tellus pellentesque eu tincidunt
      tortor aliquam nulla. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque.
    </p>
  </div>
)
