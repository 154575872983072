import { RefObject, useLayoutEffect, useState } from 'react'

export const useOverflow = (ref: RefObject<HTMLDivElement> | null) => {
  const [isOverflow, setIsOverflow] = useState<boolean>()

  useLayoutEffect(() => {
    if (!ref?.current) {
      return
    }
    const { current } = ref

    const trigger = () => {
      const hasOverflow: boolean = current.scrollHeight >= current.clientHeight
      setIsOverflow(hasOverflow)
    }

    if (current) {
      trigger()
    }
  }, [ref])

  return isOverflow
}
