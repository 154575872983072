import { ProfilesTypes, useProfile } from '@swiftctrl/api-client-react'
import { identifyMixpanel } from '../../analytics'
import { goToAccountsApp, setSessionSigningStatus } from '../../utils'
import { useIsPublicRoute } from '../router'
import { useStoreCurrentUser } from '../store'

export const useGetUserProfile = () => {
  const { updateStoreCurrentUser } = useStoreCurrentUser()
  const isPublicPath: boolean = useIsPublicRoute()
  return useProfile().self(['self'], {
    onSuccess: (data) => {
      const profileInfo: ProfilesTypes = data.data
      identifyMixpanel(profileInfo.person_id)
      updateStoreCurrentUser(profileInfo)
      setSessionSigningStatus('SIGNED')
    },
    onError: (error) => {
      if (error.request.status === 401) {
        if (!isPublicPath) {
          updateStoreCurrentUser(null)
          return goToAccountsApp('signin')
        }
        return updateStoreCurrentUser(null)
      }
    },
    retry: false,
  })
}
