import { Col, Row, Skeleton, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const ReservationDetailsLoadingSkeleton: Swift.FC = () => (
  <Row gutter={[87, 0]}>
    <Col span={14}>
      <StyledFirstSkeleton active paragraph={{ rows: 6 }} />
      <StyledSecondSkeleton active paragraph={{ rows: 7 }} />
      <Skeleton active paragraph={{ rows: 2 }} />
    </Col>
    <Col span={10}>
      <StyledImageSkeleton
        avatar={{ shape: 'square', size: 'large', style: { height: 105, width: 105 } }}
        active
      />
      <Skeleton paragraph={{ rows: 2 }} />
    </Col>
  </Row>
)

const StyledFirstSkeleton = styled(Skeleton)`
  margin-bottom: 64px;
`

const StyledSecondSkeleton = styled(Skeleton)`
  margin-bottom: 64px;
`
const StyledImageSkeleton = styled(Skeleton)`
  margin-top: 54px;
  margin-bottom: 64px;
`
