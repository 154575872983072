import { v5 } from 'uuid'
import { config } from './config'

const {
  mixpanel: { externalMapping },
} = config

export type MapToMixPanelService = keyof typeof externalMapping

export const mapToMixpanel = (service: MapToMixPanelService, id: string): string => {
  const namespace: string = externalMapping[service]

  return v5(id, namespace)
}
