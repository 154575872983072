export const search_screen_en = {
  search_search: 'Search',
  search_apply: 'Apply',
  search_sort_button_price: 'Price',
  search_filter_button: 'Filter',
  search_apply_filter_button: 'Adjust Filter',
  search_adjust_date_time_button: 'Adjust date and time',
  search_bar_where_are_you_going: 'Where are you going?',
  search_bar_smart_search_what_city: 'Where are you going?',
  search_bar_smart_search_what_date: 'When will this happen?',
  search_bar_smart_search_how_many_guests: 'Number of Guests?',
  search_bar_smart_search_search_button: 'Search',
  search_bar_smart_search_guests_one: 'guest',
  search_bar_smart_search_guests_other: 'guests',
  search_bar_smart_search_clear_all_button: 'Clear all',
  search_bar_room_details_guests: 'Guests',
  search_bar_room_details_hour: 'hour',
  search_bar_room_details_day: 'day',
  search_bar_room_details_alternate_image: 'OfficeRoom',
  search_bar_room_list_no_results: 'No matching rooms',
  search_bar_room_list_adjust_filter:
    'Try adjusting some of your filters to explore more places to stay.',
  search_bar_room_list_adjust_date_time:
    'Try adjusting the chosen date and time to explore more places to stay.',
  search_bar_room_list_adjust_filterButton: 'Adjust Filter',
  search_filter: 'Filter',
  search_filter_clear_all: 'Clear all',
  search_filter_cancel: 'Cancel',
  search_filter_show_rooms_one: 'Show {{count}} room',
  search_filter_show_rooms_other: 'Show {{count}} rooms',
  search_filter_amenities: 'Amenities',
  search_filter_amenities_show_all: 'Show all',
  search_filter_amenities_show_less: 'Show less',
  search_filter_price_range: 'Price range (per hour)',
  search_filter_min_price: 'Min price',
  search_filter_max_price: 'Max price',
  search_amenities_internet_access: 'Fast, Secure Wi-Fi',
  search_amenities_large_display: 'Large display',
  search_amenities_white_board: 'White board',
  search_amenities_projector: 'Projector',
  search_amenities_conference_phone: 'Conference phone',
  search_amenities_tea_and_coffee: 'Tea and coffee',
  search_amenities_regular_phone: 'Regular phone',
  search_amenities_catering: 'Catering',
  search_amenities_privacy_screen: 'Privacy screen',
  search_amenities_air_conditioning: 'Air conditioning',
  search_amenities_cctv: 'CCTV',
  search_amenities_standing_desk: 'Standing desk',
  search_amenities_wireless_charger: 'Wireless charger',
  search_amenities_voice_recorder: 'Voice recorder',
  search_amenities_standard_phone: 'Standard phone',
  search_amenities_soundproof_room: 'Sound proof room',
  search_amenities_security_lock: 'Security lock',
  search_amenities_heating: 'Heating',
  search_amenities_internet: 'Internet',
  search_amenities_natural_light: 'Natural light',
  search_amenities_quiet_zone: 'Quiet zone',
  search_amenities_drinks: 'Drinks',
  room_details_modal_amenities_title: 'Amenities',
  search_amenities_catering_services: 'Catering services',
  search_filter_show_rooms_default: 'Show rooms',
}
