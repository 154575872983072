import { Skeleton, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const RoomSkeleton: Swift.FC = () => (
  <StyledRoomSkeletonContainer>
    <StyledImageSize />
    <Skeleton active />
  </StyledRoomSkeletonContainer>
)

const StyledRoomSkeletonContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.medium};
  margin-right: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xxLarge};
`
const StyledImageSize = styled.div`
  height: 217px;
  width: 100%;
  background-color: #eeecec;
  border-radius: 2px;
`
