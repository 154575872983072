export const buildingRooms = {
  totalNumber: 73,
  items: [
    {
      id: '61f163fbc9664f4c233b26801',
      name: 'Zeeshans Palace',
      images: [
        'https://dzrjcxtasfoip.cloudfront.net/user-resources/organization/large-meeting-room-1587244372271.png',
      ],
      pricePerHour: 50,
      guests: { min: '1', max: '8' },
    },
    {
      id: '61f163fbc9664f4c233b26802',
      name: 'Lujains Castle',
      images: [
        'https://swiftconnectnetwork.spaces.nexudus.com/en/publicresources/getimage/1414914693',
      ],
      pricePerHour: 60,
      guests: { min: '3', max: '9' },
    },
    {
      id: '61f163fbc9664f4c233b26803',
      name: 'The Dark tower',
      images: [
        'https://swiftconnectnetwork.spaces.nexudus.com/en/publicresources/getimage/1414914579',
      ],
      pricePerHour: 80,
      guests: { min: '1', max: '12' },
    },
    {
      id: '61f163fbc9664f4c233b26804',
      name: 'Another Dark Tower',
      images: [
        'https://dzrjcxtasfoip.cloudfront.net/user-resources/organization/large-meeting-room-1587244372271.png',
      ],
      pricePerHour: 35,
      guests: { min: '4', max: '8' },
    },
    {
      id: '61f163fbc9664f4c233b26805',
      name: 'Just a Room',
      images: [
        'https://swiftconnectnetwork.spaces.nexudus.com/en/publicresources/getimage/1414914682',
      ],
      pricePerHour: 65,
      guests: { min: '1', max: '4' },
    },
    {
      id: '61f163fbc9664f4c233b26806',
      name: 'Large Meeting Room',
      images: [
        'https://dzrjcxtasfoip.cloudfront.net/user-resources/organization/large-meeting-room-1587244372271.png',
      ],
      pricePerHour: 45,
      guests: { min: '1', max: '8' },
    },
  ],
}
