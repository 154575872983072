import { mixed, number, object, ref, string } from 'yup'

import { RestApiParams, Shape, Sorted } from '../models'
import { isDateValid, sortedArray } from '../utils'

export const searchParamsSchema = object().shape<Shape<RestApiParams>>({
  startTime: number().min(0).integer().required().lessThan(24),
  endTime: number().positive().integer().moreThan(ref('startTime')).required().lessThan(25),
  guests: number().min(1).integer().required(),
  page: number().min(1).integer(),
  sorted: mixed<Sorted>().oneOf(sortedArray),
  date: string()
    .test('date', 'Wrong date format', (value: string | undefined) =>
      value ? isDateValid(value) : false,
    )
    .required(),
  city: string(),
  state: string(),
  country: string(),
  amenities: string(),
})
