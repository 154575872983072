import { Col, Divider, Modal, Row, Swift } from '@swiftctrl/swift-component-library'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import styled from 'styled-components'

import { mixPanelTrack } from '../../analytics'
import { ClickableText } from '../../components'
import { useCreateBooking, useTranslation } from '../../hooks'
import { RentalRules } from '../../legal-texts'
import { ReservationDetailsType } from '../../models'
import {
  convertHourToDateISO,
  differenceInDayFromToday,
  displayDate,
  displayTimeRange,
  formattedDateWithTime,
  redirectToRoom,
  removeTimezone,
} from '../../utils'
import { CheckoutButton } from './CheckoutButton'

interface Props {
  reservationDetails: ReservationDetailsType | undefined
}

export const ReservationCheckout: Swift.FC<Props> = ({ reservationDetails }) => {
  const { t } = useTranslation()
  const lastLocation = useLastLocation()
  const {
    mutate: createBooking,
    createStripeSessionLoading,
    isLoading: createBookingLoading,
  } = useCreateBooking()

  const startTime: string = formattedDateWithTime(
    reservationDetails?.date,
    reservationDetails?.startTime,
  )
  const endTime: string = formattedDateWithTime(
    reservationDetails?.date,
    reservationDetails?.endTime,
  )

  const [modifiedLastLocation, setModifiedLastLocation] = useState<string>('')

  useEffect(() => {
    if (lastLocation) {
      const modifiedSearch = lastLocation.search.split('?')[1]
      if (modifiedSearch.split('&')[0].split('=')[0] === 'book') {
        const deleteBookKey = modifiedSearch.split('&').slice(1).join('&')
        return setModifiedLastLocation(`?${deleteBookKey}`)
      }
      return setModifiedLastLocation(lastLocation.search)
    }
  }, [])

  const [showRefundPolicies, setShowRefundPolicies] = useState<boolean>(false)
  const [showRentalRules, setShowRentalRules] = useState<boolean>(false)
  const [showSafetyRequirement, setShowSafetyRequirement] = useState<boolean>(false)

  const createCheckout = () => {
    if (reservationDetails) {
      const payload = {
        room_id: reservationDetails.roomId!,
        guest_count: reservationDetails.numberOfGuests!,
        start_time: startTime,
        end_time: endTime,
      }
      createBooking(payload)
      mixPanelTrack('Book Space (Start Payment)', {
        'Days From Today': differenceInDayFromToday(
          removeTimezone(
            new Date(convertHourToDateISO(reservationDetails.date, reservationDetails.startTime)),
          ),
        ),
        'Guest Count': reservationDetails.numberOfGuests,
      })
    }
  }

  const openRefundPoliciesModal = () => {
    setShowRefundPolicies(true)
  }

  const closeRefundPoliciesModal = () => {
    setShowRefundPolicies(false)
  }

  const openRentalRulesModal = () => {
    setShowRentalRules(true)
  }

  const closeRentalRulesModal = () => {
    setShowRentalRules(false)
  }

  const openSafetyRequirementModal = () => {
    setShowSafetyRequirement(true)
  }

  const closeSafetyRequirementModal = () => {
    setShowSafetyRequirement(false)
  }

  return (
    <div>
      <Row>
        <Col>
          <StyledTitleRow>{t('checkout_page_title')}</StyledTitleRow>
        </Col>
      </Row>
      <Row>
        <StyledReservationLabel span={24}>
          {t('reservation_your_reservation_label')}
          <StyledEditReservation>
            <Link
              to={{
                pathname: redirectToRoom(reservationDetails?.roomId, reservationDetails?.tenantId!),
                search: modifiedLastLocation,
                state: {
                  numberOfGuests: reservationDetails?.numberOfGuests,
                },
              }}
            >
              {t('checkout_edit_link')}
            </Link>
          </StyledEditReservation>
        </StyledReservationLabel>
      </Row>
      <Row>
        <StyledLabel span={24}>{t('reservation_date_label')}</StyledLabel>
      </Row>
      <Row>
        <StyledReservationInput span={24}>
          {`${displayDate(reservationDetails?.date)}, ${displayTimeRange(
            { hours: reservationDetails!.startTime },
            { hours: reservationDetails!.endTime },
          )}`}
        </StyledReservationInput>
      </Row>
      <Row>
        <StyledLabel span={24}>{t('reservation_guests_label')}</StyledLabel>
      </Row>
      <Row>
        <StyledReservationInput span={24}>
          {reservationDetails?.numberOfGuests}{' '}
          {reservationDetails?.numberOfGuests === 1
            ? t('reservation_guests_one')
            : t('reservation_guests_other')}
        </StyledReservationInput>
      </Row>
      <Divider />
      <Row>
        <StyledConfirmLabel span={24}>
          {t('checkout_confirm_message_part1')}{' '}
          <ClickableText onClick={openRentalRulesModal}>
            {t('checkout_confirm_message_rental_rules')}
          </ClickableText>
          ,{' '}
          <ClickableText onClick={openSafetyRequirementModal}>
            {t('checkout_confirm_message_covid_requirements')}
          </ClickableText>{' '}
          {t('checkout_confirm_message_part2')}{' '}
          <ClickableText onClick={openRefundPoliciesModal}>
            {t('checkout_confirm_message_guests_refund')}
          </ClickableText>
        </StyledConfirmLabel>
      </Row>
      <Row>
        <Col span={24}>
          <CheckoutButton
            loading={createBookingLoading || createStripeSessionLoading}
            onClick={createCheckout}
          />
        </Col>
      </Row>
      <Modal
        title={t('checkout_confirm_message_rental_rules')}
        visible={showRentalRules}
        footer={null}
        onCancel={closeRentalRulesModal}
      >
        <RentalRules />
      </Modal>
      <Modal
        title={t('checkout_confirm_message_covid_requirements')}
        visible={showSafetyRequirement}
        footer={null}
        onCancel={closeSafetyRequirementModal}
      >
        <RentalRules />
      </Modal>
      <Modal
        title={t('checkout_confirm_message_guests_refund')}
        visible={showRefundPolicies}
        footer={null}
        onCancel={closeRefundPoliciesModal}
      >
        <RentalRules />
      </Modal>
    </div>
  )
}

const StyledTitleRow = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].lineHeight};
  margin-bottom: 24px;
`
const StyledEditReservation = styled.div`
  font-weight: normal;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  float: right;
  Button {
    margin-left: ${({ theme }) => theme.spacing.small};
  }
`
const StyledConfirmLabel = styled(Col)`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['12px'].lineHeight};
  margin-bottom: 24px;
  margin-top: 15px;
`
const StyledReservationLabel = styled(Col)`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  margin-bottom: 24px;
  margin-top: 10px;
`
const StyledLabel = styled(Col)`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
`
const StyledReservationInput = styled(Col)`
  display: block;
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  color: #8c8c8c;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
