import { Swift } from '@swiftctrl/swift-component-library'
import { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useValidUrl } from '../hooks'
import { paths } from '../utils'

const { DefaultFallback404 } = paths

export const DefaultRedirect: Swift.FC = () => {
  const history: RouteComponentProps['history'] = useHistory()
  const isValidUrl: boolean = useValidUrl()

  useEffect(() => {
    if (!isValidUrl) {
      return history.push(`/${DefaultFallback404}`)
    }
  }, [])

  return null
}
