import {
  AddressTypes,
  BookingBrowseTypes,
  BookingTypes,
  BuildingBrowseTypes,
  BuildingTypes,
  ModifiedBrowseRoomFilterConfig,
  RoomsTypes,
  useBooking,
  useBuilding,
  useRoom,
} from '@swiftctrl/api-client-react'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { message, Swift } from '@swiftctrl/swift-component-library'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReservationsListLoadingSkeleton } from '../../components'
import { ReservationMobileCard } from '../../components/reservation-infos/ReservationMobileCard'
import { useHandleUrlParams, useTranslation } from '../../hooks'
import { config } from '../../utils'
import { EmptyList } from './empty-list'
import { ReservationsItem } from './reservations-item'
interface Props {
  mobileView?: boolean
}

const { SWIFT_ROOT_ENTITY_ID } = config

export const ReservationsList: Swift.FC<Props> = ({ mobileView }) => {
  const [date] = useState<string>(dayjs.utc().local().format())
  const { currentParams } = useHandleUrlParams()
  const qBooking = getQueryHandler<BookingBrowseTypes>()
  const qRoom = getQueryHandler<ModifiedBrowseRoomFilterConfig>()
  const qBuilding = getQueryHandler<BuildingBrowseTypes>()
  const { t } = useTranslation()
  const [, setTimeParam] = useState<string>('fromTime')
  const [, setSortParam] = useState<string>('asc')
  const browse_page_limit = 12
  const dateWithoutSeconds = date.split(':').slice(0, 2).join(':')

  const {
    data: bookingsResponse,
    isLoading: bookingLoad,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useBooking()
    .browse.self({
      browseOptions: {
        queryKey: `self-browse`,
      },
      baseId: SWIFT_ROOT_ENTITY_ID,
      select: [
        'end_time',
        'entity_description',
        'start_time',
        'end_time',
        'host',
        'creator',
        'creator_profile_id',
        'host_profile_id',
        'entity_name',
        'booking_id',
      ],
      limit: browse_page_limit,
      filters: [
        qBooking(
          'WHERE',
          'start_time',
          currentParams.tab === 'upcoming' ? 'GTE' : 'LTE',
          dateWithoutSeconds,
        ),
      ],
      sort: [{ field: 'start_time', direction: currentParams.tab === 'upcoming' ? 'asc' : 'desc' }],
    })
    .infiniteQuery({
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length
        return (lastPage as any).data.length === browse_page_limit
          ? nextPage * browse_page_limit
          : undefined
      },
    })
  const bookings = bookingsResponse?.pages.reduce(
    (acc, curr) => [...acc, ...curr.data],
    [] as BookingTypes[],
  )

  const roomIds = getRoomIdsFromBookings(bookings)

  const { data: roomResponse, isLoading: isLoadingRooms } = useRoom()
    .browse({
      browseOptions: { queryKey: 'room-browse' },
      baseId: SWIFT_ROOT_ENTITY_ID,
      filters: [qRoom('WHERE', 'room_id', 'ANY', roomIds.join(','))],
      select: ['overseer_name', 'overseer_id', 'room_id', 'images', 'entity_name'],
    })
    .query({ enabled: Boolean(bookings?.length) })

  const rooms = roomResponse?.data.reduce((acc, curr) => [...acc, curr], [] as RoomsTypes[])
  const buildingsIds = getBuildingsIdsFromRooms(rooms)

  const { data: buildingsResponse, isLoading: isLoadingBuildings } = useBuilding()
    .browse({
      browseOptions: { queryKey: 'building-browse' },
      baseId: SWIFT_ROOT_ENTITY_ID,
      filters: [qBuilding('WHERE', 'building_id', 'ANY', buildingsIds.join(','))],
      select: ['overseer_name', 'building_id', 'overseer_id', 'entity_name'],
    })
    .query({ enabled: Boolean(rooms?.length) })

  const bookingList = getBookingList(bookings, rooms, buildingsResponse?.data)

  useEffect(() => {
    if (currentParams.tab === 'upcoming') {
      setTimeParam('fromTime')
      setSortParam('asc')
    } else {
      setTimeParam('toTime')
      setSortParam('desc')
    }
  }, [currentParams.tab])

  const afterAnyCancel = () => {
    refetch()
    message.success(
      {
        content: t('reservations_cancel_success'),
        style: {
          position: 'fixed',
          marginTop: '92vh',
          marginLeft: '20px',
        },
      },
      10,
    )
  }

  const isLoading = isLoadingRooms && isLoadingBuildings && bookingLoad

  return (
    <div>
      {isLoading && <ReservationsListLoadingSkeleton />}
      <InfiniteScroll
        dataLength={bookingList?.length}
        next={fetchNextPage}
        hasMore={hasNextPage || false}
        loader={<ReservationsListLoadingSkeleton />}
        scrollThreshold={0.5}
      >
        {bookingList?.map((booking, index, list) =>
          mobileView ? (
            <ReservationMobileCard
              booking={booking}
              hideMarinBottom={index === list.length - 1}
              key={booking.booking_id}
            />
          ) : (
            <ReservationsItem
              key={booking.booking_id}
              type={currentParams.tab!}
              booking={booking}
              afterCancel={afterAnyCancel}
            />
          ),
        )}
      </InfiniteScroll>
      {!isLoading && !bookings?.length && <EmptyList type={currentParams.tab!} />}
    </div>
  )
}

const getRoomIdsFromBookings = (bookings?: BookingTypes[]) => {
  if (!bookings || !bookings.length) {
    return []
  }
  const roomIds = new Set(bookings.map((booking) => booking.overseer_id))
  return Array.from(roomIds)
}

const getBuildingsIdsFromRooms = (rooms?: RoomsTypes[]) => {
  if (!rooms || !rooms.length) {
    return []
  }
  const buildingsIds = new Set(rooms.map((room) => room.overseer_id))
  return Array.from(buildingsIds)
}

const getBookingList = (
  bookings?: BookingTypes[],
  rooms?: RoomsTypes[],
  buildings?: BuildingTypes[],
) => {
  if (!bookings) {
    return []
  }
  const bookingsArray: (BookingTypes & {
    buildingId?: string
    organizationName?: string
    addresses?: AddressTypes[]
    buildingName?: string
    images?: string[]
    roomId?: string
  })[] = []
  bookings.forEach((booking) => {
    const room = rooms?.find((room) => room.room_id === booking.overseer_id)
    const building = buildings?.find((building) => building.building_id === room?.overseer_id)
    bookingsArray.push({
      ...booking,
      buildingId: building?.building_id,
      organizationName: building?.overseer_name,
      buildingName: building?.entity_name,
      addresses: building?.addresses,
      images: room?.images,
      roomId: room?.room_id,
    })
  })
  return bookingsArray
}
