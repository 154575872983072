import { RoomsTypes, useBooking, useBuilding, useRoom } from '@swiftctrl/api-client-react'
import { Swift } from '@swiftctrl/swift-component-library'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { mixPanelTrack } from '../../analytics'
import { ReservationDetailsScreen, ReservationDetailsScreenMobile } from '../../screens/'
import { differenceInDayFromToday } from '../../utils'

interface RouteParams {
  bookingId: string | undefined
}

const ReservationDetailsScreenRenderer: Swift.FC = () => {
  const { isMobileView } = useTheme()
  const { bookingId } = useParams<RouteParams>()

  const {
    data: bookingResponse,
    isLoading: isBookingLoading,
    error,
    isFetched: isBookingFetched,
  } = useBooking()
    .at(bookingId!)
    .read({ queryKey: ['booking-read'] }, { enabled: Boolean(bookingId), cacheTime: 0 })
  const booking = bookingResponse?.data

  const bookingStringify: string = JSON.stringify(booking)

  const {
    data: roomResponse,
    isLoading: isRoomLoading,
    isFetched: isRoomFetched,
  } = useRoom()
    .at(booking?.overseer_id!)
    .read({ queryKey: ['room-read'] }, { enabled: isBookingFetched })

  const room = roomResponse?.data as RoomsTypes

  const { data: buildingResponse, isLoading: isBuildingLoading } = useBuilding()
    .at(room?.overseer_id)
    .read({ queryKey: ['building-read'] }, { enabled: isRoomFetched })

  const building = buildingResponse?.data

  useEffect(() => {
    if (bookingStringify && booking?.end_time) {
      mixPanelTrack('View My Reservation', {
        'Days From Today': differenceInDayFromToday(booking.end_time),
      })
    }
  }, [bookingStringify])

  const isLoading = isBuildingLoading || isRoomLoading || isBookingLoading

  return isMobileView ? (
    <ReservationDetailsScreenMobile
      booking={booking}
      room={room}
      building={building}
      isLoading={isLoading}
      error={error}
    />
  ) : (
    <ReservationDetailsScreen
      booking={booking}
      room={room}
      building={building}
      isLoading={isLoading}
      error={error}
    />
  )
}

export default ReservationDetailsScreenRenderer
