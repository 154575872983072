import { BuildingTypes } from '@swiftctrl/api-client-react'

export const buildings: BuildingTypes[] = [
  {
    addresses: [
      {
        address_id: '135443f4-5398-49f8-aefb-ad4585a442f3',
        address_line_1: '281 Tresser Boulevard',
        address_line_2: 'Suite 1003',
        city: 'Stamford',
        coordinates: {
          coordinates_id: '',
          latitude: 65.123,
          longitude: 75.234,
        },
        country: 'USA',
        created_at: '',
        entity_description: '',
        entity_name: '',
        overseer_id: '8824a361-8829-4004-8e2c-6783d3676079',
        overseer_name: '',
        state: 'CT',
        updated_at: '',
        zip: '06901',
        entity_id: '',
        entity_type_id: 'building',
        entity_status: 'ACTIVE',
        extra_i: [],
        extra_s: ['building'],
      },
    ],
    building_id: '1',
    created_at: '2022-07-06T16:08:13Z',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    entity_name: 'Swift Building',
    entity_status: 'ACTIVE',
    entity_id: '',
    extra_i: [],
    extra_s: ['building'],
    schedule: {
      entity_id: '',
      extra_i: [],
      extra_s: ['building'],
      created_at: '',
      entity_description: '',
      entity_name: '',
      entity_status: 'UNDEFINED',
      entity_type_id: 'building',
      overseer_id: '6f4a005c-f042-4bf0-ba11-cb6e673688e1',
      overseer_name: '',
      schedule_id: 'e57e300e-d7b1-487b-88a6-e0091b6c485c',
      sub_schedules: [
        {
          allow: true,
          date_range: {
            close: 'EXCLUSIVE',
            end: '2999-12-31',
            open: 'INCLUSIVE',
            start: '0001-01-02',
          },
          day_times: [
            {
              day: 'MONDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'TUESDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'WEDNESDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'THURSDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'FRIDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
          ],
        },
      ],
      updated_at: '',
    },
    overseer_id: '21404460-e33c-4078-bfcc-5587c8322eaa',
    overseer_name: 'ACME Inc.',
    updated_at: '',
    entity_type_id: 'building',
  },
  {
    addresses: [
      {
        address_id: '135443f4-5398-49f8-aefb-ad4585a442f3',
        address_line_1: '486 Saint-Catherine St W',
        address_line_2: '',
        city: 'Montreal',
        coordinates: {
          coordinates_id: '',
          latitude: 65.123,
          longitude: 75.234,
        },
        country: 'Canada',
        created_at: '',
        entity_description: '',
        entity_name: '',
        overseer_id: '8824a361-8829-4004-8e2c-6783d3676079',
        overseer_name: '',
        state: 'QC',
        updated_at: '',
        zip: 'H3B 1A6',
        entity_id: '',
        extra_i: [],
        extra_s: ['building'],
        entity_type_id: 'building',
        entity_status: 'ACTIVE',
      },
    ],
    schedule: {
      entity_id: '',
      extra_i: [],
      extra_s: ['building'],
      created_at: '',
      entity_description: '',
      entity_name: '',
      entity_status: 'UNDEFINED',
      entity_type_id: 'building',
      overseer_id: '6f4a005c-f042-4bf0-ba11-cb6e673688e1',
      overseer_name: '',
      schedule_id: 'e57e300e-d7b1-487b-88a6-e0091b6c485c',
      sub_schedules: [
        {
          allow: true,
          date_range: {
            close: 'EXCLUSIVE',
            end: '2999-12-31',
            open: 'INCLUSIVE',
            start: '0001-01-02',
          },
          day_times: [
            {
              day: 'MONDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'TUESDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'WEDNESDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'THURSDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'FRIDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
          ],
        },
      ],
      updated_at: '',
    },
    building_id: '2',
    created_at: '2022-07-06T16:08:13Z',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    entity_name: 'Swift Building 2',
    entity_status: 'ACTIVE',
    overseer_id: '21404460-e33c-4078-bfcc-5587c8322eaa',
    overseer_name: 'ACME Inc.',
    updated_at: '',
    entity_id: '',
    extra_i: [],
    extra_s: ['building'],
    entity_type_id: 'building',
  },
  {
    addresses: [
      {
        address_id: '135443f4-5398-49f8-aefb-ad4585a442f3',
        address_line_1: '794 Mcallister St',
        address_line_2: '',
        city: 'San Francisco',
        coordinates: {
          coordinates_id: '',
          latitude: 65.123,
          longitude: 75.234,
        },
        country: 'USA',
        created_at: '',
        entity_description: '',
        entity_name: '',
        overseer_id: '8824a361-8829-4004-8e2c-6783d3676079',
        overseer_name: '',
        state: 'CA',
        updated_at: '',
        zip: '94102',
        entity_id: '',
        extra_i: [],
        extra_s: ['building'],
        entity_status: 'UNDEFINED',
        entity_type_id: 'building',
      },
    ],
    building_id: '3',
    created_at: '2022-07-06T16:08:13Z',
    entity_description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla varius congue. Cras tristique sed orci eu blandit. Vestibulum lacus leo, euismod at vulputate vehicula, imperdiet commodo ipsum. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer elementum lacus nec ultrices dictum. Cras vitae ligula suscipit, sollicitudin urna ac, convallis erat. Aenean rutrum et elit sed hendrerit.',
    entity_name: 'Swift Building 3',
    entity_status: 'ACTIVE',
    schedule: {
      entity_id: '',
      extra_i: [],
      extra_s: ['building'],
      created_at: '',
      entity_description: '',
      entity_name: '',
      entity_status: 'UNDEFINED',
      entity_type_id: 'schedule',
      overseer_id: '6f4a005c-f042-4bf0-ba11-cb6e673688e1',
      overseer_name: '',
      schedule_id: 'e57e300e-d7b1-487b-88a6-e0091b6c485c',
      sub_schedules: [
        {
          allow: true,
          date_range: {
            close: 'EXCLUSIVE',
            end: '2999-12-31',
            open: 'INCLUSIVE',
            start: '0001-01-02',
          },
          day_times: [
            {
              day: 'MONDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'TUESDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'WEDNESDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'THURSDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
            {
              day: 'FRIDAY',
              end_time: '20:00',
              start_time: '08:00',
            },
          ],
        },
      ],
      updated_at: '',
    },
    overseer_id: '21404460-e33c-4078-bfcc-5587c8322eaa',
    overseer_name: 'FooBar Co.',
    updated_at: '',
    entity_id: '',
    extra_i: [],
    extra_s: ['building'],
    entity_type_id: 'building',
  },
]
