import { useFeature as useOptimizelyFeature } from '@optimizely/react-sdk'

export type FeatureKey =
  | 'sc_buildings'
  | 'sc_amenitiesfilter'
  | 'sc_paymentinformation'
  | 'sc_bookings_seeinvoice'
  | 'sc_bookings_cancel'

export const useFeature = (key: FeatureKey): boolean =>
  useOptimizelyFeature(key, { autoUpdate: true })[0]
