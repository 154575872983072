import {
  Button,
  CalendarOutlined,
  ClockCircleOutlined,
  Col,
  DollarOutlined,
  Row,
  Swift,
  Typography,
} from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

import { NumberGuestsInput } from '../../../../components'
import { useStoreReservation, useTranslation } from '../../../../hooks'
import { BookingHorizonalProps } from '../../../../models'
import {
  bookingDisplayDate,
  bookingDisplayTime,
  displayLocation,
  isReservationValid,
} from '../../../../utils'

export const BookingHorizontal: Swift.FC<BookingHorizonalProps> = ({
  room,
  numberGuests,
  setNumberGuests,
  sendToAvailabilityTable,
  validateRoomAvailability,
  building,
  cost,
}) => {
  const { t } = useTranslation()

  const { reservationDetails } = useStoreReservation()

  return (
    <StyledBookingHorizontalContainer>
      <Row>
        <Col span={24}>
          <StyledBookingTitleHorizontal>
            <StyledBookingName>{room?.entity_name}</StyledBookingName>
            <StyledBookingDetails>
              <Typography.Text>
                {building?.entity_name} | {building?.overseer_name} &nbsp;
              </Typography.Text>
              <StyledBookingLocation>
                {displayLocation(building?.addresses?.[0]!)}
              </StyledBookingLocation>
            </StyledBookingDetails>
          </StyledBookingTitleHorizontal>
        </Col>
      </Row>
      <StyledReservationButtonLarge>
        <Col xs={6} sm={4} lg={4} xl={6}>
          <StyledNumberGuestsInputLarge>
            <NumberGuestsInput
              numberGuestsParent={numberGuests}
              setNumberGuestsParent={setNumberGuests}
              allowZeroGuests={false}
              maxNumberOfGuests={room?.max_capacity && room?.max_capacity}
              minNumberOfGuests={room?.min_capacity && room?.min_capacity}
              large={false}
              restrictedGuests
            />
          </StyledNumberGuestsInputLarge>
          <StyledNumberGuestsInputSmall>
            <NumberGuestsInput
              numberGuestsParent={numberGuests}
              setNumberGuestsParent={setNumberGuests}
              allowZeroGuests={false}
              maxNumberOfGuests={room?.max_capacity && room?.max_capacity}
              minNumberOfGuests={room?.min_capacity && room?.min_capacity}
              large={false}
              restrictedGuests
            />
          </StyledNumberGuestsInputSmall>
        </Col>
        <Col xs={18} sm={20} lg={20} xl={18}>
          <StyledReservationDetailsHorizontal
            $isReservation={isReservationValid(reservationDetails)}
          >
            <StyledBookingButton
              size="large"
              type="primary"
              block
              disabled={!isReservationValid(reservationDetails)}
              className={'booking-button-horizontal'}
              onClick={validateRoomAvailability}
            >
              {t('booking_book_button')}
            </StyledBookingButton>
            <StyledRight>
              <StyledReservationInfo>
                <CalendarOutlined />
                {bookingDisplayDate(reservationDetails.date)}
              </StyledReservationInfo>
              <StyledReservationInfo>
                <ClockCircleOutlined />
                {bookingDisplayTime(reservationDetails.startTime, reservationDetails.endTime)}
              </StyledReservationInfo>
              <StyledReservationInfo>
                <DollarOutlined />${cost?.price}
              </StyledReservationInfo>
            </StyledRight>
          </StyledReservationDetailsHorizontal>
          <StyledReservationDetailsHorizontal
            $isReservation={!isReservationValid(reservationDetails)}
          >
            <StyledBookingButton
              size="large"
              block
              type="primary"
              onClick={sendToAvailabilityTable}
              ghost
            >
              {t('booking_select_time')}
            </StyledBookingButton>
          </StyledReservationDetailsHorizontal>
        </Col>
      </StyledReservationButtonLarge>
      <StyledSmallReservation>
        <Col sm={6} md={7} lg={6}>
          <StyledNumberGuestsInputSmall>
            <NumberGuestsInput
              numberGuestsParent={numberGuests}
              setNumberGuestsParent={setNumberGuests}
              allowZeroGuests={false}
              maxNumberOfGuests={room?.max_capacity && room?.max_capacity}
              minNumberOfGuests={room?.min_capacity && room?.min_capacity}
              large={false}
              restrictedGuests
            />
          </StyledNumberGuestsInputSmall>
        </Col>
        <Col sm={18} md={17} lg={18}>
          <StyledDisplayBlock $isReservation={isReservationValid(reservationDetails)}>
            <StyledSmallReservationInfo>
              <StyledReservationInfo>
                <CalendarOutlined />
                {bookingDisplayDate(reservationDetails.date)}
              </StyledReservationInfo>
              <StyledReservationInfo>
                <ClockCircleOutlined />
                {bookingDisplayTime(reservationDetails.startTime, reservationDetails.endTime)}
              </StyledReservationInfo>
              <StyledReservationInfo>
                <DollarOutlined />${cost?.price}
              </StyledReservationInfo>
            </StyledSmallReservationInfo>
          </StyledDisplayBlock>
        </Col>
      </StyledSmallReservation>
      <StyledSmallButton>
        <Col span={24}>
          <StyledDisplayBlock $isReservation={isReservationValid(reservationDetails)}>
            <StyledBookingButton
              size="large"
              type="primary"
              block
              disabled={!isReservationValid(reservationDetails)}
              className={'booking-button-horizontal'}
              onClick={validateRoomAvailability}
            >
              {t('booking_book_button')}
            </StyledBookingButton>
          </StyledDisplayBlock>
          <StyledDisplayBlock $isReservation={!isReservationValid(reservationDetails)}>
            <StyledBookingButton
              size="large"
              block
              type="primary"
              onClick={sendToAvailabilityTable}
              ghost
            >
              {t('booking_select_time')}
            </StyledBookingButton>
          </StyledDisplayBlock>
        </Col>
      </StyledSmallButton>
    </StyledBookingHorizontalContainer>
  )
}

const StyledIcon = styled.div`
  span {
    margin-right: 9.5px;
    margin-left: 9.5px;
    font-size: 21px;
  }
`

const StyledBookingHorizontalContainer = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.large};
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 24px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  overflow: hidden;
`
const StyledBookingTitleHorizontal = styled.div`
  height: auto;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const StyledBookingDetails = styled.div`
  position: relative;
  display: inline-block;
`

const StyledBookingName = styled.div`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['30px'].lineHeight};
  margin-right: ${({ theme }) => theme.spacing.large};
  display: inline-block;
`

const StyledBookingLocation = styled.div`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  color: #8c8c8c;
  display: inline-block;
`

const StyledReservationButtonLarge = styled(Row)`
  @media only screen and (max-width: 990px) {
    display: none;
  }
`
const StyledNumberGuestsInputLarge = styled.div`
  max-width: 343px;
  width: 100%;

  @media only screen and (max-width: 990px) {
    display: none;
  }
`

const StyledNumberGuestsInputSmall = styled.div`
  max-width: 343px;
  width: 100%;

  @media only screen and (min-width: 991px) {
    display: none;
  }
`
const StyledReservationDetailsHorizontal = styled.div<{ $isReservation?: boolean }>`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  margin-top: 5px;
  float: right;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.small};
  display: ${({ $isReservation }) => ($isReservation ? 'block' : 'none')};
`
const StyledRight = styled(StyledIcon)`
  margin-top: 6px;
  right: 0px;
  text-align: right;
`
const StyledReservationInfo = styled.div`
  display: inline-block;
`

const StyledDisplayBlock = styled.div<{ $isReservation?: boolean }>`
  display: ${({ $isReservation }) => ($isReservation ? 'block' : 'none')};
`

const StyledSmallReservation = styled(Row)`
  @media only screen and (max-width: 990px) {
    max-height: 66px;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
  @media only screen and (min-width: 991px) {
    display: none;
  }
`

const StyledSmallReservationInfo = styled(StyledIcon)`
  text-align: right;
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  position: relative;
  top: 10px;
`
const StyledSmallButton = styled(Row)`
  @media only screen and (min-width: 991px) {
    display: none;
  }
`
const StyledBookingButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing.large};
  max-width: 228px;
  float: right;
  @media only screen and (max-width: 990px) {
    max-width: 100%;
  }
`
