import { BookingTypes, RoomsTypes, useBooking } from '@swiftctrl/api-client-react'
import {
  Button,
  Col,
  DownloadOutlined,
  ExclamationCircleOutlined,
  Modal,
  ModalFuncProps,
  Row,
  Swift,
  Typography,
} from '@swiftctrl/swift-component-library'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { mixPanelTrack } from '../../../analytics'
import { useFeature, useTranslation } from '../../../hooks'
import { config, differenceInDayFromToday, paths } from '../../../utils'

interface Props {
  booking: BookingTypes
  room: RoomsTypes
}

const { Reservations } = paths

export const PricingDetails: Swift.FC<Props> = ({ booking, room }) => {
  // const isPaymentFeatureEnable: boolean = useFeature('sc_paymentinformation')
  const {
    appConstants: { dateDisplayFormatWithoutPonctuation, timeFormatOriginal },
  } = config
  const { mutate: cancelBooking } = useBooking().delete()
  const { t } = useTranslation()
  const history = useHistory()
  // const duration = new Date(booking.end_time).getHours() - new Date(booking.start_time).getHours()
  const isPastReservation = new Date() > new Date(booking.start_time)

  const isCancelBookingEnable: boolean = useFeature('sc_bookings_cancel')
  const isInvoiceBookingEnable: boolean = useFeature('sc_bookings_seeinvoice')
  // const { payment } = booking

  const onCancel = (modalRef: {
    destroy: () => void
    update: (
      configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps),
    ) => void
  }) => {
    modalRef.update((prevConfig: ModalFuncProps) => ({
      ...prevConfig,
      okButtonProps: { loading: true, type: 'primary', danger: true },
    }))

    return new Promise((resolve, reject) => {
      cancelBooking(
        { id: booking.booking_id },
        {
          onSuccess: (response) => {
            if (response.status === 204) {
              history.push(`/${Reservations}`, { afterCancel: true })
              resolve(true)
            }
          },
          onError: () => {
            reject()
          },
        },
      )
    })
  }

  const handleOpenReceipt = () => window.open('', '_blank')

  const confirm = () => {
    const modal = Modal.confirm({
      className: 'reservations-item-confirm-cancel',
      title: t('reservation_modal_title'),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: (
        <div>
          {t('reservation_modal_message')}{' '}
          <Typography.Text strong>
            {room.entity_name.trim()},{' '}
            {format(new Date(booking.start_time), dateDisplayFormatWithoutPonctuation)},{' '}
            {format(new Date(booking.start_time), timeFormatOriginal)} -{' '}
            {format(new Date(booking.end_time), timeFormatOriginal)}
          </Typography.Text>
          ?
        </div>
      ),
      okText: t('reservation_modal_cancel_button'),
      cancelText: t('reservation_modal_no_button'),
      okButtonProps: { loading: false, type: 'primary', danger: true },
      autoFocusButton: null,
    })

    modal.update((prevConfig) => ({
      ...prevConfig,
      onOk: () => {
        mixPanelTrack('Cancel My Reservation', {
          'Days From Today': differenceInDayFromToday(booking.end_time),
        })
        onCancel(modal)
      },
    }))
  }

  return (
    <StyledPricingDetails>
      <Row>
        <StyledTitle type="secondary">{t('reservation_details_pricing_details_label')}</StyledTitle>
      </Row>
      {/* {isPaymentFeatureEnable &&      <>
      <Row justify="space-between">
        <Col>
          <Typography.Text>
            {duration}{' '}
            {duration > 1
              ? t('reservation_details_hours_other')
              : t('reservation_details_hours_one')}{' '}
            x ${room.price}
          </Typography.Text>
        </Col>
         <Col>
          <Typography.Text>{getFormattedPayment(payment.subtotal)}</Typography.Text>
        </Col>
      </Row> 
      <Row justify="space-between">
        <Col>
          <Typography.Text>{t('reservation_details_taxes_fees_label')}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text>{getFormattedPayment(payment.taxes!)}</Typography.Text>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Typography.Text>
            {t('reservation_details_total')} ({payment.currency})
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text>{getFormattedPayment(payment.total!)}</Typography.Text>
        </Col>
      </Row>
      <StyledPricingRow justify="space-between">
        <Col>
          <Typography.Text strong>{t('reservation_success_pay_label')}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text strong>
            <CreditCardOutlined /> {payment.payment_method}
          </Typography.Text>
        </Col>
      </StyledPricingRow>
      <Row justify="end">
        <Col>
          <Styled14PixelsFontSize type="secondary">
            **** {payment.credit_card_mask}
          </Styled14PixelsFontSize>
        </Col>
      </Row>
      </>} */}
      {isInvoiceBookingEnable && (
        <Row>
          <Col span={24}>
            <StyledInvoiceButton onClick={handleOpenReceipt}>
              <DownloadOutlined /> {t('reservation_success_invoice')}
            </StyledInvoiceButton>
          </Col>
        </Row>
      )}
      {isCancelBookingEnable && !isPastReservation && (
        <Row>
          <Col span={24}>
            <Button onClick={confirm} danger>
              {t('reservation_success_cancel_button')}
            </Button>
          </Col>
        </Row>
      )}
    </StyledPricingDetails>
  )
}

const StyledPricingDetails = styled.div`
  button {
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.medium};
    font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  }
`

const StyledTitle = styled(Typography.Text)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
`

// const StyledPricingRow = styled(Row)`
//   margin-top: 24px;
// `

// const Styled14PixelsFontSize = styled(Typography.Text)`
//   font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
// `

const StyledInvoiceButton = styled(Button)`
  border-color: #1890ff;
  color: #1890ff;
`
