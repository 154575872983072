import { add, format, formatISO, getHours, setHours } from 'date-fns'

import { RestApiParams } from '../../models'
import { config } from '../config'
import { displayTimeRange, timeConvertToDate } from '../date-utils'

const {
  appConstants: { cityDistanceKm, timeConvertDate },
} = config

export const getInitialSearchParams = (): RestApiParams => {
  const currentHour = getHours(new Date())
  const startTime = currentHour + 1 === 24 ? 0 : currentHour + 1
  const endTime = startTime + 1
  const initialDate = currentHour === 23 ? add(setHours(new Date(), 0), { days: 1 }) : new Date()
  return {
    date: format(initialDate, timeConvertDate),
    guests: 1,
    startTime,
    endTime,
    page: 1,
  }
}

export const getInitialRoomDetailParams = (): RestApiParams => ({
  date: format(new Date(), timeConvertDate),
  guests: 1,
})

export const searchParamsFormatter = (params: RestApiParams) => {
  if (!params.date) {
    return {}
  }
  const { date, startTime, endTime } = params

  const formattedParams = {
    ...params,
    startTime: formatISO(timeConvertToDate(startTime!, undefined, date)),
    endTime: formatISO(timeConvertToDate(endTime!, undefined, date)),
  }

  if ('city' in params) {
    formattedParams.distance = cityDistanceKm
  }

  delete formattedParams.date
  delete formattedParams.state

  return formattedParams
}

export const displaySearchParams = (params: RestApiParams) => {
  if (!params.date) {
    return ''
  }
  const { startTime, endTime } = params
  const formattedParams: RestApiParams = searchParamsFormatter(params)

  const formattedDate: string = format(new Date(formattedParams.startTime!), 'MMM dd')
  if (params.city && params.state && params.guests) {
    const { state, city } = params
    const location: string = `${city}, ${state}`
    const formattedLocation: string =
      location.length > 20 ? `${location.slice(0, params.guests >= 10 ? 17 : 20)}...` : location

    return `${formattedLocation} | ${formattedDate}, ${displayTimeRange(
      { hours: startTime! },
      { hours: endTime! },
    )}`
  }

  return `${formattedDate}, ${displayTimeRange({ hours: startTime! }, { hours: endTime! })}`
}
