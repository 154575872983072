import { Col, Row, Skeleton, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

export const RoomDetailsScreenMobileLoader: Swift.FC = () => (
  <StyledRow>
    <Col span={24}>
      <Skeleton active paragraph={{ rows: 4 }} />
      <Skeleton active paragraph={{ rows: 0 }} />
      <StyledImageSkeleton
        avatar={{
          shape: 'square',
          size: 'large',
          style: { height: 180, width: 'calc(100vw - 32px)' },
        }}
        active
      />
    </Col>
    <Col span={24}>
      <Skeleton paragraph={{ rows: 4 }} />
    </Col>
  </StyledRow>
)

const StyledRow = styled(Row)`
  padding: ${({ theme }) => theme.spacing.medium};
`

const StyledImageSkeleton = styled(Skeleton)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`
