import { ProfilesTypes } from '@swiftctrl/api-client-react'
import { Col, Row, Swift } from '@swiftctrl/swift-component-library'
import { Avatar } from 'antd'
import { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useLogOut, useStoreCurrentUser, useTranslation } from '../../hooks'
import { AvatarMenuProps } from '../../models'
import { getInitials, paths } from '../../utils'
import { Logout, Reservations as ReservationsIcon } from '../Icons'

const { Reservations } = paths

export const AvatarMenu: Swift.FC<AvatarMenuProps> = ({ showAvatarMenu, closeAvatarModal }) => {
  const { t } = useTranslation()
  const avatarMenuRef = useRef<HTMLDivElement>(null)
  const { currentUser } = useStoreCurrentUser()
  const profileInfo: ProfilesTypes | null = currentUser
  const { mutate: signOut } = useLogOut()
  const initials: string = getInitials(profileInfo?.display_name) || '?'
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      // Check if the element currently exists and if the user is clicking outside of it
      if (avatarMenuRef.current !== null && !avatarMenuRef.current.contains(e.target as Node)) {
        closeAvatarModal()
      }
    }
    // If the menu is open, then listen for the click outside
    if (showAvatarMenu) {
      window.addEventListener('click', onClick)
    }
    return () => {
      window.removeEventListener('click', onClick)
    }
  }, [showAvatarMenu, avatarMenuRef])

  return (
    <>
      {profileInfo && (
        <StyledAvatarMenu $active={showAvatarMenu} ref={avatarMenuRef}>
          <StyledRow align="middle" gutter={[16, 0]} justify="start" wrap={false}>
            <Col flex="60px">
              <Avatar size={40} src={profileInfo?.avatar}>
                {initials}
              </Avatar>
            </Col>
            <StyledInfo>
              <Row>
                <Col span={24}>
                  <StyledUsername>{profileInfo?.display_name}</StyledUsername>
                </Col>
                <Col span={24}>
                  <StyledEmail>{profileInfo?.emails[0].email}</StyledEmail>
                </Col>
              </Row>
            </StyledInfo>
          </StyledRow>
          <StyledNavLink to={`/${Reservations}`} onClick={() => closeAvatarModal()}>
            <ReservationsIcon />
            {t('avatar_my_reservations_link')}
          </StyledNavLink>
          <StyledLink onClick={() => signOut()}>
            <Logout />
            {t('avatar_logout_link')}
          </StyledLink>
        </StyledAvatarMenu>
      )}
    </>
  )
}

const StyledRow = styled(Row)`
  padding: ${({ theme }) => theme.spacing.none} ${({ theme }) => theme.spacing.medium}
    ${({ theme }) => theme.spacing.none} 24px;
`

const StyledAvatarMenu = styled.div<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 78px;
  right: 32px;
  width: 320px;
  background-color: #ffffff;
  color: #3c4043;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.none};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 10px rgba(0, 0, 0, 0.1);
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
  transform: ${({ $active }) => ($active ? 'translateY(0)' : 'translateY(-20px)')};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 9999;
  a:hover {
    color: #3c4043;
  }
  svg {
    position: absolute;
    left: 25px;
  }
`

const StyledUsername = styled.p`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.none};
`
const StyledEmail = styled.p`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['14px'].lineHeight};
  color: #969696;
  margin-bottom: ${({ theme }) => theme.spacing.none};
`
const StyledLink = styled(Row)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.smedium} ${({ theme }) => theme.spacing.medium}
    ${({ theme }) => theme.spacing.smedium} 64px;
  color: #3c4043;
  cursor: pointer;
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
`
const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.smedium} ${({ theme }) => theme.spacing.medium}
    ${({ theme }) => theme.spacing.smedium} 64px;
  color: #3c4043;
  cursor: pointer;
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
`
const StyledInfo = styled(Col)`
  margin-top: 10px;
`
