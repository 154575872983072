import { Button, Row, Swift, Col } from '@swiftctrl/swift-component-library'
import { add, getHours, isBefore, set } from 'date-fns'
import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../hooks'
import { CustomDrawer } from '../custom-drawer'
import { MobileCalendar } from './MobileCalendar'

interface Props {
  visible: boolean
  onClose: () => void
  selectedDate: Date
  onDateChange: (val: Date | null) => void
  disablePastDates?: boolean
}

export const MobileCalendarDrawer: Swift.FC<Props> = ({
  visible,
  onClose,
  selectedDate,
  onDateChange,
  disablePastDates,
}) => {
  const { t } = useTranslation()
  const [date, setDate] = useState(selectedDate)

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDate(date)
    }
  }

  const handleConfirm = () => {
    onDateChange(date)
    onClose()
  }

  const handleClose = () => {
    setDate(selectedDate)
    onClose()
  }

  const shouldDisablePastDates = (date: Date) => {
    const todaysDateAtMidnight = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    const tomorrowsDate = add(todaysDateAtMidnight, { days: 1 })
    const currentHour = getHours(new Date())

    if (currentHour === 23) {
      return isBefore(date, tomorrowsDate)
    }
    return isBefore(date, todaysDateAtMidnight)
  }

  return (
    <StyledMobileCalendarDrawer
      height="95%"
      placement="bottom"
      onClose={handleClose}
      visible={visible}
      title={t('calendar')}
      destroyOnClose
      footer={
        <StyledFooter align="middle" justify="end">
          <Col>
            <Button onClick={handleConfirm} type="primary" size="large">
              {t('apply')}
            </Button>
          </Col>
        </StyledFooter>
      }
      footerBorder
    >
      <MobileCalendar
        shouldDisableDate={disablePastDates ? shouldDisablePastDates : undefined}
        onChange={handleDateChange}
        selectionMode="single"
        value={date}
      />
    </StyledMobileCalendarDrawer>
  )
}

const StyledMobileCalendarDrawer = styled(CustomDrawer)`
  .ant-drawer-body {
    padding-top: ${({ theme }) => theme.spacing.none};
  }
`

const StyledFooter = styled(Row)`
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
  height: 72px;
`
