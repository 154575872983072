import { Col, Form, InputNumber, Row, Slider, Swift } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'

import { useTranslation } from '../../../../hooks'

type Props = {
  minPrice: number
  maxPrice: number
  setMinPrice: (minPrice: number) => void
  setMaxPrice: (maxPrice: number) => void
}

export const FiltersPrices: Swift.FC<Props> = ({
  minPrice,
  maxPrice,
  setMinPrice,
  setMaxPrice,
}) => {
  const { t } = useTranslation()

  const changeSliderPrices = (value: number[]) => {
    setMinPrice(value[0])
    setMaxPrice(value[1])
  }

  const changeMinPrice = (value: number | null) => {
    setMinPrice(value || 0)
    setMaxPrice(maxPrice)
  }

  const changeMaxPrice = (value: number | null) => {
    setMinPrice(value || 0)
  }

  return (
    <StyledDiv>
      <StyledFiltersTitle>{t('search_filter_price_range')}</StyledFiltersTitle>

      <Form.Item name="price-range">
        <Slider
          min={20}
          max={450}
          range={{ draggableTrack: true }}
          defaultValue={[minPrice, maxPrice]}
          value={[minPrice, maxPrice]}
          onChange={changeSliderPrices}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={24} sm={12}>
          {t('search_filter_min_price')}
          <br />
          <InputNumber
            formatter={(value) => `$${value}`}
            defaultValue={minPrice}
            value={minPrice}
            onChange={changeMinPrice}
          />
        </Col>

        <Col span={24} sm={12}>
          {t('search_filter_max_price')}
          <br />
          <InputNumber
            formatter={(value) => `$${value}`}
            defaultValue={maxPrice}
            value={maxPrice}
            onChange={changeMaxPrice}
          />
        </Col>
      </Row>
    </StyledDiv>
  )
}

const StyledFiltersTitle = styled.h2`
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].fontSize};
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['20px'].lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const StyledDiv = styled.div`
  .ant-input-number {
    width: 100%;
  }
`
