import styled from 'styled-components'
import { Swift, Result, ResultProps } from '@swiftctrl/swift-component-library'

export const MobileResult: Swift.FC<ResultProps> = ({ ...props }) => (
  <StyledMobileAdminFallback>
    <Result {...props} />
  </StyledMobileAdminFallback>
)

const StyledMobileAdminFallback = styled.div`
  .ant-result-title {
    font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  }

  .ant-result {
    padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.medium};
  }

  svg {
    font-size: 72px;
  }
`
