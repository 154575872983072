import { ScheduleTypes } from '@swiftctrl/api-client-react'
import { Col, Row } from '@swiftctrl/swift-component-library'
import styled from 'styled-components'
import { useTranslation } from '../../hooks'
import {
  daysToIntMap,
  getSubSchedule,
  getTranslationLabel,
  openingHoursTimeFormatter,
} from '../../utils'

interface Props {
  schedule: ScheduleTypes
}

export const OpeningHoursDisplay = ({ schedule }: Props) => {
  const { t } = useTranslation()
  const subSchedule = getSubSchedule(schedule)

  return (
    <>
      {subSchedule &&
        Object.keys(daysToIntMap).map((dayName, i) => {
          const day = subSchedule.day_times.find((d) => d.day === dayName)
          const start = Number(day?.start_time.split(':')[0])
          const end = Number(day?.end_time.split(':')[0])
          const hours =
            start && end
              ? openingHoursTimeFormatter(start, end)
              : t('room_details_opening_hours_closed')
          return (
            <StyledRow key={i}>
              <Col span={8}>{t(getTranslationLabel('days_of_the_week_', i))}</Col>
              <StyledHours span={16}>{hours}</StyledHours>
            </StyledRow>
          )
        })}
    </>
  )
}

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`
const StyledHours = styled(Col)`
  text-align: right;
`
