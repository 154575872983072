import { FilterOutlined, Swift, Row, Input } from '@swiftctrl/swift-component-library'
import { Button } from 'antd'
import { ChangeEvent, KeyboardEvent } from 'react'
import styled, { CSSObject } from 'styled-components'
import { useBoolean, useTranslation } from '../../../hooks'
import { ReservationFilterModal } from './ReservationFilterModal'

interface Props {
  handleSearch?: (search: string) => void
  handleFilters?: (filter: string[]) => void
  showReservationStatus?: boolean
  showPaymentStatus?: boolean
  shadow?: boolean
}
const { Search } = Input

export const TabSearchFilter: Swift.FC<Props> = ({
  handleSearch,
  handleFilters,
  showReservationStatus,
  showPaymentStatus,
  shadow,
}) => {
  const { t } = useTranslation()
  const onChange = (event: ChangeEvent<HTMLInputElement>) => handleSearch?.(event.target.value)
  const onPressEnter = (event: KeyboardEvent<HTMLInputElement>) =>
    handleSearch?.(event.currentTarget.value)
  const [showFilter, openFilter, closeFilter] = useBoolean()

  return (
    <StyledRow $shadow={shadow}>
      <StyledSearch
        onChange={onChange}
        onSearch={handleSearch}
        onPressEnter={onPressEnter}
        placeholder={t('admin_room_reservations_search_placeholder')}
        size="large"
      />
      <StyledButton
        type="link"
        icon={<FilterOutlined />}
        onClick={openFilter}
        className="filterButton"
        size="large"
      >
        {t('tab_search_filter_button_label')}
      </StyledButton>
      <ReservationFilterModal
        showReservationStatus={showReservationStatus}
        showPaymentStatus={showPaymentStatus}
        visible={showFilter}
        closeFilter={closeFilter}
      />
    </StyledRow>
  )
}

const StyledSearch = styled(Search)`
  ${({ theme }): CSSObject => ({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.designTokens.fonts.sizes['16px'].fontSize,
    lineHeight: theme.designTokens.fonts.sizes['16px'].lineHeight,
    color: `#8c8c8c`,
    width: '300px',
  })}
`

const StyledRow = styled(Row)<{ $shadow?: boolean }>`
  height: ${({ $shadow }) => ($shadow ? `56px` : 'default')};
`

const StyledButton = styled(Button)`
  ${({ theme }): CSSObject => ({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.designTokens.fonts.sizes['16px'].fontSize,
    lineHeight: theme.designTokens.fonts.sizes['16px'].lineHeight,
    color: `#000000`,
    '&:hover': {
      color: '#40a9ff',
    },
    '&:focus': {
      color: '#000000',
    },
  })}
`
