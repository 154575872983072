import { CitiesTypes } from '@swiftctrl/api-client-react'

export const cities: CitiesTypes[] = [
  {
    city_id: '1',
    city: 'Stamford',
    state: 'CT',
    country: 'USA',
  },
  {
    city_id: '2',
    city: 'Montreal',
    state: 'QC',
    country: 'Canada',
  },
  {
    city_id: '3',
    city: 'San Francisco',
    state: 'CA',
    country: 'USA',
  },
]
