import { config } from '../config'
import { SessionSigningStatusTypes } from './sessionSigningStatusTypes'

const {
  appConstants: { SIGNING_LOCAL_STORAGE_KEY },
} = config

export const setSessionSigningStatus = (
  value: SessionSigningStatusTypes,
  withoutLocalEvent?: boolean,
) => {
  // Since "storage" event only triggers when the storage is changed in a remote tab
  // We also create a custom event to be able to listen to it locally
  const event = new CustomEvent('signInChanged', { detail: value })
  !withoutLocalEvent && document.dispatchEvent(event)
  if (localStorage.getItem(SIGNING_LOCAL_STORAGE_KEY) !== value) {
    localStorage.setItem(SIGNING_LOCAL_STORAGE_KEY, value)
  }
}
