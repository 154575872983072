import { ModifiedBrowseRoomFilterConfig, useRoom } from '@swiftctrl/api-client-react'
import { getQueryHandler } from '@swiftctrl/api-helpers'
import { Button, Col, Row, Swift } from '@swiftctrl/swift-component-library'
import { useState } from 'react'
import styled from 'styled-components'
import { mixPanelTrack } from '../../../analytics'
import { CustomDrawer } from '../../../components'
import { useHandleUrlParams, useTranslation } from '../../../hooks'
import { config, differenceInDayFromToday, formattedDateWithTime } from '../../../utils'
import { MobileAmenitiesFilterContainer } from './MobileAmenitiesFilterContainer'

interface Props {
  applyFilters: (data: SelectedFilter) => void
  isOpen: boolean
  onClose: () => void
}

export interface SelectedFilter {
  amenities: string[]
  sort?: string
}

const { SWIFT_ROOT_ENTITY_ID } = config

export const MobileFilterContainer: Swift.FC<Props> = ({ applyFilters, isOpen, onClose }) => {
  const { currentParams } = useHandleUrlParams()
  const predefinedAmenities: string[] | undefined = currentParams.amenities?.split(',')
  const initialFilterState: SelectedFilter = {
    amenities: predefinedAmenities || [],
    sort: undefined,
  }
  const qRoom = getQueryHandler<ModifiedBrowseRoomFilterConfig>()
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter>(initialFilterState)

  const startTime: string = formattedDateWithTime(currentParams?.date, currentParams?.startTime)
  const endTime: string = formattedDateWithTime(currentParams?.date, currentParams?.endTime)

  const { data: roomsCountData, isLoading } = useRoom()
    .browse({
      browseOptions: {
        queryKey: 'building-rooms-count',
      },
      baseId: SWIFT_ROOT_ENTITY_ID,
      limit: 1000,
      filters: [
        qRoom(
          'WHERE',
          'city',
          'EQ',
          currentParams.city || currentParams.state || currentParams.country
            ? `${currentParams.city},${currentParams.state},${currentParams.country}`
            : undefined,
        ),
        qRoom('AND', 'max_capacity', 'GTE', currentParams.guests),
        qRoom('AND', 'availabilities', 'GTE', startTime),
        qRoom('AND', 'availabilities', 'LTE', endTime),
        qRoom('AND', 'amenities', 'EQ', selectedFilters.amenities.join(',')),
      ],
    })
    .query({ retry: false })

  const count = roomsCountData?.data?.length

  const handleFiltersOnChange = (amenities: string[]) =>
    setSelectedFilters((prevState) => ({ ...prevState, amenities }))
  const { t } = useTranslation()

  const handleClearAll = () =>
    setSelectedFilters((prevState) => ({ ...prevState, amenities: [], sort: undefined }))

  const handleCloseDrawer = () => {
    setSelectedFilters(initialFilterState)
    onClose()
  }

  const handleSubmit = () => {
    mixPanelTrack('Search Spaces (Filter)', {
      'Days From Today': differenceInDayFromToday(currentParams.date),
      'Guest Count': Number(currentParams.guests),
    })
    applyFilters(selectedFilters)
    onClose()
  }

  return (
    <CustomDrawer
      visible={isOpen}
      onClose={handleCloseDrawer}
      destroyOnClose
      title={t('search_filter')}
      footer={
        <StyledMobileFilterDrawerFooter justify="space-between">
          <StyledButton size="large" onClick={handleClearAll} type="link">
            {t('tab_search_filter_footer_clear_all_button_label')}
          </StyledButton>
          <Button type="primary" onClick={handleSubmit} loading={isLoading} size="large">
            {isLoading
              ? t('search_filter_show_rooms_default')
              : t(
                  (count || 0) > 0
                    ? 'search_filter_show_rooms_other'
                    : 'search_filter_show_rooms_one',
                  {
                    count: count || 0,
                  },
                )}
          </Button>
        </StyledMobileFilterDrawerFooter>
      }
    >
      <Col span={24}>
        <MobileAmenitiesFilterContainer
          title={t('search_filter_amenities')}
          onChange={handleFiltersOnChange}
          selectedFilters={selectedFilters.amenities}
        />
      </Col>
    </CustomDrawer>
  )
}

const StyledMobileFilterDrawerFooter = styled(Row)`
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  padding: ${({ theme }) => theme.spacing.medium};
`

const StyledButton = styled(Button)`
  padding: 0px;
`
