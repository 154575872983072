import { format } from 'date-fns'
import { useTranslation } from '../useTranslation'
import { RestApiParams } from '../../models'
import { config, displayTimeRange, searchParamsFormatter } from '../../utils'
import { isEmpty } from 'lodash'

export const useDisplaySearchDateAndTime = (params: RestApiParams): string => {
  const {
    appConstants: { monthDate },
  } = config

  const { t } = useTranslation()

  if (isEmpty(params) || !params.date) {
    return ''
  }

  const formattedParams: RestApiParams = searchParamsFormatter(params)
  const formattedDate: string = format(new Date(formattedParams.startTime!), monthDate)
  const { startTime, endTime } = params
  const duration = endTime! - startTime!
  return `${formattedDate} | ${displayTimeRange(
    { hours: startTime! },
    { hours: endTime! },
  )} (${duration}${duration > 1 ? t('booking_hours') : t('booking_hours_other')})`
}
