import { Swift, Tag, TagProps } from '@swiftctrl/swift-component-library'
import React from 'react'
import styled from 'styled-components'

interface Props extends TagProps {
  onTap?: (value: string) => void
  title: string
  icon?: React.ReactNode
}

export const MobileTag: Swift.FC<Props> = ({ onTap, title, icon, ...rest }) => (
  <StyledTag {...rest} onClick={() => onTap?.(title)}>
    {title}
    {icon || null}
  </StyledTag>
)

const StyledTag = styled(Tag)`
  font-weight: ${({ theme }) => theme.designTokens.fonts.weights.regular};
  font-size: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].fontSize};
  line-height: ${({ theme }) => theme.designTokens.fonts.sizes['16px'].lineHeight};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  svg {
    margin-left: ${({ theme }) => theme.spacing.xSmall};
  }
`
